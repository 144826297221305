import TextButton from 'components/Buttons/TextButton';
import React, { FC } from 'react';

interface ImplementProps {
    title: string;
    content: string;
    link?: string;
}

const data: Array<ImplementProps> = [
    {
        title: 'Decentralised private key handling',
        content:
            'Lit protocol has been implemented as our solution for encrypting and securely managing sensitive data before it is transferred to IPFS. It also provides a secure and decentralised solution for managing private keys, ensuring that data remains confidential and tamper-proof.',
        link: 'https://ergonglobal.notion.site/Lit-Protocol-MPC-ec537916c6d14233ae767cef69aa8f38',
    },
    {
        title: 'MEV front-running defense',
        content:
            'We have implemented an on-chain mechanism using the commit-reveal scheme in our private data redemption process. This allows senders to grant permission to receivers without revealing their user address, while also preventing front running attacks.',
        link: 'https://ergonglobal.notion.site/MEV-Front-running-defense-0840f5a5bb7149fe92fdd1ff41b74183',
    },
    {
        title: 'SDK integration',
        content:
            'We built an SDK (Software Development Kit) that will enable organisations to issue badges with ease by automating the issuing process.',
        link: 'https://ergonglobal.notion.site/SDK-integration-3a69ad6048f6432686ec40a0fca4274e',
    },
    {
        title: 'DECO integration',
        content:
            'DECO (Decentralised Oracle) is integrated to allow users to export integrity-protected data to other applications without the need of permission from the current data holder.',
        link: 'https://ergonglobal.notion.site/Web2-Private-data-Bridge-07c58f39730b4149875cd64ea1681fe0',
    },
];

const ImplementSection: FC<any> = ({}) => {
    return (
        <div className="clip-path-to-right mt-20 mb-20">
            <div className="clip-path-inner-container container">
                <div className="text-center">
                    <h2 className="text-base-initial-bold uppercase text-primary-700 mb-2">
                        Our Tech
                    </h2>
                    <h1 className="text-3xl-initial-bold">
                        Innovative Designs for Secure Data Handling
                    </h1>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 pt-16">
                    {data.map((item: ImplementProps, index) => (
                        <div className="bg-white border-2 border-neutral-900 rounded-2xl overflow-hidden hard-shadow-8">
                            <div className="bg-black text-center py-4">
                                <h1 className="text-xl-initial-semibold text-primary-600">
                                    {item.title}
                                </h1>
                            </div>
                            <div className="p-6">
                                <p>{item.content}</p>
                                {item.link && (
                                    <div className='mt-auto'>
                                        <TextButton
                                            bgClassName="w-fit mx-auto"
                                            className="underline text-primary-700"
                                            text="Read more"
                                            buttonType="text"
                                            size="md"
                                            onClick={() =>
                                                window.open(item.link)
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ImplementSection;
