import React, { FC } from 'react';

export interface BriefcaseIconProps {
    className?: string;
    iconClass?: string;
}

const BriefcaseIcon: FC<BriefcaseIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 18 18" fill="none">
                <path
                    d="M15.875 10.7916V14.3333C15.875 15.2454 15.2193 16.0299 14.3151 16.15C12.5762 16.3808 10.802 16.5 9 16.5C7.19798 16.5 5.42378 16.3808 3.68487 16.15C2.78075 16.0299 2.125 15.2454 2.125 14.3333V10.7916M15.875 10.7916C16.2683 10.4564 16.5 9.94906 16.5 9.4074V6.25474C16.5 5.35396 15.8601 4.57568 14.9692 4.44238C14.0314 4.30206 13.0829 4.19429 12.125 4.12027M15.875 10.7916C15.7137 10.9291 15.5251 11.0377 15.3143 11.1078C13.3294 11.7677 11.2064 12.125 9.00002 12.125C6.79357 12.125 4.67058 11.7677 2.68574 11.1078C2.47487 11.0377 2.28634 10.9291 2.125 10.7916M2.125 10.7916C1.73173 10.4563 1.5 9.94905 1.5 9.40739V6.25474C1.5 5.35396 2.13992 4.57568 3.03078 4.44238C3.96861 4.30206 4.91708 4.19429 5.875 4.12027M12.125 4.12027V3.375C12.125 2.33947 11.2855 1.5 10.25 1.5H7.75C6.71447 1.5 5.875 2.33947 5.875 3.375V4.12027M12.125 4.12027C11.0938 4.04058 10.0516 4 9 4C7.94836 4 6.9062 4.04058 5.875 4.12027"
                    stroke="#111827"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </span>
    );
};

export default BriefcaseIcon;
