export interface successIssueWorkBadgesTemplate {
    receiver: string;
    claimUrl: string;
    secretCode: string;
}

const successIssueWorkBadgesTemplate = (
    params: successIssueWorkBadgesTemplate
) => {
    const template = `Dear ${params.receiver},

I hope this email finds you well. 

This is to inform you that we have issued you a work SBT to prove your journey with us. 

This token is a representation of your commitment and dedication to your work with our company.

To claim your SBT, please follow the steps below:

1. Navigate to the following address
  ${params.claimUrl}

2. Connect your wallet
  You may create a MetaMask or Torus wallet if you do not own one.

3. Enter the following secret code
  ${params.secretCode}

4. Sign your wallet
  This will trigger on-chain transactions to mint your SBT.

To protect your personal data, please note that this code should not be shared with anyone else.

Best regards,
[name]`;

    return encodeURIComponent(template);
};
export default successIssueWorkBadgesTemplate;
