import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import EtherController from 'utils/etherController';
import { IWorkBadgesTemplate } from 'schemas/workBadges';
import { ethers, Signer } from 'ethers';
import {
    Organisation__factory,
    WorkBadges__factory,
} from 'typechain/factories';
import LitHelper, { blobToBase64 } from 'utils/LitHelper';
import { AttributeObject } from 'schemas/metadata';
import { generateMetadataAttribute } from 'utils/metadata';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';

import badgesSample from '../../static/images/badges/microsoft-badge.svg';
import IPFSHelper from 'utils/IPFSHelper';
import { randomNUmberString } from 'utils/random';
import invokeServices from 'services/invoke.services';
import { CONTRACT_ADDRESS } from 'constants/contract';

// current user handling, jwt , etc.
export interface issueSlice {
    currentStage: string;
    workContractAddress?: string;
    selectedTemplate?: IWorkBadgesTemplate;
    claimUrl?: string;
    secretCode?: string;
    name?: string;
    email?: string;
}

const initialState: issueSlice = {
    currentStage: 'home',
};

export const getRelatedOrganisationInfo = createAppAsyncThunk(
    'workBadges/getRelatedOrganisationInfo',
    async (
        payload: {
            adminAddress: string;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        const adminAddress = payload.adminAddress;
        const provider = payload.provider;

        const OrganisationContract = Organisation__factory.connect(
            CONTRACT_ADDRESS.organisation,
            provider
        );

        const orgId = await OrganisationContract.tokenOfOwnerByIndex(
            adminAddress,
            0
        );
        const workContractAddress =
            await OrganisationContract.workContractAddress(orgId);

        return {
            organisationId: orgId.toNumber(),
            workContractAddress: workContractAddress,
        };
    }
);

export const createWorkBadges = createAppAsyncThunk(
    'worksBadges/badges/create',
    async (
        payload: {
            contractAddress: string;
            employeeAddress: string;
            metadata: any;
            controller: EtherController;
        },
        thunkApi
    ) => {
        const lit = LitHelper;
        const metadata = payload.metadata;
        const controller = payload.controller;

        const salt = crypto.randomUUID().replace(/-/g, '');
        const secretCode = randomNUmberString(6);

        const hashedSecretCode = ethers.utils.keccak256(
            ethers.utils.defaultAbiCoder.encode(
                ['string', 'string'],
                [secretCode, salt]
            )
        );

        console.debug(salt, secretCode, hashedSecretCode);

        const createDto = await controller.signCreateWorkBadges(
            payload.contractAddress,
            payload.employeeAddress,
            {
                start_time: metadata.duration.start_date,
                end_time: metadata.duration.end_date,
                metadataURI: '',
            },
            salt,
            hashedSecretCode
        );

        const createRes = await invokeServices.invoke(createDto);
        const createTxnHash = createRes.data.hash;
        console.debug('createTxnHash', createTxnHash);

        const IWorkBadges = WorkBadges__factory.createInterface();

        const createTxn = await (
            await payload.controller.getProvider().getTransaction(createTxnHash)
        ).wait();

        let issueBadgesEvents = createTxn.logs
            .filter((res: any) => {
                return (
                    res.topics[0] ==
                    '0x523a7700fb9837efb92b9070b2bf92d9b92a3d65fb87ebd0790b16f91e09fa92'
                );
            })
            .map((event: ethers.providers.Log) => {
                return IWorkBadges.parseLog(event);
            });

        if (issueBadgesEvents.length != 1) {
            return thunkApi.rejectWithValue({
                message: 'Invalid number of events',
            });
        }

        const badgesId = issueBadgesEvents[0].args.badgesId;
        console.debug(issueBadgesEvents, badgesId.toNumber());

        thunkApi.dispatch(updateStage('upload-ipfs-record'));

        // For Lit Protocol encryption
        const toEncrypts: Array<AttributeObject> = [];
        if (metadata.salary && metadata.salary != '') {
            toEncrypts.push(
                generateMetadataAttribute('salary', metadata.salary)
            );
        }

        if (metadata.reporting_line && metadata.reporting_line != '') {
            toEncrypts.push(
                generateMetadataAttribute(
                    'reporting_line',
                    metadata.reporting_line
                )
            );
        }

        if (metadata.accomplishments && metadata.accomplishments.length > 0) {
            toEncrypts.push(
                generateMetadataAttribute(
                    'accomplishments',
                    JSON.stringify(metadata.accomplishments)
                )
            );
        }

        let conditions, encryptResult;
        if (toEncrypts.length > 0) {
            conditions = [
                ...lit.erc721OwnerCondition(payload.contractAddress, badgesId),
                { operator: 'or' },
                ...lit.badgeViewingCondition(payload.contractAddress, badgesId),
            ];
            encryptResult = await lit.encryptText(
                JSON.stringify(toEncrypts),
                conditions
            );
        }

        const ipfsJson: any = {
            name: metadata.name,
            description: metadata.description,
        };

        // Upload image to IPFS
        const imageRealPath = new URL(
            badgesSample,
            window.location.href
        ).toString();
        const image = await fetch(imageRealPath).then((res) => res.blob());
        ipfsJson.image = 'ipfs://' + (await IPFSHelper.uploadImage(image));

        // Create IPFS json obj
        const state = thunkApi.getState();
        const { selectedTemplate } = state.issue;

        if (!selectedTemplate) {
            return thunkApi.rejectWithValue({
                message: 'No work badges template selected',
            });
        }

        ipfsJson.name = selectedTemplate.title;
        ipfsJson.description = metadata.description;

        const attribute: Array<AttributeObject> = [];
        attribute.push(
            generateMetadataAttribute(
                'employment_type',
                selectedTemplate.employmentType
            )
        );
        attribute.push(
            generateMetadataAttribute(
                'workspace',
                selectedTemplate.location == ''
                    ? selectedTemplate.locationType
                    : selectedTemplate.location
            )
        );
        if (metadata.skills.length > 0) {
            attribute.push(
                generateMetadataAttribute('skills', metadata.skills)
            );
        }
        ipfsJson.attributes = attribute;

        if (encryptResult) {
            ipfsJson.encrypted_attributes = await blobToBase64(
                encryptResult.encryptedString
            );
            ipfsJson.encrypted_symmetric_key =
                encryptResult.encryptedSymmetricKey;
            ipfsJson.encrypted_conditions = JSON.stringify(conditions);
        }

        const metadataCid = await IPFSHelper.uploadJson(ipfsJson);
        console.debug(metadataCid);

        thunkApi.dispatch(updateStage('update-badges'));

        const updateDto = await controller.signUpdateWorkBadges(
            payload.contractAddress,
            badgesId,
            {
                start_time: metadata.duration.start_date,
                end_time: metadata.duration.end_date,
                metadataURI: `ipfs://${metadataCid}`,
            }
        );

        const updateRes = await invokeServices.invoke(updateDto);
        const updateTxnHash = createRes.data.hash;

        return {
            claimUrl: new URL(
                `/claim/${payload.contractAddress}/${badgesId}`,
                window.location.href
            ).toString(),
            secretCode: secretCode,
            name: metadata.name,
            email: metadata.email,
        };
    }
);

export const issueSlice = createSlice({
    name: 'issue',
    initialState,
    reducers: {
        updateStage(state, action: PayloadAction<string>) {
            state.currentStage = action.payload;
        },
        updateSelectedTemplate(
            state,
            action: PayloadAction<IWorkBadgesTemplate>
        ) {
            state.selectedTemplate = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(createWorkBadges.pending, (state, { payload }) => {
            state.currentStage = 'create-badges';
        });
        builder.addCase(createWorkBadges.rejected, (state, { payload }) => {
            alert('Fail to issue');
        });
        builder.addCase(createWorkBadges.fulfilled, (state, { payload }) => {
            state.currentStage = 'finish-issue';
            state.claimUrl = payload.claimUrl;
            state.secretCode = payload.secretCode;
            state.name = payload.name;
            state.email = payload.email;
        });

        builder.addCase(
            getRelatedOrganisationInfo.pending,
            (state, { payload }) => {}
        );
        builder.addCase(
            getRelatedOrganisationInfo.rejected,
            (state, { payload }) => {}
        );
        builder.addCase(
            getRelatedOrganisationInfo.fulfilled,
            (state, { payload }) => {
                state.workContractAddress = payload.workContractAddress;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const { updateStage, updateSelectedTemplate } = issueSlice.actions;

export default issueSlice.reducer;
