import React, { FC, useState } from 'react';
import LineTextField from 'components/Input/TextFields/LineTextField';
import LineTextArea from 'components/Input/TextArea/LineTextField';
import TextButton from 'components/Buttons/TextButton';
import toCPTemplate from 'utils/mail/template/ToCPMail';

const ContactUsSection: FC<any> = ({}) => {
    const [name, setName] = useState('');
    const [message, setMessage] = useState('');

    const sendEmail = () => {
        window.open(
            `mailto:gm@ergon.global?body=${toCPTemplate({ sender: name, message })}`
        );
    };

    return (
        <div className="bg-primary-500">
            <div className="container py-20 flex flex-col lg:flex-row">
                <div className="basis-1/2 text-4xl-initial-bold">
                    Contact us!
                </div>
                <div className="basis-1/2">
                    <div className="flex flex-col gap-8">
                        <LineTextField label="Name" onChangeHandler={setName} />
                        <LineTextArea
                            label="Message"
                            onChangeHandler={setMessage}
                        />
                        <TextButton
                            bgClassName="w-fit ml-auto"
                            size="lg"
                            buttonType="secondary"
                            text="Send message"
                            onClick={sendEmail}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactUsSection;
