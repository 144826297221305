import { FC, useMemo } from 'react';
import TextButton from 'components/Buttons/TextButton';
import { useNavigate } from 'react-router-dom';
import { getTimeAgo } from 'utils/dateFormatter';

import dummyImage from 'static/animations/component-loading.gif';

export interface SingleJobProps {}

const LoadingJobCard: FC<SingleJobProps> = ({}) => {
    return <div className="loading_job_card bg-loading"></div>;
};

export default LoadingJobCard;
