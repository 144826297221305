import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(relativeTime);
dayjs.extend(updateLocale);
dayjs.extend(utc)
dayjs.extend(timezone)

dayjs.updateLocale("en", {
  relativeTime: {
    future: "in %s",
    past: "%s",
    s: "1s",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1m",
    MM: "%dm",
    y: "1y",
    yy: "%dy",
  },
});

export function getTimeAgo(timestamp: number) {
  return dayjs(new Date(timestamp * 1000)).fromNow(true);
}

export function convertDateTimeToString(timestamp: number, format: string) {
  return dayjs(new Date(timestamp * 1000)).tz("Asia/Hong_Kong").format(format);
}