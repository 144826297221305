import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import BadgeImage from "static/images/badges/sampleBadges_3.svg"
import { ReactComponent as CopyIcon} from "assets/images/icons/copy.svg";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon, LockClosedIcon, LockOpenIcon } from "@heroicons/react/solid";
import BadgeTag from "components/Tags/BadgeTag";
import TextButton from "components/Buttons/TextButton";
import RequestUserAccessModal from "components/RequestUserAccessModal";
import { Line } from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register( CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend, ChartDataLabels );
ChartJS.defaults.font.family = "Poppins";

export interface SingleBadgeProps {
  className?: string;
}
const SingleBadge: FC<SingleBadgeProps> = ({ className = "" }) => {
    const creator = "0xahkuwbrg87qa34og34t3oerbvuo43nbvou34r"
    const owner = "0xs6r541tb78rt1retb61retb168er1t"
    function handleAddressBtn(content: string){
        navigator.clipboard.writeText(content);
    }
    const [showPrivateData, setShowPrivateData] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const unlockPrivateData = () => {
        setShowPrivateData(true);
        setShowModal(false);
    }
    const labels = ["DEC 2021", "FEB 2022", "NOV 2022"]; 
    const data = {
        labels,
        datasets: [
            {
                fill: true,
                label: 'Dataset 2',
                data: labels.map(() => Math.random()*100000),
                borderColor: 'rgba(68, 191, 18, 0.5)',
                backgroundColor: 'rgba(68, 191, 18, 0.3)',
              }
        ]
    }
    const options:any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                anchor: "end",
                display: true,
                color: "black",
                offset: -20,
                formatter: Math.round,
                align: "bottom"
              }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                font: "Poppins"
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                },
                    display: false
            },
        },
    }
    return (
        <div
        className={`nc-SingleBadge ${className}`}
        data-nc-id="SingleBadge"
        >
            <Helmet>
                <title>My Badge</title>
            </Helmet>
            <div className="container my-16">
                <div className="lg:grid lg:grid-cols-2 lg:gap-16 w-100">
                    <div>
                        <img src={BadgeImage} className="" />
                    </div>
                    <div>
                        <BadgeTag text="SBT" className="text-xs-uppercase-medium mt-10 lg:mt-0 py-0.5 px-2.5 bg-violet-100 w-fit" />
                        <div className="text-4xl-initial-bold mt-2">Proof of Employment Certificate</div>
                        <div className="mt-8 grid grid-cols-2 lg:grid-cols-4 gap-12">
                            <div>
                                <div className="text-xs-initial-medium text-neutral-400">
                                    Creator
                                </div>
                                <div className="text-base-initial-medium text-neutral-800 flex">
                                {
                                    creator.slice(0, 6) +
                                    "..." +
                                    creator.slice(creator.length - 4, creator.length)
                                }
                                <div className="cursor-pointer ml-2 mt-1" onClick={() => handleAddressBtn(creator)}><CopyIcon className="hover:text-neutral-100 hover:fill-white" /></div>
                                </div>
                            </div>
                            <div className="border-neutral-200 border-l-2 pl-4 lg:pl-8">
                                <div className="text-xs-initial-medium text-neutral-400">
                                    Owner
                                </div>
                                <div className="text-base-initial-medium text-neutral-800 flex">
                                {
                                    owner.slice(0, 6) +
                                    "..." +
                                    owner.slice(owner.length - 4, owner.length)
                                }
                                <div className="cursor-pointer ml-2 mt-1" onClick={() => handleAddressBtn(owner)}><CopyIcon /></div>
                                </div>
                            </div>
                        </div>
                        <div className="w-full rounded-2xl mt-12">
                            <Disclosure defaultOpen>
                                {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 outline outline-2 focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                                    <span className="text-lg-initial-semibold">Attributes</span>
                                    <ChevronUpIcon
                                        className={`${
                                        open ? "transform rotate-180" : ""
                                        } w-5 h-5 text-neutral-500 mt-1`}
                                    />
                                    </Disclosure.Button>
                                    <Disclosure.Panel
                                    className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                                    as="p"
                                    >
                                    <span className="text-xs-initial-medium text-neutral-400">Job title</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        Attendee
                                    </span>
                                    <br />

                                    <span className="text-xs-initial-medium text-neutral-400">Organisation</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        Microsoft
                                    </span>
                                    <br />
                                
                                    <span className="text-xs-initial-medium text-neutral-400">Duration</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        SEP 2021 - Present
                                    </span>
                                    <br />

                                    <span className="text-xs-initial-medium text-neutral-400">Seniority</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        Junior
                                    </span>
                                    <br />
                                    <span className="text-xs-initial-medium text-neutral-400">Industry</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        Information Technology and Services
                                    </span>
                                    <br />
                                    <span className="text-xs-initial-medium text-neutral-400">Employment Type</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        Full-time
                                    </span>
                                    <br />
                                    <span className="text-xs-initial-medium text-neutral-400">Workplace</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                        Hybrid
                                    </span>
                                    <br />
                                    {
                                        showPrivateData
                                        ?(
                                            <>
                                                <span className="text-xs-initial-medium text-neutral-400 flex">
                                                    Reporting Line
                                                    <LockOpenIcon className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1" />
                                                </span>
                                                <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                                    Digital Design Lead
                                                </span>

                                                <br />
                                                <span className="text-xs-initial-medium text-neutral-400 flex">
                                                    Salary (HKD)
                                                    <LockOpenIcon className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1" />
                                                </span>
                                                <div className="flex justify-center">
                                                    <Line
                                                        options={options}
                                                        data={data}
                                                        plugins={[ChartDataLabels]}
                                                    />
                                                </div>
                                                <span className="text-xs-initial-medium text-neutral-400 justify-end flex mt-2">Retrieved from BambooHR</span>
                                                <br />
                                                <span className="text-xs-initial-medium text-neutral-400 flex">
                                                    Achievements
                                                    <LockOpenIcon className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1" />
                                                </span>
                                                <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                                    <div className="flex">
                                                        <span className="justify-start">Click rate of a redesigned CTA button</span>
                                                        <span className="ml-auto">+41.0%</span>
                                                    </div>
                                                </span>
                                                <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                                    <div className="flex">
                                                        <span className="justify-start">Conversion rate</span>
                                                        <span className="ml-auto">+20.7%</span>
                                                    </div>
                                                </span>
                                                <span className="text-xs-initial-medium text-neutral-400 justify-end flex mt-2">Retrieved from Google Analytics</span>
                                                <br />
                                                <span className="text-xs-initial-medium text-neutral-400 flex">
                                                    Performance
                                                    <LockOpenIcon className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1" />
                                                </span>
                                                <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                                    <div className="flex">
                                                        <span className="justify-start">Task completion</span>
                                                        <span className="ml-auto">99.7%</span>
                                                    </div>
                                                </span>
                                                <span className="text-xs-initial-medium text-neutral-400 justify-end flex mt-2">Retrieved from Jira</span>
                                                <br />
                                            </>
                                        )
                                        :(
                                            <>
                                                <div className="bg-white w-100">
                                                    <div className="py-6">
                                                        <div className="flex justify-center">
                                                            <LockClosedIcon className="h-5 w-5" />
                                                        </div>
                                                        <div className="text-sm-initial-semibold flex justify-center mt-2">More information available including</div>
                                                        <div className="text-sm-initial-medium flex justify-center text-primary-700">Salary, Direct reporting line, Achievements, Performance</div>
                                                        <div className="flex justify-center mt-6">
                                                            <TextButton text="Request Access" buttonType="primary" size="sm" className="text-sm-initial-medium text-neutral-900" onClick={()=>setShowModal(true)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    }

                                    <br />
                                    </Disclosure.Panel>
                                </>
                                )}
                            </Disclosure>
                            <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
                                {({ open }) => (
                                <>
                                    <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 outline outline-2 focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                                    <span className="text-lg-initial-semibold">Details</span>
                                    <ChevronUpIcon
                                        className={`${
                                        open ? "transform rotate-180" : ""
                                        } w-5 h-5 text-neutral-500`}
                                    />
                                    </Disclosure.Button>
                                    <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
                                    
                                    <span className="text-xs-initial-medium text-neutral-400 my-6">2000 x 2000 px.IMAGE(685KB)</span>
                                    <span className="text-xs-initial-medium text-neutral-400">Contract Address</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                    0x50f5474724e0ee42d9a4e711ccfb275809fd6d4a
                                    </span>

                                    <br />
                                    
                                    <span className="text-xs-initial-medium text-neutral-400">Token ID</span>
                                    <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                                    214678712946586
                                    </span>
                                    </Disclosure.Panel>
                                </>
                                )}
                            </Disclosure>
                        </div>
                    </div>
                </div>
            </div>
            <RequestUserAccessModal metadata={null} show={showModal} onCloseModal={()=>setShowModal(false)} ownerAddress={owner} onShowPrivateData={unlockPrivateData} />
        </div>
    );
};

export default SingleBadge;
