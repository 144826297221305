import { FC, useState, useContext } from "react";
import React from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import Avatar from "shared/Avatar/Avatar";
import { SnackbarContext } from "shared/Snackbar/Snackbar";
import { gql, useQuery } from "@apollo/client";
import { useWeb3 } from "utils/useWeb3";
import { useDispatch } from "react-redux";
import { notificatonSliceFunction } from "store/slices/notificationSlice";
import { AppDispatch } from "store/store";
import { getProvider } from "constants/blockchain";
import { getTimeAgo } from "utils/dateFormatter";



export interface NotificationButtonProps {}

const GET_REQUESTS = gql`
  query User($userAddress: ID!) {
    user(id: $userAddress) {
      id
      profile {
        followers(where: {status: Requesting}) {
          id
          status
          updated_at
          profileId
          requestorAddress
          requestor {
            id
            profile {
              id
              name
            }
          }
        }
      }
    }
  }
`;

const NotificationButton: FC<NotificationButtonProps> = () => {
  const [newNotificationCount, setNewNotificationCount] = React.useState(0);
  const [notifications, setNotifications] = React.useState([]);
  const [snackbarContext, setSnackbarContext] =
    React.useContext<any>(SnackbarContext);
  const toggleSnackbar = (address: string, id: number) => {
    const content = (
      <div className="flex text-xs-initial-regular text-white">
        You accepted
        <span className="text-primary-600 ml-1">
          {address.slice(0, 6) +
            "..." +
            address.slice(address.length - 4, address.length)}
        </span>
        's request.
      </div>
    );
    setSnackbarContext({ ...snackbarContext, show: true, text: content });
    setNotifications(
      notifications.filter((notification: any) => notification.id != id)
    );
    setNewNotificationCount(newNotificationCount - 1);
  };
  const ignoreNotification = (id: number) => {
    setNotifications(
      notifications.filter((notification: any) => notification.id != id)
    );
    setNewNotificationCount(newNotificationCount - 1);
  };

  let controller = useWeb3()!;
  const dispatch = useDispatch<AppDispatch>();

  const { loading, error, data, refetch } = useQuery(GET_REQUESTS, {
    variables: { userAddress: controller.address.toLowerCase() },
  });

  React.useEffect(() => {
    console.log("follower reuqests", data?.user?.profile?.followers);
    const followers = data?.user?.profile?.followers || [];

    setNotifications(followers);
    setNewNotificationCount(followers.length);
  }, [data, controller.address]);

  const workBadgeDetail = () => {
    return (
      <div
        className={`w-auto h-[92px] rounded-lg border-2 border-neutral-900 mt-2 bg-white`}
      >
        <div className="mx-4 my-3 items-center flex">
          <div className="">
            <Avatar containerClassName="border-none" sizeClass="w-12 h-12" />
          </div>
          <div className="w-full ml-4">
            <div className="text-sm-initial-semibold">UI/UX Designer</div>
            <div className="text-sm-initial-regular">Ergon Global</div>
            <div className="text-sm-initial-regular">1 year 2 months</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Menu as="div" className="relative inline-block text-left ">
        <div className="bg-neutral-700 w-10 h-10 rounded-full">
          <Menu.Button className="inline-flex justify-center items-center w-10 h-10 rounded-full cursor-pointer bg-neutral-200 button-popup-on-hover">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-7 h-7"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0"
                />
              </svg>
            {newNotificationCount > 0 ? (
              <div className="absolute -top-2 -right-2 rounded-full bg-red-600 text-white w-fit px-1 text-xs font-semibold">
                {newNotificationCount}
              </div>
            ) : null}
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            className="absolute overflow-hidden right-0 z-10 mt-2 w-[196px] lg:w-[360px] origin-top-right bg-white border-2 border-neutral-900"
            style={{
              boxShadow: "4px 4px 0 0 #111827",
              borderRadius: "8px 0 8px 8px",
            }}
          >
            <div className="py-1">
              <div className="px-3 mt-1.5 mb-2 text-lg-initial-semibold">
                Notifications
              </div>
              {notifications.map((notification: any) => {
                return (
                  <div
                    className={`${
                      notification.read == false ? "bg-primary-50" : ""
                    }`}
                  >
                    <div className="grid grid-cols-12 pt-4">
                      <div className="col-span-11 text-sm-initial-semibold flex justify-start text-left">
                        <div className="text-neutral-900 mr-1 ml-3 mb-3">
                          <span className="text-primary-700">
                            {notification.requestorAddress.slice(0, 6) +
                              "..." +
                              notification.requestorAddress.slice(
                                notification.requestorAddress.length - 4,
                                notification.requestorAddress.length
                              )}
                          </span>{" "}
                          requested to view your SBT private data
                        </div>
                      </div>
                      <div className="text-xs-initial-regular text-neutral-400 flex">
                        { getTimeAgo(notification.updated_at)  }
                      </div>
                    </div>
                    <div className="grid grid-cols-2 gap-x-2 pl-3 mr-8">
                      <div
                        className="cursor-pointer border-2 border-neutral-900 rounded-lg px-auto flex justify-center pt-1 w-auto h-7 bg-white hover:bg-neutral-200 mb-4"
                        onClick={() => ignoreNotification(notification.id)}
                      >
                        <div
                          className="text-xs-initial-medium"
                          onClick={() => {}}
                        >
                          Ignore
                        </div>
                      </div>
                      <div
                        className="cursor-pointer border-2 border-neutral-900 rounded-lg px-auto flex justify-center pt-1 w-auto h-7 bg-primary-600 hover:bg-primary-700 mb-4"
                        onClick={() => {
                          dispatch(
                            notificatonSliceFunction.approveFollow({
                              profileId: parseInt(notification.profileId),
                              requestorAddress: notification.requestorAddress,
                              controller: controller.controller!,
                              provider: getProvider(),
                            })
                          ).then(() => {

                            toggleSnackbar(
                              notification.requestorAddress,
                              notification.id
                            );
                          })
                        }}
                      >
                        <div className="text-xs-initial-medium">Accept</div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export default NotificationButton;
