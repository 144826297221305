import useWindowSize from 'hooks/useWindowResize';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import POAPExample from 'static/images/profile/poap_example.png';
import { POAPData } from './ProfilePOAP';

// export interface ProfilePOAPBadgeProps {
//   className?: string;
//   hide_text?: boolean
// }

const ProfilePOAPBadge: FC<{ poap?: POAPData; hide_text?: boolean }> = ({
    poap,
}) => {
    const thisRef = useRef<HTMLDivElement>(null);
    const { width } = useWindowSize();

    const objectWidth = useMemo(() => {
        return thisRef.current?.offsetWidth;
    }, [width, thisRef.current]);
    return (
        <div className="" ref={thisRef}>
            <img
                src={poap?.event.image_url}
                className="cursor-pointer poap-image"
                style={{ height: objectWidth }}
                onClick={() =>
                    window.open(
                        `https://poap.gallery/r/event/${poap?.event.id}`
                    )
                }
            />
            <div className="text-xs-initial-medium text-center hidden lg:block">
                <div className="mt-3 text-neutral-400 truncate">
                    {poap?.event?.city}
                </div>
                <div className="mt-1 text-neutral-800">{poap?.event?.name}</div>
            </div>
        </div>
    );
};

export default ProfilePOAPBadge;
