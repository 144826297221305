import {
    createAsyncThunk,
    createSlice,
    isRejectedWithValue,
    PayloadAction,
} from '@reduxjs/toolkit';
import jobServices from 'services/job.services';
import userServices from 'services/user.services';
import { RootState } from 'store/store';

// current user handling, jwt , etc.
export interface JobState {
    isLoading: boolean;
    isError: boolean;
    error: any;
    job_tags: Array<string>;
    job_list: any;
    job_detail: any;
}

const initialState: JobState = {
    isLoading: true,
    isError: false,
    error: {},
    job_tags: [],
    job_list: {},
    job_detail: {},
};

export const getJobList = createAsyncThunk(
    '/getJobList',
    async ({ query, pagination }: { query?: string; pagination?: any }) => {
        const res = await jobServices.getJobList(query, pagination);
        return res.data;
    }
);

export const getJobDetail = createAsyncThunk(
    '/getJobDetail',
    async (jobId: any, thunkAPI) => {
        try {
            const res = await jobServices.getJobDetail(jobId);
            return res.data;
        } catch (err) {
            return thunkAPI.rejectWithValue(err);
        }
    }
);

export const getJobTags = createAsyncThunk('/getJobTags', async () => {
    const res = await jobServices.getJobTags();
    return res.data;
});

export const jobSlice = createSlice({
    name: 'job',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getJobList.pending, (state, { payload }) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getJobList.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
        });
        builder.addCase(getJobList.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.job_list = payload;
        });

        builder.addCase(getJobDetail.pending, (state, { payload }) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getJobDetail.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.error = payload;
        });
        builder.addCase(getJobDetail.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.job_detail = payload;
        });

        builder.addCase(getJobTags.pending, (state, { payload }) => {
            state.isLoading = true;
            state.isError = false;
        });
        builder.addCase(getJobTags.rejected, (state, { payload }) => {
            state.isLoading = false;
            state.isError = true;
            state.error = payload;
        });
        builder.addCase(getJobTags.fulfilled, (state, { payload }) => {
            state.isLoading = false;
            state.isError = false;
            state.job_tags = payload.map((val: any) => val._id);
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = jobSlice.actions;

export const job = (state: RootState) => state.job;

export default jobSlice.reducer;
