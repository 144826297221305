import { ethers, BigNumber } from 'ethers';
import Torus, { LoginConfig } from '@toruslabs/torus-embed';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { DEPLOY_CHAIN } from './chainConfig';

export const TORUS_PROVIDER_INFO = {
    host: DEPLOY_CHAIN.rpc,
    chainId: DEPLOY_CHAIN.chainId,
    networkName: 'Polygon Mumbai',
};

export const torusProviderOptions = {
    torus: {
        display: {
            name: 'Torus',
            description: 'Connect to your Torus Wallet',
        },
        package: Torus, // required
        options: {
            networkParams: {
                chainId: DEPLOY_CHAIN.chainId, // optional
                networkId: DEPLOY_CHAIN.chainId, // optional
            },
        },
    },
};

export const walletConnectProviderOptions = {
    walletconnect: {
        display: {
            name: 'WalletConnect',
            description: 'Connect to your Metamask Wallet via WalletConnect',
        },
        package: WalletConnectProvider,
        options: {
            rpc: {
                1287: 'https://rpc.api.moonbase.moonbeam.network',
            },
            qrcodeModalOptions: {
                mobileLinks: ['metamask'],
            },
        },
    },
};

export function getProvider() {
    return new ethers.providers.StaticJsonRpcProvider(DEPLOY_CHAIN.rpc, {
        chainId: DEPLOY_CHAIN.chainId,
        name: DEPLOY_CHAIN.name,
    });
}

export const TORUS_INIT_LOGIN_CONFIG: LoginConfig = {
    reddit: {
        showOnModal: false,
        name: 'reddit',
        typeOfLogin: 'reddit',
    },
    twitch: {
        showOnModal: false,
        typeOfLogin: 'twitch',
        name: 'twitch',
    },
    discord: {
        showOnModal: false,
        typeOfLogin: 'discord',
        name: 'discord',
    },
    'torus-auth0-line': {
        showOnModal: false,
        typeOfLogin: 'line',
        name: 'line',
    },
    'torus-auth0-apple': {
        showOnModal: false,
        typeOfLogin: 'apple',
        name: 'apple',
    },
    'torus-auth0-github': {
        showOnModal: false,
        typeOfLogin: 'github',
        name: 'github',
    },
    'torus-auth0-weibo': {
        showOnModal: false,
        typeOfLogin: 'weibo',
        name: 'weibo',
    },
    'torus-auth0-twitter': {
        name: '',
        typeOfLogin: 'twitter',
        showOnModal: false,
    },
    'torus-auth0-wechat': {
        name: '',
        typeOfLogin: 'twitter',
        showOnModal: false,
    },
    'torus-auth0-kakao': {
        name: '',
        typeOfLogin: 'twitter',
        showOnModal: false,
    },
};

//MetaMask login
export const MOBILE_METAMASK_DEEPLINK =
    'https://metamask.app.link/dapp/dev.tride.id'; // safari/chrome/duckduckgo/edge to metamask browser

export const Alchemy_API_KEY = 'yBq25TpTOlwPvZgk6-qyLNg8tUV1p6PH';
