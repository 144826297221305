import VerifyIcon from "components/VerifyIcon";
import React, { FC } from "react";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import IPFSHelper from "utils/IPFSHelper";
import sampleBadgesImage from "../../static/images/badges/sampleBadges_1.png"
export interface BadgeCardProps {
  className?: string;
  badgeName?: string;
  badgeImage?: string;
  organisationImage?: string;
  attributes?: string[];
  onClick?: () => void;
}

function BadgeCardTag(props: any){
  const { name } = props;
  return(
    <div className="py-2.5 px-4 bg-black/25 rounded-full flex items-center justify-center leading-none text-sm font-medium text-white">
      {name}
    </div>
  )
}

const BadgeCard: FC<BadgeCardProps> = ({
  className = "",
  badgeName = "",
  badgeImage = "",
  organisationImage = "",
  attributes = [],
  onClick
}) => {
  return (
    <div 
    className={`badge-card flex flex-col overflow-hidden bg-white rounded-3xl hover:bg-neutral-100 card-shadow border-2 border-neutral-900 ease-in-out duration-200 ${className}`}
    onClick={onClick}
    >
      {/* badge image area */}
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            containerClassName="aspect-w-7 aspect-h-5 sm:aspect-h-6 overflow-hidden"
            className="object-cover w-full h-full ease-in-out duration-500 badges-image"
            src={IPFSHelper.convertURI(badgeImage)}
          />
        </div>
        <div className="absolute top-3 inset-x-3 flex gap-2">
          {
            attributes?.map((value) => {
              return ( <BadgeCardTag name={value} />)
            })
          }
        </div>
      </div>
      {/* badge image area / end */}
      {/* badge info area */}
      <div className="-mt-8 m-6 text-center">
        <div className="text-center relative flex items-center justify-center">
          <svg
            className="mx-auto h-14"
            width="134"
            height="54"
            viewBox="0 0 134 54"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M101.734 19.8581C99.2658 17.4194 96.9737 14.8065 94.5052 12.1935C94.1526 11.671 93.6237 11.3226 93.0947 10.8C92.7421 10.4516 92.5658 10.2774 92.2131 9.92903C85.6895 3.83226 76.6974 0 67 0C57.3026 0 48.3105 3.83226 41.6105 9.92903C41.2579 10.2774 41.0816 10.4516 40.7289 10.8C40.2 11.3226 39.8474 11.671 39.3184 12.1935C36.85 14.8065 34.5579 17.4194 32.0895 19.8581C23.2737 28.7419 11.4605 30.4839 -0.176331 30.8323V54H16.3974H32.0895H101.558H110.197H134V30.6581C122.363 30.3097 110.55 28.7419 101.734 19.8581Z"
            />
          </svg>
          <div className="absolute top-[5px] left-1/2 -translate-x-1/2">
            <Avatar
              containerClassName=""
              sizeClass="w-[56px] h-[56px] text-2xl"
              radius="rounded-full"
              imgUrl={IPFSHelper.convertURI(organisationImage)}
            />
          </div>
        </div>

        <div className="mt-3">          
          <div className={`text-base font-medium flex items-center justify-center w-100`}>
            <h6 className="truncate font-semibold text-base">{badgeName}</h6>
            <VerifyIcon />
          </div>
          {/* <span className={`block mt-1 text-sm text-neutral-500 dark:text-neutral-400`}>
            Unknown date
          </span> */}
        </div>
      </div>
      {/* badge info area / end */}
    </div>
  );
};

export default BadgeCard;
