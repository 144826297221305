export const initUserProfileMetadata = (userAddress: string) => {
    return {
        "name": userAddress,
        "description": "",
        "image": "",
        "attributes": [
            generateMetadataAttribute("twitter_id", ""),
            generateMetadataAttribute("contact_email", ""),
        ]
    };
};

export const generateMetadataAttribute = (key: string, value: any) => {
    return {
        "trait_type": key,
        "value": value
    }
};