import React, { FC } from 'react';

export interface ShieldTickIconProps {
    className?: string;
    iconClass?: string;
}

const ShieldTickIcon: FC<ShieldTickIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 27 28" fill="none">
                <path
                    d="M16.75 10.9982C14.6812 12.8241 13.0883 15.1765 12.1809 17.8458L9.33325 14.9982M13.3333 1.61719C10.4659 4.33269 6.59409 5.99819 2.33325 5.99819C2.26555 5.99819 2.19795 5.99777 2.13044 5.99693C1.61312 7.57041 1.33325 9.25164 1.33325 10.9982C1.33325 18.4536 6.43234 24.718 13.3333 26.4942C20.2342 24.718 25.3333 18.4536 25.3333 10.9982C25.3333 9.25164 25.0534 7.57041 24.5361 5.99693C24.4686 5.99777 24.401 5.99819 24.3333 5.99819C20.0724 5.99819 16.2006 4.33269 13.3333 1.61719Z"
                    stroke="#60E829"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </span>
    );
};

export default ShieldTickIcon;
