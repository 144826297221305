import React, { FC, useEffect, useRef } from 'react';
import NcModal from 'shared/NcModal/NcModal';
import { useLocation } from 'react-router-dom';
import ShieldTickIcon from './Icon/ShieldTickIcon';
import TextButton from './Buttons/TextButton';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { useWeb3 } from 'utils/useWeb3';
import authSlice, {
    AuthState,
    loginWithSIWE,
    updateShowPopup,
} from 'store/slices/authSlice';

import { createUserProfile } from 'store/slices/profileSlice';
import { SiweMessage } from 'siwe';
import { haveValidJwt } from 'utils/jwt';
import { initUserProfileMetadata } from 'utils/metadata';

declare var window: any;

export interface SIWEModelProps {}

const SIWEModel: FC<SIWEModelProps> = () => {
    const dispatch = useDispatch<AppDispatch>();

    const { controller, displayAddress, address } = useWeb3()!;

    const isShowSIWEPopUp = useSelector(
        (root: RootState) => root.auth.isShowSIWEPopup
    );

    const loginSucess = useSelector((root: RootState) => root.auth.loginSucess);

    useEffect(() => {
        console.log('updatePopupState -> loginSucess', loginSucess)
        if (loginSucess) {
            createUserProfileOnChain();
        }
    }, [loginSucess]);

    const updatePopupState = (newValue: boolean) => {
        dispatch(updateShowPopup(newValue));
    };

    const HandleSIWE = () => {
        if (controller) {
            dispatch(loginWithSIWE({ address, controller }));
        }
    };

    const createUserProfileOnChain = () => {
        controller?.getUserId().then(async (result: number) => {
            console.log(`Current User Id: ${result}`);
            if (result == 0) {
                const userProfile = {
                    name: displayAddress,
                    description: displayAddress,
                    imageURI: '',
                };
                console.log(userProfile);

                dispatch(
                    createUserProfile({
                        userProfile: userProfile,
                        controller: controller,
                    })
                );
            }
        });
    };

    const renderContent = () => {
        return (
            <>
                <div className="py-5 px-10">
                    <div className="flex gap-3 text-neutral-900 font-semibold">
                        <div>
                            <ShieldTickIcon className="inline-block align-middle" />
                        </div>
                        <div className="text-xl">Verify your account</div>
                    </div>
                    <div className="mt-2 text-neutral-500">
                        You must sign a message in your wallet to confirm your
                        ownership of this account before you can complete the
                        connection.
                    </div>
                </div>
                <div className="px-10 py-5 border-t-2 border-neutral-900 border-solid flex justify-end gap-3">
                    <div className="my-auto">
                        <TextButton
                            size="sm"
                            text="Cancel"
                            buttonType="text"
                            onClick={() => {
                                updatePopupState(false);
                            }}
                        />
                    </div>
                    <div>
                        <TextButton
                            size="sm"
                            text="Sign a message"
                            buttonType="primary"
                            onClick={HandleSIWE}
                        />
                    </div>
                </div>
            </>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <NcModal
            isOpenProp={isShowSIWEPopUp}
            onCloseModal={() => {
                updatePopupState(false);
            }}
            contentExtraClass="max-w-lg !border-2 !border-neutral-900 !border-solid !border-opacity-100"
            contentPaddingClass=""
            renderContent={renderContent}
            renderTrigger={renderTrigger}
            modalTitle="siwe"
        />
    );
};

export default SIWEModel;
