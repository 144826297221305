import NavAccountButton from 'shared/Button/NavAccountButton';
import NotificationButton from 'shared/Button/NotificationButton';
import TrideLoginButton from 'shared/Button/TrideLoginButton';
import { userIsLoggedIn } from 'utils/providers/ethereumProvider';
import { useWeb3 } from 'utils/useWeb3';

const AccountRelatedArea = () => {
    const { address } = useWeb3()!;

    return (
        <div>
            {userIsLoggedIn() && address != '' ? (
                <div className="flex gap-4 lg:gap-5 items-center">
                    <div>
                        <NotificationButton />
                    </div>
                    <div>
                        <NavAccountButton />
                    </div>
                </div>
            ) : (
                <TrideLoginButton loginLabel="Connect Wallet" />
            )}
        </div>
    );
};

export default AccountRelatedArea;
