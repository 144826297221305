import TextButton from 'components/Buttons/TextButton';
import React, { FC, useEffect, useState } from 'react';
import POAPsLogo from 'static/images/profile/poap_logo.svg';
import POAPExample from 'static/images/profile/poap_example.png';
import ProfilePOAPBadge from './ProfilePOAPBadge';
import ProfilePOAPSection from './ProfilePOAPSection';
import { useAppSelector } from 'store/hooks';
import { Address } from 'wagmi';
import LoadingProfilePOAPBadge from './LoadingProfilePOAPBadge';

export interface ProfilePOAPsProps {
    className?: string;
}

export interface POAPData {
    event: POAPEvent;
    owner: Address;
    tokenId: string;
}
export interface POAPEvent {
    id: Number;
    name: string;
    image_url: string;
    city: string;
    issuer: string;
    rating: Number;
    category: string;
}

const ProfilePOAPs: FC<ProfilePOAPsProps> = ({ className }) => {
    const isLoading = useAppSelector((state) => state.userProfile.loading);
    const poaps = useAppSelector((state) => state.userProfile.currentPoaps);
    const count = poaps.length || 0;

    // const filterSkills = (skill: string) => {
    //     let result = skillDummyData
    //     if(skill !== "Overall"){
    //         result = skillDummyData.filter((item: SkillNftProps) => {return item.category == skill})
    //     }
    //     setData({filter: skill, items: result})
    // }
    return (
        <div className={className}>
            <div className="flex flex-row justify-between">
                <div className="flex my-auto">
                    <div className="my-auto">
                        <img src={POAPsLogo} />
                    </div>
                    <div className="text-2xl-initial-semibold ml-3">
                        POAP ({count})
                    </div>
                </div>
                {/* <TextButton className="ml-auto" size="md" buttonType="primary" text={<div className="text-sm-initial-medium">Edit</div>} /> */}
            </div>
            <div className="bg-neutral-100 rounded-3xl px-6 py-10 mt-6">
                {/* <div className="text-lg-initial-medium">Featured</div> */}
                <div className="grid grid-cols-3 lg:grid-cols-5 gap-x-4 gap-y-6 lg:gap-[28px]">
                    {isLoading
                        ? Array(15)
                            .fill(null)
                            .map((_, i) => (
                                <LoadingProfilePOAPBadge key={i} />
                            ))
                        : poaps.map((poap: POAPData) => (
                            <ProfilePOAPBadge poap={poap} />
                        ))}
                </div>
                {/* <div className="w-100 border-b-2 border-neutral-200 my-8"></div> */}
                {/* <ProfilePOAPSection title="2023" />
                <ProfilePOAPSection title="2022" className="mt-12" /> */}
            </div>
        </div>
    );
};

export default ProfilePOAPs;
