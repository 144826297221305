import React, { FC, useEffect, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import GeneralModal from 'components/Modal/GeneralModal';
import TextField from 'components/Input/TextFields/TextField';
import { useAccount, useEnsAvatar, useEnsName, useEnsResolver } from 'wagmi';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';

import imageSrc from 'static/images/profile/WalletCardModelImage.png';
import NcModal from 'shared/NcModal/NcModal';
import ShieldTickIcon from 'components/Icon/ShieldTickIcon';
import WarningIcon from 'components/Icon/WarningIcon';

export interface EditProfileModalProps {
    className?: string;
    openEditModal: boolean;
    setOpenEditModal: Function;
}

const ConnectSocialNetwork = () => {
    return (
        <div className="basis-1/4">
            <div className="text-sm-initial-medium">Discord</div>
            <TextButton
                size="sm"
                text="Connect"
                buttonType="secondary"
                className="mt-2"
            />
        </div>
    );
};

const WalletCardRejectModel: FC<EditProfileModalProps> = ({
    className,
    openEditModal,
    setOpenEditModal,
}) => {
    const renderContent = () => {
        return (
            <>
                <div className="py-5 px-10">
                    <div className="flex gap-3 text-neutral-900 font-semibold">
                        <div>
                            <WarningIcon className="inline-block align-middle" />
                        </div>
                        <div className="text-xl">Oops!</div>
                    </div>
                    <div className="mt-2 text-neutral-500">
                        It appears that you're currently using a desktop
                        computer. Please open this link on your phone or tablet
                        to create your pass.
                    </div>
                </div>
                <div className="px-10 py-4 border-t-2 border-neutral-900 border-solid flex justify-end gap-3">
                    <TextButton
                        size="sm"
                        text="Dismiss"
                        buttonType="text"
                        onClick={() => setOpenEditModal(false)}
                    />
                </div>
            </>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <NcModal
            isOpenProp={openEditModal}
            contentExtraClass="max-w-lg !border-2 !border-neutral-900 !border-solid !border-opacity-100"
            contentPaddingClass=""
            renderContent={renderContent}
            renderTrigger={renderTrigger}
        />
    );
};

export default WalletCardRejectModel;
