import { configureStore, combineReducers } from '@reduxjs/toolkit';
import {
    persistStore,
    persistReducer,
    FLUSH,
    REHYDRATE,
    PAUSE,
    PERSIST,
    PURGE,
    REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import logger from 'redux-logger';
import rootReducers from './rootReducers';
import { mediaRunningSlice } from './slices/mediaRunning';
import userSlice from './slices/userSlice';
import thunkMiddleware from 'redux-thunk';
import eventSlice from './slices/eventSlice';
import userProfileSlice from './slices/profileSlice';
import badgesSlice from './slices/badgesSlice';
import workBadgesSlice from './slices/workBadgesSlice';
import authSlice from './slices/authSlice';
import jobSlice from './slices/jobSlice';
import issueSlice from './slices/issueSlice';
import subscribeSlice from './slices/subscribeSlice';

const isDev = process.env.NODE_ENV === 'development';

const persistConfig = {
    key: 'root',
    version: 1,
    storage,
    whitelist: [],
};

const rootReducer = combineReducers(rootReducers);
const persistedReducer = persistReducer(persistConfig, rootReducer);
const middlewareLogger: any = !!isDev ? logger : [];

export const store = configureStore({
    reducer: {
        mediaRunning: persistedReducer,
        user: userSlice,
        event: eventSlice,
        userProfile: userProfileSlice,
        badges: badgesSlice,
        workBadges: workBadgesSlice,
        auth: authSlice,
        job: jobSlice,
        issue: issueSlice,
        subscribe: subscribeSlice
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [
                    FLUSH,
                    REHYDRATE,
                    PAUSE,
                    PERSIST,
                    PURGE,
                    REGISTER,
                ],
            },
        }).concat(middlewareLogger),
});

export let persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
