import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import badgesServices from 'services/badges.services';
import IPFSHelper from 'utils/IPFSHelper';
import axios, { AxiosResponse, responseEncoding } from 'axios';
import { badgesInfoRespone } from 'schemas/response';
import EtherController from 'utils/etherController';
import { BADGES_TYPE } from 'constants/types';
import eventServices from 'services/event.services';
import workServices from 'services/work.services';
import { WorkBadges__factory } from 'typechain/factories/WorkBadges__factory';
import { ethers } from 'ethers';
import invokeServices from 'services/invoke.services';
import { calCommit, hashGenerator } from 'utils/signHelper';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import randomstring from 'randomstring';
import { Subscription__factory } from 'typechain/factories';
import shareSecretCodeTemplate from 'utils/mail/template/ShareSecretCode';
import { CONTRACT_ADDRESS } from 'constants/contract';

export interface badgesInformation {
    contractAddress: string;
    tokenId: number;
    type: string;
    metadataURI: string;
    metadata: any;
}

export interface badgesState {
    badgesInformation: badgesInformation | null;
    transactionHash: string | null;
    claimed: boolean;
    errorMsg: [string] | null;
    loading: boolean;
    failed: boolean;
}

const initialState: badgesState = {
    badgesInformation: null,
    transactionHash: null,
    claimed: false,
    errorMsg: null,
    loading: false,
    failed: false,
};

interface getBadgesInfoInputParam {
    id: string;
}

export const getBadgesInfoFromContract = createAsyncThunk(
    'badges/getBadgesInfoContract',
    async (
        payload: {
            address: string;
            tokenId: number;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        let resData: badgesInfoRespone;

        const tokenURI = await WorkBadges__factory.connect(
            payload.address,
            payload.provider
        ).tokenURI(payload.tokenId);

        let metadata: any;
        try {
            const cid = await IPFSHelper.convertURI(tokenURI);
            console.log(`metadata_cid: ${cid}`);
            metadata = await (await axios.get(cid)).data;
        } catch (err: any) {
            return thunkApi.rejectWithValue({
                error: 'IPFS Error',
            });
        }

        const returnObject = {
            contractAddress: payload.address,
            tokenId: payload.tokenId,
            type: 'WORK',
            metadataURI: tokenURI,
            metadata: metadata,
        };

        return returnObject;
    }
);

export const getBadgesInfo = createAsyncThunk(
    'badges/getBadgesInfo',
    async (id: string, thunkApi) => {
        let resData: badgesInfoRespone;

        try {
            resData = (await badgesServices.getBadgesInfo(id)).data;
        } catch (err: any) {
            return thunkApi.rejectWithValue({
                error: 'Backend Error',
            });
        }

        let metadata: any;
        try {
            const cid = await IPFSHelper.convertURI(resData.metadataURI);
            console.log(`metadata_cid: ${cid}`);
            metadata = await (await axios.get(cid)).data;
        } catch (err: any) {
            return thunkApi.rejectWithValue({
                error: 'IPFS Error',
            });
        }

        const returnObject = {
            contractAddress: resData.contractAddress,
            tokenId: resData.tokenId,
            type: resData.type,
            metadataURI: resData.metadataURI,
            metadata: metadata,
        };

        console.debug(returnObject);

        return returnObject;
    }
);

export const claimBadges = createAsyncThunk(
    'badges/claimBadges',
    async (
        {
            secret,
            contractAddress,
            tokenId,
            controller,
        }: {
            secret?: string;
            contractAddress: string;
            tokenId: number;
            controller: EtherController;
        },
        thunkApi
    ) => {
        try {
            const workContract = WorkBadges__factory.connect(
                contractAddress,
                controller.getProvider()
            );

            const commitHash = calCommit({
                secretCode: secret || '',
                senderAddr: await controller.getAccount(),
            });

            let commitData = await workContract.interface.encodeFunctionData(
                'commitBadge',
                [tokenId, commitHash]
            );

            console.log('commitData', commitData);

            let claimData = await workContract.interface.encodeFunctionData(
                'claimBadge',
                [tokenId, secret || '']
            );
            console.log('claimData', claimData);

            const signedCommit = await controller.signFunctionCall(
                workContract,
                commitData
            );

            const resCommit = await invokeServices.invoke(signedCommit);

            const commitTxn = await (
                await controller
                    .getProvider()
                    .getTransaction(resCommit.data.hash)
            ).wait();

            const signedClaim = await controller.signFunctionCall(
                workContract,
                claimData
            );

            const resClaim = await invokeServices.invoke(signedClaim);

            const claimTxn = await (
                await controller
                    .getProvider()
                    .getTransaction(resClaim.data.hash)
            ).wait();

            // return { transactionHash: '' };
            return { transactionHash: resClaim.data.hash };
        } catch (err) {
            console.error(err);
            return thunkApi.rejectWithValue({
                error: 'Backend Error',
                err_detail: err,
            });
        }
    }
);

export const badgesSlice = createSlice({
    name: 'badges',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getBadgesInfo.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(getBadgesInfo.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(getBadgesInfo.fulfilled, (state, { payload }) => {
            state.badgesInformation = payload;
            state.loading = false;
            state.failed = false;
        });

        builder.addCase(
            getBadgesInfoFromContract.pending,
            (state, { payload }) => {
                state.loading = true;
                state.failed = false;
            }
        );
        builder.addCase(
            getBadgesInfoFromContract.rejected,
            (state, { payload }) => {
                state.loading = false;
                state.failed = true;
            }
        );
        builder.addCase(
            getBadgesInfoFromContract.fulfilled,
            (state, { payload }) => {
                state.badgesInformation = payload;
                state.loading = false;
                state.failed = false;
            }
        );

        builder.addCase(claimBadges.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
            state.claimed = false;
        });
        builder.addCase(claimBadges.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(claimBadges.fulfilled, (state, { payload }) => {
            state.transactionHash = payload.transactionHash;
            state.loading = false;
            state.failed = false;
            state.claimed = true;
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = badgesSlice.actions;

export const badgesSliceFunction = {
    getBadgesInfo: getBadgesInfo,
    claimBadges: claimBadges,
    getBadgesInfoFromContract,
};

export default badgesSlice.reducer;
