import React, { FC, useMemo } from 'react';
import TextButton from 'components/Buttons/TextButton';
import TextField from 'components/Input/TextFields/TextField';
import { useDispatch } from 'react-redux';
import { updateStage } from 'store/slices/issueSlice';
import TextArea from 'components/Input/TextArea/TextArea';
import IconButton from 'components/Buttons/IconButton';
import { LockClosedIcon } from '@heroicons/react/solid';
import { TrashIcon } from '@heroicons/react/outline';
import { CalendarDaysIcon } from 'components/Icon/outline';
import { useNavigate } from 'react-router-dom';
import BadgeTemplateCard from '../component/TemplateCard';
import { useAppSelector } from 'store/hooks';
import FakeSkillsInputField from '../component/FakeSkillsInputField';

import skillsList from 'static/data/skills.json';
import Datepicker from 'shared/DatePicker/Datepicker';
import Checkbox from 'shared/Checkbox/Checkbox';

export interface CreateWorkSBTPageProps {
    className?: string;
}

interface badgesInformationProps {
    full_name: string;
    email: string;
    duration: { start_date: string; end_date: string };
    description: string;
    skills: Array<any>;
    salary: string;
    reporting_line?: string;
    accomplishments?: Array<any>;
    [key: string]: any;
}

const CreateWorkSBTPage: FC<CreateWorkSBTPageProps> = ({ className }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const selectedTemplate = useAppSelector(
        (state) => state.issue.selectedTemplate
    );

    const [isCurrentJob, setIsCurrentJob] = React.useState(false);

    const [accomplishmentsCount, setAccomplishmentsCount] = React.useState(1);
    const [badgesInformation, setBadgesInformation] =
        React.useState<badgesInformationProps>({
            full_name: '',
            email: '',
            duration: { start_date: '', end_date: '' },
            description: '',
            skills: [],
            salary: '',
            reporting_line: '',
            accomplishments: [],
        });

    const toCreateTemplate = () => {
        dispatch(updateStage('create-template'));
    };

    const toIssue = () => {
        let cleanBadgesInformation = { ...badgesInformation };
        if (cleanBadgesInformation.reporting_line == '') {
            delete cleanBadgesInformation.reporting_line;
        }

        if (
            cleanBadgesInformation.accomplishments &&
            cleanBadgesInformation.accomplishments[0].title == '' &&
            cleanBadgesInformation.accomplishments[0].percentage == ''
        ) {
            delete cleanBadgesInformation.accomplishments;
        }

        if (isCurrentJob) {
            cleanBadgesInformation.duration.end_date = '0';
        }

        console.debug(JSON.stringify(cleanBadgesInformation, null, 4));

        navigate('/issue/loading', {
            state: cleanBadgesInformation,
        });
    };

    const addMoreHandle = () => {
        setAccomplishmentsCount((prev) => {
            return prev + 1;
        });
    };

    const updateDuration = (
        key: 'start_date' | 'end_date',
        duration: string | boolean
    ) => {
        setBadgesInformation((prev: any) => {
            return {
                ...prev,
                duration: { ...prev.duration, [key]: duration },
            };
        });
    };

    const isCurrentJobHandler = (checked: boolean) => {
        setIsCurrentJob(checked);
    };

    const updateBadgesInformation = (
        key: string,
        val: string | Array<string> | undefined
    ) => {
        setBadgesInformation((prev: any) => {
            return {
                ...prev,
                [key]: val,
            };
        });
    };

    const removeAccomplishments = (index: number) => {
        setBadgesInformation((prev: any) => {
            return {
                ...prev,
                accomplishments: [
                    ...prev.accomplishments.slice(0, index),
                    ...prev.accomplishments.slice(index + 1),
                ],
            };
        });
        setAccomplishmentsCount((prev) => {
            return prev - 1;
        });
    };

    const updateAccomplishments = (index: number, key: string, val: string) => {
        setBadgesInformation((prev: any) => {
            return {
                ...prev,
                accomplishments: [
                    ...prev.accomplishments.slice(0, index),
                    { ...prev.accomplishments[index], [key]: val },
                    ...prev.accomplishments.slice(index + 1),
                ],
            };
        });
    };

    const canGoNextStep = useMemo(() => {
        const accomplishmentsCheck = badgesInformation.accomplishments?.every(
            (val: any) => {
                return val.title != '' && val.percentage != '';
            }
        )
            ? true
            : false ||
              (accomplishmentsCount == 1 &&
                  badgesInformation.accomplishments &&
                  badgesInformation.accomplishments[0].title == '' &&
                  badgesInformation.accomplishments[0].percentage == '');

        const startDateCheck =
            badgesInformation.duration?.start_date &&
            badgesInformation.duration?.start_date != '';
        const endDateCheck =
            (badgesInformation.duration?.end_date &&
                badgesInformation.duration?.end_date != '') ||
            isCurrentJob;
        const isValidateDuration =
            isCurrentJob ||
            badgesInformation.duration?.end_date >
                badgesInformation.duration?.start_date;

        return (
            startDateCheck &&
            endDateCheck &&
            isValidateDuration &&
            badgesInformation.description != '' &&
            badgesInformation.skills?.length > 0 &&
            badgesInformation.skills?.length <= 5 &&
            badgesInformation.salary != '' &&
            badgesInformation.full_name != '' &&
            badgesInformation.email != '' &&
            accomplishmentsCheck
        );
    }, [badgesInformation, isCurrentJob]);

    return (
        <div>
            <div className="my-20 mx-16 flex gap-12">
                <div className="flex flex-col gap-16">
                    <div className="text-3xl text-primary-700 font-semibold">
                        Provide an employee
                    </div>
                    <div>
                        <div className="text-lg text-neutral-900 font-semibold">
                            Work details
                        </div>
                        <div className="issue-input-background-card mt-6">
                            <div>
                                <label className="block text-sm font-medium text-neutral-900">
                                    Duration{' '}
                                    <span style={{ color: '#F43F5E' }}>
                                        {' *'}
                                    </span>
                                </label>
                                <div className="grid grid-cols-2 gap-2 mt-2">
                                    <Datepicker
                                        maxDate={new Date().toLocaleDateString()}
                                        placeholder="Start date"
                                        onChangeHandler={(date: Date[]) =>
                                            updateDuration(
                                                'start_date',
                                                (
                                                    date[0].getTime() / 1000
                                                ).toString()
                                            )
                                        }
                                    />
                                    <Datepicker
                                        maxDate={new Date().toLocaleDateString()}
                                        disabled={isCurrentJob}
                                        placeholder="End date"
                                        onChangeHandler={(date: Date[]) =>
                                            updateDuration(
                                                'end_date',
                                                (
                                                    date[0].getTime() / 1000
                                                ).toString()
                                            )
                                        }
                                    />
                                </div>
                            </div>
                            <div>
                                <Checkbox
                                    label="The employee is currently working in this position."
                                    defaultChecked={isCurrentJob}
                                    onChange={isCurrentJobHandler}
                                />
                            </div>
                            <div>
                                <TextArea
                                    required
                                    label="Work description"
                                    placeholder="A brief description about the role function, work done by the employee."
                                    onChangeHandle={(description: string) =>
                                        updateBadgesInformation(
                                            'description',
                                            description
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <FakeSkillsInputField
                                    onChangeHandle={(val: Array<string>) =>
                                        updateBadgesInformation('skills', val)
                                    }
                                />
                            </div>
                            <div>
                                <TextField
                                    required
                                    encrypted
                                    label="Salary"
                                    placeholder="in USD"
                                    onChangeHandle={(val: string) =>
                                        updateBadgesInformation('salary', val)
                                    }
                                />
                            </div>
                            <div>
                                <TextField
                                    encrypted
                                    label="Direct reporting line"
                                    placeholder="Ex: Chief Tech Executive"
                                    onChangeHandle={(val: string) =>
                                        updateBadgesInformation(
                                            'reporting_line',
                                            val
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-neutral-900">
                                    Measurable accomplishments{' '}
                                    <LockClosedIcon className="inline-block h-4 w-4" />
                                </label>
                                <div
                                    className="flex flex-col gap-2 mt-2"
                                    key={`accomplishments-${accomplishmentsCount}`}
                                >
                                    {Array(accomplishmentsCount)
                                        .fill(0)
                                        .map((_, index) => (
                                            <div
                                                className="grid grid-cols-12 gap-2"
                                                key={`accomplishments-item-${index}`}
                                            >
                                                <TextField
                                                    className="col-span-9"
                                                    placeholder="Ex: Reduced page load times by code cleanup"
                                                    defaultValue={
                                                        badgesInformation
                                                            .accomplishments?.[
                                                            index
                                                        ]?.title ?? ''
                                                    }
                                                    onChangeHandle={(
                                                        val: string
                                                    ) =>
                                                        updateAccomplishments(
                                                            index,
                                                            'title',
                                                            val
                                                        )
                                                    }
                                                />
                                                <TextField
                                                    className="col-span-2"
                                                    placeholder="%"
                                                    defaultValue={
                                                        badgesInformation
                                                            .accomplishments?.[
                                                            index
                                                        ]?.percentage ?? ''
                                                    }
                                                    onChangeHandle={(
                                                        val: string
                                                    ) =>
                                                        updateAccomplishments(
                                                            index,
                                                            'percentage',
                                                            val
                                                        )
                                                    }
                                                />
                                                {index != 0 && (
                                                    <div className="col-span-1">
                                                        <IconButton
                                                            className=""
                                                            buttonType="danger"
                                                            icon={
                                                                <TrashIcon className="stroke-white" />
                                                            }
                                                            onClick={() =>
                                                                removeAccomplishments(
                                                                    index
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        ))}
                                    <div className="w-fit">
                                        <TextButton
                                            size="sm"
                                            buttonType="secondary"
                                            text="Add more"
                                            onClick={addMoreHandle}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="text-lg text-neutral-900 font-semibold">
                            Employee Contact
                        </div>
                        <div className="issue-input-background-card mt-6">
                            <div>
                                <TextField
                                    required
                                    label="Employee full name"
                                    placeholder="Ex: Sandeep Nailwal"
                                    onChangeHandle={(val: string) =>
                                        updateBadgesInformation(
                                            'full_name',
                                            val
                                        )
                                    }
                                />
                            </div>
                            <div>
                                <TextField
                                    required
                                    label="Email"
                                    placeholder="Ex: sandeep@matic.network"
                                    onChangeHandle={(val: string) =>
                                        updateBadgesInformation('email', val)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <div className="flex justify-between">
                        <div>
                            <TextButton
                                size="md"
                                buttonType="text"
                                text="Back"
                                onClick={toCreateTemplate}
                            />
                        </div>
                        <div>
                            <TextButton
                                size="md"
                                buttonType="primary"
                                text="Issue"
                                isDisabled={!canGoNextStep}
                                onClick={toIssue}
                            />
                        </div>
                    </div>
                </div>
                <div>
                    <BadgeTemplateCard
                        className="sticky top-1"
                        title={selectedTemplate?.title}
                        employmentType={selectedTemplate?.employmentType}
                        locationType={selectedTemplate?.locationType}
                    />
                </div>
            </div>
        </div>
    );
};

export default CreateWorkSBTPage;
