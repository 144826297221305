import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { DEPLOY_CHAIN } from 'constants/chainConfig';
import { ethers } from 'ethers';
import { workInfoStruct } from 'schemas/structure';
import { Organisation__factory } from 'typechain/factories/Organisation__factory';
import { Subscription__factory } from 'typechain/factories/Subscription__factory';
import { WorkBadges__factory } from 'typechain/factories/WorkBadges__factory';
import EtherController from 'utils/etherController';
import IPFSHelper from 'utils/IPFSHelper';

import workServices from 'services/work.services';
import invokeServices from 'services/invoke.services';

export interface workBadgesState {
    organisatinoId: number | null;
    workInfo: workInfoStruct | null;
    metadata: any;
    isAllowedToViewPrivateInfo: boolean;
    ownerAddress: string | null;
    errorMsg: [string] | null;
    loading: boolean;
    requesting: boolean;
    failed: boolean;
}

const initialState: workBadgesState = {
    organisatinoId: null,
    workInfo: null,
    ownerAddress: null,
    isAllowedToViewPrivateInfo: false,
    metadata: null,
    errorMsg: null,
    requesting: false,
    loading: false,
    failed: false,
};

export const getOrganisationId = createAsyncThunk(
    'worksBadges/getOrganisationId',
    async (
        payload: {
            contractAddress: string;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        const workBadgesContractCaller = WorkBadges__factory.connect(
            payload.contractAddress,
            payload.provider
        );

        const orgId = await workBadgesContractCaller.organisationId();

        return { orgId: orgId.toNumber() };
    }
);

export const getWorkInfo = createAsyncThunk(
    'worksBadges/getWorkInfo',
    async (
        payload: {
            contractAddress: string;
            tokenId: number;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        const workBadgesContractCaller = WorkBadges__factory.connect(
            payload.contractAddress,
            payload.provider
        );

        const workInfo: workInfoStruct =
            await workBadgesContractCaller.workInfo(payload.tokenId);

        let ownerAddress = null;
        try {
            ownerAddress = await workBadgesContractCaller.ownerOf(
                payload.tokenId
            );
        } catch (error) {
            console.log('maybe no owner will throw');
            console.log(error);
        }

        const cid = await IPFSHelper.convertURI(workInfo.metadataURI);
        console.log(`metadata_cid: ${cid}`);
        const metadata = await (await axios.get(cid)).data;

        return { workInfo, metadata, ownerAddress };
    }
);

export const requestWorkAccess = createAsyncThunk(
    'worksBadges/requestWorkAccess',
    async (
        payload: {
            profileId: number;

            userAddress: string;
            controller: EtherController;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        const orgContract = Organisation__factory.connect(
            '0x5B3D00c02901dbbA9e00a37Ab80c4BDFD2123865',
            payload.provider
        );

        const address = await orgContract.subscriptionContractAddress();

        const dto = await payload.controller.signRequestSubscription(
            address,
            payload.profileId
        );

        const res = await invokeServices.invoke(dto);

        console.log('request subscription response,', res.data);
        return { response: res };
    }
);

export const isAllowedToViewPrivateInfo = createAsyncThunk(
    'worksBadges/checkAllowPermission',
    async (
        payload: {
            contractAddress: string;
            tokenId: number;
            userAddress: string;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        const workBadgesContractCaller = WorkBadges__factory.connect(
            payload.contractAddress,
            payload.provider
        );

        try {
            const ownerAddress = await workBadgesContractCaller.ownerOf(
                payload.tokenId
            );

            if (
                ownerAddress.toLowerCase() == payload.userAddress.toLowerCase()
            ) {
                return { isAllowed: true };
            }
        } catch (error) {
            console.warn(`isOwner error ${error}`);
        }

        try {
            const isAllowed =
                await workBadgesContractCaller.canViewEncryptedData(
                    payload.tokenId,
                    payload.userAddress
                );

            return { isAllowed };
        } catch (error) {
            console.warn(error);
        }

        return { isAllowed: false };
    }
);

export const workBadgesSlice = createSlice({
    name: 'workBadges',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            isAllowedToViewPrivateInfo.pending,
            (state, { payload }) => {
                state.loading = true;
                state.failed = false;
            }
        );
        builder.addCase(
            isAllowedToViewPrivateInfo.rejected,
            (state, { payload }) => {
                state.loading = false;
                state.failed = true;
            }
        );
        builder.addCase(
            isAllowedToViewPrivateInfo.fulfilled,
            (state, { payload }) => {
                if (payload) {
                    state.isAllowedToViewPrivateInfo = payload.isAllowed;
                    state.loading = false;
                    state.failed = false;
                }
            }
        );

        // request subscription
        builder.addCase(requestWorkAccess.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(requestWorkAccess.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(requestWorkAccess.fulfilled, (state, { payload }) => {
            if (payload) {
                state.loading = false;
                state.failed = false;
                state.requesting = true;
            }
        });

        builder.addCase(getOrganisationId.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(getOrganisationId.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(getOrganisationId.fulfilled, (state, { payload }) => {
            if (payload) {
                state.organisatinoId = payload.orgId;
                state.loading = false;
                state.failed = false;
            }
        });

        builder.addCase(getWorkInfo.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(getWorkInfo.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(getWorkInfo.fulfilled, (state, { payload }) => {
            state.workInfo = payload.workInfo;
            state.metadata = payload.metadata;
            state.ownerAddress = payload.ownerAddress;
            state.loading = false;
            state.failed = false;
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = workBadgesSlice.actions;

export const workBadgesSliceFunction = {
    getOrganisationId: getOrganisationId,
    getWorkInfo: getWorkInfo,
    isAllowedToViewPrivateInfo: isAllowedToViewPrivateInfo,
    requestWorkAccess: requestWorkAccess,
};

export default workBadgesSlice.reducer;
