import NavigationScroll from "layout/NavigationScroll";
import React from "react";
import Router from "routers";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { ProvideWeb3 } from "utils/useWeb3";
import Snackbar from "shared/Snackbar/Snackbar";
import { SnackbarContext } from "shared/Snackbar/Snackbar";

import { configureChains, createClient, mainnet, WagmiConfig } from "wagmi";
import { alchemyProvider } from "@wagmi/core/providers/alchemy";
import { polygonMumbai } from "wagmi/chains";
import { publicProvider } from "wagmi/providers/public";
import { Alchemy_API_KEY } from "constants/blockchain";

const { chains, provider, webSocketProvider } = configureChains(
  [polygonMumbai, mainnet],
  [alchemyProvider({apiKey: Alchemy_API_KEY})]
);

console.log('chains', chains)

const client = createClient({
  autoConnect: true,
  provider,
  webSocketProvider,
});

function App() {
  const [snackbarState, setSnackbarState] = React.useState({
    show: false,
    onClick: () => console.log("snackbar"),
    duration: 3000,
    text: "Snackbar called",
  });
  return (
    <div
      className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200"
      style={{ backgroundColor: "#F8F8F8" }}
    >
      <WagmiConfig client={client}>
        <ProvideWeb3>
          <SnackbarContext.Provider value={[snackbarState, setSnackbarState]}>
            <BrowserRouter basename="/">
              {
                //bg color overridden by inline style
              }
              <NavigationScroll>
                <Router />
              </NavigationScroll>
            </BrowserRouter>
            <Snackbar />
          </SnackbarContext.Provider>
        </ProvideWeb3>
      </WagmiConfig>
    </div>
  );
}

export default App;
