import AvatarDropdown from 'components/Header/AvatarDropdown';
import NotifyDropdown from 'components/Header/NotifyDropdown';
import React, { useState } from 'react';
import MenuBar from 'shared/MenuBar/MenuBar';
import SwitchDarkMode from 'shared/SwitchDarkMode/SwitchDarkMode';



function Header({
  sidebarOpen,
  setSidebarOpen
}: {
  sidebarOpen: boolean,
  setSidebarOpen: (open: boolean) => void
}) {

  const [searchModalOpen, setSearchModalOpen] = useState(false)

  return (
    <header className="sticky top-0 bg-gray-50 dark:bg-gray-800 border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16 -mb-px">

          {/* Header: Left side */}
          <div className="flex">

            {/* Hamburger button */}
            <button
              className="text-slate-500 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => { e.stopPropagation(); setSidebarOpen(!sidebarOpen); }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg className="w-6 h-6 fill-current" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

          </div>

          {/* Header: Right side */}
          <div className="flex items-center space-x-3">


            <div className="hidden items-center xl:flex space-x-2">
              {/* <Navigation /> */}
              <div className="hidden sm:block h-6 border-l border-neutral-300 dark:border-neutral-600"></div>
              <div className="flex">
                <SwitchDarkMode />
                <NotifyDropdown />
              </div>
              <div></div>
              <AvatarDropdown />
            </div>
            <div className="flex items-center space-x-3 xl:hidden">
              <NotifyDropdown />
              <AvatarDropdown />
              <MenuBar />
            </div>



          </div>

        </div>
      </div>
    </header>
  );
}

export default Header;