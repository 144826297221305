export const EmptyState = () => {
    return (
        <div className="list_empty_state">
            <div className="text-base-initial-semibold">Whoops, no results</div>
            <div className="text-xs-initial-regular">
                Don't worry, we'll keep searching for you. WAGMI! &#128170;
            </div>
        </div>
    );
};
