import { LockClosedIcon } from '@heroicons/react/solid';
import useDebounceValue from 'hooks/useDebounceValue';
import React, { FC, ReactElement, useEffect, useState } from 'react';
import { useKey } from 'react-use';
import { JsxElement } from 'typescript';

export interface TextFieldProps {
    className?: string;
    type?: string;
    label?: string;
    placeholder?: string;
    defaultValue?: string;
    prependIcon?: ReactElement;
    appendIcon?: ReactElement;
    isError?: boolean;
    errorMessage?: string;
    required?: boolean;
    encrypted?: boolean;
    disabled?: boolean;
    onChangeHandle?: any;
}

const TextField: FC<TextFieldProps> = ({
    className,
    type,
    label,
    placeholder,
    defaultValue,
    isError,
    errorMessage,
    prependIcon,
    appendIcon,
    required,
    encrypted,
    disabled,
    onChangeHandle,
}) => {
    const [_userInput, _setUserInput] = useState(defaultValue ?? '');

    const _onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        _setUserInput(e.target.value);
    };

    const _debounceValue = useDebounceValue(_userInput, 500);

    useEffect(() => {
        if (onChangeHandle) {
            onChangeHandle(_debounceValue);
        }
    }, [_debounceValue]);

    return (
        <div className={className}>
            {(label || required || encrypted) && (
                <label
                    htmlFor="price"
                    className="block text-sm font-medium text-neutral-900 mb-2"
                >
                    {label}
                    {required ? (
                        <span style={{ color: '#F43F5E' }}>{' *'}</span>
                    ) : null}
                    {encrypted && (
                        <span>
                            {' '}
                            <LockClosedIcon className="inline-block h-4 w-4" />
                        </span>
                    )}
                </label>
            )}

            <div className={`relative rounded-md`}>
                {prependIcon && (
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        {prependIcon}
                    </div>
                )}

                <input
                    type="text"
                    className={`block w-full rounded-md ${
                        prependIcon ? 'pl-11' : 'pl-5'
                    }  ${appendIcon ? 'pr-11' : 'pr-5'} ${
                        disabled
                            ? 'text-neutral-400 border-transparent bg-neutral-200 cursor-text'
                            : 'border-2 border-neutral-900 text-neutral-900 focus:border-neutral-900 focus:ring-primary-500 focus:ring-2'
                    } sm:text-sm ${
                        isError
                            ? 'border-rose-500 focus:ring-transparent'
                            : ''
                    }`}
                    placeholder={placeholder}
                    disabled={disabled}
                    defaultValue={defaultValue}
                    onChange={_onChangeHandle}
                />

                {appendIcon && (
                    <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                        {appendIcon}
                    </div>
                )}
            </div>
            {isError && errorMessage && (
                <div className="mt-2 text-sm text-red-500">
                    {errorMessage}
                </div>
            )}
        </div>
    );
};

export default TextField;
