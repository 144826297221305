import { createSlice } from '@reduxjs/toolkit';
import { ethers, utils } from 'ethers';
import { defaultAbiCoder } from 'ethers/lib/utils.js';
import { createAppAsyncThunk } from 'store/createAppAsyncThunk';
import { RootState } from 'store/store';
import {
    Subscription__factory,
    UserProfile__factory,
} from 'typechain/factories';
import EtherController from 'utils/etherController';

import randomstring from 'randomstring';
import { hashGenerator } from 'utils/signHelper';
import shareSecretCodeTemplate from 'utils/mail/template/ShareSecretCode';
import invokeServices from 'services/invoke.services';
import { CONTRACT_ADDRESS } from 'constants/contract';
import { eventFilterWithTxn } from 'utils/eventFilter';

// current user handling, jwt , etc.
export interface SubscribeState {
    loading: boolean;
    success: boolean;
}

const initialState: SubscribeState = {
    loading: false,
    success: false,
};

export const createShareCode = createAppAsyncThunk(
    'create/shareCode',
    async (
        {
            controller,
            badgesInfo,
        }: {
            controller: EtherController;
            badgesInfo?: Object;
        },
        thunkApi
    ) => {
        const subscriptionContract = Subscription__factory.connect(
            CONTRACT_ADDRESS.subscription,
            controller.getProvider()
        );
        const ISubscription = subscriptionContract.interface;

        const userAddress = await controller.getAccount();

        const salt = randomstring.generate({
            length: 12,
            charset: 'alphanumeric',
        });
        const secretCode = randomstring.generate({
            length: 6,
            charset: 'numeric',
        });
        const toHash = [
            { type: 'string', value: secretCode },
            { type: 'string', value: salt },
            { type: 'address', value: userAddress },
        ];
        const hash = hashGenerator(toHash);

        let createShareCodeData = await ISubscription.encodeFunctionData(
            'createShareCode',
            [hash, salt]
        );

        const signedDataCall = await controller.signFunctionCall(
            subscriptionContract,
            createShareCodeData
        );

        const createRes = await invokeServices.invoke(signedDataCall);
        const createTxnHash = createRes.data.hash;

        const eventLogs = await eventFilterWithTxn(
            controller.getProvider(),
            createTxnHash,
            ISubscription,
            ISubscription.getEventTopic('CreateSecretCode')
        );

        if (eventLogs.length != 1) {
            return thunkApi.rejectWithValue({
                message: 'Invalid number of events',
            });
        }

        const hashIndex = eventLogs[0].args.proposeIndex;

        let claimUrl = new URL(
            `/subscribe/${userAddress}`,
            window.location.href
        );

        if (badgesInfo) {
            for (const [key, value] of Object.entries(badgesInfo)) {
                claimUrl.searchParams.append(key, value);
            }
        }

        claimUrl.searchParams.append('i', hashIndex);

        window.open(
            'mailto:?subject="You can view my private data"&body=' +
                shareSecretCodeTemplate({
                    claimUrl: claimUrl.toString(),
                    secretCode: secretCode,
                }),
            '_blank'
        );
    }
);

export const claimSubscribe = createAppAsyncThunk(
    '/claimSubscribe',
    async (
        payload: {
            toFollowAddress: string;
            hashIndex: number;
            secretCode: string;
            controller: EtherController;
        },
        thunkApi
    ) => {
        const { toFollowAddress, hashIndex, secretCode, controller } = payload;
        const provider = await controller.getProvider();

        const subscriptionContract = Subscription__factory.connect(
            CONTRACT_ADDRESS.subscription,
            controller.getProvider()
        );
        const ISubscription = subscriptionContract.interface;

        //  get user id
        const UserContract = UserProfile__factory.connect(
            CONTRACT_ADDRESS.user_profile,
            provider
        );
        const userId = (
            await UserContract.getUserId(toFollowAddress)
        ).toNumber();
        if (userId == 0) {
            return thunkApi.rejectWithValue({
                message: 'Unknown address to follow',
            });
        }

        // commit
        const toCommitHash = [
            { type: 'string', value: secretCode },
            { type: 'address', value: await controller.getAccount() },
        ];
        const commitHash = hashGenerator(toCommitHash);
        const commitDataPayload = ISubscription.encodeFunctionData(
            'commitShareCode',
            [userId, commitHash]
        );

        const commitDataCall = await controller.signFunctionCall(
            subscriptionContract,
            commitDataPayload
        );

        const commitRes = await invokeServices.invoke(commitDataCall);
        const commitTxnHash = commitRes.data.hash;
        await (await provider.getTransaction(commitTxnHash)).wait();

        // reveal
        const claimDataPayload = ISubscription.encodeFunctionData(
            'claimSubscription',
            [userId, hashIndex, secretCode]
        );

        const claimDataCall = await controller.signFunctionCall(
            subscriptionContract,
            claimDataPayload
        );

        const claimRes = await invokeServices.invoke(claimDataCall);
        const claimTxnHash = claimRes.data.hash;
        await (await provider.getTransaction(claimTxnHash)).wait();

        return;
    }
);

export const subscribeSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(claimSubscribe.pending, (state, { payload }) => {
            state.loading = true;
            state.success = false;
        });
        builder.addCase(claimSubscribe.fulfilled, (state, { payload }) => {
            state.loading = true;
            state.success = true;
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = subscribeSlice.actions;

export const subscribeSliceFunctions = { createShareCode, claimSubscribe };

export const subscribeSliceState = (state: RootState) => state.subscribe;

export default subscribeSlice.reducer;
