
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TextButton from "components/Buttons/TextButton";

import sampleBadge from "../../static/images/badges/sampleBadges_1.png";
import threeDots from "../../../src/static/images/3dots.jpg";
import metamaskWalletIcon from "../../../src/static/images/icons/metamaskIcon.png";
import torusWalletIcon from "../../../src/static/images/icons/torusIcon.png";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "store/store";
import IPFSHelper from "utils/IPFSHelper";
import NcImage from "shared/NcImage/NcImage";
import eventSlice, { getEventDetail } from "store/slices/eventSlice";
import { useNavigate, useParams } from "react-router-dom";

const PageSuccessClaiming: React.FC = () => {
  
  const detailInfo = useSelector((root: RootState) => root.badges.badgesInformation);
  const [ metadata, setMetadata ] = useState<any>(null);
  
  const txn = useSelector((root: RootState) => root.badges.transactionHash);

  let { id } = useParams();


  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (id) {
      dispatch(getEventDetail(id));
    }
  }, []);

  useEffect(() => {
    if(detailInfo){
      const metadata = detailInfo?.metadata;
      setMetadata(metadata);
    }
  }, [detailInfo])
  
  const navigate = useNavigate();

  return (
  <div className="nc-PagelOADING">
    <Helmet>
      <title>Success</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="my-10 max-w-2xl mx-auto space-y-2">
        
        <div className="relative">
                <NcImage
                  src={IPFSHelper.convertURI(metadata?.image || '')}
                  containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden border-4 border-black"
                />
              </div>
        <div className="text-center ">
        <div className="my-10 font-bold text-4xl-initial-bold">You’ve successfully claimed this badge &#x1F389;</div>
        <div className="my-10">
        <a href={`https://mumbai.polygonscan.com/tx/${txn}`} target="_blank">

        <TextButton                            
            buttonType="secondary"
            text="View on Polygonscan"
            size="sm:px-5"
            />
        </a>
        </div>
        </div>
        
        <div className="flex justify-center my-10">
            <img src={threeDots} className="w-fit" />
        </div>
        <div className="my-10 text-lg-initial-medium mb-6">
        If the badge is not displayed in your wallet, you can manually import it using the following details:
        </div>
        <div className="rounded-sm border-2 border-black p-8">
            <div>
                <div className="text-base-initial-normal text-neutral-500">Contact Address</div>
                <div className="break-words">{detailInfo?.contractAddress || ''}</div>
            </div>
            <div className="mt-4">
                <div className="text-base-initial-normal text-neutral-500">Token ID</div>
                <div className="break-words">{(""+detailInfo?.tokenId) || ''}</div>
            </div>
        </div>

        <table className="table-fixed border-separate border-spacing-2">
            <tbody>
                <tr>
                <td><img src={metamaskWalletIcon} /></td>
                <td className="text-left text-lg-initial-bold">MetaMask Wallet</td>
                </tr>
                <tr>
                <td></td>
                <td className="text-left text-lg-initial-medium">Learn how to import NFT tokens into your wallet here.</td>
                </tr>
                <tr>
                <td><img src={torusWalletIcon} /></td>
                <td className="text-left text-lg-initial-bold">Torus Wallet</td>
                </tr>
                <tr>
                <td></td>
                <td className="text-left text-lg-initial-medium">View your collectibles <a href="#" className="underline text-blue-700">here</a>. It may take a while.</td>
                </tr>
            </tbody>
            </table>


      </header>
    </div>
  </div>
)};

export default PageSuccessClaiming;
