import http from './http-common';
import axios from 'axios';
import { BACKEND_URL } from 'constants/backend';
import { EventResponse } from 'schemas/response';
import { ForwardingSignatureDTO } from 'schemas/params';

class InvokeService {
    client = axios.create({ baseURL: BACKEND_URL });

    async invoke(dataDTO: ForwardingSignatureDTO) {
        const data = await this.client.post('/utils/invoke', dataDTO);
        return data;
    }
}

export default new InvokeService();
