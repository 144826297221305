import React, { FC, useEffect, useState } from "react";
import {useWeb3} from "utils/useWeb3"
import { ethers } from "ethers";
import Banner from "static/images/cover_photo_placeholder.png"

export interface ProfileBannerProps {
  className?: string;
}

const ProfileBanner: FC<ProfileBannerProps> = ({ className
}) => {

    
    return (
        <>
            <div className={className+" h-60"}>
                <img src={Banner} className="w-screen h-full object-cover" />
            </div>
        </>
    );
};

export default ProfileBanner;
