import { Contract, ethers, utils } from 'ethers';

export async function eventFilterWithTxn(
    provider: ethers.providers.BaseProvider,
    txnHash: string,
    IContract: utils.Interface,
    eventTopic: string,
): Promise<Array<ethers.utils.LogDescription>> {
    const txn = await (await provider.getTransaction(txnHash)).wait();

    let eventLogs = txn.logs
        .filter((log) => log.topics[0] == eventTopic)
        .map((log: ethers.providers.Log) => IContract.parseLog(log));

    return eventLogs;
}

export default { eventFilterWithTxn };
