import React, { FC, useEffect, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';

import GeneralModal from 'components/Modal/GeneralModal';
import TextField from 'components/Input/TextFields/TextField';
import { useWeb3 } from 'utils/useWeb3';
import verifyAddressOwnership from 'utils/mail/template/VerifyAddressOwnership';

export interface EditProfileModalProps {
    isOpen: boolean;
    setOpenEditModal: Function;
}

const CreateProofOfOwnershipModal: FC<EditProfileModalProps> = ({
    isOpen,
    setOpenEditModal,
}) => {
    // const { data: signResult, error, isLoading, signMessage, variables } = useSignMessage()
    const { controller, address } = useWeb3()!;

    const [email, setEmail] = useState('');
    const [sign, setSign] = useState('');

    const signMessageHandler = () => {
        async function signMsg() {
            setSign(
                await controller!.signMessageString(
                    `This is the proof of ownership to ${email}`
                )
            );
        }
        signMsg();
    };

    useEffect(() => {
        if (sign) {
            let link = new URL(`/verify/${address}`, window.location.href);

            link.searchParams.append('sign', sign);

            window.open(
                `mailto:${email}?&body=${verifyAddressOwnership({
                    address: address,
                    link: link.toString(),
                })}`,
                '_blank'
            );
        }
    }, [sign]);

    const renderContent = () => {
        return (
            <div className="p-6">
                <div className="mb-6">
                    <h1 className="text-xl-initial-semibold text-neutral-800 mb-3">
                        Enter the recipient's email
                    </h1>
                    <p className="text-sm-initial-regular text-neutral-500">
                        An email with a one-time URL will be generated for you,
                        which you can send to the recipient to prove your
                        ownership of the profile. To protect your privacy,
                        please make sure that you do not share the email with
                        anyone other than the intended recipient.
                    </p>
                </div>
                <div>
                    <TextField
                        label="Recipient's email"
                        placeholder="example@example.com"
                        onChangeHandle={setEmail}
                    />
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="px-6 py-4 flex flex-row gap-6 justify-end border-t-2 border-neutral-900">
                <div className="">
                    <TextButton
                        className="h-9 my-auto"
                        size="sm"
                        text="Cancel"
                        buttonType="text"
                        onClick={() => setOpenEditModal(false)}
                    />
                </div>
                <div className="w-fit">
                    <TextButton
                        className=""
                        size="sm"
                        text="Save"
                        buttonType="primary"
                        onClick={signMessageHandler}
                    />
                </div>
            </div>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <>
            <GeneralModal
                isOpenProp={isOpen}
                onCloseModal={() => setOpenEditModal(false)}
                contentExtraClass="max-w-lg border-2 !border-neutral-900"
                renderContent={renderContent}
                renderFooter={renderFooter}
                renderTrigger={renderTrigger}
                modalTitle="Prove my ownership"
                closeButton={true}
            />
        </>
    );
};

export default CreateProofOfOwnershipModal;
