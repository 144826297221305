import React, { FC, ReactElement } from "react";

export interface BadgeTagProps {
    className?: string;
    text: string;
}

const BadgeTag: FC<BadgeTagProps> = (
    {className, text}
    ) => {

    return (
        <div className={`${className} rounded-full`}>
            {text}
        </div>
    );
};

export default BadgeTag;
