import React, { FC, ReactElement, useEffect, useState } from "react";
import TextButton from "components/Buttons/TextButton";
import VerifiedIcon from "static/images/verified.svg"
import LinkedinIcon from "static/images/socials/linkedin.svg"
import FacebookIcon from "static/images/socials/facebook.svg"
import DiscordIcon from "static/images/socials/discord.svg"
import TwitterIcon from "static/images/socials/twitter.svg"
import WebsiteIcon from "static/images/socials/website.svg"
import GithubIcon from "static/images/socials/github.svg"
import { Link, useNavigate } from "react-router-dom";
import { Address, useEnsAvatar, useEnsName } from "wagmi";
import Placeholder from "static/images/avatar_placeholder.png"
import GradientAvatars from "shared/Avatar/GradientAvatars";

export interface ProfileListingAccountProps {
  className?: string;
  image: string;
  accountName?: string;
  tag?: string[];
  website?: string;
  twitter?: string;
  facebook?: string;
  linkedin?: string;
  discord?: string;
  github?: string;
  verified?: boolean
  setActiveFilter: Function;
  address: Address;
}

const ProfileListingAccount: FC<ProfileListingAccountProps> = ({ className, image, accountName, tag,website, twitter, facebook, linkedin, discord, github, verified, address, setActiveFilter
}) => {
    const setFilter = (event: any, tag: string) => {
        event.stopPropagation();
        setActiveFilter(tag);
    }

    const navigate = useNavigate();

    const { data: ensAvatar } = useEnsAvatar({ address, chainId: 1 })

    const { data: ensName } = useEnsName({ address, chainId: 1 })

    const RenderLinks = () => {
return  <div className="profile_listing_card_links">
                {
                    website
                    ?(
                        <img src={WebsiteIcon} className="profile_listing_card_link" />
                    )
                    :<></>
                }
                {
                    twitter
                    ?(
                        <img src={TwitterIcon} className="profile_listing_card_link" />
                    )
                    :<></>
                }
                {
                    facebook
                    ?(
                        <img src={FacebookIcon} className="profile_listing_card_link" />
                    )
                    :<></>
                }
                {
                    linkedin
                    ?(
                        <img src={LinkedinIcon} className="profile_listing_card_link" />
                    )
                    :<></>
                }
                {
                    discord
                    ?(
                        <img src={DiscordIcon} className="profile_listing_card_link" />
                    )
                    :<></>
                }
                {
                    github
                    ?(
                        <img src={GithubIcon} className="profile_listing_card_link" />
                    )
                    :<></>
                }
            </div>
    }
    console.debug(image, ensAvatar,Placeholder, image || ensAvatar || Placeholder)

  return (
    <div className="profile_listing_card_background" onClick={()=>navigate(`/profile/${address}`)}>
        <div 
            className="profile_listing_account_card"
        >
            <div className="w-36 h-36 relative mx-auto ">
                {/* {image} */}
                {
                    image || ensAvatar ?
                    <div className="h-36 overflow-hidden rounded-2xl border-2 border-neutral-900" >
                        <img src={image || ensAvatar || Placeholder} />
                    </div>
                    : 
                        <GradientAvatars 
                            className='h-36 object-cover rounded-2xl border-2 border-neutral-900 overflow-hidden' 
                            seed={address} 
                        />
                }
                {
                    verified?
                    (<>
                        <img src={VerifiedIcon} className="profile_listing_card_verify" />
                    </>)
                    :<></>
                }
            </div>
            <div className="mt-5 text-base-initial-semibold text-center">
                {ensName
                ?ensName
                :address?.slice(0, 8) + "..." + address?.slice(address.length - 6, address.length)
                }
            </div>
            <div className="mt-3 flex gap-2 justify-center">
                {
                    tag && tag.length?(
                        <>
                        <div onClick={(event: any) => setFilter(event, tag[0])}>
                            <TextButton text={tag[0]} size="sm" buttonType="neutral" className="rounded-full" />
                        </div>
                        {
                            tag.length>1?(
                                <>
                                <div onClick={(event: any) => setFilter(event, tag[1])}>    
                                    <TextButton text={tag[1]} size="sm" buttonType="neutral" className="rounded-full" />
                                </div>
                                {
                                    tag.length>2?(
                                        <>
                                            <TextButton text={`+${tag.length-2}`} size="sm" buttonType="neutral" className="rounded-full" />
                                        </>
                                    )
                                    :<></>
                                }
                                </>
                            )
                            :<></>
                        }
                        </>
                    )
                    :<></>
                }
            </div>

            {/* <RenderLinks /> */}
        </div>
    </div>
  );
};

export default ProfileListingAccount;
