import BasicTextChip from 'components/Chips/BasicTextChip';
import React, { FC, useEffect, useState } from 'react';
import dummySkills from 'static/data/skills.json';

export interface FakeSkillsInputFieldProps {
    className?: string;
    defaultValue?: string;
    onChangeHandle?: any;
}

const FakeSkillsInputField: FC<FakeSkillsInputFieldProps> = ({
    className,
    defaultValue,
    onChangeHandle,
}) => {
    const [_skills, _setSkills] = useState<Array<any>>([]);

    const updateSkills = (skill: any) => {
        const newSkills = [..._skills];
        if (newSkills.includes(skill)) {
            _setSkills(newSkills.filter((s) => s.id !== skill.id));
        } else {
            _setSkills([...newSkills, skill]);
        }
    };

    useEffect(() => {
        if (onChangeHandle) {
            onChangeHandle(_skills);
        }
    }, [_skills]);

    return (
        <div className={className}>
            <label
                htmlFor="price"
                className="block text-sm font-medium text-neutral-900 mb-2"
            >
                Skills (Max 5)
                <span style={{ color: '#F43F5E' }}>{' *'}</span>
            </label>

            <div className="flex gap-2 flex-wrap mt-2">
                {dummySkills.map((skill) => {
                    return (
                        <BasicTextChip
                            key={skill.id}
                            size="sm"
                            text={skill.name}
                            selected={_skills.includes(skill)}
                            onChangeHandle={() => updateSkills(skill)}
                            disabled={
                                _skills.length >= 5 &&
                                !_skills.includes(skill)
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default FakeSkillsInputField;
