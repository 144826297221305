import React from "react";
import { Helmet } from "react-helmet";
import CircularLoader from "shared/Loadable/CircularLoader";


const PageLoadingClaiming: React.FC = () => (
  <div className="nc-PagelOADING page-wapper">
    <Helmet>
      <title>Loading</title>
    </Helmet>
    <div className="container relative pt-5 pb-16 lg:pb-20 lg:pt-5">
      {/* HEADER */}
      <header className="text-center max-w-md mx-auto space-y-2 h-full">
        <CircularLoader />
        <div className="text-lg-initial-medium">Hold tight, your badge is on the way. It'll be worth the wait. 😉</div>
      </header>
    </div>
  </div>
);

export default PageLoadingClaiming;
