import axios from 'axios';
import { BACKEND_URL } from 'constants/backend';
import { ForwardingSignatureDTO } from 'schemas/params';
import { Address } from 'wagmi';

class userProfileService {
    client = axios.create({ baseURL: BACKEND_URL });

    init() {
        this.client.interceptors.request.use(async (config) => {
            const jwt = localStorage.getItem('jwt');
            config!.headers!['Authorization'] = `Bearer ${jwt}`;
            return config;
        });
    }

    async createUserProfile(parameters: ForwardingSignatureDTO) {
        console.log(parameters);
        const data = await this.client.post('/profile/me', parameters);
        return data;
    }
    async getMyProfile() {
        const data = await this.client.get('/profile/me');
        return data;
    }

    async getPoaps(address: Address) {
        const data = await this.client.get('/profile/' + address + '/poap');
        return data;
    }

    async getProfile(address: Address) {
        const data = await this.client.get('/profile/' + address);
        return data;
    }

    async updateProfile(profile: any) {
        const data = await this.client.put('/profile/me', profile);
        return data;
    }

    async getMetadata(url: string) {
        const data = await this.client.get('/utils/metadata', {
            params: { url },
        });
        return data;
    }

    async generatePass(
        platform: string, //'ios' | 'android',
        phone: string,
        company: string,
        title: string
    ) {
        const data = await this.client.post('/pass/generate', {
            platform,
            phone,
            company,
            title,
        });
        return data.data;
    }

    async listProfiles(
        query?: string,
        tags?: string,
        limit?: number,
        offset?: number
    ) {
        const data = await this.client.get('/profile/list', {
            params: { query: query, tags: tags, limit: limit ?? 30, offset },
        });
        return data;
    }

    async getSkillsTags() {
        const data = await this.client.get(`/profile/tags`);
        return data;
    }
}

const service = new userProfileService();
service.init();
export default service;
