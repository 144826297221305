import { current } from '@reduxjs/toolkit';
import React, { FC, ReactElement, createContext } from 'react';

export interface SnackbarProps {}

export const SnackbarContext: any = createContext({
    show: true,
    onClick: () => console.log('snackbar'),
    duration: 3000,
    text: 'Snackbar called',
});

const Snackbar: FC<SnackbarProps> = () => {
    const [currentContext, setCurrentContext] =
        React.useContext<any>(SnackbarContext);

    React.useEffect(() => {
        if (currentContext.show) {
            setTimeout(() => {
                setCurrentContext({ ...currentContext, show: false });
            }, currentContext.duration);
        }
    }, [currentContext]);

    return (
        <>
            {currentContext.show ? (
                <>
                    <div
                        className={`fixed bottom-2 w-max sm:w-[420px] bg-neutral-900 rounded-xl p-6 text-xs-initial-regular text-white left-1/2 hidden md:block transition ease-in-out`}
                        style={{ boxShadow: '3px 3px 0px #60E829' }}
                    >
                        <div className="flex">
                            {currentContext.text}
                            <div
                                className="ml-auto text-primary-600 cursor-pointer"
                                onClick={() =>
                                    setCurrentContext({
                                        ...currentContext,
                                        show: false,
                                    })
                                }
                            >
                                Got it
                            </div>
                        </div>
                    </div>
                    <div
                        className={`fixed bottom-0 w-screen bg-neutral-900 p-6 text-xs-initial-regular text-white block md:hidden border-2 border-primary-600 transition-opacity`}
                    >
                        <div className="flex">
                            {currentContext.text}
                            <div
                                className="ml-auto text-primary-600 cursor-pointer"
                                onClick={() =>
                                    setCurrentContext({
                                        ...currentContext,
                                        show: false,
                                    })
                                }
                            >
                                Got it
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
        </>
    );
};

export default Snackbar;
