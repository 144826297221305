import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
    HeroSection,
    CarouselSection,
    ShortSummarySection,
    AdvantageSection,
    StepSection,
    StartBuildSection,
} from './section';

function PageHome() {
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <div>
                <HeroSection />
                <CarouselSection />
                <ShortSummarySection />
                <AdvantageSection />
                <StepSection />
                <StartBuildSection />
            </div>
        </>
    );
}

export default PageHome;
