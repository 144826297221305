import React, { FC } from 'react';
import HeroImage from 'static/images/issue-badges-hero.png';
import {
    LightBulbIcon,
    ShieldTickIcon,
    UserGroupIcon,
} from 'components/Icon/outline';

export interface IntroductionPageProps {
    className?: string;
}

const IntroductionPage: FC<IntroductionPageProps> = ({ className }) => {
    return (
        <div className="h-full min-h-[700px] container mx-auto flex flex-col justify-center">
            <div>
                <img className="h-[280px] mx-auto" src={HeroImage} />
            </div>
            <div className="mt-10">
                <div className="text-4xl font-semibold text-primary-700 text-center">
                    Work SBT Issuing Platform
                </div>
                <div className="text-sm text-neutral-500 text-center mt-4">
                    Issue work SBT which serves as a proof of work experience
                    for your employees.
                </div>
            </div>
            <div className="flex justify-center gap-3 mt-10">
                <div className="issue-hero-card">
                    <div>
                        <ShieldTickIcon iconClass="h-8 w-8 mx-auto" />
                    </div>
                    <div className="text-sm text-center">
                        Provide a secure and tamper-proof way to verify work
                        experience of your employees
                    </div>
                </div>
                <div className="issue-hero-card">
                    <div>
                        <LightBulbIcon iconClass="h-8 w-8 mx-auto" />
                    </div>
                    <div className="text-sm text-center">
                        Enable employees to showcase their skills and expertise
                        to potential future opportunities
                    </div>
                </div>
                <div className="issue-hero-card">
                    <div>
                        <UserGroupIcon iconClass="h-8 w-8 mx-auto" />
                    </div>
                    <div className="text-sm text-center">
                        Help attract and retain top talent by providing a
                        valuable proof of work experience to employees
                    </div>
                </div>
            </div>
        </div>
    );
};

export default IntroductionPage;
