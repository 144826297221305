import http from "./http-common";
import axios from "axios";
import { BACKEND_URL } from "constants/backend";
class UserService {
  client = axios.create({ baseURL: BACKEND_URL });

  async getUser() {
    //return http.get("/posts");
    const data = await axios.get("https://jsonplaceholder.typicode.com/posts");
    return data;
  }
}

export default new UserService();
