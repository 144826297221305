import React, { FC, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import { useAppDispatch } from 'store/hooks';
import { setLoginModel } from 'store/slices/authSlice';

export const StartBuildSection: FC<any> = ({}) => {
    const dispatch = useAppDispatch();
    const showLoginPopup = () => {
        dispatch(setLoginModel(true))
    }

    return (
        <div className="bg-black py-20">
            <div className="container text-center">
                <h1 className="text-3xl-initial-bold uppercase text-white">
                    Start building your professional profile now!
                </h1>
                <div className="mt-8">
                    <TextButton
                        size="xl"
                        buttonType="secondary"
                        text="Connect Wallet"
                        onClick={showLoginPopup}
                    />
                </div>
            </div>
        </div>
    );
};
