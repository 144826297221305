import { NFTStorage } from 'nft.storage';

const NFT_STORAGE_API_KEY =
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDdDOWZjM2VEMzQ4NDJhNzAyNzJjODBDMTQwQWE4ODkzRTRlYjAzRjAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MTMxMzg2NTAyOSwibmFtZSI6IlRyaWRlVGVzdGluZyJ9.yiKy8NKStP7oTQAL07lA88oUKVA89NpP00Dx-nf-2lk';

const IPFS_NFT_STORAGE = 'https://nftstorage.link/ipfs/';
const IPFS_CLOUDFLARE = 'https://cloudflare-ipfs.com/ipfs/';

const convertURI = (tokenURI: string) => {
    let uri = tokenURI ?? '';

    if (uri.startsWith('http')) {
        return tokenURI;
    } else {
        if (uri.startsWith('ipfs://')) {
            return uri.replace('ipfs://', IPFS_NFT_STORAGE);
        }
        return IPFS_NFT_STORAGE + uri;
    }
};

// tokenURI.replace("ipfs://", "https://ipfs.io/ipfs/");

const uploadJson = (JsonObj: Object) => {
    const client = new NFTStorage({ token: NFT_STORAGE_API_KEY });

    const stringifyJson = JSON.stringify(JsonObj);
    const blobData = new Blob([stringifyJson]);
    const cid = client.storeBlob(blobData);
    return cid;
};

const uploadImage = async (imageBlob: Blob) => {
    const client = new NFTStorage({ token: NFT_STORAGE_API_KEY });
    const cid = await client.storeBlob(imageBlob);
    return cid;
}

export default {
    convertURI,
    uploadJson,
    uploadImage
};
