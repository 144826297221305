import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import EtherController from 'utils/etherController';
import { userProfileStruct } from 'schemas/structure';
import userProfileServices from 'services/userProfile.services';
import { Console } from 'console';
import { Address } from 'wagmi';
import axios from 'axios';
import { CONTRACT_ADDRESS } from 'constants/contract';
import * as ethers from 'ethers';

export interface userProfileState {
    profiles: any[];
    currentProfile: any;
    currentPoaps: any[];

    latestTransactionHash: string | null;
    errorMsg: [string] | null;
    loading: boolean;
    failed: boolean;

    currentPage: number;
    pageSize: number;
    totalProfiles: number;

    skills_tag: Array<string>;
}

const initialState: userProfileState = {
    profiles: [],
    currentProfile: null,
    currentPoaps: [],

    latestTransactionHash: null,
    errorMsg: null,
    loading: false,
    failed: false,

    currentPage: 1,
    pageSize: 90,
    totalProfiles: 0,

    skills_tag: [],
};

interface createUserProfileInputType {
    userProfile: userProfileStruct;
    controller: EtherController;
}

export interface EditProfileInputType {
    name?: string;
    description: string;
    profile_image_url: string;
    socials?: {
        twitter: string;
        website: string;
        github: string;
        discord: string;
    };
}

export interface GetProfileInputType {
    address: Address;

    ensResolver?: ethers.providers.Resolver;
}

export const getMostFrequencyTags = createAsyncThunk(
    '/most-frequency-tags',
    async () => {
        const res = await userProfileServices.getSkillsTags();
        return res.data;
    }
);

export const getUserProfile = createAsyncThunk(
    '/get-profile',
    async ({ address, ensResolver }: GetProfileInputType, thunkApi) => {
        const client = axios.create({
            headers: {
                'Content-Type': 'application/json',
            },
        });
        const [res, poap] = await Promise.all([
            userProfileServices.getProfile(address),
            userProfileServices.getPoaps(address),
        ]);

        if (ensResolver) {
            const ensResponse = await fetchENSData(ensResolver);

            const socials = { ...ensResponse?.socials, ...res.data.socials };

            return {
                profile: { ...ensResponse, ...res.data, socials: socials },
                poap: poap.data,
            };
        }

        console.log('res.data.skill_objs', res.data.skill_objs);

        return { profile: res.data, poap: poap.data };
    }
);

export const listUserProfiles = createAsyncThunk(
    '/list-profiles',
    async (
        {
            query,
            tags,
            currentPage,
        }: { query?: string; tags?: string; currentPage: number },
        api
    ) => {
        const state = api.getState() as RootState;
        const res = await userProfileServices.listProfiles(
            query,
            tags,
            state.userProfile.pageSize,
            (currentPage - 1) * state.userProfile.pageSize
        );
        return {
            list: res.data.data,
            total: res.data.total,
            offset: res.data.offset,
        };
    }
);

async function fetchENSData(resolver: ethers.providers.Resolver) {
    if (resolver) {
        const [image, desc, url, _twitter, _discord, _github] =
            await Promise.all([
                resolver.getAvatar().then((res) => res?.url || ''),
                resolver.getText('description'),
                resolver.getText('url'),
                resolver.getText('com.twitter'),
                // resolver.getText('com.telegram'),
                // resolver.getText('email'),

                resolver.getText('com.discord'),
                resolver.getText('com.github'),
            ]);

        return {
            profile_image_url: image,
            description: desc,
            socials: {
                website: url,
                twitter: _twitter,
                discord: _discord,
                github: _github,
            },
        };
    } else {
        return null;
    }
}

export const createUserProfile = createAsyncThunk(
    '/create',
    async (
        { userProfile, controller }: createUserProfileInputType,
        thunkApi
    ) => {
        const dto = await controller!.signCreateUserProfile(userProfile);

        if (!dto.sign) {
            return thunkApi.rejectWithValue({ error: 'Invalid signature' });
        }

        const res = await userProfileServices.createUserProfile(dto);

        const response = res.data;
        console.log(`transactionHash: ${response}`);

        return { response };
    }
);

export const updateUserProfile = createAsyncThunk(
    '/update',
    async (dto: EditProfileInputType, thunkApi) => {
        const res = await userProfileServices.updateProfile(dto);

        const response = res.data;
        console.log(`transactionHash: ${response}`);

        return { response };
    }
);

export const userSlice = createSlice({
    name: 'userProfile',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUserProfile.pending, (state, { payload }) => {
            state.currentProfile = null;
            state.currentPoaps = [];

            state.loading = true;
            state.failed = false;
        });
        builder.addCase(getUserProfile.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
            state.currentProfile = payload.profile;

            state.currentPoaps = payload.poap;

            state.loading = false;
            state.failed = false;
        });

        builder.addCase(updateUserProfile.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(updateUserProfile.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(updateUserProfile.fulfilled, (state, { payload }) => {
            state.currentProfile = {
                ...state.currentProfile,
                ...payload.response,
            };

            state.loading = false;
            state.failed = false;
        });

        builder.addCase(listUserProfiles.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(listUserProfiles.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(listUserProfiles.fulfilled, (state, { payload }) => {
            state.profiles = payload.list;
            state.totalProfiles = payload.total;
            state.loading = false;
            state.failed = false;
        });

        builder.addCase(createUserProfile.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(createUserProfile.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(createUserProfile.fulfilled, (state, { payload }) => {
            state.latestTransactionHash = payload.response;
            state.loading = false;
            state.failed = false;
        });

        builder.addCase(getMostFrequencyTags.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(getMostFrequencyTags.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(
            getMostFrequencyTags.fulfilled,
            (state, { payload }) => {
                state.skills_tag = payload.map((val: any) => val._id);
                state.loading = false;
                state.failed = false;
            }
        );
    },
});

// Action creators are generated for each case reducer function
export const {} = userSlice.actions;

export const user = (state: RootState) => state.user;

export default userSlice.reducer;
