import React, { FC } from 'react';
import { useWindowSize } from 'react-use';
import employerImg from 'static/images/PageUseCase/sections/employer.svg';

const EmployersSection: FC<any> = ({ className = '' }) => {
    const { width } = useWindowSize();

    return (
        <div className="flex flex-row p-8">
            {width >= 1280 ? (
                <>
                    <div className="basis-1/3 flex items-center">
                        <img className="mx-auto" src={employerImg} />
                    </div>
                    <div className="basis-2/3">
                        <h1 className="text-2xl-initial-semibold text-primary-700 mb-2">
                            Employers
                        </h1>
                        <p className="text-base-initial-regular text-neutral-600">
                            Credentialproof.io offers a solution for businesses
                            to create NFT credentials for their{' '}
                            <span className="font-bold">
                                employment records
                            </span>
                            , enabling them to establish trust and reputation
                            within their industries and attract potential
                            employees or students.
                            <br />
                            <br />
                            Additionally, businesses can use Credentialproof.io
                            to create NFT credentials for their{' '}
                            <span className="font-bold">
                                training and development programs
                            </span>
                            , allowing them to track the progress and
                            achievements of their employees and provide them
                            with tangible rewards for their efforts.
                        </p>
                    </div>
                </>
            ) : (
                <div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start">
                            <img className="mx-auto" src={employerImg} />
                        </div>
                        <div className="basis-3/4 flex items-center">
                            <h1 className="text-2xl-initial-semibold text-primary-700">
                                Employers
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start"></div>
                        <div className="basis-3/4 flex items-center">
                            <p className="text-base-initial-regular text-neutral-600">
                                Credentialproof.io offers a solution for
                                businesses to create NFT credentials for their{' '}
                                <span className="font-bold">
                                    employment records
                                </span>
                                , enabling them to establish trust and
                                reputation within their industries and attract
                                potential employees or students.
                                <br />
                                <br />
                                Additionally, businesses can use
                                Credentialproof.io to create NFT credentials for
                                their{' '}
                                <span className="font-bold">
                                    training and development programs
                                </span>
                                , allowing them to track the progress and
                                achievements of their employees and provide them
                                with tangible rewards for their efforts.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default EmployersSection;
