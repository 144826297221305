import { FC } from 'react';

export interface AddNoteIconProps {
    className?: string;
    iconClass?: string;
}

const AddNoteIcon: FC<AddNoteIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 28 28" fill="none">
                <path
                    d="M5.83334 24.5C5.19167 24.5 4.64217 24.2713 4.18484 23.814C3.7275 23.3567 3.49922 22.8076 3.5 22.1667V5.83334C3.5 5.19167 3.72867 4.64217 4.186 4.18484C4.64334 3.7275 5.19245 3.49922 5.83334 3.5H22.1667C22.8083 3.5 23.3578 3.72867 23.8152 4.186C24.2725 4.64334 24.5008 5.19245 24.5 5.83334V13.65C24.1306 13.475 23.7514 13.3241 23.3625 13.1973C22.9736 13.0706 22.575 12.9784 22.1667 12.9208V5.83334H5.83334V22.1667H12.8917C12.95 22.5944 13.0426 23.0028 13.1693 23.3917C13.2961 23.7806 13.4466 24.15 13.6208 24.5H5.83334ZM5.83334 21V22.1667V5.83334V12.9208V12.8333V21ZM8.16667 19.8333H12.9208C12.9792 19.425 13.0717 19.0264 13.1985 18.6375C13.3253 18.2486 13.4661 17.8694 13.6208 17.5H8.16667V19.8333ZM8.16667 15.1667H15.2833C15.9056 14.5833 16.6009 14.0972 17.3693 13.7083C18.1378 13.3194 18.9591 13.0569 19.8333 12.9208V12.8333H8.16667V15.1667ZM8.16667 10.5H19.8333V8.16667H8.16667V10.5ZM21 26.8333C19.3861 26.8333 18.0102 26.2644 16.8723 25.1265C15.7344 23.9886 15.1659 22.6131 15.1667 21C15.1667 19.3861 15.7356 18.0102 16.8735 16.8723C18.0114 15.7344 19.3869 15.1659 21 15.1667C22.6139 15.1667 23.9898 15.7356 25.1277 16.8735C26.2656 18.0114 26.8341 19.3869 26.8333 21C26.8333 22.6139 26.2644 23.9898 25.1265 25.1277C23.9886 26.2656 22.6131 26.8341 21 26.8333ZM20.4167 24.5H21.5833V21.5833H24.5V20.4167H21.5833V17.5H20.4167V20.4167H17.5V21.5833H20.4167V24.5Z"
                    fill="#206D00"
                />
            </svg>
        </span>
    );
};

export default AddNoteIcon;
