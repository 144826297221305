import useDebounceValue from 'hooks/useDebounceValue';
import React, { FC, useEffect, useState } from 'react';
import { JsxElement } from 'typescript';

export interface TextAreaProps {
    className?: string;
    //   colorClass?: string;
    //   isLiked?: boolean;
    type?: string;
    label: string;
    placeholder: string;
    prependIcon?: JsxElement;
    appendIcon?: JsxElement;
    errorCondition?: Function;
    required?: boolean;
    disabled?: boolean;
    defaultValue? :string;
    onChangeHandle?: Function;
}

const TextArea: FC<TextAreaProps> = ({
    className,
    type,
    label,
    placeholder,
    errorCondition,
    prependIcon,
    appendIcon,
    required,
    disabled,
    defaultValue,
    onChangeHandle
}) => {
    const [_userInput, _setUserInput] = useState(defaultValue ?? '');

    const _onChangeHandle = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        _setUserInput(e.target.value);
    };

    const _debounceValue = useDebounceValue(_userInput, 500);

    useEffect(() => {
        if (onChangeHandle) {
            onChangeHandle(_debounceValue);
        }
    }, [_debounceValue]);

    return (
        <div className={className}>
            <label
                htmlFor="price"
                className="block text-sm font-medium text-neutral-900"
            >
                {label}
                {required ? (
                    <span style={{ color: '#F43F5E' }}>{' *'}</span>
                ) : null}
            </label>
            <div className="relative mt-2 rounded-md">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                    {
                        //align left stuff
                    }
                </div>
                <textarea
                    className={`block w-full rounded-md ${
                        prependIcon ? 'pl-11' : 'pl-5'
                    }  ${appendIcon ? 'pr-11' : 'pr-5'} ${
                        disabled
                            ? 'text-neutral-400 border-transparent bg-neutral-200 cursor-text'
                            : 'border-2 border-neutral-900 text-neutral-900 focus:border-neutral-900 focus:ring-primary-500 focus:ring-2'
                    } sm:text-sm ${
                        errorCondition
                            ? 'border-rose-500 focus:ring-transparent'
                            : ''
                    } resize-vertical`}
                    rows={5}
                    defaultValue={defaultValue}
                    placeholder={placeholder}
                    disabled={disabled}
                    onChange={_onChangeHandle}
                />
                <div className="absolute inset-y-0 right-0 flex items-center">
                    {
                        //align right stuff
                    }
                </div>
            </div>
        </div>
    );
};

export default TextArea;
