import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";

import PageHome from "containers/template/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import PageLoadingClaiming from "containers/PageLoading/PageLoadingClaiming";
import PageSuccessClaiming from "containers/PageSuccess/PageSuccessClaiming";
import AccountPage from "containers/template/AccountPage/AccountPage";
import PageContact from "containers/template/PageContact/PageContact";
import PageAbout from "containers/template/PageAbout/PageAbout";
import PageSubcription from "containers/template/PageSubcription/PageSubcription";

import NftDetailPage from "containers/template/NftDetailPage/NftDetailPage";
import PageConnectWallet from "containers/PageConnectWallet";

import MainLayout from "layout/MainLayout";
import Create_org from "containers/test/Create_org";
import PageBadge from "containers/PageBadge/PageBadge";
import PageClaim from "containers/PageBadge/PageClaim";
import PageAddressBadgesViewer from "containers/template/PageAddressBadgesViewer/PageAddressBadgesViewer";
import SBTGatedNetworkDetail from "containers/template/SBTGatedNetwork/SBTGatedNetworkDetail";
import SingleBadge from "containers/template/NftDetailPage/SingleBadge";
import UserProfile from "containers/template/ProfilePage/UserProfile";
import ProfileListing from "containers/template/ProfileListing/ProfileListing";
import PageJobListing from "containers/PageJob/PageJobListing";
import JobDetailPage from "containers/PageJob/PageJobDetail";
import IssueHomePage from "containers/IssuePage/IssueHomePage";
import PageClaimSubscribe from "containers/template/PageSubscribe/PageClaimSubscribe";
import PageUseCase from "containers/PageUseCase/PageUseCase";
import VerifyPage from "containers/VerifyPage/VerifyPage";


export const pages: any[] = [
  { path: "/", exact: true, element: <PageHome /> },
  { path: "/#", exact: true, element: <PageHome /> },
  { path: "*",  element: <Page404/> },

  //
  { path: "/nft-detail", element: <NftDetailPage/> },
  
  // { path: "/profile/:id", element: <AuthorPage/> },
  
//   { path: "/account", element: <AccountPage/> },

  { path: "/connect-wallet", element: <PageConnectWallet/> },
  //
  
  { path: "/contact", element: <PageContact/> },
  { path: "/about", element: <PageAbout/> },
  { path: "/use-case", element: <PageUseCase /> },

//   { path: "/subscription", element: <PageSubcription/> },
  
  { path: "loading", element: <PageLoadingClaiming /> },
  { path: "/success", element: <PageSuccessClaiming /> },
  { path: "/badges/:id/success", element: <PageSuccessClaiming /> },

  // ---
  
  { path: "/test-poc-create-org", element: <Create_org/> },

  
  { path: "/issue", element: <IssueHomePage/> },

  
  { path: "/badge/:id", element: <PageBadge/> },
  { path: "/badge/:address/:tokenId", element: <PageBadge/> },
  { path: "/claim/:address/:tokenId", element: <PageClaim/> },
  

  { path:"/user-badges/me", element: <PageAddressBadgesViewer currentAddress/> },
  { path:"/user-badges/:address", element: <PageAddressBadgesViewer/> },
  
  { path: "sbt-gated-network/:id", element: <SBTGatedNetworkDetail />},
  { path:"/my-badges/:id", element: <SingleBadge /> },
  { path:"/profile/:addr", element: <UserProfile/> },
  { path:"/profiles", element: <ProfileListing /> },
  
  { path:"/jobs", element: <PageJobListing /> },
  { path:"/jobs/:id", element: <JobDetailPage /> },

  { path:"/subscribe/:userAddress", element: <PageClaimSubscribe /> },

  // For verify ownership of address
  { path:"/verify/:address", element: <VerifyPage /> },
];



const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: pages
};

export default MainRoutes;
