import React, { FC } from 'react';

export interface ClrclePlusIconProps {
    className?: string;
    iconClass?: string;
}

const ClrclePlusIcon: FC<ClrclePlusIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg
                className={iconClass}
                viewBox="0 0 16 16"
                fill="none"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM8.75 4.75C8.75 4.33579 8.41421 4 8 4C7.58579 4 7.25 4.33579 7.25 4.75V7.25H4.75C4.33579 7.25 4 7.58579 4 8C4 8.41421 4.33579 8.75 4.75 8.75H7.25V11.25C7.25 11.6642 7.58579 12 8 12C8.41421 12 8.75 11.6642 8.75 11.25V8.75H11.25C11.6642 8.75 12 8.41421 12 8C12 7.58579 11.6642 7.25 11.25 7.25H8.75V4.75Z"
                    fill="#111827"
                />
            </svg>
        </span>
    );
};

export default ClrclePlusIcon;
