import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';
import MobileImage from 'static/images/index/phone.svg';
import BackgroundImage from 'static/images/index/hero-background.svg';
import { useWindowSize } from 'react-use';

import JobCard from 'static/images/index/job-card.svg';
import EventCard from 'static/images/index/event-card.svg';
import CertificateCard from 'static/images/index/certificate-card.svg';

import {
    useTransition,
    animated,
    config,
    useSpringRef,
    AnimatedProps,
} from '@react-spring/web';
import { SearchCircleIcon, SearchIcon } from '@heroicons/react/outline';
import { DOC_NOTION_URL } from 'constants/backend';
import useDebounceValue from 'hooks/useDebounceValue';
import { useNavigate } from 'react-router-dom';
import { useEnsAddress, useEnsResolver } from 'wagmi';

const cards = [
    {
        text: 'work experience',
        image: (width: number) => (
            <img src={JobCard} className="absolute top-54" style={{ width }} />
        ),
    },
    {
        text: 'events',
        image: (width: number) => (
            <img
                src={EventCard}
                className="absolute top-54"
                style={{ width }}
            />
        ),
    },
    {
        text: 'assessments',
        image: (width: number) => (
            <img
                src={CertificateCard}
                className="absolute top-54"
                style={{ width }}
            />
        ),
    },
];

function GetCards(width: number) {
    const [index, set] = useState(0);
    const transitions = useTransition(index, {
        key: index,
        from: { opacity: 0 },
        enter: { opacity: 1 },
        leave: { opacity: 0 },
        config: { ...config.slow, tension: 450 },
        delay: 800,
        onRest: (_a, _b, item) => {
            setTimeout(() => {
                if (index === item) {
                    set((state) => (state + 1) % cards.length);
                }
            }, 4200);
        },
    });

    return (
        <div style={{ display: 'flex' }}>
            {transitions((style, i) => (
                <animated.div
                    style={{
                        ...style,
                    }}
                >
                    {cards[i].image(width)}
                </animated.div>
            ))}
        </div>
    );
}

const InputField: FC<any> = ({ className, ...props }: any) => {
    const navigate = useNavigate();

    const possibleFeild = ['Profile'];
    const [searchItem, setSearchItem] = useState(possibleFeild[0]);

    const [disabled, setDisabled] = useState(false);

    const [_searchInput, _setSearchInput] = useState('');
    const _debounceValue = useDebounceValue(_searchInput, 500);

    const validateAddress = new RegExp('0x[0-9A-F]{40}');

    const {
        data: address,
        isLoading: ensAddressIsLoading,
        isError: ensAddressIsError,
    } = useEnsAddress({
        name: _debounceValue || '',
        chainId: 1,
    });

    const search = () => {
        if (_debounceValue.length > 0) {
            if (address != null) {
                navigate(`/profile/${address.toLowerCase()}`);
            }
            if (validateAddress.test(_debounceValue)) {
                navigate(`/profile/${_debounceValue.toLowerCase()}`);
            }
        }
    };

    useEffect(() => {
        if (
            !ensAddressIsLoading &&
            ((ensAddressIsError && validateAddress.test(_debounceValue)) ||
                address != null)
        ) {
            setDisabled(false);
        } else {
            setDisabled(true);
        }
    }, [ensAddressIsLoading, ensAddressIsError, _debounceValue]);

    return (
        <div className="relative rounded-2xl h-12">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <SearchIcon className="w-4 h-4" />
            </div>
            <input
                className="block w-full h-full rounded-2xl pl-11 pr-24 border-2 border-neutral-900 text-neutral-900 focus:border-neutral-900 focus:ring-primary-500 focus:ring-2"
                type="text"
                placeholder="ENS name or address"
                onChange={(e) => {
                    _setSearchInput(e.target.value);
                }}
            />
            <div className="absolute inset-y-0 right-0 flex items-center pr-3">
                <div className="flex gap-2">
                    <button
                        className={`py-1.5 px-3 rounded-md text-white text-sm-initial-medium ${
                            disabled
                                ? 'bg-neutral-100 text-neutral-400 pointer-events-none'
                                : 'bg-primary-700 text-white'
                        }`}
                        onClick={search}
                    >
                        Search
                    </button>
                </div>
            </div>
        </div>
    );
};

export const HeroSection: FC<any> = ({}) => {
    const { width } = useWindowSize();
    const sectionRef = useRef<HTMLDivElement>(null);
    const [expectedWidth, setExpectedWidth] = useState(600);

    useLayoutEffect(() => {
        if (sectionRef) {
            setExpectedWidth(sectionRef.current?.offsetWidth || 600);
        }
    }, [width]);

    return (
        <div className="container py-20 flex flex-col-reverse lg:flex-row w-full gap-14">
            <div className="flex-1" ref={sectionRef}>
                <div className="flex flex-col gap-6 justify-center min-h-full">
                    <div className="text-5xl-initial-bold">
                        Experience the Future of{' '}
                        <span className="text-primary-700">Credentialing</span>
                    </div>
                    <div className="text-base-initial-regular text-neutral-700">
                        Enabling professionals to create, own, and share their
                        professional footprints as SBT credentials.
                    </div>
                    {/* Todo */}
                    <div>
                        <InputField />
                    </div>
                    <div>
                        <a
                            className="text-sm-initial-medium underline text-neutral-600"
                            href={DOC_NOTION_URL}
                        >
                            Read the docs
                        </a>
                    </div>
                </div>
            </div>
            <div className="flex-1">
                {GetCards(expectedWidth)}
                <img
                    className="absolute"
                    src={MobileImage}
                    style={{ width: expectedWidth }}
                />
                <img src={BackgroundImage} style={{ width: expectedWidth }} />
            </div>
        </div>
    );
};
