import React from "react";
import { BrowserRouter, Routes, Route, useRoutes } from "react-router-dom";
import { Page } from "./types";
import MainRoutes from "./MainRoutes";
import DashboardRoutes from "./DashboardRoutes";
import IssueRoutes from "./IssueRoute";



export default function ThemeRoutes() {
  return useRoutes([IssueRoutes, MainRoutes, DashboardRoutes]);
}
