import React, { FC } from 'react';

export interface ShieldTickIconProps {
    className?: string;
    iconClass?: string;
}

const ShieldTickIcon: FC<ShieldTickIconProps> = ({
    className = 'ml-1',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg
                className={iconClass}
                viewBox="0 0 24 24"
                fill="none"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12.6017 0.531316C12.2642 0.211729 11.7358 0.211729 11.3983 0.531316C9.04544 2.75961 5.87062 4.125 2.375 4.125C2.31939 4.125 2.26385 4.12466 2.20841 4.12397C1.82648 4.11922 1.48561 4.36276 1.36632 4.72561C0.885018 6.1895 0.625 7.75278 0.625 9.37506C0.625 16.3074 5.3664 22.1301 11.7819 23.7814C11.925 23.8182 12.075 23.8182 12.2181 23.7814C18.6336 22.1301 23.375 16.3074 23.375 9.37506C23.375 7.75278 23.115 6.1895 22.6337 4.72561C22.5144 4.36276 22.1735 4.11922 21.7916 4.12397C21.7361 4.12466 21.6806 4.125 21.625 4.125C18.1294 4.125 14.9546 2.75961 12.6017 0.531316ZM15.5686 10.031C15.9309 9.71122 15.9654 9.15828 15.6456 8.79597C15.3259 8.43365 14.7729 8.39918 14.4106 8.71896C12.8263 10.1173 11.5417 11.8485 10.6665 13.804L9.11872 12.2563C8.77701 11.9146 8.22299 11.9146 7.88128 12.2563C7.53957 12.598 7.53957 13.152 7.88128 13.4937L10.373 15.9854C10.5868 16.1992 10.8958 16.2875 11.1902 16.2189C11.4847 16.1503 11.7229 15.9346 11.8202 15.6483C12.5641 13.4599 13.8706 11.5297 15.5686 10.031Z"
                    fill="#60E829"
                />
            </svg>
        </span>
    );
};

export default ShieldTickIcon;
