import React, { FC } from 'react';
import star from 'static/images/icons/star.svg';
import { KeyIcon, LinkIcon, SearchIcon } from '@heroicons/react/outline';
import { OwnershipIcon } from 'components/Icon/outline';
import TextButton from 'components/Buttons/TextButton';

const SDK_NOTION_PAGE = 'https://ergonglobal.notion.site/SDK-integration-3a69ad6048f6432686ec40a0fca4274e'

const data = [
    {
        title: (
            <>
                <SearchIcon className="h-7 w-7" />
                <span>Discoverability</span>
            </>
        ),
        content: 'Your users will be searchable based on their identifiers.',
    },
    {
        title: (
            <>
                <LinkIcon className="h-7 w-7" />
                <span>Soulbound</span>
            </>
        ),
        content: 'The NFT proof should be the best form of proof',
    },
    {
        title: (
            <>
                <OwnershipIcon iconClass="h-7 w-7 fill-primary-900" />
                <span>Data Ownership</span>
            </>
        ),
        content: (
            <p>
                Own your own data.
                <br />
                Even if we are gone, the proofs won't.
            </p>
        ),
    },
    {
        title: (
            <>
                <KeyIcon className="h-7 w-7" />
                <span>Partial Encryption</span>
            </>
        ),
        content:
            'You decide what to show publicly, what to share privately, and who to share with.',
    },
];

const AdvantageSection: FC<any> = ({}) => {
    return (
        <div className="container mt-20 mb-20 flex flex-col gap-10">
            <div className="relative">
                <h1 className="bg-white border-2 border-primary-900 py-5 px-12 rounded-2xl text-3xl-initial-bold text-center text-primary-900 hard-shadow-8">
                    Build web3 identity with CredentialProof.io
                </h1>
                <img className="absolute -top-[8px] -right-[8px]" src={star} />
                <img
                    className="absolute -bottom-[8px] -left-[8px]"
                    src={star}
                />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-4 gap-10 mt-8">
                {data.map(({ title, content }, index) => (
                    <div className="">
                        <div className="flex flex-col gap-5">
                            <div className="flex gap-2 items-center text-2xl-initial-semibold text-primary-900">
                                {title}
                            </div>
                            <div className="text-sm-initial-regular text-primary-900">
                                {content}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="hidden lg:flex gap-6 justify-center">
                <TextButton
                    buttonType="primary"
                    size="xl"
                    text="Download SDK"
                    onClick={() => window.open(SDK_NOTION_PAGE)}
                />
                <TextButton
                    buttonType="tertiary"
                    size="xl"
                    text="Read the docs"
                    onClick={() => window.open(SDK_NOTION_PAGE)}
                />
            </div>
        </div>
    );
};

export default AdvantageSection;
