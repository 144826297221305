export interface IToCPTemplate {
    sender: string;
    message: string;
}

const toCPTemplate = (
    params: IToCPTemplate
) => {
    const template = `${params.message}

Best regards,
${params.sender}`;

    return encodeURIComponent(template);
};
export default toCPTemplate;
