import React, { FC, useEffect, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import { ChevronLeftIcon, ArrowRightIcon } from '@heroicons/react/solid';
import { useNavigate, useParams } from 'react-router-dom';
import { JobDetailSummaryCard } from './component/JobDetailSummaryCard';
import BoxArrowUpRightIcon from 'components/Icon/BoxArrowUpRightIcon';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { getJobDetail } from 'store/slices/jobSlice';
import { getTimeAgo } from 'utils/dateFormatter';
import { Helmet } from 'react-helmet';

export interface PageJobDetailProps {}

const PageJobDetail: FC<PageJobDetailProps> = ({}) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch<AppDispatch>();

    const jobState = useSelector((root: RootState) => root.job);
    const jobDetail = useSelector((root: RootState) => root.job.job_detail);

    useEffect(() => {
        console.log(id);
        dispatch(getJobDetail(id));
    }, []);

    useEffect(() => {
        if (!jobState.isLoading) {
            if (jobState.isError) {
                navigate('/jobs');
            } else {
                console.debug(jobDetail);
            }
        }
    }, [jobState.isLoading]);

    useEffect(()=>{
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <div className="container mt-8 mb-20 scroll-smooth">
                <TextButton
                    size="sm"
                    text="Back to jobs"
                    buttonType="neutral"
                    prependIcon={
                        <ChevronLeftIcon className="w-5 h-5 relative -left-1" />
                    }
                    className="job_return_page_button"
                    onClick={() => navigate('/jobs')}
                />
                <div className="relative">
                    {jobState.isLoading ? (
                        <></>
                    ) : (
                        <div className="job_grid mt-20 lg:mt-24">
                            <div
                                className="job_grid_content"
                                id="job_detail_header"
                            >
                                <div className="text-xs-initial-regular lg:text-sm-initial-regular">
                                    &#8987;{' '}
                                    {getTimeAgo(Date.parse(jobDetail.date) / 1000)}
                                </div>
                                <div className="mt-3 text-2xl-initial-semibold text-primary-700">
                                    {jobDetail.title}
                                </div>
                                <div className="mt-3 flex gap-2 flex-wrap">
                                    {jobDetail.tags?.map((tag: string) => {
                                        return (
                                            <TextButton
                                                text={tag}
                                                size="xs"
                                                buttonType="neutral"
                                                className="rounded-full whitespace-nowrap pointer-events-none"
                                            />
                                        );
                                    })}
                                </div>
                                <div className="block lg:hidden mt-3 ">
                                    <JobDetailSummaryCard
                                        image={jobDetail.company_img}
                                        name={jobDetail.company}
                                        location={jobDetail.location}
                                        applyLink={jobDetail.apply_url}
                                    />
                                </div>
                                <div className="gray_linebreak mt-8" />
                                <div
                                    className="mt-8"
                                    dangerouslySetInnerHTML={{
                                        __html: jobDetail.description,
                                    }}
                                />
                                <div className="mt-8 w-fit">
                                    <TextButton
                                        isDisabled={jobDetail.apply_url ? false : true}
                                        text="Apply now"
                                        buttonType="primary"
                                        size="sm"
                                        textClassName="justify-center"
                                        appendIcon={
                                            <BoxArrowUpRightIcon className="block" />
                                        }
                                        onClick={() => {
                                            window.open(jobDetail.apply_url, '_blank');
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="job_grid_card hidden lg:block">
                                <JobDetailSummaryCard
                                    image={jobDetail.company_img}
                                    name={jobDetail.company}
                                    location={jobDetail.location}
                                    applyLink={jobDetail.apply_url}
                                />
                            </div>
                            {/* <div className="gray_linebreak mt-8" /> */}
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default PageJobDetail;
