import React, { FC, useState, useEffect } from 'react';
import { Disclosure } from '@headlessui/react';
import { AttributeObject, EventBadgeMetadata } from 'schemas/metadata';
import { EventResponse } from 'schemas/response';
import LitHelper from 'utils/LitHelper';
import { AppDispatch, RootState } from 'store/store';
import { useDispatch, useSelector } from 'react-redux';
import { workBadgesSliceFunction } from 'store/slices/workBadgesSlice';
import { useWeb3 } from 'utils/useWeb3';
import { getProvider } from 'constants/blockchain';
import unlockImg from 'static/images/lock-open@2x.png';

import {
    ChevronUpIcon,
    LockClosedIcon,
    ChartBarIcon,
} from '@heroicons/react/solid';
import TextButton from 'components/Buttons/TextButton';
import RequestUserAccessModal from 'components/RequestUserAccessModal';
import { SalaryChart } from './SalaryChart';
import { gql, useQuery } from '@apollo/client';
import { BigNumber, constants } from 'ethers';
import { WorkBadges__factory } from 'typechain/factories/WorkBadges__factory';
import { useNavigate } from 'react-router-dom';
import { subscribeSliceFunctions } from 'store/slices/subscribeSlice';
// import { createShareCode } from 'store/slices/badgesSlice';

const GET_REQUESTS = gql`
    query User($userAddress: ID!) {
        user(id: $userAddress) {
            id
            profile {
                id
            }
        }
    }
`;

export default function WorkBadgeAccordion({
    detailInfo,
    company,
}: {
    detailInfo: any;
    company?: any;
}) {
    const dispatch = useDispatch<AppDispatch>();

    const metadata = detailInfo.metadata;
    const [attributes, setAttributes] = useState<{ [key: string]: any }>({});

    const lit = LitHelper;
    const encryptedText = (metadata as any).encrypted_attributes;
    const encryptedSymmetricKey = (metadata as any).encrypted_symmetric_key;
    const encryptedCondition = JSON.parse(
        (metadata as any).encrypted_conditions
    );
    const [decryptedText, setDecryptedText] = useState('');

    const { controller, address } = useWeb3()!;

    const [profileId, setProfileId] = useState(-1);

    const ownerAddress =
        useSelector((root: RootState) => root.workBadges.ownerAddress) || '';

    // const [salary, setSalary] = useState(0);
    // const [salaryUnit, setSalaryUnit] = useState("HKD");
    const [salaryObj, setSalaryObj] = useState(null);

    const orgId = useSelector(
        (root: RootState) => root.workBadges.organisatinoId
    );

    const allowViewing = useSelector(
        (root: RootState) => root.workBadges.isAllowedToViewPrivateInfo
    );

    const isRequestingPermission = useSelector(
        (root: RootState) => root.workBadges.requesting
    );
    const workInfo = useSelector((root: RootState) => root.workBadges.workInfo);

    const { loading, error, data, refetch } = useQuery(GET_REQUESTS, {
        variables: { userAddress: ownerAddress.toLowerCase() },
    });

    React.useEffect(() => {
        console.log('profileId: ', data?.user?.profile?.id);
        setProfileId(parseInt(data?.user?.profile?.id) || -1);
    }, [data]);

    useEffect(() => {
        dispatch(
            workBadgesSliceFunction.isAllowedToViewPrivateInfo({
                contractAddress: detailInfo.contractAddress,
                tokenId: detailInfo.tokenId,
                userAddress: address,
                provider: getProvider(),
            })
        );
    }, [address]);

    useEffect(() => {
        console.log('detailInfo', detailInfo);
        let newAttributes: { [key: string]: string } = {};
        if (detailInfo?.metadata?.attributes) {
            detailInfo.metadata.attributes.forEach((value: AttributeObject) => {
                newAttributes[value.trait_type] = value.value;
            });

            console.log('detailInfo newAttributes', newAttributes);
        }
        setAttributes(newAttributes);

        dispatch(
            workBadgesSliceFunction.getOrganisationId({
                contractAddress: detailInfo.contractAddress,
                provider: getProvider(),
            })
        );

        dispatch(
            workBadgesSliceFunction.getWorkInfo({
                contractAddress: detailInfo.contractAddress,
                tokenId: detailInfo.tokenId,
                provider: getProvider(),
            })
        );

        const decryptData = async () => {
            console.log('start decrypting', encryptedText);
            console.log('start decrypting', encryptedSymmetricKey);

            console.log('start decrypting', encryptedCondition, metadata);
            console.log('start decrypting', metadata);
            let text = '';
            if (detailInfo && encryptedText && encryptedSymmetricKey) {
                console.log('start decrypting 2!!!');

                // lit.ownerCondition
                console.warn('condition: ', encryptedCondition);

                try {
                    text = await lit.decryptText(
                        encryptedText,
                        encryptedSymmetricKey,
                        encryptedCondition
                    );

                    const decryptedData: Array<any> = JSON.parse(text);

                    for (let obj of decryptedData) {
                        if (obj['trait_type'] == 'salary') {
                            // setSalary(obj['value']);
                            // setSalaryUnit(obj['unit']);

                            setSalaryObj(obj);
                        }
                    }
                } catch (error) {
                    console.warn(error);
                }
            }
            // alert(text);
            console.log('lit result', text);
            setDecryptedText(text);
        };
        decryptData();
    }, [detailInfo, address]);

    const calDuration = React.useMemo(() => {
        if (workInfo) {
            console.log('calDuration', workInfo.start_time.toNumber());
            const start_time = new Date(
                workInfo.start_time.toNumber() * 1000
            ).toLocaleDateString('en-GB', { timeZone: 'Asia/Hong_Kong' });
            const end_time =
                workInfo.end_time.toNumber() === 0
                    ? 'Now'
                    : new Date(
                          workInfo.end_time.toNumber() * 1000
                      ).toLocaleDateString('en-GB', {
                          timeZone: 'Asia/Hong_Kong',
                      });

            return `${start_time} - ${end_time}`;
        }
    }, [workInfo?.start_time, workInfo?.end_time]);

    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const unlockPrivateData = () => {
        dispatch(
            workBadgesSliceFunction.requestWorkAccess({
                profileId: profileId,
                userAddress: address,
                controller: controller!,
                provider: getProvider(),
            })
        );

        setShowModal(false);
    };

    const renderRequestInformation = () => {
        return (
            <div className="bg-white w-100">
                <div className="py-6">
                    <div className="flex justify-center">
                        <LockClosedIcon className="h-5 w-5" />
                    </div>
                    <div className="text-sm-initial-semibold flex justify-center mt-2">
                        More information available including
                    </div>
                    <div className="text-sm-initial-medium flex justify-center text-primary-700">
                        Salary, Direct reporting line, Achievements, Performance
                    </div>
                    <div className="flex justify-center mt-6">
                        <TextButton
                            text={
                                isRequestingPermission
                                    ? 'Requesting Access'
                                    : 'Request Access'
                            }
                            buttonType="primary"
                            size="sm"
                            isDisabled={isRequestingPermission}
                            className="text-sm-initial-medium text-neutral-900"
                            onClick={() => setShowModal(true)}
                        />
                    </div>
                </div>
            </div>
        );
    };

    const ChartLoadingPlaceholder = () => {
        return (
            <div role="status" className="space-y-8 animate-pulse w-full ">
                <div className="space-y-2">
                    <div className="animate-pulse w-1/4 h-3 bg-gray-300"></div>
                    <div className="animate-pulse w-1/3 h-3 bg-gray-300"></div>
                </div>
                <div className="flex items-center justify-center w-full h-60 bg-gray-300 rounded dark:bg-gray-700">
                    <ChartBarIcon className="w-12 h-12 text-gray-200" />
                </div>
            </div>
        );
    };

    const shareButtonHandler = () => {
        if (controller) {
            dispatch(
                subscribeSliceFunctions.createShareCode({
                    controller: controller,
                    badgesInfo: {
                        contractAddress: detailInfo.contractAddress,
                        tokenId: detailInfo.tokenId,
                    },
                })
            );
        }
    };

    const renderShowingPrivateData = () => {
        const start_time = new Date(
            (workInfo?.start_time.toNumber() || 0) * 1000
        ).toLocaleDateString('en-GB', { timeZone: 'Asia/Hong_Kong' });
        const end_time = new Date(
            (workInfo?.end_time.toNumber() || 0) * 1000
        ).toLocaleDateString('en-GB', { timeZone: 'Asia/Hong_Kong' });

        const labels = salaryObj?.['date']
            ? (salaryObj?.['date'] as number[]).map((d) =>
                  new Date(d * 1000).toLocaleDateString('en-GB', {
                      timeZone: 'Asia/Hong_Kong',
                  })
              )
            : [start_time, end_time];
        const values =
            (Array.isArray(salaryObj?.['value'])
                ? salaryObj?.['value']
                : [start_time, end_time].map(
                      () => salaryObj?.['value'] || 0
                  )) || [];

        // alert(workInfo?.start_time);
        return salaryObj ? (
            <>
                <span className="text-xs-initial-medium text-neutral-400 flex">
                    Contract Term
                    <img
                        src={unlockImg}
                        className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1"
                    />
                </span>
                <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
                    {salaryObj?.['term']}
                </span>

                <br />
                <span className="text-xs-initial-medium text-neutral-400 flex">
                    Salary ({salaryObj?.['unit']})
                    <img
                        src={unlockImg}
                        className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1"
                    />
                </span>
                <SalaryChart
                    chartData={{
                        labels: labels,
                        values: values,
                    }}
                />
                <span className="text-xs-initial-medium text-neutral-400 justify-end flex mt-2">
                    Retrieved from BambooHR
                </span>
                <br />
                {/* <span className="text-xs-initial-medium text-neutral-400 flex">
          Achievements
          <LockOpenIcon className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1" />
        </span> */}
                {/* <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
          <div className="flex">
            <span className="justify-start">
              Click rate of a redesigned CTA button
            </span>
            <span className="ml-auto">+41.0%</span>
          </div>
        </span> */}
                {/* <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
          <div className="flex">
            <span className="justify-start">Conversion rate</span>
            <span className="ml-auto">+20.7%</span>
          </div>
        </span> */}
                {/* <span className="text-xs-initial-medium text-neutral-400 justify-end flex mt-2">
          Retrieved from Google Analytics
        </span> */}
                <br />
                {/* <span className="text-xs-initial-medium text-neutral-400 flex">
          Performance
          <LockOpenIcon className="h-2.5 w-2.5 text-primary-700 mt-0.5 ml-1" />
        </span>
        <span className="text-base-initial-medium text-neutral-800 dark:text-neutral-100 line-clamp-1 mb-2">
          <div className="flex">
            <span className="justify-start">Task completion</span>
            <span className="ml-auto">99.7%</span>
          </div>
        </span>
        <span className="text-xs-initial-medium text-neutral-400 justify-end flex mt-2">
          Retrieved from Jira
        </span> */}
                <br />
            </>
        ) : (
            <ChartLoadingPlaceholder />
        );
    };

    const renderPrivateDataSection = () => {
        if (!ownerAddress) return null;

        return allowViewing
            ? renderShowingPrivateData()
            : renderRequestInformation();
    };

    const isOwner =
        !!ownerAddress &&
        ownerAddress.toLocaleLowerCase() === address.toLocaleLowerCase();

    console.log('attributes', attributes);

    return (
        <div className="w-full rounded-2xl">
            {/* Public data */}

            <Disclosure defaultOpen>
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                            <span className="text-base-initial-semibold">
                                Attributes
                            </span>
                            <ChevronUpIcon
                                className={`${
                                    open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-neutral-500`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel
                            className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
                            as="p"
                        >
                            <span className="text-xs-initial-medium">
                                Job Title
                            </span>
                            <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                {detailInfo.metadata.name}
                            </span>
                            <br />

                            <span className="text-xs-initial-medium">
                                Organisation
                            </span>
                            <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                {company?.name || orgId || ''}
                            </span>
                            <br />

                            <span className="text-xs-initial-medium">
                                Duration
                            </span>
                            <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                {calDuration}
                            </span>
                            <br />

                            {attributes.seniority && (
                                <>
                                    <span className="text-xs-initial-medium">
                                        Seniority
                                    </span>
                                    <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                        {attributes.seniority || ''}
                                    </span>
                                    <br />
                                </>
                            )}

                            {attributes.industry && (
                                <>
                                    <span className="text-xs-initial-medium">
                                        Industry
                                    </span>
                                    <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                        {attributes.industry || ''}
                                    </span>
                                    <br />
                                </>
                            )}

                            {attributes.employment_type && (
                                <>
                                    <span className="text-xs-initial-medium">
                                        Employment type
                                    </span>
                                    <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                        {attributes.employment_type || ''}
                                    </span>
                                    <br />
                                </>
                            )}

                            {attributes.workspace && (
                                <>
                                    <span className="text-xs-initial-medium">
                                        Workplace
                                    </span>
                                    <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                        {attributes.workspace || ''}
                                    </span>
                                </>
                            )}

                            <br />
                            {/* <div className="h-2.5 bg-gray-200 rounded-full dark:bg-gray-700 w-48 mb-4 animate-pulse" ></div> */}
                            {renderPrivateDataSection()}
                            {ownerAddress.toLocaleLowerCase() ==
                                address.toLocaleLowerCase() && (
                                <TextButton
                                    className='w-full mt-4'
                                    textClassName='justify-center'
                                    size="sm"
                                    text="Share private data"
                                    buttonType="secondary"
                                    onClick={shareButtonHandler}
                                />
                            )}
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* skills info */}
            <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                            <span className="text-base-initial-semibold">
                                Related Skills
                            </span>
                            <ChevronUpIcon
                                className={`${
                                    open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-neutral-500`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
                            <span className="text-xs-initial-medium">
                                Skills
                            </span>
                            <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 w-full flex flex-wrap">
                                {(attributes?.skills as Array<any>)?.map(
                                    (skill) => (
                                        <span
                                            className={`text-chip m-2 cursor-pointer mr-2 pt-[3px] whitespace-nowrap ${''}`}
                                            onClick={() => {}}
                                        >
                                            <span className="relative mb-2">
                                                {skill.name}
                                            </span>
                                        </span>
                                    )
                                )}
                            </span>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {/* badges info */}
            <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
                {({ open }) => (
                    <>
                        <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                            <span className="text-base-initial-semibold">
                                Transaction Details
                            </span>
                            <ChevronUpIcon
                                className={`${
                                    open ? 'transform rotate-180' : ''
                                } w-5 h-5 text-neutral-500`}
                            />
                        </Disclosure.Button>
                        <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
                            <span className="text-xs-initial-medium">
                                Contract Address
                            </span>
                            <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                                {detailInfo.contractAddress}
                            </span>

                            <br />
                            <span className="text-xs-initial-medium">
                                Token ID
                            </span>
                            <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100">
                                {detailInfo.tokenId}
                            </span>
                        </Disclosure.Panel>
                    </>
                )}
            </Disclosure>
            {isOwner && (
                <Disclosure as="div" className="mt-5 md:mt-8">
                    {({ open }) => (
                        <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
                                <span className="text-base-initial-semibold">
                                    Badge Management
                                </span>
                                <ChevronUpIcon
                                    className={`${
                                        open ? 'transform rotate-180' : ''
                                    } w-5 h-5 text-neutral-500`}
                                />
                            </Disclosure.Button>
                            <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
                                <div className="flex justify-center mt-6">
                                    <TextButton
                                        text={'Burn badge'}
                                        buttonType="secondary"
                                        size="sm"
                                        isDisabled={isRequestingPermission}
                                        className="text-sm-initial-medium text-neutral-900"
                                        onClick={async () => {
                                            const signer = controller
                                                ?.getProvider()
                                                ?.getSigner();
                                            if (!signer) {
                                                return;
                                            }
                                            const txn =
                                                await WorkBadges__factory.connect(
                                                    detailInfo.contractAddress,
                                                    signer
                                                ).transferFrom(
                                                    ownerAddress,
                                                    constants.AddressZero,
                                                    detailInfo.tokenId
                                                );
                                            const result = await txn.wait();
                                            console.log(result);
                                            navigate('/user-badges/me');
                                        }}
                                    />
                                </div>
                            </Disclosure.Panel>
                        </>
                    )}
                </Disclosure>
            )}
            {/* encrypted data */}
            {/* <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span className="text-base-initial-semibold">
                Encrypted Information
              </span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
              <span className="text-xs-initial-medium">Encrypted Content</span>
              <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100 line-clamp-1">
                {(metadata as any).encrypted_attributes || ""}
              </span>

              <br />
              <span className="text-xs-initial-medium">Decrypted Content</span>
              <span className="text-base-initial-medium text-neutral-900 dark:text-neutral-100">
                {decryptedText}
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure> */}
            <RequestUserAccessModal
                show={showModal}
                metadata={detailInfo.metadata}
                dateRange={calDuration}
                onCloseModal={() => setShowModal(false)}
                ownerAddress={ownerAddress}
                onShowPrivateData={unlockPrivateData}
            />
        </div>
    );
}
