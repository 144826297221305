import React, { ReactElement, ReactNode, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Outlet } from 'react-router-dom';

import Footer from "shared/Footer/Footer";
import SiteHeader from "containers/SiteHeader";
import SIWEModel from "components/SIWEModel";
import MobileLoginModal from "components/LoginModal";
import MobileLoginModalV2 from "components/Modal/LoginModalV2";

const MainLayout = ({children}:{children?: ReactNode}) => {

  return (
    <>
        <SiteHeader />
            <React.Suspense fallback={<></>}>
                <Outlet />
            </React.Suspense>   
        <Footer />
        <MobileLoginModalV2 />
        <SIWEModel />
    </>
  );
};

export default MainLayout;
