import React, { FC, useEffect, useState } from 'react';
import { useWeb3 } from 'utils/useWeb3';
import { ethers } from 'ethers';

import LinkedinIcon from 'static/images/socials/linkedin.svg';
import FacebookIcon from 'static/images/socials/facebook.svg';
import DiscordIcon from 'static/images/socials/discord.svg';
import TwitterIcon from 'static/images/socials/twitter.svg';
import WebsiteIcon from 'static/images/socials/website.svg';
import GithubIcon from 'static/images/socials/github.svg';
import TextButton from 'components/Buttons/TextButton';
import Avatar from 'shared/Avatar/Avatar';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from 'store/hooks';
import ProfileCardActionBtn from './ProfileCardActionBtn';
import EditProfileModal from '../Modals/EditProfileModal';
import GradientAvatars from 'shared/Avatar/GradientAvatars';
import { useEnsName } from 'wagmi';

export interface ProfileCardProps {
    className?: string;
}

const ProfileCard: FC<ProfileCardProps> = ({ className }) => {
    const { address: myAddress } = useWeb3()!;

    // const { address: addr } = useAccount()

    const { addr } = useParams();

    const profile = useAppSelector((state) => state.userProfile.currentProfile);

    const address = (addr?.toLowerCase() as `0x${string}`) || '';

    const navigate = useNavigate();

    // const { data: ensAvatar } = useEnsAvatar({ address, chainId: 1 });
    const { data: ensName } = useEnsName({ address, chainId: 1 });
    const displayName =
        profile?.name || ensName ||
        (address || '').slice(0, 10) +
            '...' +
            address.slice(address.length - 4, address.length);

    const [openEditModal, setOpenEditModal] = useState(false);

    const handleAddressBtn = (content: string) => {
        navigator.clipboard.writeText(content);
    };

    useEffect(() => {
        console.log('getting username...');
    }, []);

    const renderTrigger = () => {
        return null;
    };

    return (
        <>
            <div className={className + ' user_profile_top_card'}>
                <div className="lg:flex">
                    {profile?.profile_image_url ? (
                        <img
                            src={profile?.profile_image_url}
                            className="w-32 h-32 lg:w-52 lg:h-52 rounded-[20px]"
                        />
                    ) : (
                        <GradientAvatars
                            className="w-32 h-32 lg:w-52 lg:h-52 rounded-[20px]"
                            seed={address}
                        />
                    )}
                    <div className="lg:ml-10 flex-1">
                        <div className="flex justify-between">
                            <div className="text-neutral-900 text-2xl-initial-bold mt-6 lg:mt-4 lg:my-auto">
                                {displayName}
                            </div>
                        </div>
                        <div className="flex mt-[14px] lg:justify-start">
                            <div className="text-neutral-500 text-sm-initial-regular">
                                {profile?.name ? (
                                    (address || '').slice(0, 10) +
                                    '...' +
                                    address.slice(
                                        address.length - 4,
                                        address.length
                                    )
                                ) : (
                                    <></>
                                )}
                            </div>
                        </div>
                        <div className="mt-[18px]">
                            <div className="text-neutral-700 text-sm-initial-regular mb-10">
                                {profile?.description}
                            </div>
                        </div>
                        <div className="absolute bottom-6">
                            {profile?.socials?.website ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="cursor-pointer"
                                    imgUrl={WebsiteIcon}
                                    onClick={() =>
                                        window.open(profile?.socials.website)
                                    }
                                />
                            ) : null}
                            {profile?.socials?.twitter ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="ml-3 cursor-pointer"
                                    imgUrl={TwitterIcon}
                                    onClick={() =>
                                        window.open(
                                            'https://www.twitter.com/' +
                                                profile?.socials.twitter
                                        )
                                    }
                                />
                            ) : null}

                            {profile?.socials?.discord ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="ml-3 cursor-pointer"
                                    imgUrl={DiscordIcon}
                                    onClick={() =>
                                        window.open('https://www.discord.com')
                                    }
                                />
                            ) : null}
                            {profile?.socials?.github ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="ml-3 cursor-pointer"
                                    imgUrl={GithubIcon}
                                    onClick={() =>
                                        window.open(
                                            'https://www.github.com/' +
                                                profile?.socials.github
                                        )
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                <div className="absolute right-5 lg:right-6 top-5 lg:top-6 flex">
                    <div className="mt-0">
                        {address === myAddress && (
                            <TextButton
                                size="md"
                                text={
                                    <div className="text-xs lg:text-sm font-medium">
                                        Edit
                                    </div>
                                }
                                buttonType="primary"
                                className="h-7 lg:h-9 px-2.5 lg:px-4 py-1 lg:py-1.5"
                                onClick={() => setOpenEditModal(true)}
                            />
                        )}
                    </div>
                    <div className="ml-4 relative -top-[7px]">
                        <ProfileCardActionBtn address={address} />
                    </div>
                </div>
            </div>
            <EditProfileModal
                defaultValue={profile}
                openEditModal={openEditModal}
                setOpenEditModal={setOpenEditModal}
            />
        </>
    );
};

export default ProfileCard;
