import React, { FC } from 'react';

export interface WalletCardIconProps {
    className?: string;
    iconClass?: string;
}

const WalletCardIcon: FC<WalletCardIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 18 12" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 0C0.671573 0 0 0.671573 0 1.5V2H18V1.5C18 0.671573 17.3284 0 16.5 0H1.5ZM18 4.5H0V10.5C0 11.3284 0.671573 12 1.5 12H16.5C17.3284 12 18 11.3284 18 10.5V4.5ZM2 9.25C2 8.83579 2.33579 8.5 2.75 8.5H4.25C4.66421 8.5 5 8.83579 5 9.25C5 9.66421 4.66421 10 4.25 10H2.75C2.33579 10 2 9.66421 2 9.25ZM6.75 8.5C6.33579 8.5 6 8.83579 6 9.25C6 9.66421 6.33579 10 6.75 10H10.25C10.6642 10 11 9.66421 11 9.25C11 8.83579 10.6642 8.5 10.25 8.5H6.75Z"
                    fill="#1F2937"
                />
            </svg>
        </span>
    );
};

export default WalletCardIcon;
