import React, { FC } from 'react';

export interface LightBulbIconProps {
    className?: string;
    iconClass?: string;
}

const LightBulbIcon: FC<LightBulbIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 22 30" fill="none">
                <path
                    d="M11 22.999V15.999M11 15.999C11.6906 15.999 12.3608 15.9116 13 15.7471M11 15.999C10.3094 15.999 9.63924 15.9116 9 15.7471M14 25.7183C13.0283 25.9026 12.0254 25.9991 11 25.9991C9.97461 25.9991 8.97175 25.9026 8 25.7183M13 28.895C12.3427 28.9638 11.6755 28.999 11 28.999C10.3245 28.999 9.65727 28.9638 9 28.895M14 22.999V22.7435C14 21.4329 14.8777 20.3124 16.0113 19.6547C18.9941 17.924 21 14.6958 21 10.999C21 5.47618 16.5228 0.999023 11 0.999023C5.47715 0.999023 1 5.47618 1 10.999C1 14.6958 3.00594 17.924 5.98873 19.6547C7.1223 20.3124 8 21.4329 8 22.7435V22.999"
                    stroke="#60E829"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </span>
    );
};

export default LightBulbIcon;
