import React, { FC } from 'react';

export interface FootprintIconProps {
    className?: string;
    iconClass?: string;
}

const FootprintIcon: FC<FootprintIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 28 28" fill="none">
                <path
                    d="M4.66683 21H11.0835V22.4584C11.0835 23.3093 10.7455 24.1253 10.1438 24.727C9.54212 25.3287 8.72607 25.6667 7.87516 25.6667C7.02426 25.6667 6.20821 25.3287 5.60653 24.727C5.00485 24.1253 4.66683 23.3093 4.66683 22.4584V21ZM9.40116 16.3334L9.45366 16.1782C10.3485 13.7224 10.5002 13.2662 10.5002 12.8334C10.5002 11.9584 10.2633 10.9165 9.88416 10.1815C9.60183 9.63321 9.4035 9.47338 9.3335 9.47338C7.84016 9.47338 6.41683 11.0647 6.41683 12.8334C6.41683 13.9522 6.50433 14.9019 6.68166 16.051L6.726 16.3334H9.40116ZM9.3335 7.14004C11.6668 7.14004 12.8335 10.5 12.8335 12.8334C12.8335 14 12.2502 15.1667 11.6668 16.9167L11.0835 18.6667H4.66683C4.66683 17.5 4.0835 15.75 4.0835 12.8334C4.0835 9.91671 6.4145 7.14004 9.3335 7.14004ZM23.3965 16.4477L23.1433 17.8839C23.0749 18.3031 22.9239 18.7046 22.6991 19.065C22.4743 19.4254 22.1802 19.7376 21.8338 19.9834C21.4874 20.2293 21.0957 20.4039 20.6813 20.4971C20.2669 20.5904 19.8381 20.6004 19.4198 20.5267C19.0014 20.4529 18.6019 20.2968 18.2444 20.0675C17.8869 19.8381 17.5785 19.54 17.3371 19.1905C17.0957 18.841 16.9261 18.4471 16.8381 18.0315C16.7501 17.6159 16.7456 17.187 16.8247 16.7697L17.0767 15.3347L23.3965 16.4477ZM22.1715 11.4929C22.364 10.9095 22.5227 10.4499 22.525 10.4429C22.7607 9.67521 22.9462 8.92388 23.0908 8.10138C23.3977 6.35954 22.2718 4.54538 20.8018 4.28638C20.733 4.27471 20.5102 4.39721 20.1368 4.88721C19.6352 5.54638 19.2222 6.53221 19.0693 7.39205C19.0017 7.77704 19.0122 7.86338 19.3843 10.0322C19.4368 10.3297 19.48 10.5992 19.5197 10.8687L19.543 11.0297L22.1715 11.4929ZM21.2078 1.98921C24.0825 2.49555 25.8955 5.63388 25.3892 8.50621C24.8828 11.3785 24.0043 13.0014 23.8025 14.1505L17.4827 13.0364L17.212 11.2105C16.9413 9.38588 16.5703 8.13638 16.7722 6.98721C17.177 4.68888 18.9095 1.58321 21.2078 1.98921Z"
                    fill="parent"
                />
            </svg>
        </span>
    );
};

export default FootprintIcon;
