import React, { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';

import Footer from 'shared/Footer/Footer';
import SiteHeader from 'containers/SiteHeader';
import SIWEModel from 'components/SIWEModel';

const IssueLayout = ({ children }: { children?: ReactNode }) => {
    return (
        <>
            <SiteHeader />
            <React.Suspense fallback={<></>}>
                <Outlet />
            </React.Suspense>
            <SIWEModel />
        </>
    );
};

export default IssueLayout;
