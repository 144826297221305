import { decodeJwt, JWTPayload } from 'jose';

export interface JwtInterface {
    address: string;
    iat: number;
    exp: number;
}

export function haveValidJwt() {
    const jwt = window.localStorage.getItem('jwt');
    let result = jwt ? true : false;

    if (jwt) {
        let jwtData: JWTPayload = decodeJwt(jwt);
        result = jwtData.exp! >= Date.now() / 1000;
    }

    return result;
}

export function getJwtPayload(): JWTPayload {
    const jwt = window.localStorage.getItem('jwt') ?? '';
    return decodeJwt(jwt);
}