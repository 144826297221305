import * as LitJsSdk from '@lit-protocol/lit-node-client';
import { UnifiedAccessControlConditions } from '@lit-protocol/types';

const client = new LitJsSdk.LitNodeClient({ debug: true });
const chain = 'mumbai';

class Lit {
    litNodeClient: any;

    async connect() {
        await client.connect();
        this.litNodeClient = client;
    }

    async SIWEforLit() {}

    ownerCondition(contractAddress: string, token_id: number) {
        const accessControlConditions = [
            {
                contractAddress,
                standardContractType: 'ERC1155',
                conditionType: 'evmBasic',
                chain: chain,
                method: 'balanceOf',
                parameters: [':userAddress', `${token_id}`],
                returnValueTest: {
                    comparator: '>',
                    value: '0',
                },
            },
        ];

        return accessControlConditions;
    }

    async encryptText(text: string, accessControlConditions: Array<any>) {
        if (!this.litNodeClient) {
            await this.connect();
        }
        LitJsSdk.disconnectWeb3();
        const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain });
        const { encryptedString, symmetricKey } = await LitJsSdk.encryptString(
            text
        );

        const encryptedSymmetricKey =
            await this.litNodeClient.saveEncryptionKey({
                unifiedAccessControlConditions: accessControlConditions,
                symmetricKey,
                authSig,
                chain,
            });

        return {
            encryptedString,
            encryptedSymmetricKey: LitJsSdk.uint8arrayToString(
                encryptedSymmetricKey,
                'base16'
            ),
        };
    }

    async decryptText(
        encryptedString: string,
        encryptedSymmetricKey: string,
        conditions: any[]
    ) {
        if (!this.litNodeClient) {
            await this.connect();
        }
        // LitJsSdk.disconnectWeb3();
        const authSig = await LitJsSdk.checkAndSignAuthMessage({ chain });
        return '';

        const symmetricKey = await this.litNodeClient.getEncryptionKey({
            unifiedAccessControlConditions: conditions,
            toDecrypt: encryptedSymmetricKey,
            chain,
            authSig,
        });

        const decodedKey = Buffer.from(symmetricKey.buffer).toString();
        const strObj = await fetch(encryptedString).then((res) => {
            console.debug(res);
            return res.blob();
        });
        const text = await LitJsSdk.decryptString(strObj, symmetricKey);
        return text;
    }

    erc721OwnerCondition(contractAddress: string, token_id: number) {
        const accessControlConditions = [
            {
                chain: chain,
                contractAddress,
                method: 'ownerOf',
                parameters: [`${token_id}`],
                returnValueTest: {
                    comparator: '=',
                    value: ':userAddress',
                },
                standardContractType: 'ERC721',
                conditionType: 'evmBasic',
            },
        ];
        return accessControlConditions;
    }

    erc1155OwnerCondition(contractAddress: string, token_id: number) {
        const accessControlConditions = [
            {
                chain: chain,
                contractAddress,
                method: 'balanceOf',
                parameters: [':userAddress', `${token_id}`],
                returnValueTest: {
                    comparator: '>',
                    value: '0',
                },
                standardContractType: 'ERC1155',
                conditionType: 'evmBasic',
            },
        ];
        return accessControlConditions;
    }

    badgeViewingCondition(contractAddress: string, token_id: number) {
        const accessControlConditions = [
            {
                chain: chain,
                contractAddress,
                functionName: 'canViewEncryptedData',
                functionParams: [`${token_id}`, ':userAddress'],
                functionAbi: {
                    inputs: [
                        {
                            internalType: 'uint256',
                            name: 'badgesId',
                            type: 'uint256',
                        },
                        {
                            internalType: 'address',
                            name: 'viewerAddress',
                            type: 'address',
                        },
                    ],
                    name: 'canViewEncryptedData',
                    outputs: [
                        {
                            internalType: 'bool',
                            name: '',
                            type: 'bool',
                        },
                    ],
                    stateMutability: 'view',
                    type: 'function',
                },
                returnValueTest: {
                    key: '',
                    comparator: '=',
                    value: 'true',
                },
                conditionType: 'evmContract',
            },
        ];
        return accessControlConditions;
    }
}

export const blobToBase64 = async (blob: Blob) => {
    return (
        'data:' +
        blob.type +
        ';base64,' +
        Buffer.from(await blob.arrayBuffer()).toString('base64')
    );
};

export default new Lit();
