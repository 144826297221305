import React, { FC, ReactElement, useEffect, useState } from 'react';

export interface LoadingProfileListingAccountProps {
    className?: string;
}

const LoadingProfileListingAccount: FC<LoadingProfileListingAccountProps> = ({
    className,
}) => {
    return <div className="profile_listing_card_background bg-loading pointer-events-none h-[288px]"></div>;
};

export default LoadingProfileListingAccount;
