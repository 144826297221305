import React, { FC, useEffect, useRef } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";
import TextButton from "./Buttons/TextButton";
import CompanyLogo from "static/images/logos/microsoft-sample.svg"
import SBTLogo from "static/images/badges/sbt-sample.svg"

export interface RequestUserAccessModalProps {
  show: boolean;
  metadata: any;
  dateRange?: string;
  onCloseModal: () => void;
  ownerAddress: string;
  onShowPrivateData: () => void;
}

const RequestUserAccessModal: FC<RequestUserAccessModalProps> = ({ show, dateRange, onCloseModal, ownerAddress, metadata,  onShowPrivateData }) => {
  const textareaRef = useRef(null);

  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
          (element as HTMLTextAreaElement).setSelectionRange(
            (element as HTMLTextAreaElement).value.length,
            (element as HTMLTextAreaElement).value.length
          );
        }
      }, 400);
    }
  }, [show]);

  const renderContent = () => {
    return (
      <>
        <div className="text-lg-initial-semibold text-neutral-900 dark:text-neutral-200 flex justify-center">
          Unlock SBT Private Data
        </div>
        <span className="text-xs-initial-regular mt-5">You are requesting full access to the following work badge owned by <span className="text-xs-intial-semibold">{ownerAddress.slice(0, 6) + "..." + ownerAddress.slice(ownerAddress.length - 4, ownerAddress.length)}:</span></span>
        <div className="py-3 px-4 rounded-lg border-2 border-neutral-900 mt-4 grid grid-cols-5 gap-x-3">
          <div>
            <img src={CompanyLogo} className="h-12 w-12" />
          </div>
          <div className="col-span-3">
            <div className="text-xs-initial-semibold">{metadata.name}</div>
            {/* <div className="text-xs-initial-regular">{}</div> */}
            <div className="text-xs-initial-regular">{dateRange}</div>
          </div>
          <div className="w-100">
            <div className="text-xs-initial-medium flex justify-end mr-1.5">SBT</div>
            <img src={SBTLogo} className="h-8 w-8 flex ml-auto" />
          </div>
        </div>
        <div className="mt-8 relative rounded-md shadow-sm">
            <TextButton text="Send Request" size="sm" buttonType="primary" className="w-full flex justify-center mt-8 text-xs-initial-medium" onClick={onShowPrivateData} />
        </div>
        <div className="relative rounded-md">
            <TextButton text="Not now" size="sm" buttonType="text" className="w-full flex justify-center mt-3 text-xs-initial-medium text-neutral-500 hover:text-neutral-900" onClick={onCloseModal} />
        </div>
      </>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModal}
      contentExtraClass="max-w-lg"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
  );
};

export default RequestUserAccessModal;
