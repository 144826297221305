import React, { FC, useEffect } from 'react';
import TextButton from 'components/Buttons/TextButton';
import { BriefcaseIcon, NewsletterIcon } from 'components/Icon/outline';
import IntroductionPage from './IssueSubPage/Introduction';
import CreateTemplatePage from './IssueSubPage/CreateTemplatePage';
import { useDispatch, useSelector } from 'react-redux';
import {
    getRelatedOrganisationInfo,
    updateSelectedTemplate,
    updateStage,
} from 'store/slices/issueSlice';
import { RootState } from 'store/store';
import CreateWorkSBTPage from './IssueSubPage/CreateWorkSBT';
import { ClrclePlusIcon } from 'components/Icon/solid';
import { useLocalStorage } from 'react-use';
import { IWorkBadgesTemplate } from 'schemas/workBadges';
import { useWeb3 } from 'utils/useWeb3';
import { useAppDispatch } from 'store/hooks';

export interface IssueHomePageProps {
    className?: string;
}

const IssueHomePage: FC<IssueHomePageProps> = ({ className }) => {
    const { controller } = useWeb3()!;

    const dispatch = useAppDispatch();
    const stage = useSelector((state: RootState) => state.issue.currentStage);

    const [templateRecord, setTemplateRecord, removeTemplateRecord] =
        useLocalStorage<Array<IWorkBadgesTemplate>>('work-badges-template', []);

    const createTemplateHandle = (val: IWorkBadgesTemplate) => {
        setTemplateRecord(templateRecord?.concat(val));
    };

    const templateSelectHandle = (val: IWorkBadgesTemplate) => {
        dispatch(updateSelectedTemplate(val));
        dispatch(updateStage('create-badges'));
    };

    const stageFactory = () => {
        switch (stage) {
            case 'home':
                return <IntroductionPage />;
            case 'create-template':
                return (
                    <CreateTemplatePage
                        setTemplateHandle={createTemplateHandle}
                    />
                );
            case 'create-badges':
                return <CreateWorkSBTPage />;
            default:
                return <IntroductionPage />;
        }
    };

    useEffect(() => {
        console.debug(templateRecord);
    }, [templateRecord]);

    useEffect(() => {
        if (controller) {
            async function getInfo() {
                dispatch(
                    getRelatedOrganisationInfo({
                        adminAddress: await controller?.getAccount()!,
                        provider: controller!.getProvider()!,
                    })
                );
            }
            getInfo()
        }
    }, [controller]);

    return (
        <div className="grid grid-cols-12 border-neutral-900 border-t-2">
            <div className="col-span-3 bg-neutral-200 border-r-2 border-neutral-900 flex flex-col justify-between full-page-layout">
                <div className="py-4 px-3 flex-1 overflow-y-auto">
                    <div className="flex flex-col gap-3 h-0">
                        <TextButton
                            className="w-full"
                            size="md"
                            text="New Work SBT"
                            buttonType="primary"
                            prependIcon={<ClrclePlusIcon />}
                            onClick={() => {
                                dispatch(updateStage('create-template'));
                            }}
                        />
                        {templateRecord?.map(
                            (val: IWorkBadgesTemplate, index: number) => {
                                return (
                                    <TextButton
                                        key={val.title + index}
                                        className="w-full"
                                        size="md"
                                        text={val.title}
                                        buttonType="text"
                                        prependIcon={<BriefcaseIcon />}
                                        onClick={() =>
                                            templateSelectHandle(val)
                                        }
                                    />
                                );
                            }
                        )}
                    </div>
                </div>
                <div className="py-1 px-3 border-t-2 border-neutral-900">
                    <TextButton
                        className="w-full"
                        size="md"
                        text="History"
                        buttonType="text"
                        prependIcon={<NewsletterIcon />}
                    />
                </div>
            </div>
            <div className="col-span-9 full-page-layout overflow-y-auto">
                {stageFactory()}
            </div>
        </div>
    );
};

export default IssueHomePage;
