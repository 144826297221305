import { FC, useEffect, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import MobileLoginModal from "components/LoginModal";
import { userIsLoggedIn } from "utils/providers/ethereumProvider";
import { useWeb3 } from "utils/useWeb3";
import { useNavigate } from "react-router-dom";
import Placeholder from "static/images/avatar_placeholder.png"
import { ethers } from "ethers";
import TextButton from "components/Buttons/TextButton";
import { DEPLOY_CHAIN } from "constants/chainConfig";
import NotificationButton from "./NotificationButton";
import GradientAvatars from "shared/Avatar/GradientAvatars";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const NavAccountButton: FC<any> = ({
}) => {
    const [mobileModal, setMobileModal] = useState(false);

    const { address, currentNetwork, disconnect, changeMetamaskNetwork } = useWeb3()!;

    const connectedNetwork = DEPLOY_CHAIN.name
    

    const navigate = useNavigate();

    
    const {displayAddress} = useWeb3()!;


    return (
        <Menu
            as="div"
            className="relative inline-block text-left"
        >
            <Menu.Button className="flex justify-center items-center mt-1.5">
                <div className="w-10 h-10 rounded-full bg-neutral-900">
                    <GradientAvatars
                        className="navbar-avatar button-popup-on-hover"
                        seed={address}
                    />
                </div>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-[252px] lg:w-80 origin-top-right bg-white border-2 border-neutral-900"
                    style={{
                        boxShadow: "6px 6px 0 0 #111827",
                        borderRadius: "16px 0 16px 16px",
                    }}
                >
                    <div className="py-4">
                        <Menu.Item>
                            <div className="flex gap-4 mx-5">
                                <GradientAvatars
                                    className="w-12 h-12 border-2 border-neutral-900 rounded-full"
                                    seed={address}
                                />
                                <div className="block">
                                    <div className="text-base-initial-semibold text-primary-700">{displayAddress}</div>
                                    <div className="text-xs-initial-regular text-neutral-400 mt-1">
                                        {
                                            address.slice(0, 6) +
                                            "..." +
                                            address.slice(address.length - 4, address.length)
                                        }
                                    </div>
                                </div>
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div className="mx-5">
                                <TextButton isDisabled text={connectedNetwork} buttonType="neutral" size="xs" className="w-full flex justify-center cursor-default mt-5 mb-3 text-sm-initial-medium" />
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    onClick={() => {
                                        navigate("/profile/" + address, {state: null});
                                        // navigate("/user-badges/me");
                                    }}
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "block py-2 px-5 text-sm-initial-medium cursor-pointer"
                                    )}
                                >
                                    My Profile
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <a
                                    href={`https://mumbai.polygonscan.com/address/${address}`}
                                    target="_blank"
                                >
                                    <div
                                        className={classNames(
                                            active
                                                ? "bg-gray-100 text-gray-900"
                                                : "text-gray-700",
                                            "block py-2 px-5 text-sm-initial-medium cursor-pointer"
                                        )}
                                    >
                                        View on PolygonScan
                                    </div>
                                </a>
                            )}
                        </Menu.Item>
                        <div className="w-100 border-b-2 border-neutral-200 my-3 mx-5"></div>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    onClick={() => disconnect()}
                                    className={classNames(
                                        active
                                            ? "bg-gray-100 text-gray-900"
                                            : "text-gray-700",
                                        "block py-2 px-5 text-sm-initial-medium cursor-pointer"
                                    )}
                                >
                                    Disconnect
                                </div>
                            )}
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
    );
};

export default NavAccountButton;
