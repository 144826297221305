import React, { FC } from 'react';

export interface VirtualMeetingIconProps {
    className?: string;
    iconClass?: string;
}

const VirtualMeetingIcon: FC<VirtualMeetingIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 28 28" fill="none">
                <g clip-path="url(#clip0_15088_60732)">
                    <path
                        d="M23.3333 4.66663C23.9522 4.66663 24.5457 4.91246 24.9832 5.35004C25.4208 5.78763 25.6667 6.38112 25.6667 6.99996V18.6666C25.6667 19.9616 24.6283 21 23.3333 21H28V23.3333H0V21H4.66667C4.04783 21 3.45434 20.7541 3.01675 20.3165C2.57917 19.879 2.33333 19.2855 2.33333 18.6666V6.99996C2.33333 5.70496 3.37167 4.66663 4.66667 4.66663H23.3333ZM23.3333 6.99996H4.66667V18.6666H23.3333V6.99996ZM14 14C16.5783 14 18.6667 15.05 18.6667 16.3333V17.5H9.33333V16.3333C9.33333 15.05 11.4217 14 14 14ZM14 8.16663C14.6188 8.16663 15.2123 8.41246 15.6499 8.85004C16.0875 9.28763 16.3333 9.88112 16.3333 10.5C16.3333 11.1188 16.0875 11.7123 15.6499 12.1499C15.2123 12.5875 14.6188 12.8333 14 12.8333C12.705 12.8333 11.6667 11.795 11.6667 10.5C11.6667 9.20496 12.7167 8.16663 14 8.16663Z"
                        fill="parent"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_15088_60732">
                        <rect width="28" height="28" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </span>
    );
};

export default VirtualMeetingIcon;
