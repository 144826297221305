import React, { FC, useState } from "react";

import { Line } from "react-chartjs-2";
import {Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Filler, Legend} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';


interface ChartData {
    labels: Array<string>;
    values: Array<number>;
}

export const SalaryChart = ({chartData}: {chartData: ChartData}) => { 
     

    const data = {
        labels: chartData.labels,
        datasets: [
            {
                fill: true,
                label: 'Salary',
                data: chartData.values,
                borderColor: 'rgba(68, 191, 18, 0.5)',
                backgroundColor: 'rgba(68, 191, 18, 0.3)',
              }
        ]
    }

    const options:any = {
        responsive: true,
        plugins: {
            legend: {
                display: false,
            },
            title: {
                display: false,
            },
            datalabels: {
                anchor: "end",
                display: true,
                color: "black",
                offset: 0,
                formatter: Math.round,
                align: "bottom"
              }
        },
        scales: {
            x: {
                grid: {
                    display: false
                },
                font: "Poppins"
            },
            y: {
                grid: {
                    display: false
                },
                ticks: {
                    display: false
                },
                    display: false
            },
        },
    }


    return <div className="flex justify-center">
    <Line
        options={options}
        data={data}
        plugins={[ChartDataLabels]}
    />
</div>


}