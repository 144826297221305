import React, { FC, ReactElement } from 'react';

export interface IconButtonProps {
    className?: string;
    //   colorClass?: string;
    //   isLiked?: boolean;
    isDisabled?: boolean; // normal, hover, active, disabled
    onClick?: () => void;
    buttonType?: string;
    icon: ReactElement;
}

const IconButton: FC<IconButtonProps> = ({
    className,
    isDisabled,
    onClick,
    buttonType,
    icon,
}) => {
    const getButtonType = () => {
        switch (true) {
            case buttonType == 'primary':
                return 'text-button-primary';
            case buttonType == 'secondary':
                return 'text-button-secondary';
            case buttonType == 'neutral':
                return 'text-button-neutral';
            case buttonType == 'danger':
                return 'text-button-danger';
            case buttonType == 'text':
                return 'text-button-text';
            default:
                return 'text-button-primary';
        }
    };

    return (
        <div className="bg-black rounded-lg h-full w-full">
            <button
                className={`${className} text-button px-2 py-2 ${
                    isDisabled ? 'text-button-disabled' : getButtonType()
                } w-full h-full`}
                onClick={
                    isDisabled
                        ? () => console.log('disabled')
                        : () => {
                              onClick && onClick();
                          }
                }
            >
                {icon}
            </button>
        </div>
    );
};

export default IconButton;
