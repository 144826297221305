import React, { FC, useMemo, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import TextField from 'components/Input/TextFields/TextField';
import { useDispatch } from 'react-redux';
import { updateSelectedTemplate, updateStage } from 'store/slices/issueSlice';
import { IWorkBadgesTemplate } from 'schemas/workBadges';

export interface CreateTemplatePageProps {
    className?: string;
    setTemplateHandle: any;
}

const CreateTemplatePage: FC<CreateTemplatePageProps> = ({
    className,
    setTemplateHandle,
}) => {
    const dispatch = useDispatch();

    const [currentTemplate, setCurrentTemplate] = useState<IWorkBadgesTemplate>(
        {
            title: '',
            employmentType: '',
            locationType: '',
        }
    );

    const canGoNextStep = useMemo(() => {
        return currentTemplate.title != '' &&
            currentTemplate.employmentType != '' &&
            currentTemplate.locationType != ''
            ? true
            : false;
    }, [currentTemplate]);

    const updateTemplateValue = (key: string, value: string) => {
        setCurrentTemplate((prevState) => ({
            ...prevState,
            [key]: value,
        }));
    };

    const toHomePage = () => {
        dispatch(updateStage('home'));
    };

    const toIssuePage = () => {
        setTemplateHandle(currentTemplate);
        dispatch(updateSelectedTemplate(currentTemplate));
        dispatch(updateStage('create-badges'));
    };

    return (
        <div className="w-[550px] my-20 mx-16">
            <div className="col-span-6 flex flex-col gap-16">
                <div className="text-3xl text-primary-700 font-semibold">
                    New Work SBT
                </div>
                <div>
                    <div className="text-lg text-neutral-900 font-semibold">
                        Employee Contract
                    </div>
                    <div className="issue-input-background-card mt-6">
                        <div>
                            <TextField
                                label="Job title"
                                required
                                placeholder="Job title"
                                onChangeHandle={(newValue: string) =>
                                    updateTemplateValue('title', newValue)
                                }
                            />
                        </div>
                        <div>
                            <TextField
                                label="Employment type"
                                required
                                placeholder="Employment type"
                                onChangeHandle={(newValue: string) =>
                                    updateTemplateValue(
                                        'employmentType',
                                        newValue
                                    )
                                }
                            />
                        </div>
                        <div>
                            <TextField
                                label="Location type"
                                required
                                placeholder="Location type"
                                onChangeHandle={(newValue: string) =>
                                    updateTemplateValue(
                                        'locationType',
                                        newValue
                                    )
                                }
                            />
                        </div>
                        <div>
                            <TextField
                                label="Location (if applicable)"
                                placeholder="Location (if applicable)"
                                onChangeHandle={(newValue: string) =>
                                    updateTemplateValue('location', newValue)
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className="flex justify-between">
                    <div>
                        <TextButton
                            size="md"
                            buttonType="text"
                            text="Back"
                            onClick={toHomePage}
                        />
                    </div>
                    <div>
                        <TextButton
                            size="md"
                            buttonType="primary"
                            text="Save and continue"
                            isDisabled={!canGoNextStep}
                            onClick={toIssuePage}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreateTemplatePage;
