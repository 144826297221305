import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import EtherController from 'utils/etherController';
import { userProfileStruct } from 'schemas/structure';
import userProfileServices from 'services/userProfile.services';
import { gql, useQuery } from '@apollo/client';
import { ethers } from 'ethers';
import { Organisation__factory } from 'typechain/factories/Organisation__factory';
import { CONTRACT_ADDRESS } from 'constants/contract';
import invokeServices from 'services/invoke.services';

export interface NotificationState {
    latestTransactionHash: string | null;
    errorMsg: [string] | null;
    loading: boolean;
    failed: boolean;
}

const initialState: NotificationState = {
    latestTransactionHash: null,
    errorMsg: null,
    loading: false,
    failed: false,
};

export const approveFollow = createAsyncThunk(
    '/notification/approve',
    async (
        payload: {
            profileId: number;
            requestorAddress: string;

            controller: EtherController;
            provider: ethers.providers.Provider;
        },
        thunkApi
    ) => {
        const userAddress = (await payload.controller.getAccount()) || '';

        const orgContract = Organisation__factory.connect(
            CONTRACT_ADDRESS.organisation,
            payload.provider
        );

        const address = await orgContract.subscriptionContractAddress();

        const dto = await payload.controller.signApproveSubscription(
            address,
            payload.profileId,
            payload.requestorAddress
        );

        if (!dto.sign) {
            return thunkApi.rejectWithValue({ error: 'Invalid signature' });
        }

        const res = await invokeServices.invoke(dto);

        const transactionHash = res.data.hash;
        console.log(`transactionHash: ${transactionHash}`);

        return { transactionHash: transactionHash };
    }
);

export const notificationSlice = createSlice({
    name: 'notificationSlice',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(approveFollow.pending, (state, { payload }) => {
            state.loading = true;
            state.failed = false;
        });
        builder.addCase(approveFollow.rejected, (state, { payload }) => {
            state.loading = false;
            state.failed = true;
        });
        builder.addCase(approveFollow.fulfilled, (state, { payload }) => {
            state.latestTransactionHash = payload.transactionHash;
            state.loading = false;
            state.failed = false;
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = notificationSlice.actions;

export const notificatonSliceFunction = {
    approveFollow,
};

export default notificationSlice.reducer;
