import TextButton from 'components/Buttons/TextButton';
import TextField from 'components/Input/TextFields/TextField';
import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import Avatar from 'shared/Avatar/Avatar';
import GradientAvatars from 'shared/Avatar/GradientAvatars';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { getUserProfile } from 'store/slices/profileSlice';
import { useEnsName, useEnsResolver } from 'wagmi';

import DiscordIcon from 'static/images/socials/discord.svg';
import TwitterIcon from 'static/images/socials/twitter.svg';
import WebsiteIcon from 'static/images/socials/website.svg';
import GithubIcon from 'static/images/socials/github.svg';
import { ethers } from 'ethers';
import { SnackbarContext } from 'shared/Snackbar/Snackbar';

const VerifyPage: FC<any> = () => {
    const dispatch = useAppDispatch();

    const { address } = useParams();
    const [searchParams, _] = useSearchParams();

    const [stage, setStage] = useState('input-email');
    const [email, setEmail] = useState('');

    const [isError, setIsError] = useState(false);

    const _address = `0x${address!.slice(2)}` as `0x${string}`;

    const profile = useAppSelector((state) => state.userProfile.currentProfile);

    const { data: ensName } = useEnsName({
        address: _address,
        chainId: 1,
    });

    const { data: ensResolver } = useEnsResolver({
        name: ensName || '',
        chainId: 1,
    });

    const shortAddress = useMemo(() => {
        const targetAddress = address!;
        return `${targetAddress.slice(0, 6)}...${targetAddress.slice(
            targetAddress.length - 4,
            targetAddress.length
        )}`;
    }, [address]);

    useEffect(() => {
        dispatch(
            getUserProfile({
                address: _address,
                ensResolver: ensResolver || undefined,
            })
        );
    }, []);

    const displayName = useMemo(() => {
        return ensName || profile?.name || shortAddress;
    }, [ensName, profile, shortAddress]);

    const [snackbarContext, setSnackbarContext] =
        useContext<any>(SnackbarContext);

    const verifyAddressOwnership = () => {
        const sign = searchParams.get('sign');
        if (!sign) {
            console.error('sign param is missing');
            setSnackbarContext({
                ...snackbarContext,
                show: true,
                text: 'Signature is missing',
            });
            return;
        }

        let messageAddress = '';
        try {
            messageAddress = ethers.utils.verifyMessage(
                `This is the proof of ownership to ${email}`,
                sign!
            );
        } catch (e) {
            setSnackbarContext({
                ...snackbarContext,
                show: true,
                text: 'Invalidate Signature',
            });
            return;
        }

        if (messageAddress.toLowerCase() === address!.toLowerCase()) {
            setIsError(false);
            setStage('success');
        } else {
            setIsError(true);
        }
    };

    const renderContent = () => {
        switch (stage) {
            case 'input-email':
                return (
                    <div>
                        <p className="text-lg-initial-regular text-neutral-700 mb-8">
                            You are verifying the ownership for the profile of{' '}
                            <span className="font-semibold">{displayName}</span>
                            .
                            <br />
                            <br />
                            Please enter{' '}
                            <span className="font-semibold text-primary-700">
                                your email address
                            </span>{' '}
                            that you used to receive the verification link.
                        </p>
                        <TextField
                            className="mb-12"
                            placeholder="example@example.com"
                            onChangeHandle={setEmail}
                            isError={isError}
                            errorMessage="Email incorrect. Please enter the same email address you used to receive this verification link."
                        />
                        <TextButton
                            bgClassName="w-full lg:w-fit"
                            className="w-full"
                            textClassName="justify-center"
                            size="md"
                            buttonType="primary"
                            text="Verify"
                            onClick={verifyAddressOwnership}
                        />
                    </div>
                );
        }
    };

    const renderSummaryCard = () => {
        return (
            <>
                {stage === 'success' && (
                    <h1 className="flex flex-col items-center justify-center mb-10">
                        <span className="text-3xl-initial-bold text-primary-700">
                            Verified!
                        </span>
                        <span className="text-lg-initial-semibold text-neutral-700">
                            He/She own this address
                        </span>
                    </h1>
                )}
                <div className="bg-neutral-900 rounded-xl w-full">
                    <div
                        className="p-6 pb-8 flex flex-col bg-white border-2 border-neutral-900 rounded-xl gap-5"
                        style={{
                            transform: 'translateX(-2px) translateY(-2px)',
                        }}
                    >
                        <div>
                            {profile?.profile_image_url ? (
                                <img
                                    src={profile?.profile_image_url}
                                    className="w-[250px] lg:w-[250px] border-2 border-neutral-900 rounded-[20px] overflow-hidden"
                                />
                            ) : (
                                <div className="w-[250px] lg:w-[250px] mx-auto">
                                    <GradientAvatars
                                        className="border-2 border-neutral-900 rounded-[20px] overflow-hidden"
                                        seed={address!.toLowerCase()}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="flex flex-col gap-3 items-center">
                            <h1 className="text-xl-initial-semibold">
                                {displayName}
                            </h1>
                        </div>
                        <div className="flex justify-center">
                            {profile?.socials?.website ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="cursor-pointer"
                                    imgUrl={WebsiteIcon}
                                    onClick={() =>
                                        window.open(profile?.socials.website)
                                    }
                                />
                            ) : null}
                            {profile?.socials?.twitter ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="ml-3 cursor-pointer"
                                    imgUrl={TwitterIcon}
                                    onClick={() =>
                                        window.open(
                                            'https://www.twitter.com/' +
                                                profile?.socials.twitter
                                        )
                                    }
                                />
                            ) : null}
                            {profile?.socials?.discord ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="ml-3 cursor-pointer"
                                    imgUrl={DiscordIcon}
                                    onClick={() =>
                                        window.open('https://www.discord.com')
                                    }
                                />
                            ) : null}
                            {profile?.socials?.github ? (
                                <Avatar
                                    sizeClass="h-7 w-7"
                                    containerClassName="ml-3 cursor-pointer"
                                    imgUrl={GithubIcon}
                                    onClick={() =>
                                        window.open(
                                            'https://www.github.com/' +
                                                profile?.socials.github
                                        )
                                    }
                                />
                            ) : null}
                        </div>
                    </div>
                </div>
                {stage === 'success' && (
                    <h1 className="flex flex-row items-center justify-center mt-10">
                        <TextButton
                            size="md"
                            buttonType="primary"
                            text="Go to profile"
                            onClick={() =>
                                window.open(
                                    new URL(
                                        `/profile/${address}`,
                                        window.location.href
                                    ).toString()
                                )
                            }
                        />
                    </h1>
                )}
            </>
        );
    };

    return (
        <div
            className="container flex flex-col-reverse lg:flex-row gap-12 lg:gap-28 justify-center items-center"
            style={{ minHeight: 'calc(100vh - 62px)' }}
        >
            {stage !== 'success' && (
                <div className="lg:basis-2/3">{renderContent()}</div>
            )}

            <div className="lg:basis-1/3">
                {renderSummaryCard()}
            </div>
        </div>
    );
};

export default VerifyPage;
