import React from "react";
import IssueLayout from "layout/IssueLayout";

import Page404 from "containers/Page404/Page404";
import IssueHomePage from "containers/IssuePage/IssueHomePage";
import LoadingPage from "containers/IssuePage/LoadingPage";
import ResultPage from "containers/IssuePage/ResultPage";

export const pages: any[] = [
  { path: "/issue/", exact: true, element: <IssueHomePage/> },
  { path: "/issue/loading", exact: true, element: <LoadingPage/> },
  { path: "/issue/success", exact: true, element: <ResultPage/> },
  { path: "*",  element: <Page404/> },
];

const IssueRoutes = {
    path: 'issue',
    element: <IssueLayout />,
    children: pages
};

export default IssueRoutes;
