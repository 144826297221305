import { FC, useMemo } from 'react';
import TextButton from 'components/Buttons/TextButton';
import { useNavigate } from 'react-router-dom';
import { getTimeAgo } from 'utils/dateFormatter';

import dummyImage from 'static/images/placeholder/organisation.png';

export interface SingleJobProps {
    id: string;
    image: string;
    name: string;
    company_name: string;
    location: string[];
    tags: string[];
    date: any;
}

const JobCard: FC<SingleJobProps> = ({
    id,
    image,
    name,
    company_name,
    location,
    tags,
    date,
}) => {
    const navigate = useNavigate();

    const timeAge = useMemo(() => {
        return getTimeAgo(Date.parse(date) / 1000)
    }, [date]);

    const redirect = () => {
        navigate(`/jobs/${id}`)
    }

    return (
        <div className="job_card_background" onClick={redirect}>
            <div className="job_card_container">
                <div className="flex">
                    <img src={image ?? dummyImage} className="job_card_image" />
                    <div className="ml-4 w-full">
                        <div className="flex justify-between">
                            <div className="job_card_job_title lg:text-base-initial-semibold">
                                {name}
                            </div>
                        </div>
                        <div className="mt-1.5 flex">
                            <div className="job_card_company_name lg:text-sm-initial-regular">
                                &#127970; {company_name}
                            </div>
                            <div className="job_card_location_container lg:text-sm-initial-regular">
                                &#128205;
                                <div className="job_card_location_text">
                                    {location.toString().replace(/,/g, ', ')}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-4 flex justify-between">
                    <div className="gap-2 hidden lg:flex flex-wrap">
                        {tags.map((tag: string, index: number) => {
                            return (
                                <TextButton
                                    key={`${tag}-${index}`}
                                    text={tag}
                                    size="xs"
                                    buttonType="neutral"
                                    className="rounded-full pointer-events-none"
                                />
                            );
                        })}
                    </div>
                    <div className="text-xs-initial-regular lg:text-sm-initial-regular mt-auto flex-none">
                        &#8987; {timeAge}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobCard;
