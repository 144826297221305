import React, { FC } from 'react';
import image from 'static/images/PageAbout/nft.svg';

const BelieveSection: FC<any> = ({}) => {
    return (
        <div className="container py-20 flex flex-col lg:flex-row">
            <div className="basis-1/2">
                <div className="flex flex-col justify-center min-h-full gap-5">
                    <div>
                        <h2 className="text-base-initial-bold text-primary-700 uppercase mb-2">
                            Our Belief
                        </h2>
                        <h1 className="text-3xl-initial-bold text-neutral-900">
                            Proving proofs should be effortlessly easy
                        </h1>
                    </div>
                    <p>
                        Traditional recruitment involves tedious and costly
                        reference checks, which often leads to headaches in the
                        hiring process.
                        <br />
                        <br />
                        We believe that everyone should have a
                        verified-by-default identity with non-transferrable
                        proofs issued by trusted issuers on blockchain with
                        immutable and encryptable data.
                    </p>
                </div>
            </div>
            <div className="basis-1/2">
                <img className="mx-auto" src={image} />
            </div>
        </div>
    );
};

export default BelieveSection;
