import { FC, useLayoutEffect, useRef, useState } from 'react';
import MobileImage from 'static/images/index/phone.svg';
import BackgroundImage from 'static/images/index/hero-background.svg';
import { useWindowSize } from 'react-use';
import TrideRating from 'static/images/profile/tride_rating.svg';
import Marquee from 'react-fast-marquee';

const displayText = ['Work', 'Skills', 'Education'];

export const CarouselSection: FC<any> = ({}) => {
    return (
        <div className="w-full bg-neutral-900 py-8 text-4xl-uppercase-bold text-white text-center">
            <Marquee className="overflow-hidden" speed={25} autoFill={true} play={true}>
                {displayText.map((text, index) => (
                    <>
                        <span className="px-7">{text}</span>
                        <img
                            className="inline-block h-[0.5em]"
                            src={TrideRating}
                        />
                    </>
                ))}
            </Marquee>
        </div>
    );
};
