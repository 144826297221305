import React, { FC } from 'react';
import lottie from 'lottie-web';
import circularLoader from '../../static/animations/circular-loader.json';

// ==============================|| LOADER ||============================== //
export interface CircularLoaderProps {
    baseClassName?: string;
    iconClassName?: string;
}

const CircularLoader: FC<CircularLoaderProps> = ({
    baseClassName = '',
    iconClassName = '',
}) => {
    React.useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector('#circular-loader')!,
            animationData: circularLoader,
        });
    }, []);

    return (
        <div className={baseClassName ?? ''}>
            <div className={iconClassName} id="circular-loader" />
        </div>
    );
};

export default CircularLoader;
