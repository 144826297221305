import { FC } from 'react';
import dummyImage from 'static/images/placeholder/organisation.png';
import LinkedinIcon from 'static/images/socials/linkedin.svg';
import FacebookIcon from 'static/images/socials/facebook.svg';
import DiscordIcon from 'static/images/socials/discord.svg';
import TwitterIcon from 'static/images/socials/twitter.svg';
import WebsiteIcon from 'static/images/socials/website.svg';
import TextButton from 'components/Buttons/TextButton';
import BoxArrowUpRightIcon from 'components/Icon/BoxArrowUpRightIcon';

export interface JobDetailSummaryCardProps {
    image?: string;
    name: string;
    location: string;
    applyLink?: string;
}

export const JobDetailSummaryCard: FC<JobDetailSummaryCardProps> = ({
    image,
    name,
    location,
    applyLink,
}) => {
    return (
        <div className="job_apply_card">
            <img src={image ?? dummyImage} className="w-12 h-12 mx-auto" />
            <div className="text-xl-initial-semibold mt-6">{name}</div>
            {/* <div className="text-xs-initial-regular text-neutral-500 mt-1">
                8 jobs posted
            </div>
            <div className="flex gap-3 mt-6">
                <img src={WebsiteIcon} className="h-6 w-6" />
                <img src={TwitterIcon} className="h-6 w-6" />
                <img src={FacebookIcon} className="h-6 w-6" />
                <img src={LinkedinIcon} className="h-6 w-6" />
                <img src={DiscordIcon} className="h-6 w-6" />
            </div> */}
            <div className="mt-6">
                <span className="text-sm-initial-regular text-neutral-700">
                    &#128205; {location}
                </span>
            </div>
            {/* <div className="text-sm-initial-regular mt-2">
                &#128176; $60k - $95k
            </div> */}
            <div className="mt-6 w-full">
                <TextButton
                    isDisabled={applyLink ? false : true}
                    text="Apply now"
                    buttonType="primary"
                    size="sm"
                    className="w-full"
                    textClassName="justify-center"
                    appendIcon={<BoxArrowUpRightIcon className="block" />}
                    onClick={() => {
                        window.open(applyLink, '_blank');
                    }}
                />
                {/* <TextButton
                    text="View space"
                    buttonType="neutral"
                    size="sm"
                    className="text-sm-initial-medium w-full mt-2"
                    textClassName="justify-center"
                    isDisabled
                /> */}
            </div>
        </div>
    );
};
