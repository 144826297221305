import React, { FC } from 'react';

export interface UserGroupIconProps {
    className?: string;
    iconClass?: string;
}

const UserGroupIcon: FC<UserGroupIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 29 25" fill="none">
                <path
                    d="M22.6664 20.9579C22.9964 20.9851 23.3302 20.999 23.6673 20.999C25.065 20.999 26.407 20.76 27.6545 20.3207C27.663 20.2146 27.6673 20.1073 27.6673 19.999C27.6673 17.7899 25.8764 15.999 23.6673 15.999C22.8304 15.999 22.0536 16.256 21.4115 16.6953M22.6664 20.9579C22.6665 20.9716 22.6665 20.9853 22.6665 20.999C22.6665 21.299 22.65 21.5951 22.6178 21.8866C20.2755 23.2306 17.5607 23.999 14.6665 23.999C11.7723 23.999 9.05753 23.2306 6.71518 21.8866C6.68302 21.5951 6.6665 21.299 6.6665 20.999C6.6665 20.9853 6.66654 20.9717 6.66661 20.958M22.6664 20.9579C22.6585 19.3891 22.1991 17.9272 21.4115 16.6953M21.4115 16.6953C19.9903 14.4726 17.5005 12.999 14.6665 12.999C11.8329 12.999 9.34337 14.4722 7.92209 16.6945M7.92209 16.6945C7.28014 16.2557 6.50378 15.999 5.66748 15.999C3.45834 15.999 1.66748 17.7899 1.66748 19.999C1.66748 20.1073 1.67178 20.2146 1.68023 20.3207C2.92774 20.76 4.2697 20.999 5.66748 20.999C6.00394 20.999 6.33716 20.9852 6.66661 20.958M7.92209 16.6945C7.13411 17.9266 6.67448 19.3889 6.66661 20.958M18.6665 4.99902C18.6665 7.20816 16.8756 8.99902 14.6665 8.99902C12.4574 8.99902 10.6665 7.20816 10.6665 4.99902C10.6665 2.78988 12.4574 0.999023 14.6665 0.999023C16.8756 0.999023 18.6665 2.78988 18.6665 4.99902ZM26.6665 8.99902C26.6665 10.6559 25.3234 11.999 23.6665 11.999C22.0096 11.999 20.6665 10.6559 20.6665 8.99902C20.6665 7.34217 22.0096 5.99902 23.6665 5.99902C25.3234 5.99902 26.6665 7.34217 26.6665 8.99902ZM8.6665 8.99902C8.6665 10.6559 7.32336 11.999 5.6665 11.999C4.00965 11.999 2.6665 10.6559 2.6665 8.99902C2.6665 7.34217 4.00965 5.99902 5.6665 5.99902C7.32336 5.99902 8.6665 7.34217 8.6665 8.99902Z"
                    stroke="#60E829"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </span>
    );
};

export default UserGroupIcon;
