import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import TextButton from "components/Buttons/TextButton";
import BadgeCard from "components/BadgeCard/BadgeCard";
import { useNavigate, useParams } from "react-router-dom";
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy.svg";
import { gql, useQuery } from "@apollo/client";
import { ethers } from "ethers";
import { useWeb3 } from "utils/useWeb3";

export interface AccountPageProps {
  className?: string;
  currentAddress?: boolean;
}

const GET_USER_BADGES = gql`
  query User($userAddress: ID!) {
    user(id: $userAddress) {
      badge {
        id
        name
        description
        type
        ... on EventBadge {
          metadata {
            image
            attributes {
              value
            }
          }
          event {
            organisation {
              name
              metadata {
                image
              }
            }
          }
        }
        ... on WorkBadge {
          organisation {
            name
            metadata {
              image
            }
          }
          metadata {
            name
            image
            attributes {
              value
            }
          }
        }
      }
    }
  }
`;

const PageAddressBadgesViewer: FC<AccountPageProps> = ({
  className = "",
  currentAddress = false,
}) => {
  const { address } = useParams();
  let controller = useWeb3()!;
  const navigate = useNavigate();

  let targetAddress: string;
  let shortAddress: string;

  if (currentAddress) {
    targetAddress = controller.address;
  } else {
    targetAddress = address ?? "";
  }

  const { loading, error, data, refetch } = useQuery(GET_USER_BADGES, {
    variables: { userAddress: targetAddress.toLowerCase() },
  });
  const [badgesInfoList, setBadgesInfoList] = useState<any[]>();

  if (!ethers.utils.isAddress(targetAddress)) {
    console.error("The address is not a valid Address");
  }

  shortAddress = React.useMemo(() => {
    return `${targetAddress.slice(0, 6)}...${targetAddress.slice(
      targetAddress.length - 4,
      targetAddress.length
    )}`;
  }, [targetAddress]);

  if (loading) {
    console.log("loading...");
  }
  if (error) {
    console.error(error);
  }

  useEffect(() => {
    let newBadgesInfoList = data?.user?.badge.map((value: any) => {
      const [address, tokenId] = (value.id as string).split("-");

      return {
        name: value.name || value.metadata.name,
        badgesImage: value.metadata.image,
        organisationImage:
          value.event?.organisation?.metadata?.image ||
          value.organisation?.metadata?.image,
        attributes: value.attributes,
        address,
        tokenId,
        type: value.type,
      };
    });
    setBadgesInfoList(newBadgesInfoList);
    console.log(data);
  }, [data]);

  function handleAddressBtn() {
    navigator.clipboard.writeText(targetAddress);
  }
  console.log(data);
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Credential Proof Badge Viewer</title>
      </Helmet>
      <div className="container mb-[120px] space-y-24 pt-24">
        <div className="text-center pt-[80px] pb-[48px] border-b-2 border-neutral-900">
          <h1 className="text-5xl mb-[32px] font-bold">
            {currentAddress ? "My" : "User's"} Badge Collection
          </h1>
          <TextButton
            size="sm"
            text={shortAddress}
            buttonType="neutral"
            className="bg-white mx-auto"
            appendIcon={<CopyIcon />}
            onClick={handleAddressBtn}
          />
        </div>

        {badgesInfoList && badgesInfoList.length > 0  ? (
          <div className="mt-12 grid grid-cols-1 xl:grid-cols-3 gap-6">
            {badgesInfoList?.map((value: any) => {
              return (
                <BadgeCard
                  key={value.id}
                  badgeName={value.name}
                  badgeImage={value.badgesImage}
                  organisationImage={value.organisationImage}
                  attributes={value.attributes}
                  onClick={() => {
                    console.log(value);
                    navigate(
                      `/badge/${value.type}/${value.address}/${value.tokenId}`
                    );
                  }}
                />
              );
            })}
          </div>
        ) : (
          <div className="w-full items-center flex-col space-y-4">
            <p className="w-full text-center text-xl-uppercase-medium " style={{
                color: "#9CA3AF"
            }}>No Badges Found</p>
            <p className="w-full text-center" style={{
                color: "#9CA3AF"
            }}>
              Please check that the correct wallet is connected.
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageAddressBadgesViewer;
