import {
    createAsyncThunk,
    createSlice,
    isRejectedWithValue,
    PayloadAction,
} from '@reduxjs/toolkit';
import userServices from 'services/user.services';
import { RootState } from 'store/store';

// current user handling, jwt , etc.
export interface UserState {
    address?: string;
    loggedIn?: boolean;
    balance?: number;
    userType: string;
}

const initialState: UserState = {
    address: '',
    loggedIn: false,
    balance: 0,
    userType: '',
};

export const getUser = createAsyncThunk(
    '/posts',
    async (data: any, { rejectWithValue }: any) => {
        console.log('called');
        try {
            const res = await userServices.getUser();
            return res.data;
        } catch (error: any) {
            if (!error.response) {
                throw error;
            }
            return rejectWithValue(error.response.data);
        }
    }
);

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUser.fulfilled, (state, { payload }) => {
            console.log(payload);
        });
        builder.addCase(getUser.rejected, (state, { payload }) => {
            console.log(payload);
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = userSlice.actions;

export const user = (state: RootState) => state.user;

export default userSlice.reducer;
