import { FC, ReactElement, useEffect, useState } from 'react';
import useDebounceValue from 'hooks/useDebounceValue';

interface LineTextFieldProps {
    className?: string;
    label?: string | ReactElement;
    onChangeHandler?: (value: string) => void;
}

const LineTextField: FC<LineTextFieldProps> = ({ className, label, onChangeHandler }) => {
    const [_userInput, _setUserInput] = useState('');
    const _debounceValue = useDebounceValue(_userInput, 500);

    const _onChangeHandle = (e: React.ChangeEvent<HTMLInputElement>) => {
        _setUserInput(e.target.value);
    };

    useEffect(() => {
        if (onChangeHandler) {
            onChangeHandler(_debounceValue);
        }
    }, [_debounceValue]);

    return (
        <div className="w-full">
            {label ? (
                <div className="text-base-initial-regular text-neutral-900">
                    {label}
                </div>
            ) : (
                <></>
            )}
            <input
                className="mt-2 w-full bg-transparent text-base-initial-regular border-b-2 border-neutral-900"
                onChange={_onChangeHandle}
            />
        </div>
    );
};

export default LineTextField;
