import React, { FC, useEffect, useMemo, useState } from 'react';
import { useWeb3 } from 'utils/useWeb3';
import { ethers } from 'ethers';
import TextField from 'components/Input/TextFields/TextField';
import TextButton from 'components/Buttons/TextButton';
import ProfileListingAccount from './ProfileListingAccount';
import MagnifyingGlass from 'static/images/icons/magnifying-glass.svg';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
    getMostFrequencyTags,
    listUserProfiles,
} from 'store/slices/profileSlice';
import ProfileListingPagination from './ProfileListingPagination';
import { Helmet } from 'react-helmet';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import Pagination from 'rc-pagination';
import { EmptyState } from 'components/EmptyState/EmptyState';
import { useSearchParams } from 'react-router-dom';
import { useUpdateEffect } from 'react-use';
import LoadingProfileListingAccount from './LoadingProfileListingAccount';

export interface ProfileListingProps {
    className?: string;
}

const TagsFilter = ({
    possibleFilter,
    activeFilter,
    onClickHandle,
}: {
    possibleFilter: Array<string>;
    activeFilter: Array<string>;
    onClickHandle: any;
}) => {
    return (
        <div className="flex gap-4 justify-center flex-wrap">
            {possibleFilter.map((item: string) => {
                return (
                    <TextButton
                        text={item}
                        size="sm"
                        buttonType="neutral"
                        className={`rounded-full ${
                            activeFilter.includes(item)
                                ? 'text-button-neutral-selected'
                                : ''
                        }`}
                        onClick={() => onClickHandle(item)}
                    />
                );
            })}
        </div>
    );
};

const ProfileListing: FC<ProfileListingProps> = ({ className }) => {
    const dispatch = useAppDispatch();
    const [urlSearchParam, setUrlSearchParam] = useSearchParams();
    const initSearchParam = useMemo(() => {
        return urlSearchParam;
    }, []);

    const isLoading = useAppSelector((state) => state.userProfile.loading);

    const profiles = useAppSelector((state) => state.userProfile.profiles);

    const [currentPage, setCurrentPage] = React.useState(
        parseInt(initSearchParam.get('page') ?? '1')
    );
    const pageSize = useAppSelector((state) => state.userProfile.pageSize);
    const totalProfiles = useAppSelector(
        (state) => state.userProfile.totalProfiles
    );

    const [searchField, setSearchField] = useState<string>(
        initSearchParam.get('query') ?? ''
    );
    const [activeFilter, setActiveFilter] = useState<Array<string>>(
        initSearchParam.getAll('tags') ?? []
    );
    const frequentlyTags = useAppSelector(
        (state) => state.userProfile.skills_tag
    );
    const displayTags = useMemo(() => {
        return [...new Set(frequentlyTags.concat(activeFilter))];
    }, [frequentlyTags]);

    function createQueryTags() {
        let result = activeFilter;
        if (searchField != '') {
            result = activeFilter.concat(searchField);
        }
        return result.length ? result.join(',') : undefined;
    }

    const updateFilter = (data: string) => {
        if (activeFilter.includes(data)) {
            setActiveFilter(
                activeFilter.filter((tag: String) => {
                    return tag != data;
                })
            );
        } else {
            setActiveFilter([...activeFilter, data]);
        }
    };

    const updatePage = (page: number) => {
        setCurrentPage(page);
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const overrideLink = (event: any) => {
        event.stopPropagation();
    };

    useEffect(() => {
        dispatch(getMostFrequencyTags());
        dispatch(
            listUserProfiles({
                query: searchField,
                tags: createQueryTags(),
                currentPage: currentPage,
            })
        );
    }, []);

    useUpdateEffect(() => {
        setCurrentPage(1);
        dispatch(
            listUserProfiles({
                query: searchField,
                tags: createQueryTags(),
                currentPage: 1,
            })
        );
    }, [searchField, activeFilter]);

    useUpdateEffect(() => {
        dispatch(
            listUserProfiles({
                query: searchField,
                tags: createQueryTags(),
                currentPage: currentPage,
            })
        );
    }, [currentPage]);

    useUpdateEffect(() => {
        let newParam: { [key: string]: string | Array<string> } = {};

        if (currentPage > 1) {
            newParam.page = `${currentPage}`;
        }
        if (searchField) {
            newParam.query = searchField;
        }
        if (activeFilter) {
            newParam.tags = activeFilter;
        }

        setUrlSearchParam(newParam);
    }, [searchField, activeFilter, currentPage]);

    return (
        <>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <div className="profile_listing_page_container">
                <div className="text-center">
                    <div className="user_profile_name">
                        Explore profiles &#10024;
                    </div>
                    <div className="profile_listing_page_subtitle">
                        Discover skilled professionals on the chains.
                    </div>
                </div>

                <div className="profile_listing_searchbar_container">
                    <TextField
                        label=""
                        placeholder="Search by ENS domain, wallet address, job title or skills"
                        prependIcon={<img src={MagnifyingGlass} />}
                        onChangeHandle={setSearchField}
                        defaultValue={initSearchParam.get('query') ?? ''}
                    />
                </div>

                <TagsFilter
                    possibleFilter={displayTags}
                    activeFilter={activeFilter}
                    onClickHandle={updateFilter}
                />

                <div className="text-sm-intial-regular text-neutral-500 mt-10">
                    {totalProfiles} results
                </div>

                {isLoading ? (
                    <div className="profile_listing_grid">
                        {Array(18)
                            .fill(0)
                            .map(() => (
                                <LoadingProfileListingAccount />
                            ))}
                    </div>
                ) : profiles.length ? (
                    <div className="profile_listing_grid">
                        {profiles.map((item: any) => {
                            return (
                                <>
                                    <ProfileListingAccount
                                        image={item.profile_image_url}
                                        accountName={item.name}
                                        address={item.user_address}
                                        tag={item.skill_tags}
                                        website={item.externals?.url}
                                        twitter={item.externals?.twitter}
                                        discord={item.externals?.discord}
                                        github={item.externals?.github}
                                        verified={!!item.profile_token_id}
                                        setActiveFilter={updateFilter}
                                    />
                                </>
                            );
                        })}
                    </div>
                ) : (
                    <EmptyState />
                )}

                <div className="flex justify-center mt-10">
                    <Pagination
                        defaultPageSize={pageSize}
                        total={totalProfiles}
                        onChange={updatePage}
                        current={currentPage}
                        locale={{
                            // Options.jsx
                            items_per_page: '/ page',
                            jump_to: 'Go to',
                            jump_to_confirm: 'confirm',
                            page: 'Page',

                            // Pagination.jsx
                            prev_page: 'Previous Page',
                            next_page: 'Next Page',
                            prev_5: '',
                            next_5: '',
                            prev_3: '',
                            next_3: '',
                        }}
                        prevIcon={
                            <div className="pagination_button_normal">
                                <ChevronLeftIcon />
                            </div>
                        }
                        nextIcon={
                            <div className="pagination_button_normal">
                                <ChevronRightIcon />
                            </div>
                        }
                        jumpPrevIcon={
                            <div
                                className="pagination_button_jump"
                                onClick={overrideLink}
                            >
                                ...
                            </div>
                        }
                        jumpNextIcon={
                            <div
                                className="pagination_button_jump"
                                onClick={overrideLink}
                            >
                                ...
                            </div>
                        }
                        className="ant-pagination text-sm-initial-semibold"
                        showLessItems={true}
                    />
                </div>
            </div>
        </>
    );
};

export default ProfileListing;
