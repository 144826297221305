import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Tab } from '@headlessui/react';
import useCaseImg from 'static/images/PageUseCase/useCase.svg';
import {
    AcademicSection,
    BusinessesSection,
    EmployersSection,
    TalentSection,
} from './sections';
import TextButton from 'components/Buttons/TextButton';
import { useWindowSize } from 'react-use';

export interface PageUseCaseProps {
    className?: string;
}

const UseCaseTab: FC<any> = ({ text }) => {
    return (
        <Tab className="basis-1/3 py-3 text-neutral-500 ui-selected:text-neutral-900 ui-selected:outline-0 ui-selected:border-2 ui-selected:border-neutral-900 ui-selected:bg-primary-600 ui-selected:rounded-lg">
            <span className="text-base-initial-bold uppercase ">{text}</span>
        </Tab>
    );
};

const SDK_NOTION_PAGE =
    'https://ergonglobal.notion.site/SDK-integration-3a69ad6048f6432686ec40a0fca4274e';

const PageUseCase: FC<PageUseCaseProps> = ({ className = '' }) => {
    const { width } = useWindowSize();

    const renderDesktopContent = () => {
        return (
            <Tab.Group>
                <Tab.List
                    className="flex flex-row border-2 border-neutral-900 text-center p-1 items-center rounded-lg bg-white"
                    style={{ boxShadow: '3px 3px 0px #111827' }}
                >
                    <UseCaseTab text="For Issuers" />
                    <UseCaseTab text="For Provers" />
                    <UseCaseTab text="For Verifiers" />
                </Tab.List>
                <Tab.Panels
                    className="mt-5 border-2 border-neutral-900 items-center rounded-lg text-left bg-white"
                    style={{ boxShadow: '3px 3px 0px #111827' }}
                >
                    <Tab.Panel>
                        <div className="divide-y">
                            <EmployersSection />
                            <AcademicSection />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="divide-y">
                            <TalentSection />
                        </div>
                    </Tab.Panel>
                    <Tab.Panel>
                        <div className="divide-y">
                            <BusinessesSection />
                        </div>
                    </Tab.Panel>
                </Tab.Panels>
            </Tab.Group>
        );
    };

    const renderMobileContent = () => {
        return (
            <div className="flex flex-col gap-11">
                <div>
                    <div
                        className="relative p-[5px] rounded-xl bg-neutral-900"
                        style={{ boxShadow: '3px 3px 0px #111827' }}
                    >
                        <h1 className="text-center text-base-initial-bold uppercase bg-neutral-900 text-white py-3 rounded-lg ring-offset-0 ring-2 ring-white">
                            For Issuers
                        </h1>
                    </div>
                    <div
                        className="mt-5 border-2 border-neutral-900 items-center rounded-lg text-left bg-white divide-y"
                        style={{ boxShadow: '3px 3px 0px #111827' }}
                    >
                        <EmployersSection />
                        <AcademicSection />
                    </div>
                </div>
                <div>
                    <div
                        className="relative p-[5px] rounded-xl bg-neutral-900"
                        style={{ boxShadow: '3px 3px 0px #111827' }}
                    >
                        <h1 className="text-center text-base-initial-bold uppercase bg-neutral-900 text-white py-3 rounded-lg ring-offset-0 ring-2 ring-white">
                            For Provers
                        </h1>
                    </div>
                    <div
                        className="mt-5 border-2 border-neutral-900 items-center rounded-lg text-left bg-white"
                        style={{ boxShadow: '3px 3px 0px #111827' }}
                    >
                        <TalentSection />
                    </div>
                </div>
                <div>
                    <div
                        className="relative p-[5px] rounded-xl bg-neutral-900"
                        style={{ boxShadow: '3px 3px 0px #111827' }}
                    >
                        <h1 className="text-center text-base-initial-bold uppercase bg-neutral-900 text-white py-3 rounded-lg ring-offset-0 ring-2 ring-white">
                            For Verifiers
                        </h1>
                    </div>
                    <div
                        className="mt-5 border-2 border-neutral-900 items-center rounded-lg text-left bg-white"
                        style={{ boxShadow: '3px 3px 0px #111827' }}
                    >
                        <BusinessesSection />
                    </div>
                </div>
            </div>
        );
    };

    const renderContent = () => {
        if (width < 1280) {
            return renderMobileContent();
        }

        return renderDesktopContent();
    };

    return (
        <div>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <div className="container py-20">
                <div className="max-w-[780px] mx-auto mb-20">
                    <h2 className="text-base-initial-bold text-primary-700 text-center mb-4">
                        Use Cases
                    </h2>
                    <h1 className="text-5xl-initial-bold text-neutral-900 text-center mb-6">
                        Creating opportunities for various parties
                    </h1>
                    <div>
                        <p className="text-base-initial-regular text-neutral-700 text-center">
                            Credentialproof.io streamlines processes, builds
                            trust and reputation, and opens up opportunities for
                            career advancement, professional networking,
                            efficient recruitment, and more.
                        </p>
                    </div>
                </div>
                <div className="text-center mb-10">
                    <img className="mx-auto my-10" src={useCaseImg} />
                    <span className="text-4xl-initial-bold text-neutral-900">
                        What are CredentialProof use cases?
                    </span>
                </div>
                <div className="mb-20">{renderContent()}</div>
                <div className="hidden lg:flex gap-6 justify-center">
                    <TextButton
                        buttonType="primary"
                        size="xl"
                        text="Download SDK"
                        onClick={() => window.open(SDK_NOTION_PAGE)}
                    />
                    <TextButton
                        buttonType="tertiary"
                        size="xl"
                        text="Read the docs"
                        onClick={() => window.open(SDK_NOTION_PAGE)}
                    />
                </div>
            </div>
        </div>
    );
};

export default PageUseCase;
