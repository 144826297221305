import React, { FC } from 'react';
import { IWorkBadgesTemplate } from 'schemas/workBadges';
import badgesImg from 'static/images/badges/microsoft-badge.svg';

export interface BadgeTemplateCardProps {
    className?: string;
    img?: string;
    title?: string;
    employmentType?: string;
    locationType?: string;
}

const BadgeTemplateCard: FC<BadgeTemplateCardProps> = ({
    className = '',
    img = badgesImg,
    title = 'Unknown Title',
    employmentType = 'Unknown Employment Type',
    locationType = 'Unknown Location Type',
}) => {
    return (
        <div className={`bg-neutral-100 rounded-3xl p-6 ${className}`}>
            <div className="flex flex-col gap-6">
                <div>
                    <img src={img} />
                </div>
                <div>
                    <div className="text-base">
                        <h3 className="text-gray-600">Job title</h3>
                        <div className="font-medium">{title}</div>
                    </div>
                    <div className="text-base my-3">
                        <h3 className="text-gray-600">Employment type</h3>
                        <div className="font-medium">
                            {employmentType}
                        </div>
                    </div>
                    <div className="text-base">
                        <h3 className="text-gray-600">Location type</h3>
                        <div className="font-medium">
                            {locationType}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BadgeTemplateCard;
