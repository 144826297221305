import React, { FC, useEffect, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import GeneralModal from 'components/Modal/GeneralModal';
import BannerTestImage from 'static/images/cover_photo_placeholder.png';
import TextArea from 'components/Input/TextArea/TextArea';
import TextField from 'components/Input/TextFields/TextField';
import { useAccount, useEnsAvatar, useEnsName, useEnsResolver } from 'wagmi';
import { useNavigate, useParams } from 'react-router-dom';
import Placeholder from 'static/images/avatar_placeholder.png';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import SelectNFTModal from './SelectNFTModal';
import EditProfilePictureDropdown from '../Dropdowns/EditProfilePictureDropdown';
import { EditProfileInputType, updateUserProfile } from 'store/slices/profileSlice';

export interface EditProfileModalProps {
    className?: string;
    defaultValue?: EditProfileInputType;
    openEditModal: boolean;
    setOpenEditModal: Function;
}

const ConnectSocialNetwork = () => {
    return (
        <div className="basis-1/4">
            <div className="text-sm-initial-medium">Discord</div>
            <TextButton
                size="sm"
                text="Connect"
                buttonType="secondary"
                className="mt-2"
            />
        </div>
    );
};

const EditProfileModal: FC<EditProfileModalProps> = ({
    className,
    defaultValue,
    openEditModal,
    setOpenEditModal,
}) => {
    // const { address, displayAddress } = useWeb3()!;
    const [name, setName] = useState(defaultValue?.name);
    const [description, setDescription] = useState(defaultValue?.description); // ens description
    const [url, setURL] = useState(defaultValue?.socials?.website);

    const [twitter, setTwitter] = useState(defaultValue?.socials?.twitter);
    const [discord, setDiscord] = useState(defaultValue?.socials?.discord);
    const [github, setGithub] = useState(defaultValue?.socials?.github);
    const { addr } = useParams();

    console.log('defaultValue', defaultValue)

    // const db_profile = useAppSelector(
    //     (state) => state.userProfile.currentProfile
    // );

    useEffect(() => {
        setTwitter(defaultValue?.socials?.twitter);
        setDiscord(defaultValue?.socials?.discord);
        setURL(defaultValue?.socials?.website);
        setGithub(defaultValue?.socials?.github);
        setChosenPfp(defaultValue?.profile_image_url);
        setDescription(defaultValue?.description);
    }, [defaultValue]);

    const dispatch = useAppDispatch();

    // const addr = "0x7b47640ed97cc08aa188ae091efab2cf3ef48469";

    const address = (addr as `0x${string}`) || '';

    const { data: ensName } = useEnsName({ address, chainId: 1 });

    const navigate = useNavigate();
    const [chosenPfp, setChosenPfp] = useState(defaultValue?.profile_image_url);

    const displayName =
        ensName ||
        (address || '').slice(0, 10) +
            '...' +
            address.slice(address.length - 4, address.length);

    const handleAddressBtn = (content: string) => {
        navigator.clipboard.writeText(content);
    };

    useEffect(() => {
        console.log('getting username...');
    }, []);

    const renderContent = () => {
        return (
            <div className="h-3/5 max-h-[580px] overflow-y-scroll hiddenScrollbar">
                <div className={className}>
                    <img
                        src={BannerTestImage}
                        className="w-full object-cover h-32"
                    />
                </div>
                <div className="flex justify-center">
                    <div className="border-2 border-neutral-900 rounded-2xl w-20 h-20 -mt-8 overflow-hidden cursor-pointer">
                        <EditProfilePictureDropdown
                            image={
                                chosenPfp != ''
                                    ? chosenPfp
                                    : Placeholder
                            }
                            imageClassName="w-20 h-20 object-cover"
                            setProfilePicture={(path: string) => {
                                setChosenPfp(path);
                            }}
                        />
                    </div>
                </div>
                <div className="px-8 pb-10">
                    <div className="text-xl-initial-semibold">About</div>
                    <div className="text-xs-initial-regular text-neutral-500 mt-3">
                        Share a few sentences that makes you stand out. Don’t be
                        afraid to be creative and show your personality!
                    </div>
                    <TextArea
                        label=""
                        defaultValue={description}
                        onChangeHandle={(text: string) => setDescription(text)}
                        placeholder="Input your description here!"
                    />
                    <div className="text-xl-initial-semibold mt-16">Links</div>
                    <div className="mt-2">
                        <TextField
                            label="Website"
                            placeholder="Paste your website here!"
                            defaultValue={url}
                            onChangeHandle={(text: string) => setURL(text)}
                        />
                    </div>
                    <div className="mt-2">
                        <TextField
                            label="Twitter"
                            placeholder="Paste your Twitter here!"
                            defaultValue={twitter}
                        />
                    </div>
                    <div className="mt-2">
                        <TextField
                            label="Github"
                            placeholder="Paste your Github here!"
                            defaultValue={github}
                        />
                    </div>
                    <div className="mt-2">
                        <TextField
                            label="Discord"
                            placeholder="Paste your Discord here!"
                            defaultValue={discord}
                        />
                    </div>
                    <div className="mt-4 flex flex-row flex-wrap pb-10">
                        {/* <ConnectSocialNetwork/> */}
                    </div>
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="edit_user_profile_modal_footer">
                <TextButton
                    size="sm"
                    text="Cancel"
                    buttonType="text"
                    className="h-9 my-auto"
                    onClick={() => setOpenEditModal(false)}
                />
                <div className="ml-4 h-9 my-auto">
                    <TextButton
                        size="sm"
                        text="Save"
                        buttonType="primary"
                        className=""
                        onClick={() => {
                            dispatch(updateUserProfile({
                                description: description || "",
                                profile_image_url: chosenPfp || "",
                                socials: {
                                    website: url || "",
                                    twitter: twitter || "",
                                    discord: discord || "",
                                    github: github || "",
                                }
                            }))
                            setOpenEditModal(false);
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <>
            <GeneralModal
                isOpenProp={openEditModal}
                onCloseModal={() => setOpenEditModal(false)}
                contentExtraClass="max-w-lg"
                renderContent={renderContent}
                renderFooter={renderFooter}
                renderTrigger={renderTrigger}
                modalTitle="Profile"
                closeButton={true}
            />
        </>
    );
};

export default EditProfileModal;
