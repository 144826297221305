import { FC, useEffect } from 'react';
import JobCard from 'components/JobCard/JobCard';
import { EmptyState } from 'components/EmptyState/EmptyState';

export interface JobListDisplaySectionProps {
    jobsData: Array<any>;
}

export const JobListDisplaySection: FC<JobListDisplaySectionProps> = ({
    jobsData = [],
}) => {
    useEffect(() => {
        console.log('job data,  ', jobsData);
    });
    return (
        <div className='flex flex-col gap-3 mt-3'>
            {jobsData.length == 0 ? (
                <EmptyState />
            ) : (
                jobsData?.map((item: any, index: number) => {
                    return (
                        <JobCard
                            key={`${item._id}-${index}`}
                            id={item._id}
                            image={item.company_img}
                            name={item.title}
                            company_name={item.company}
                            location={item.location}
                            tags={item.tags}
                            date={item.date}
                        />
                    );
                })
            )}
        </div>
    );
};

export default JobListDisplaySection;
