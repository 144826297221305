import { ethers } from 'ethers';

export interface commitPayloadPrams {
    secretCode: string;
    salt?: string;
    senderAddr?: string;
}

export interface toHashParam {
    type: string;
    value: any;
}

export function calCommit(payload: commitPayloadPrams) {
    let types = ['string'];
    let params = [payload.secretCode];

    if (payload.salt) {
        types.push('string');
        params.push(payload.salt);
    }

    if (payload.senderAddr) {
        types.push('address');
        params.push(payload.senderAddr);
    }

    return ethers.utils.keccak256(
        ethers.utils.defaultAbiCoder.encode(types, params)
    );
}

export function hashGenerator(payload: Array<toHashParam>) {
    const types = payload.map((value: toHashParam) => value.type);
    const value = payload.map((value: toHashParam) => value.value);

    return ethers.utils.keccak256(
        ethers.utils.defaultAbiCoder.encode(types, value)
    );
}
