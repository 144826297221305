import TextButton from 'components/Buttons/TextButton';
import React, { FC } from 'react';

import cpImg from 'static/images/PageAbout/table/protocolIcon/cp.png';
import accredibleImg from 'static/images/PageAbout/table/protocolIcon/accredible.png';
import blockcertImg from 'static/images/PageAbout/table/protocolIcon/blockcerts.png';
import galxeImg from 'static/images/PageAbout/table/protocolIcon/galxe.png';
import poapImg from 'static/images/PageAbout/table/protocolIcon/poap.png';

import tickImg from 'static/images/PageAbout/table/tick.svg';
import crossImg from 'static/images/PageAbout/table/cross.svg';
import costImg from 'static/images/PageAbout/table/costIcon.svg';

import {
    CogIcon,
    CurrencyDollarIcon,
    KeyIcon,
    LinkIcon,
    SearchIcon,
} from '@heroicons/react/outline';
import { OwnershipIcon } from 'components/Icon/outline';

const data = {
    // [cp, accredible, blockcert, galxe, poap]
    discoverability: [true, false, false, true, true],
    soulbound: [true, false, false, true, false],
    ownership: [true, false, true, true, true],
    encryption: [true, false, false, false, false],
    cost: [1, 4, 3, 2, 1],
};

const TableSection: FC<any> = ({}) => {
    const renderTickCrossIcon = (result: boolean) => {
        return (
            <div className="basis-1/8 min-w-[100px]">
                <img className="mx-auto" src={result ? tickImg : crossImg} />
            </div>
        );
    };

    return (
        <div className="container py-20">
            <h2 className="text-base-initial-bold uppercase text-primary-700 text-center mb-2">
                Why We Need CredentialProof.io
            </h2>
            <h1 className="text-5xl-initial-bold text-center mb-4 lg:mb-11">
                Existing solutions failed us...
            </h1>
            <div className="lg:hidden text-sm-initial-regular text-neutral-700 text-center mb-8">
                <p>
                    No current protocol has the capability to allow
                    professionals to be discoverable to the public while also
                    having complete control over which information is displayed
                    publicly, encrypted, or only shared with their consent.
                </p>
            </div>
            <div className="overflow-x-auto max-w-full">
                <div
                    id="about-table"
                    className="border-separate table overflow-scroll-y"
                >
                    <div className="thead flex flex-row">
                        <div className="basis-3/8 min-w-[300px] text-sm-initial-regular text-neutral-700 text-left">
                            <p className="hidden lg:block">
                                No current protocol has the capability to allow
                                professionals to be discoverable to the public
                                while also having complete control over which
                                information is displayed publicly, encrypted, or
                                only shared with their consent.
                            </p>
                        </div>
                        <div className="basis-1/8 min-w-[100px] text-primary-600">
                            <img
                                className="h-16 w-16 border-2 border-primary-600 rounded-lg mx-auto mb-3"
                                src={cpImg}
                            />
                            CP.io
                        </div>
                        <div className="basis-1/8 min-w-[100px]">
                            <img
                                className="h-16 w-16 border-2 border-neutral-300 rounded-lg mx-auto mb-3"
                                src={accredibleImg}
                            />
                            Accredible
                        </div>
                        <div className="basis-1/8 min-w-[100px]">
                            <img
                                className="h-16 w-16 border-2 border-neutral-300 rounded-lg mx-auto mb-3"
                                src={blockcertImg}
                            />
                            Blockcerts
                        </div>
                        <div className="basis-1/8 min-w-[100px]">
                            <img
                                className="h-16 w-16 border-2 border-neutral-300 rounded-lg mx-auto mb-3"
                                src={galxeImg}
                            />
                            Galxe
                        </div>
                        <div className="basis-1/8 min-w-[100px]">
                            <img
                                className="h-16 w-16 border-2 border-neutral-300 rounded-lg mx-auto mb-3"
                                src={poapImg}
                            />
                            POAP
                        </div>
                    </div>
                    <div className="tbody text-lg-initial-medium text-neutral-900 overflow-hidden">
                        <div className="tr">
                            <div className="basis-3/8 min-w-[300px]">
                                <SearchIcon className="h-7 w-7 inline-block text-primary-700" />{' '}
                                <span className="ml-4">Discoverability</span>
                            </div>
                            {data.discoverability.map((value: boolean) => {
                                return (
                                    <div className="basis-1/8 min-w-[100px]">
                                        <img
                                            className="mx-auto"
                                            src={value ? tickImg : crossImg}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="tr">
                            <div className="basis-3/8 min-w-[300px]">
                                <LinkIcon className="h-7 w-7 inline-block text-primary-700" />{' '}
                                <span className="ml-4">Soulbound</span>
                            </div>
                            {data.soulbound.map((value: boolean) => {
                                return (
                                    <div className="basis-1/8 min-w-[100px]">
                                        <img
                                            className="mx-auto"
                                            src={value ? tickImg : crossImg}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="tr">
                            <div className="basis-3/8 min-w-[300px]">
                                <OwnershipIcon
                                    className="ml-0!"
                                    iconClass="h-7 w-7 fill-primary-700 inline"
                                />{' '}
                                <span className="ml-4">Data Ownership</span>
                            </div>
                            {data.ownership.map((value: boolean) => {
                                return (
                                    <div className="basis-1/8 min-w-[100px]">
                                        <img
                                            className="mx-auto"
                                            src={value ? tickImg : crossImg}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="tr">
                            <div className="basis-3/8 min-w-[300px]">
                                <KeyIcon className="h-7 w-7 inline-block text-primary-700" />{' '}
                                <span className="ml-4">Partial Encryption</span>
                            </div>
                            {data.encryption.map((value: boolean) => {
                                return (
                                    <div className="basis-1/8 min-w-[100px]">
                                        <img
                                            className="mx-auto"
                                            src={value ? tickImg : crossImg}
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="tr">
                            <div className="basis-3/8 min-w-[300px]">
                                <CurrencyDollarIcon className="h-7 w-7 inline-block text-primary-700" />{' '}
                                <span className="ml-4">Cost</span>
                            </div>
                            {data.cost.map((value: number) => {
                                return (
                                    <div className="basis-1/8 min-w-[100px]">
                                        <div className="w-fit mx-auto cost">
                                            {Array(value)
                                                .fill(0)
                                                .map(() => {
                                                    return (
                                                        <img
                                                            className=""
                                                            src={costImg}
                                                        />
                                                    );
                                                })}
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        <div className="tr items-center">
                            <div className="basis-3/8 min-w-[300px]">
                                <CogIcon className="h-7 w-7 inline-block text-primary-700" />{' '}
                                <span className="ml-4">Utilities</span>
                            </div>
                            <div className="basis-1/8 min-w-[100px]">
                                <span className="bg-white py-4 px-3 border-2 border-primary-600 border-dashed rounded-lg text-primary-600">
                                    Diverse
                                </span>
                            </div>
                            <div className="basis-1/8 min-w-[100px]">
                                Achievement Badges
                            </div>
                            <div className="basis-1/8 min-w-[100px]">
                                Educational Certificates
                            </div>
                            <div className="basis-1/8 min-w-[100px]">
                                Decentralised ID
                            </div>
                            <div className="basis-1/8 min-w-[100px]">
                                Attendance Proof
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TableSection;
