import React, { FC } from 'react';
import { Helmet } from 'react-helmet';
import {
    AdvantageSection,
    BelieveSection,
    ContactUsSection,
    HeroSection,
    ImplementSection,
    TableSection,
} from './sections';

export interface PageAboutProps {
    className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = '' }) => {
    return (
        <div>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <div>
                <HeroSection />
                <TableSection />
                <BelieveSection />
                <ImplementSection />
                <AdvantageSection />
                <ContactUsSection />
            </div>
        </div>
    );
};

export default PageAbout;
