import React, { FC, useEffect, useMemo, useState } from 'react';
import TextField from 'components/Input/TextFields/TextField';
import MagnifyingGlass from 'static/images/icons/magnifying-glass.svg';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store/store';
import { getJobList, getJobTags } from 'store/slices/jobSlice';
import JobListDisplaySection from './JobListDisplaySection';
import Pagination from 'rc-pagination';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import { Helmet } from 'react-helmet';
import { useSearchParams } from 'react-router-dom';
import TextButton from 'components/Buttons/TextButton';
import LoadingJobCard from 'components/JobCard/LoadingJobCard';
// import JobListingPagination from './jobListingPagination';
export interface JobListingProps {
    className?: string;
}

const PageJobListing: FC<JobListingProps> = ({ className }) => {
    const dispatch = useDispatch<AppDispatch>();

    const [urlSearchParam, setUrlSearchParam] = useSearchParams();
    const initSearchParam = useMemo(() => urlSearchParam, []);
    const [searchItem, setSearchItem] = useState(
        initSearchParam.get('query') ?? ''
    );
    const [selectedTags, setSelectedTags] = useState<Array<string>>(
        initSearchParam.getAll('tags') ?? []
    );

    const isLoading = useSelector((root: RootState) => root.job.isLoading);
    const jobs = useSelector((root: RootState) => root.job.job_list);
    const frequentlyTags = useSelector((root: RootState) => root.job.job_tags);

    const displayTags = useMemo(() => {
        return [
            ...new Set(frequentlyTags.concat(initSearchParam.getAll('tags'))),
        ];
    }, [frequentlyTags, initSearchParam]);

    // Handler for Pagination
    const updatePage = (page: number) => {
        const searchParam = {
            query: createQueryString(),
            pagination: { offset: (page - 1) * 16 },
        };
        dispatch(getJobList(searchParam));
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const currentPage = useMemo(() => {
        return Number.parseInt((jobs.offset / jobs.limit).toFixed(0)) + 1;
    }, [jobs]);

    // Handler for tags
    const updateSelectedTags = (value: string) => {
        if (!selectedTags.includes(value)) {
            setSelectedTags((prev) => {
                return [...prev, value];
            });
        } else {
            const newArray = selectedTags.filter((data: string) => {
                return data != value;
            });
            setSelectedTags(newArray);
        }
    };

    function createQueryString() {
        let result = selectedTags;
        if (searchItem != '') {
            result = selectedTags.concat(searchItem);
        }
        return result.join(',');
    }

    // Update job if searchParam change
    useEffect(() => {
        const searchParam = { query: createQueryString() };
        dispatch(getJobList(searchParam));
    }, [searchItem, selectedTags]);

    // Update url
    useEffect(() => {
        let newParam: { [key: string]: string | Array<string> } = {};

        if (currentPage > 1) {
            newParam.page = `${currentPage}`;
        }
        if (searchItem) {
            newParam.query = searchItem;
        }

        if (selectedTags) {
            newParam.tags = selectedTags;
        }
        setUrlSearchParam(newParam);
    }, [currentPage, searchItem, selectedTags]);

    // Read init param and do searching
    useEffect(() => {
        dispatch(getJobTags());

        let page = parseInt(urlSearchParam.get('page') ?? '');
        if (isNaN(page)) {
            page = 1;
        }

        const searchParam = {
            query: createQueryString(),
            pagination: { offset: (page - 1) * 16 },
        };
        dispatch(getJobList(searchParam));
    }, []);

    return (
        <>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <div className="container my-20 scroll-smooth">
                <div className="text-center">
                    <div className="job_page_title">
                        Search for jobs &#128188;
                    </div>
                    <div className="job_page_subtitle">
                        Discover skilled professionals on the chains.
                    </div>
                    {/* <div className="mt-10 text-base-initial-medium">
                        Hiring?
                        <span className="post_job_link">
                            Request to post here
                        </span>
                        .
                    </div> */}
                </div>
                <div className="searchbar_container">
                    <TextField
                        label=""
                        defaultValue={initSearchParam.get('query') ?? ''}
                        placeholder="Search job title or keyword..."
                        prependIcon={<img src={MagnifyingGlass} />}
                        onChangeHandle={setSearchItem}
                    />
                </div>
                <div className="skilltags_container">
                    {displayTags?.map((value: any) => {
                        return (
                            <TextButton
                                text={value}
                                size="sm"
                                buttonType="neutral"
                                className={`rounded-full ${
                                    selectedTags.indexOf(value) == -1
                                        ? ''
                                        : 'text-button-neutral-selected'
                                }`}
                                onClick={() => updateSelectedTags(value)}
                            />
                        );
                    })}
                </div>
                {isLoading ? (
                    <div className='flex flex-col gap-3 mt-3'>
                        {
                            [... new Array(10)].map((_, index) => {
                                return <LoadingJobCard />
                            })
                        }
                    </div>
                ) : (
                    <>
                        <div className="filter_text_regular">
                            showing{' '}
                            <span className="filter_text_bold">
                                {jobs.total ?? 0}
                            </span>{' '}
                            jobs
                        </div>
                        <JobListDisplaySection jobsData={jobs.data} />
                        {jobs.total ? (
                            <div className="flex justify-center mt-10">
                                <Pagination
                                    defaultPageSize={jobs.limit}
                                    total={jobs.total}
                                    onChange={updatePage}
                                    current={currentPage}
                                    locale={{
                                        items_per_page: '/ page',
                                        jump_to: 'Go to',
                                        jump_to_confirm: 'confirm',
                                        page: 'Page',
                                        prev_page: 'Previous Page',
                                        next_page: 'Next Page',
                                        prev_5: '',
                                        next_5: '',
                                        prev_3: '',
                                        next_3: '',
                                    }}
                                    prevIcon={
                                        <div className="pagination_button_normal">
                                            <ChevronLeftIcon />
                                        </div>
                                    }
                                    nextIcon={
                                        <div className="pagination_button_normal">
                                            <ChevronRightIcon />
                                        </div>
                                    }
                                    jumpPrevIcon={
                                        <div className="pagination_button_jump">
                                            ...
                                        </div>
                                    }
                                    jumpNextIcon={
                                        <div className="pagination_button_jump">
                                            ...
                                        </div>
                                    }
                                    className="ant-pagination text-sm-initial-semibold"
                                    showLessItems={true}
                                />
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default PageJobListing;
