import TextButton from 'components/Buttons/TextButton';
import React, { FC, useEffect, useState } from 'react';
import ExperienceLogo from 'static/images/profile/experience_logo.svg';
import IPFSHelper from 'utils/IPFSHelper';
import ProfileSingleExperience from './ProfileSingleExperience';
import axios from 'axios';
import { WorkBadges__factory } from 'typechain/factories/WorkBadges__factory';
import { useWeb3 } from 'utils/useWeb3';
import { workInfoStruct } from 'schemas/structure';
import { DataTypes } from 'typechain/WorkBadges';
import LoadingProfileSingleExperience from './LoadingProfileSingleExperience';

export interface ProfileExperienceProps {
    className?: string;
    badges: any[];
    isLoading: boolean;
    // badges: DataTypes.WorkInfoStructOutput[];
}

const ProfileExperience: FC<ProfileExperienceProps> = ({
    className,
    badges,
    isLoading,
}) => {
    return (
        <>
            <div className="flex flex-row justify-between">
                <div className="flex my-auto">
                    {/* <BriefcaseIcon className="my-auto w-8 h-8" fill="" /> */}
                    <img src={ExperienceLogo} />
                    <div className="text-2xl-initial-semibold ml-3">
                        Experience ({badges?.length || 0})
                    </div>
                </div>
                {/* <TextButton className="ml-auto" size="md" buttonType="primary" text={<div className="text-sm-initial-medium">Edit</div>} /> */}
            </div>
            <div className="bg-neutral-100 rounded-3xl px-6 py-10 mt-6 mb-6">
                <ol className="ml-28">
                    {isLoading
                        ? Array(3)
                            .fill(0)
                            .map(() => {
                                return (
                                    <LoadingProfileSingleExperience />
                                );
                            })
                        : badges?.map((badge, idx) => {
                            let skill_tags =
                                badge?.metadata?.skillAttributes.map(
                                    (val: any) => {
                                        return val?.name;
                                    }
                                );

                            let duration = {
                                start_time: badge.start_time,
                                end_time: badge.end_time,
                            };

                            return (
                                <ProfileSingleExperience
                                    date={duration}
                                    verified={true}
                                    address={badge.address}
                                    tokenId={badge.tokenId}
                                    name={badge.name}
                                    organization={
                                        badge.organisation?.metadata?.name ||
                                        ''
                                    }
                                    image_url={IPFSHelper.convertURI(
                                        badge?.metadata?.image || ''
                                    )}
                                    organization_description={''}
                                    work_description={badge.description || ''}
                                    tags={skill_tags}
                                />
                            );
                        })}
                </ol>
            </div>
        </>
    );
};

export default ProfileExperience;
