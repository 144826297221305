import { FC } from "react"

export interface ProfileSkillsFilterProps {
    items: string[]
    selectedSkill: string;
    className?: string
    filterSkills: (skill_tag: string) => void
}

const ProfileSkillsFilter: FC<ProfileSkillsFilterProps> = ({ items, selectedSkill, className, filterSkills
}) => {
    return(
        <div className={className+" flex flex-nowrap overflow-x-scroll hiddenScrollbar"}>
            <div 
                className={`tag_button ${selectedSkill == "Overall"? "selected" : ""}`}
                onClick={()=>filterSkills("Overall")}
            >
                Overall
            </div> 
            {
                items.map((item: string) => {
                    return(
                        <div 
                            className={`tag_button ml-[10px] ${selectedSkill == item? "selected" : ""}`}
                            onClick={()=>filterSkills(item)}
                        >
                            {item}
                        </div> 
                    )
                })
            }
        </div>
    )
}

export default ProfileSkillsFilter;