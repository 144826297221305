import React, { FC } from 'react';
import image from 'static/images/index/short-summary-image.svg';

export const ShortSummarySection: FC<any> = ({}) => {
    return (
        <div className="container mt-20 mb-20 flex flex-col lg:flex-row gap-10">
            <div className="basis-1/2">
                <img className="mx-auto w-full" src={image} />
            </div>
            <div className="basis-1/2 flex flex-col justify-center gap-5">
                <div className='flex flex-col justify-center gap-2'>
                    <div className="uppercase text-primary-700 text-base-initial-bold">
                        CredentialProof.io
                    </div>
                    <div className="text-3xl-initial-bold">
                        Securely manage your professional identity
                    </div>
                </div>
                <p className="text-sm-initial-regular text-neutral-700">
                    Our platform leverages a decentralised approach to provide a
                    secure and reliable way to manage your professional
                    credentials.
                    <br />
                    <br />
                    With CredentialProof.io, you can easily showcase your
                    qualifications and achievements and take control of your
                    professional identity.
                </p>
            </div>
        </div>
    );
};
