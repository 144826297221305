import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";

import TrideLogoWhite from "static/images/logos/tride-logo-white.png"
// import { CustomLink } from "data/types";
import React from "react";
import LinkedinLogo from "static/images/logos/linkedin.svg"
import FacebookLogo from "static/images/logos/facebook.svg"
import InstagramLogo from "static/images/logos/instagram.svg"
import CredentialProofLogoWhite from "static/images/logos/credential-proof-logo-white.svg"
import CredentialProofTextWhite from "static/images/footer_website_img.svg"
import { CustomLink } from "constants/types";

export interface WidgetFooterMenu {
  id: string;
  title: string;
  menus: CustomLink[];
}

const widgetMenus: WidgetFooterMenu[] = [
  {
    id: "5",
    title: "Getting started",
    menus: [
      { href: "#", label: "Installation" },
      { href: "#", label: "Release Notes" },
      { href: "#", label: "Upgrade Guide" },
      { href: "#", label: "Browser Support" },
      { href: "#", label: "Editor Support" },
      { href: "#", label: "Dark Mode" },
    ],
  },
  {
    id: "1",
    title: "Explore",
    menus: [
      { href: "#", label: "Design features" },
      { href: "#", label: "Prototyping" },
      { href: "#", label: "Design systems" },
      { href: "#", label: "Pricing" },
      { href: "#", label: "Customers" },
      { href: "#", label: "Security" },
    ],
  },
  {
    id: "2",
    title: "Resources",
    menus: [
      { href: "#", label: "Best practices" },
      { href: "#", label: "Support" },
      { href: "#", label: "Developers" },
      { href: "#", label: "Learn design" },
      { href: "#", label: "What's new" },
      { href: "#", label: "Releases" },
    ],
  },
  {
    id: "4",
    title: "Community",
    menus: [
      { href: "#", label: "Discussion Forums" },
      { href: "#", label: "Code of Conduct" },
      { href: "#", label: "Community Resources" },
      { href: "#", label: "Contributing" },
      { href: "#", label: "Concurrent Mode" },
      { href: "#", label: "API Reference" },
    ],
  },
];

const Footer: React.FC = () => {
  const renderWidgetMenuItem = (menu: WidgetFooterMenu, index: number) => {
    return (
      <div key={index} className="text-sm">
        <h2 className="font-semibold text-neutral-700 dark:text-neutral-200">
          {menu.title}
        </h2>
        <ul className="mt-5 space-y-4">
          {menu.menus.map((item, index) => (
            <li key={index}>
              <a
                key={index}
                className="text-neutral-600 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <>
    <div className="nc-Footer relative py-20 lg:pb-28 bg-black h-72 hidden lg:block">
      <div className="container grid grid-cols-4">
          <div className="col-span-3">
            <div className="flex">
              <img src={CredentialProofLogoWhite} className="mr-2 w-8 h-8" />
              <img src={CredentialProofTextWhite} className="h-4 w-auto mt-2" />
            </div>
            <div className="text-white text-xs pt-20">© 2023 CredentialProof.io - All rights reserved</div>
          </div>
          {/* <div className="text-white text-base font-semibold">
            <div className="mb-4">CONNECT WITH US</div>
            <div className="flex">
              <a href="https://www.linkedin.com/company/trideai2022/" target="_blank"><img src={LinkedinLogo} className="h-6"/></a>
              <a href="https://www.instagram.com/tride_id/" target="_blank"><img src={InstagramLogo} className="h-6 ml-6"/></a>
            </div>
          </div> */}
          <div className="text-white text-base font-semibold col-span-1">
            <div className="mb-4 gap-6">CONTACT US</div>
            <div className="text-sm text-regular font-normal">
              <div>15C-D West Gate Tower</div>
              <div>9 Wing Hong Street</div>
              <div>Lai Chi Kok</div>
              <div>Hong Kong</div>
              <div className="mt-4"><ul>gm@ergon.global</ul></div>
            </div>
          </div>
        {
        //widgetMenus.map(renderWidgetMenuItem)
      }
      </div>
    </div>
    <div className="nc-Footer relative bg-black flex lg:hidden justify-center text-center">
      <div className="px-4 py-11">
          <div className="col-span-2 flex justify-center">
            <img src={CredentialProofLogoWhite} className="mr-2" />
            <img src={CredentialProofTextWhite} />
          </div>
          {/* <div className="text-white text-base font-semibold">
            <div className="mb-4 mt-10">CONNECT WITH US</div>
            <div className="flex justify-center gap-6">
              <a href="https://www.linkedin.com/company/trideai2022/" target="_blank"><img src={LinkedinLogo} className="h-6"/></a>
              <a href="https://www.instagram.com/tride.ai/" target="_blank"><img src={InstagramLogo} className="h-6 ml-6"/></a>
            </div>
          </div> */}
          <div className="text-white text-base font-semibold mt-16">
            <div className="mb-4">CONTACT US</div>
            <div className="text-sm text-regular font-normal">
              <div>15C-D West Gate Tower</div>
              <div>9 Wing Hong Street</div>
              <div>Lai Chi Kok</div>
              <div>Hong Kong</div>
              <div className="mt-4"><ul>gm@ergon.global</ul></div>
            </div>
          </div>
          <div className="text-white text-xs mt-10">© 2023 CredentialProof.io - All rights reserved</div>
      </div>
    </div>
    </>
  );
};

export default Footer;
