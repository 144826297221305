import http from './http-common';
import axios from 'axios';
import { BACKEND_URL } from 'constants/backend';
import { EventResponse } from 'schemas/response';

class AuthService {
    defaultHeader = axios.defaults.headers.common;
    client = axios.create({
        baseURL: BACKEND_URL,
        headers: {
            ...this.defaultHeader,
            // 'Access-Control-Allow-Origin': '*',
        },
    });

    async getNonce() {
        const data = await this.client.get('/auth/nonce', {});
        return data;
    }

    async getJwt(message: string, signature: string) {
        const data = await this.client.post('/auth/login', {
            message: message,
            signature: signature,
        });
        return data;
    }
}

export default new AuthService();
