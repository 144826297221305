import http from './http-common';
import axios from 'axios';
import { BACKEND_URL } from 'constants/backend';
import { EventResponse } from 'schemas/response';

class EventService {
    client = axios.create({ baseURL: BACKEND_URL });

    async createEvent({
        eventContractAddress,
        tokenId,
    }: {
        eventContractAddress: string;
        tokenId: number;
    }) {
        const data = await this.client.post('/events', {
            eventContractAddress,
            tokenId,
        });
        return data;
    }

    async getEvent(id: string) {
        const data = await this.client.get<EventResponse>(`/events/${id}`);
        return data;
    }
}

export default new EventService();
