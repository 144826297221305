import React, { FC, useState } from 'react';
import Badge from 'shared/Badge/Badge';
import Avatar from 'shared/Avatar/Avatar';
import collectionPng from 'static/images/org_esperanza.png';
import VerifyIcon from 'components/VerifyIcon';
import TextButton from 'components/Buttons/TextButton';
import { EventBadgeMetadata } from 'schemas/metadata';
import { useAppDispatch } from 'store/hooks';
import { claimEvent } from 'store/slices/eventSlice';
import { EventResponse } from 'schemas/response';
import { useWeb3 } from 'utils/useWeb3';
import CircularLoader from 'shared/Loadable/CircularLoader';
import {
    badgesInformation,
    badgesSliceFunction,
} from 'store/slices/badgesSlice';
import { useNavigate } from 'react-router-dom';
import IPFSHelper from 'utils/IPFSHelper';
import OtpInput from 'react-otp-input';

export default function BadgeClaimCard({
    badgesInformation,
    backend_BadgesId,
    company,
}: {
    badgesInformation: badgesInformation;
    backend_BadgesId: string;
    company: any;
}) {
    const dispatch = useAppDispatch();
    const { controller } = useWeb3()!;
    const navigate = useNavigate();

    const [otp, setOtp] = useState('');

    const onClick = () => {
        if (controller && badgesInformation) {
            dispatch(
                badgesSliceFunction.claimBadges({
                    secret: otp,
                    contractAddress: badgesInformation.contractAddress,
                    tokenId: badgesInformation.tokenId,
                    controller: controller,
                })
            ).then(() => {

                // todo
                // navigate(`/badges/${backend_BadgesId}/success`);
            });
        }
    };

    return (
        <div className="w-full rounded-2xl  p-8 mt-8 mb-16">
            <div className="flex flex-col items-center  mb-4">
                <h4 className=" font-semibold mb-6">Enter the secret code</h4>

                <OtpInput
                    value={otp}
                    onChange={setOtp}
                    numInputs={6}
                    containerStyle={{
                        gap: '8px',
                    }}
                    inputStyle="rounded-md border-2 border-neutral-900 text-neutral-900 focus:border-neutral-900 focus:ring-primary-500 focus:ring-2 sm:text-sm text-center"
                    renderInput={(props) => (
                        <input
                            {...props}
                            style={{
                                width: '40px',
                                height: '48px',
                            }}
                        />
                    )}
                />
            <div className="mt-8">
                <TextButton
                    buttonType="primary"
                    text="Claim now"
                    size="md"
                    onClick={onClick}
                />
            </div>
            </div>

        </div>
    );
}
