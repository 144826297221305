import React, { FC } from 'react';
import {
    AddNoteIcon,
    FootprintIcon,
    VirtualMeetingIcon,
} from 'components/Icon/outline';
import star from 'static/images/icons/star.svg';

const data = [
    {
        title: (
            <>
                <FootprintIcon
                    className="inline-block"
                    iconClass="fill-primary-900 h-7 w-7"
                />
                <span>Imprint</span>
            </>
        ),
        content:
            'Leave your professional footprints by completing courses, assessments, attending events, or working for organisations within our ecosystem.',
    },
    {
        title: (
            <>
                <AddNoteIcon
                    className="inline-block"
                    iconClass="fill-primary-900 h-7 w-7"
                />
                <span>Collect</span>
            </>
        ),
        content:
            'Earn SBT credentials as a representation of your commitment and dedication and prove your professional journey.',
    },
    {
        title: (
            <>
                <VirtualMeetingIcon
                    className="inline-block"
                    iconClass="fill-primary-900 h-7 w-7"
                />
                <span>Showcase</span>
            </>
        ),
        content:
            'Impress potential employers and professional connections by showcasing your verified-by-default on-chain professional profile.',
    },
];

export const StepSection: FC<any> = ({}) => {
    return (
        <div className="container mt-20 mb-20 flex flex-col gap-10">
            <div className="relative bg-white">
                <h1 className="border-2 border-primary-900 py-5 px-12 rounded-2xl text-3xl-initial-bold text-center text-primary-900 hard-shadow-8">
                    Own and share your professional achievements
                </h1>
                <img className="absolute -top-[8px] -right-[8px]" src={star} />
                <img
                    className="absolute -bottom-[8px] -left-[8px]"
                    src={star}
                />
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                {data.map(({ title, content }, index) => (
                    <div className=''>
                        <div className="bg-white w-12 h-12 border-2 border-primary-900 rounded-xl hard-shadow-4 grid place-content-center">
                            <div className='text-2xl-initial-bold text-primary-900'>{index + 1}</div>
                        </div>
                        <div className="flex flex-col gap-5 mt-8">
                            <div className="flex gap-2 items-center text-2xl-initial-semibold text-primary-900">
                                {title}
                            </div>
                            <div className='text-sm-initial-regular text-primary-900'>{content}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};
