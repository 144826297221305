import React, { FC } from 'react';
import { useWindowSize } from 'react-use';
import academicImg from 'static/images/PageUseCase/sections/academic.svg';

const AcademicSection: FC<any> = ({ className = '' }) => {
    const { width } = useWindowSize();

    return (
        <div className="flex flex-row p-8">
            {width >= 1280 ? (
                <>
                    <div className="basis-1/3 flex items-center">
                        <img className="mx-auto" src={academicImg} />
                    </div>
                    <div className="basis-2/3">
                        <h1 className="text-2xl-initial-semibold text-primary-700 mb-2">
                            Academic Organisation
                        </h1>
                        <p className="text-base-initial-regular text-neutral-600">
                            Academic organisations can use Credentialproof.io to
                            issue{' '}
                            <span className="font-bold">
                                academic certificates
                            </span>{' '}
                            to students who have completed their courses. This
                            eliminates the need for manual record keeping and
                            verification processes, saving time and resources.
                            Also, this can generate more exposure for the
                            organisation, as these certificates are discoverable
                            on the chain.
                        </p>
                    </div>
                </>
            ) : (
                <div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start">
                            <img className="mx-auto" src={academicImg} />
                        </div>
                        <div className="basis-3/4 flex items-center">
                            <h1 className="text-2xl-initial-semibold text-primary-700">
                                Academic Organisation
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start"></div>
                        <div className="basis-3/4 flex items-center">
                            <p className="text-base-initial-regular text-neutral-600">
                                Academic organisations can use
                                Credentialproof.io to issue{' '}
                                <span className="font-bold">
                                    academic certificates
                                </span>{' '}
                                to students who have completed their courses.
                                This eliminates the need for manual record
                                keeping and verification processes, saving time
                                and resources. Also, this can generate more
                                exposure for the organisation, as these
                                certificates are discoverable on the chain.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default AcademicSection;
