import React, { FC } from 'react';
import CredentialProofLogo from 'static/images/logos/credential-proof-logo.svg';
import CredentialProofTextImage from 'static/images/navbar_website_img.svg';
import { useNavigate } from 'react-router-dom';
import TextButton from 'components/Buttons/TextButton';
import AccountRelatedArea from './component/AccountRelatedArea';
import SearchDropdown from './SearchDropdown';
import { DOC_NOTION_URL } from 'constants/backend';
import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/outline';

export interface NavbarProps {}

const SDK_NOTION_PAGE =
    'https://ergonglobal.notion.site/SDK-integration-3a69ad6048f6432686ec40a0fca4274e';

const Navbar: FC<NavbarProps> = () => {
    const [showSidebar, setShowSidebar] = React.useState(false);
    const navigate = useNavigate();

    const navigateTo = (path: string) => {
        setShowSidebar(false);
        navigate(path);
    };

    const renderDevButton = () => {
        return (
            <Menu className="relative" as="div">
                <Menu.Button>
                    {({ open }) => (
                        <TextButton
                            size="md"
                            buttonType="text"
                            className="navbar-link-button outline-none"
                            text="For Builder"
                            appendIcon={
                                <ChevronDownIcon
                                    className={`transition-transform ${
                                        open ? 'rotate-180' : ''
                                    }`}
                                />
                            }
                        />
                    )}
                </Menu.Button>
                <Transition
                    as={React.Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute right-4 z-10 mt-2 py-1 w-60 rounded-lg rounded-tr-none origin-top-right bg-white border-2 border-neutral-900">
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={`px-5 py-4 text-sm-initial-regular ${
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700'
                                    }`}
                                    onClick={() => window.open(SDK_NOTION_PAGE)}
                                >
                                    Builder SDK
                                </div>
                            )}
                        </Menu.Item>
                        <Menu.Item>
                            {({ active }) => (
                                <div
                                    className={`px-5 py-4 text-sm-initial-regular ${
                                        active
                                            ? 'bg-gray-100 text-gray-900'
                                            : 'text-gray-700'
                                    }`}
                                    onClick={() => window.open(SDK_NOTION_PAGE)}
                                >
                                    Documentations
                                </div>
                            )}
                        </Menu.Item>
                    </Menu.Items>
                </Transition>
            </Menu>
        );
    };

    return (
        <>
            <div
                className={`sticky top-0 w-full z-50 transition-all border-b-2 bg-white border-neutral-900`}
            >
                <div className="container py-5 relative flex justify-between items-center h-[60px] lg:h-16">
                    <div className="flex justify-between flex-grow items-center">
                        <div
                            className="flex cursor-pointer"
                            onClick={() => navigate('/')}
                        >
                            <img src={CredentialProofLogo} className="mr-2" />
                            <img
                                src={CredentialProofTextImage}
                                className="hidden lg:block"
                            />
                        </div>
                        <div className="flex items-center gap-4">
                            <TextButton
                                size="md"
                                buttonType="text"
                                className={`navbar-link-button${
                                    window.location.pathname == '/about'
                                        ? '-active'
                                        : ''
                                }`}
                                onClick={() => navigate('/about')}
                                text="About"
                            />
                            <TextButton
                                size="md"
                                buttonType="text"
                                className={`navbar-link-button${
                                    window.location.pathname == '/use-case'
                                        ? '-active'
                                        : ''
                                }`}
                                onClick={() => navigate('/use-case')}
                                text="Use Cases"
                            />

                            {renderDevButton()}

                            <a href={DOC_NOTION_URL} target="_blank">
                                <TextButton
                                    size="md"
                                    buttonType="text"
                                    className="navbar-link-button"
                                    text="Docs"
                                />
                            </a>
                            <div className="flex gap-3 lg:gap-3 items-center ml-4 border-0 lg:border-l-2 border-neutral-200">
                                <div className="ml-4 lg:ml-5">
                                    <AccountRelatedArea />
                                </div>
                                <div
                                    className="navbar-menu-button"
                                    onClick={() => setShowSidebar(true)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        strokeWidth={1.5}
                                        stroke="currentColor"
                                        className="w-7 h-7"
                                    >
                                        <path
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                            d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                                        />
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div
                className={`side-menu-overlay ${
                    showSidebar
                        ? 'opacity-100 z-50 duration-300 transition-opacity flex visible'
                        : 'opacity-0 z-10 duration-300 transition-opacity invisible'
                }`}
            >
                <div
                    className="side-menu-exit-button"
                    onClick={() => setShowSidebar(false)}
                >
                    <div className="relative">
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-7 h-7"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </div>
                </div>
                <div className="flex flex-col my-auto mx-auto gap-9 text-center">
                    <div
                        className="text-2xl-initial-medium cursor-pointer"
                        onClick={() => navigateTo('/about')}
                    >
                        About
                    </div>
                    <div
                        className="text-2xl-initial-medium cursor-pointer"
                        onClick={() => navigateTo('/use-case')}
                    >
                        Use Cases
                    </div>
                    <a href={DOC_NOTION_URL} target="_blank">
                        <div className="text-2xl-initial-medium cursor-pointer">
                            Docs
                        </div>
                    </a>
                </div>
            </div>
        </>
    );
};

export default Navbar;
