// Hook (use-auth.js)
import React, {
    useState,
    useEffect,
    useContext,
    createContext,
    ReactNode,
} from 'react';

import Torus, { TorusPublicKey, UserInfo } from '@toruslabs/torus-embed';
import { ethers } from 'ethers';
import { isMobile } from 'utils/isMobile';
import {
    Alchemy_API_KEY,
    torusProviderOptions,
    TORUS_INIT_LOGIN_CONFIG,
    TORUS_PROVIDER_INFO,
    walletConnectProviderOptions,
} from 'constants/blockchain';
import EtherController from './etherController';
import { DEPLOY_CHAIN } from 'constants/chainConfig';
import IPFSHelper from './IPFSHelper';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { haveValidJwt } from './jwt';
import { setSIWEModel } from 'store/slices/authSlice';
import { Alchemy, Network } from 'alchemy-sdk';
declare var window: any;

const getProviderOptions = () => {
    if (isMobile()) {
        return {
            ...torusProviderOptions,
            ...walletConnectProviderOptions,
        };
    } else {
        return torusProviderOptions;
    }
};

interface W3Context {
    alchemyInstance: Alchemy;
    connectTorus: () => Promise<void>;
    connectMetamask: () => Promise<void>;
    controller: EtherController | null;
    address: string;
    currentNetwork: number | null;
    displayAddress: string;
    disconnect: () => void;
    changeMetamaskNetwork: () => Promise<void>;
}
const Web3Context = createContext<W3Context | null>(null);
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideWeb3({ children }: { children: ReactNode }) {
    const auth = useProvideAuth();
    return <Web3Context.Provider value={auth}>{children}</Web3Context.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useWeb3 = () => {
    return useContext(Web3Context);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [address, setAddress] = useState<string>('');
    const [displayAddress, setDisplayAddress] = useState('');
    const [currentNetwork, setCurrentNetwork] = useState<number | null>(null);
    const [controller, setController] = useState<EtherController | null>(null);
    const [provider, setProvider] =
        useState<ethers.providers.Web3Provider | null>(null);
    const dispatch = useDispatch<AppDispatch>();

    const [torus, setTorus] = useState(new Torus());

    const alchemySettings = {
        apiKey: Alchemy_API_KEY, // Replace with your Alchemy API Key.
        network: Network.ETH_MAINNET, // Replace with your network.
    };

    const alchemyInstance = new Alchemy(alchemySettings);

    useEffect(() => {
        torus
            .init({ loginConfig: TORUS_INIT_LOGIN_CONFIG })
            .then((initData: void) => {
                console.log('Initialization Success');
                torus.hideTorusButton();
            })
            .catch((err: Error) => {
                console.error('Torus initialization failure: ' + err.message);
            });
    }, []);

    const changeMetamaskNetwork = async () => {
        const chainId = DEPLOY_CHAIN.chainId;
        if (currentNetwork !== chainId) {
            try {
                await (window as any)?.ethereum?.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: ethers.utils.hexValue(chainId) }],
                });
                setCurrentNetwork(chainId);
            } catch (err: any) {
                // This error code indicates that the chain has not been added to MetaMask
                if (err.code != 4001) {
                    await (window as any)?.ethereum?.request({
                        method: 'wallet_addEthereumChain',
                        params: [
                            {
                                chainName: DEPLOY_CHAIN.name,
                                chainId: ethers.utils.hexValue(chainId),
                                nativeCurrency: {
                                    name: DEPLOY_CHAIN.currency,
                                    decimals: 18,
                                    symbol: DEPLOY_CHAIN.currency,
                                },
                                rpcUrls: [DEPLOY_CHAIN.rpc],
                            },
                        ],
                    });
                    const currentChain = await window?.ethereum?.request?.({
                        method: 'eth_chainId',
                    });
                    setCurrentNetwork(currentChain);
                }
            }
        }
    };

    useEffect(() => {
        if (address.toString().length !== 0 && provider) {
            lookupAddress(address.toString());
        } else {
            setDisplayAddress('');
        }
    }, [address, provider]);

    const disconnect = () => {
        const cleanUp = async () => {
            await torus
                .logout()
                .then((data: any) => {
                    console.log(data);
                })
                .catch((error: Error) => {
                    console.log(error.message);
                });
            await torus.cleanUp();
        };
        cleanUp();
        localStorage.removeItem('metamaskAccount');
        localStorage.removeItem('loglevel:torus-embed');
        setAddress('');
        setDisplayAddress('');
        localStorage.removeItem('jwt');
        window.location.reload();
    };

    const connectMetamask = async () => {
        //metamask login popup with switch network if not on correct network

        const accounts = await window.ethereum?.request?.({
            method: 'eth_requestAccounts',
        });

        localStorage.setItem('metamaskAccount', accounts[0]);
        setAddress(accounts[0]);

        await changeMetamaskNetwork();
        const provider = new ethers.providers.Web3Provider(
            (window as any).ethereum
        );

        setProvider(provider);
        const controller = new EtherController(provider);
        setController(controller);
        // const cid = await _createOnChainUserProfile(accounts[0]);
    };

    const lookupAddress = (address: string) => {
        //   const provider = ethers.providers.getDefaultProvider();
        const provider = new ethers.providers.AlchemyProvider(
            'mainnet',
            Alchemy_API_KEY
        );

        console.log('lookupAddress', address, provider);

        setDisplayAddress(
            address.slice(0, 6) +
                '...' +
                address.slice(address.length - 4, address.length)
        );

        provider
            .lookupAddress(address)
            .then((ens) => {
                if (ens) {
                    console.log('lookupAddress, ens', ens);
                    setDisplayAddress(ens || '??');

                    let resolver = provider.getResolver(ens);
                } else {
                    setDisplayAddress(
                        address.slice(0, 6) +
                            '...' +
                            address.slice(address.length - 4, address.length)
                    );
                }
            })
            .catch((err) => {
                console.log('lookupAddress, err', err);
                setDisplayAddress(
                    address.slice(0, 6) +
                        '...' +
                        address.slice(address.length - 4, address.length)
                );
            });
    };

    const connectTorus = async () => {
        await torus
            .login()
            .then(async (loginData: string[]) => {
                console.log('Torus login successful: ' + loginData);
                torus.hideTorusButton();
                await torus
                    .getUserInfo('Retrieving information...')
                    .then(async (userInfoData: UserInfo) => {
                        const userInfo = userInfoData;
                        console.log('Torus get user info successful: ');
                        console.log(userInfoData);
                        await torus
                            .setProvider(TORUS_PROVIDER_INFO)
                            .then(async (providerData: void) => {
                                console.log('Torus set provider successful');
                                await torus
                                    .getPublicAddress({
                                        verifier: 'google',
                                        verifierId: userInfo.email,
                                    })
                                    .then(
                                        async (
                                            publicAddress:
                                                | string
                                                | TorusPublicKey
                                        ) => {
                                            console.log(
                                                'Torus get public address successful: ' +
                                                    publicAddress
                                            );
                                            setAddress(
                                                publicAddress.toString()
                                            );
                                            localStorage.setItem(
                                                'torusAccount',
                                                publicAddress.toString()
                                            );
                                            setCurrentNetwork(
                                                DEPLOY_CHAIN.chainId
                                            );
                                            const provider =
                                                new ethers.providers.Web3Provider(
                                                    torus.provider
                                                );
                                            const controller =
                                                new EtherController(provider);

                                            console.warn(
                                                await controller.getAccount()
                                            );
                                            setAddress(
                                                publicAddress.toString()
                                            );
                                            setController(controller);
                                            setProvider(provider);
                                            torus.showTorusButton();
                                            // setShow(false);

                                            // const cid = await _createOnChainUserProfile(publicAddress);
                                        }
                                    )
                                    .catch((err: Error) => {
                                        console.error(
                                            'Torus get public address error: ' +
                                                err.message
                                        );
                                    });
                            })
                            .catch((err: Error) => {
                                console.error(
                                    'Torus set provider error: ' + err.message
                                );
                            });
                    })
                    .catch((err: Error) => {
                        console.error(
                            'Torus get user info error: ' + err.message
                        );
                    });
            })
            .catch((err: Error) => {
                console.error('Torus login error: ' + err.message);
            });
    };

    //autologin if previously logged in
    React.useEffect(() => {
        const metamaskLogin = async () => {
            const accounts = await window.ethereum?.request?.({
                method: 'eth_requestAccounts',
            });
            setAddress(accounts[0]);
            const chainId = await window.ethereum?.request?.({
                method: 'eth_chainId',
            });
            if (chainId != DEPLOY_CHAIN.chainId) {
                changeMetamaskNetwork();
            } else {
                setCurrentNetwork(DEPLOY_CHAIN.chainId);
            }
            const provider = new ethers.providers.Web3Provider(
                (window as any).ethereum
            );
            const controller = new EtherController(
                provider,
                provider.getSigner()
            );
            setController(controller);
            setProvider(provider);
        };

        if (localStorage.getItem('metamaskAccount')) {
            metamaskLogin();
        } else if (localStorage.getItem('torusAccount')) {
            localStorage.removeItem('torusAccount');
            setAddress('');
        }
    }, []);

    React.useEffect(() => {
        if (address && controller) {
            if (!haveValidJwt()) {
                async function delay() {
                    setTimeout(() => {
                        dispatch(setSIWEModel(true));
                    }, 250);
                }
                delay()
            }
        }
    }, [address, controller]);

    React.useEffect(() => {
        if (window?.ethereum) {
            console.log('window.ethereum', window.ethereum);
            window?.ethereum?.on('chainChanged', async () => {
                const chainId = await window.ethereum?.request?.({
                    method: 'eth_chainId',
                });
                // setCurrentNetwork(chainId)
                setCurrentNetwork(parseInt(ethers.utils.hexValue(chainId)));
            });
            window.ethereum?.on('accountsChanged', async () => {
                const accounts = await window.ethereum?.request?.({
                    method: 'eth_requestAccounts',
                });

                setAddress((addr) => {
                    console.log('previous addr:', addr);
                    if (addr !== '') {
                        return accounts[0];
                    }
                    return addr;
                });
            });
        }

        return () => {
            window.ethereum?.off('chainChanged');
            window.ethereum?.off('accountsChanged');
        };
    }, []);

    return {
        alchemyInstance,
        connectTorus,
        connectMetamask,
        controller,
        address,
        displayAddress,
        currentNetwork,
        disconnect,
        changeMetamaskNetwork,
    };
}
