import React, { FC } from 'react';

export interface WarningIconProps {
    className?: string;
    iconClass?: string;
}

const WarningIcon: FC<WarningIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 26 23" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.96811 2.25439C11.315 -0.0802388 14.6845 -0.0802397 16.0314 2.25439L24.6115 17.1265C25.9576 19.4598 24.2736 22.3755 21.5798 22.3755H4.41971C1.7259 22.3755 0.0419046 19.4598 1.38806 17.1265L9.96811 2.25439ZM13 8.37543C13.4833 8.37543 13.875 8.76718 13.875 9.25043V13.6254C13.875 14.1087 13.4833 14.5004 13 14.5004C12.5168 14.5004 12.125 14.1087 12.125 13.6254V9.25043C12.125 8.76718 12.5168 8.37543 13 8.37543ZM13 18.0004C13.4833 18.0004 13.875 17.6087 13.875 17.1254C13.875 16.6422 13.4833 16.2504 13 16.2504C12.5168 16.2504 12.125 16.6422 12.125 17.1254C12.125 17.6087 12.5168 18.0004 13 18.0004Z"
                    fill="#EF4444"
                />
            </svg>
        </span>
    );
};

export default WarningIcon;
