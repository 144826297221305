import React, { FC, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import eventServices from 'services/event.services';
import Avatar from 'shared/Avatar/Avatar';
import Badge from 'shared/Badge/Badge';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import ButtonSecondary from 'shared/Button/ButtonSecondary';
import NcImage from 'shared/NcImage/NcImage';
import eventSlice, { getEventDetail } from 'store/slices/eventSlice';
import { AppDispatch, RootState, store } from 'store/store';
import IPFSHelper from 'utils/IPFSHelper';
import { useWeb3 } from 'utils/useWeb3';
import BadgeAccordion from './BadgeAccordion';
import BadgeClaimCard from './BadgeClaimCard';
import EVENT_ABI from 'abi/EventBadges.json';
import axios from 'axios';
import { EventBadgeMetadata } from 'schemas/metadata';
import CircularLoader from 'shared/Loadable/CircularLoader';
import badgesSlice, { badgesSliceFunction } from 'store/slices/badgesSlice';
import { BADGES_TYPE } from 'constants/types';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy.svg';
import WorkBadgeAccordion from './WorkBadgeAccordion';
import { getProvider } from 'constants/blockchain';
import { gql, useQuery } from '@apollo/client';
import SmallCircularLoader from 'shared/Loadable/SmallCircularLoader';

export interface NftDetailPageProps {
    className?: string;
    isPreviewMode?: boolean;
}

const Overlay = () => (
    <div
        style={{
            position: 'absolute',
            top: 0,
            width: '100%',
            zIndex: 20,
            height: '100%',
            background: '#F8F8F8',
            // background: "#00000044",
        }}
    >
        <SmallCircularLoader className="flex flex-col justify-center items-center w-full h-full" />
    </div>
);

const GET_ORG_REQUESTS = gql`
    query Organisation($orgId: ID!) {
        organisation(id: $orgId) {
            id
            name
            metadata {
                name
                description
                image
            }
        }
    }
`;

const PageClaimBadge: FC<NftDetailPageProps> = ({
    className = '',
    isPreviewMode,
}) => {
    const navigate = useNavigate();

    const renderBadgesInfo = () => {
        let result: any;

        if (badgesInformation) {
            result = (
                <WorkBadgeAccordion
                    detailInfo={badgesInformation}
                    company={company}
                />
            );
        }

        return result;
    };

    const dispatch = useDispatch<AppDispatch>();
    const isLoading = useSelector((root: RootState) => root.badges.loading);
    const isClaimed = useSelector((root: RootState) => root.badges.claimed);

    const [company, setCompany] = useState<any>(null);

    const orgId = useSelector(
        (root: RootState) => root.workBadges.organisatinoId
    );

    const {
        loading,
        error,
        data: orgData,
        refetch,
    } = useQuery(GET_ORG_REQUESTS, {
        variables: { orgId: orgId },
    });

    React.useEffect(() => {
        console.log('orgData', orgData);

        const comp = {
            id: orgData?.organisation?.['id'] as number,
            name: orgData?.organisation?.metadata?.['name'] as string,
            image: orgData?.organisation?.metadata?.['image'] as string,
        };
        setCompany(comp);
    }, [orgData]);

    const ownerAddress =
        useSelector((root: RootState) => root.workBadges.ownerAddress) || '';

    const isFetchByAddress = false;

    const badgesInformation = useSelector(
        (root: RootState) => root.badges.badgesInformation
    );
    const metadata = useSelector(
        (root: RootState) => root.badges.badgesInformation?.metadata
    );

    const { controller, address: myAddress } = useWeb3()!;
    const [hasBadge, setHasBadge] = useState(false);

    const creator = badgesInformation?.contractAddress || '';

    function handleAddressBtn(content: string) {
        navigator.clipboard.writeText(content);
    }

    const renderOwnerAddress = () => {
        // if (!hasBadge) return null;
        return (
            <div className="mt-8 grid grid-cols-2 lg:grid-cols-2 gap-12 p-4">
                <div>
                    <div className="text-xs-initial-medium text-neutral-400">
                        Creator
                    </div>
                    <div className="text-base-initial-medium text-neutral-800 flex">
                        {creator.slice(0, 6) +
                            '...' +
                            creator.slice(creator.length - 4, creator.length)}
                        <div
                            className="cursor-pointer ml-2 mt-1"
                            onClick={() => handleAddressBtn(creator)}
                        >
                            <CopyIcon className="hover:text-neutral-100 hover:fill-white" />
                        </div>
                    </div>
                </div>
                <div className="border-neutral-200 border-l-2 pl-4 lg:pl-8">
                    <div className="text-xs-initial-medium text-neutral-400">
                        Owner
                    </div>
                    <div className="text-base-initial-medium text-neutral-800 flex">
                        {ownerAddress.slice(0, 6) +
                            '...' +
                            ownerAddress.slice(
                                ownerAddress.length - 4,
                                ownerAddress.length
                            )}
                        <div
                            className="cursor-pointer ml-2 mt-1"
                            onClick={() => handleAddressBtn(ownerAddress)}
                        >
                            <CopyIcon />
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    let { id, address, tokenId } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(badgesSliceFunction.getBadgesInfo(id));
        }
    }, [id]);

    useEffect(() => {
        if (address && tokenId) {
            dispatch(
                badgesSliceFunction.getBadgesInfoFromContract({
                    address,
                    tokenId: parseInt(tokenId),
                    provider: getProvider(),
                })
            );
        }
    }, [address, tokenId]);

    useEffect(() => {
        if (badgesInformation && controller) {
            controller
                ?.isOwnBadges(
                    badgesInformation.type,
                    badgesInformation.contractAddress,
                    badgesInformation.tokenId
                )
                .then((isOwnBadges) => {
                    setHasBadge(isOwnBadges);
                });

            controller
                ?.isBadgeClaimable(
                    badgesInformation.type,
                    badgesInformation.contractAddress,
                    badgesInformation.tokenId
                )
                .then((able) => {
                    setClaimable(able);
                })
                .catch((e) => console.log('my address ', e));
        }
    }, [badgesInformation, controller]);

    const [claimable, setClaimable] = useState(false);

    const checkClaimable = () => {
        return (badgesInformation && !hasBadge && claimable) || false;
    };

    const Headline = () => {
        // if (id == null) return <>Badge Information</>;
        return (
            <>
                {hasBadge
                    ? 'You’ve already claimed the following badge.'
                    : claimable
                    ? 'Claim your SBT'
                    : 'You’re not allowed to claim this badge'}
            </>
        );
    };

    useEffect(() => {
        if (isClaimed) {
        }
    }, [isClaimed]);

    return (
        <div
            className={`nc-NftDetailPage  ${className}`}
            data-nc-id="NftDetailPage"
        >
            {/* MAIn */}
            {isLoading ? <Overlay /> : null}
            <main className="container  mt-11 ">
                <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold mb-6 text-center">
                    <Headline />
                </h2>

                <div className="flex">
                    <div className="w-full flex flex-col items-center">
                        {/* CONTENT */}
                        <div className="space-y-8 lg:space-y-10 content-center pb-9 max-w-xl w-full">
                            {/* HEADING */}
                            <div className="relative">
                                <NcImage
                                    src={IPFSHelper.convertURI(
                                        badgesInformation?.metadata?.image || ''
                                    )}
                                    containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden border-4 border-black"
                                />
                            </div>
                        </div>

                        {checkClaimable() && (
                            <BadgeClaimCard
                                company={company}
                                badgesInformation={badgesInformation!}
                                backend_BadgesId={id!}
                            />
                        )}

                        {/* SIDEBAR */}
                        <div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
                            <div className="divide-y divide-neutral-100 dark:divide-neutral-800">
                                <div className="pb-9 space-y-5">
                                    {renderOwnerAddress()}

                                    {renderBadgesInfo()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    );
};

export default PageClaimBadge;
