import React, { FC, useEffect, useId, useMemo, useRef } from 'react';
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/themes/material_blue.css';
import { CalendarDaysIcon } from 'components/Icon/outline';
import { Instance } from 'flatpickr/dist/types/instance';

export interface DatepickerProps {
    mode?: 'single' | 'multiple' | 'range' | 'time';
    minDate?: Date | string | number;
    maxDate?: Date | string | number;
    defaultDate?: Date | string | number;
    placeholder?: string;
    disabled?: boolean;
    onChangeHandler?: (date: Date[]) => void;
}

const Datepicker: FC<DatepickerProps> = ({
    mode = 'single',
    minDate,
    maxDate,
    defaultDate,
    placeholder,
    disabled,
    onChangeHandler,
}) => {
    const _thisId = useId();

    const options: any = {
        mode: mode,
        static: true,
        monthSelectorType: 'dropdown',
        dateFormat: 'j/n/Y',
        minDate: minDate,
        maxDate: maxDate,
        defaultDate: defaultDate,
        prevArrow:
            '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M5.4 10.8l1.4-1.4-4-4 4-4L5.4 0 0 5.4z" /></svg>',
        nextArrow:
            '<svg class="fill-current" width="7" height="11" viewBox="0 0 7 11"><path d="M1.4 10.8L0 9.4l4-4-4-4L1.4 0l5.4 5.4z" /></svg>',
        wrap: true,
    };

    const style = useMemo(() => {
        return disabled
            ? 'border-neutral-300 text-neutral-400 bg-neutral-200 cursor-text pointer-events-none placeholder:text-neutral-300'
            : 'border-neutral-900 text-neutral-900 focus:border-neutral-900 focus:ring-primary-500 focus:ring-2';
    }, [disabled]);

    const _onChangeHandler = (
        dates: Date[],
        currentDateString: string,
        self: Instance,
        data?: any
    ) => {
        if (onChangeHandler && !disabled) {
            onChangeHandler(dates);
        }
    };

    return (
        <div className="relative w-full">
            <Flatpickr data-input options={options} onChange={_onChangeHandler}>
                <input
                    id={_thisId}
                    data-input
                    type="text"
                    className={`block w-full rounded-md pl-5 pr-11 sm:text-sm border-2 ${style}`}
                    placeholder={placeholder}
                />
                <label
                    data-toggle
                    htmlFor={`${_thisId}`}
                    className={`absolute inset-y-0 right-0 flex items-center pr-3 ${
                        !disabled ? 'cursor-pointer' : 'pointer-events-none fill-neutral-300'
                    }`}
                >
                    <CalendarDaysIcon iconClass="h-5 w-5" />
                </label>
            </Flatpickr>
        </div>
    );
};

export default Datepicker;
