import { FC, ReactElement } from 'react';

export interface AdvantageCardProps {
    image: string;
    title: string;
    content: string | ReactElement;
    link?: string;
}

export const AdvantageCard: FC<AdvantageCardProps> = ({ image, title, content, link }) => {
    return (
        <div
            className="flex flex-col gap-4 p-6 bg-white border border-neutral-700 rounded-3xl"
            style={{ boxShadow: `8px 8px 0 0 #60E829` }}
        >
            <img className="mx-auto -mt-[50px]" src={image} />
            <h1 className="text-xl-initial-semibold text-primary-700">
                {title}
            </h1>
            <p className="text-sm-initial-regular text-neutral-700">{content}</p>
            {link && (
                <div className='mt-auto'>
                    <a className="text-primary-700 underline">Learn more</a>
                </div>
            )}
        </div>
    );
};
