import React, { FC, useEffect, useRef, useState } from 'react';
import ExperienceExample from 'static/images/profile/experience_example.png';
import { CheckCircleIcon } from '@heroicons/react/solid';
import TextButton from 'components/Buttons/TextButton';
import { convertDateTimeToString } from 'utils/dateFormatter';
import VerifyIcon from 'components/VerifyIcon';
import CircleTickIcon from 'components/Icon/CircleTickIcon';
import useWindowSize from 'hooks/useWindowResize';
import { useNavigate } from 'react-router-dom';

export interface ProfileSingleExperienceProps {
    className?: string;
    date: { start_time: number; end_time: number };
    verified: boolean;
    name: string;
    organization: string;
    address: string;
    tokenId: string;
    organization_description: string;
    work_description?: string[];
    image_url?: string;
    tags?: string[];
}

const ProfileSingleExperience: FC<ProfileSingleExperienceProps> = ({
    className,
    date,
    verified,
    name,
    address,
    tokenId,
    organization,
    organization_description,
    work_description,
    tags,
    image_url,
}) => {
    const ref = useRef<any>(null)
    
    const [showMoreDescription, setShowMoreDescription] = useState(false);
    const [descriptionHeight, setDescriptionHeight] = useState(0);
    const { width } = useWindowSize();

    const startTimeString = convertDateTimeToString(date.start_time, 'MM/YYYY');
    const endTimeString = date.end_time === 0 ? 'Now' : convertDateTimeToString(date.end_time, 'MM/YYYY');

    useEffect(() => {
        if(ref!=null && ref.current!=null) {
            setDescriptionHeight(ref.current.clientHeight)
        }
    }, [])

    const navigate =  useNavigate()


    return (
        <li className="border-l-2 border-neutral-200 last:border-transparent experience_border_bottom" >
            <div className="absolute flex-start flex items-top">
                <div className="-ml-[9px] h-4 w-4 rounded-full bg-neutral-200"><CircleTickIcon className='' iconClass='h-4 w-4' /></div>
                <p className="-ml-28 absolute text-sm text-neutral-500 dark:text-neutral-300 text-right">
                    {startTimeString}
                    <br />- {endTimeString}
                </p>
            </div>
            <a href={'/badge/'+ address + '/' + tokenId}>

            <div className="ml-10 flex flex-col lg:flex-row gap-5 lg:gap-8">
                <img
                    src={image_url || ExperienceExample}
                    className="border-2 border-neutral-900 rounded-xl min-h-[144px] min-w-[144px] max-h-[144px] max-w-[144px]"
                />
                <div>
                    {name && (
                        <div className="text-neutral-800 text-base-initial-semibold">
                            {name}
                        </div>
                    )}
                    {organization && (
                        <div className="text-neutral-700 text-sm-initial-medium mt-2">
                            {organization}
                        </div>
                    )}
                    {organization_description && (
                        <div className="text-neutral-500 text-sm-initial-regular mt-2">
                            {organization_description}
                        </div>
                    )}
                    {work_description && (
                        <>
                        <li 
                            className={"mt-2 text-sm-initial-regular lg:text-sm-initial-regular text-neutral-500 overflow-ellipsis h-auto"+(showMoreDescription==false && width<1024 && descriptionHeight>80?" mask-last-line-active line-clamp-4":width<1024?" sm:h-auto":"")} 
                            ref={ref}
                        >
                            {work_description}
                        </li>
                    <div 
                        className={
                            (showMoreDescription==false?"-top-[18px] right-1.5":"")
                            +(descriptionHeight>80&&width<1024?" visible":" hidden")
                            +" w-max ml-auto text-primary-700 text-xs-initial-medium relative lg:hidden my-auto cursor-pointer"
                        } 
                        onClick={()=>{setShowMoreDescription(!showMoreDescription)}}
                    >
                        ...see {showMoreDescription==false?"more":"less"}
                    </div>
                    </>
                    )}

                    {tags && tags.length > 0 && (
                        <div className="mt-4 hidden lg:flex flex-wrap gap-2">
                            {tags.map((item: string) => {
                                return (
                                    <TextButton
                                        size="xs"
                                        text={item}
                                        className="white-tag rounded-full pointer-events-none"
                                        buttonType="neutral"
                                    />
                                );
                            })}
                        </div>
                    )}
                </div>
            </div>
            </a>
        </li>
    );
};

export default ProfileSingleExperience;
