import React, { FC } from "react";
import { Helmet } from "react-helmet";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";

import { ethers, BigNumber } from "ethers";
import { NFTStorage, File } from 'nft.storage'

import { organisations_abi } from '../../abi/Organisation';



function Create_org(props: any){

  const [ contractResult, setContractResult ] = React.useState("");
  const [ companyInfo, setCompanyInfo ] = React.useState({});
  const [ nonce, setNonce ] = React.useState(0);

  const providerRPC = {
    private_moonbase: {
      name: 'private-moonbase',
      rpc: 'http://rpc.api.tride.ai:9933/',
      chainId: 1281,
    },
    moonbaseAlpha: {
      name: 'moonbase-alpha',
      rpc: 'https://rpc.api.moonbase.moonbeam.network',
      chainId: 1287,
    }
  };
  
  const currentChain = 'private_moonbase'
  
  const account_from = {
    addr: '0xf24FF3a9CF04c71Dbc94D0b566f7A27B94566cac',
    key: '0x5fb92d6e98884f76de468fa3f6278f8807c48bebc13595d45af5bdc4da702133'
  };
  const contractAddress = '0x3ed62137c5DB927cb137c26455969116BF0c23Cb';

  
  function getProvider(){
    return new ethers.providers.StaticJsonRpcProvider(
      providerRPC[currentChain].rpc, 
      {
        chainId: providerRPC[currentChain].chainId,
        name: providerRPC[currentChain].name,
      }
    );
  }
  
  async function uploadIPFS(_companyDetail: any) {
    // return null;
    const NFT_STORAGE_TOKEN = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJkaWQ6ZXRocjoweDdDOWZjM2VEMzQ4NDJhNzAyNzJjODBDMTQwQWE4ODkzRTRlYjAzRjAiLCJpc3MiOiJuZnQtc3RvcmFnZSIsImlhdCI6MTY2MTMxMzg2NTAyOSwibmFtZSI6IlRyaWRlVGVzdGluZyJ9.yiKy8NKStP7oTQAL07lA88oUKVA89NpP00Dx-nf-2lk'
    const client = new NFTStorage({ token: NFT_STORAGE_TOKEN })

    const someData = new Blob([JSON.stringify(_companyDetail)])
    const cid = await client.storeBlob(someData);
    return cid
  }

  async function getNew(){
    const provider = getProvider();
    let wallet = new ethers.Wallet(account_from.key, provider);
    const caller = new ethers.Contract(contractAddress, organisations_abi.abi, wallet);

    const newId = (await caller.totalOrg()).toNumber() - 1;
    console.log("ok now get");
    const result = await caller.getOrganisation(newId);
    setNonce(nonce + 1);
    setContractResult(result)
  }

  async function createOrg() {

    let fakeName = "Org-" + (Math.random() + 1).toString(36).substring(7);
    let fakeDescription = "description-" + (Math.random() + 1).toString(36).substring(2);
    const org_info = {
      name: fakeName,
      description: fakeDescription,
    }

    setCompanyInfo(org_info);

    const IPFS_result = await uploadIPFS(org_info);

    const provider = getProvider();
    let wallet = new ethers.Wallet(account_from.key, provider);
    const caller = new ethers.Contract(contractAddress, organisations_abi.abi, wallet);
    
    await caller.createOrganisation(account_from.addr, org_info.name, org_info.description, "ipfs://" + IPFS_result);
    const newId = (await caller.totalOrg()).toNumber() - 1;
    console.log("ok now get");
    const result = await caller.getOrganisation(newId);
    setNonce(nonce + 1);
    setContractResult(result)
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || NFT React Template</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          PoC Create Organisation
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 gap-12 ">

            {/* Connect Smart Contract */}
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                Connect Smart Contract
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                <ButtonPrimary onClick={createOrg}>Create Organisation</ButtonPrimary>
                <ButtonPrimary onClick={getNew}>get newest Organisation</ButtonPrimary>
              </span>
            </div>
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                random result
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                { JSON.stringify(companyInfo) }
              </span>
            </div>
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                tx result
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                { contractResult }
              </span>
            </div>
            <div>
              <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                number of call
              </h3>
              <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                { nonce }
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Create_org;
