import React, { FC } from 'react';

export interface NewsletterIconProps {
    className?: string;
    iconClass?: string;
}

const NewsletterIcon: FC<NewsletterIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 18 16" fill="none">
                <path
                    d="M9 4.25H10.25M9 6.75H10.25M4 9.25H10.25M4 11.75H10.25M12.75 4.25H15.5625C16.0803 4.25 16.5 4.66973 16.5 5.1875V13C16.5 14.0355 15.6605 14.875 14.625 14.875M12.75 4.25V13C12.75 14.0355 13.5895 14.875 14.625 14.875M12.75 4.25V2.0625C12.75 1.54473 12.3303 1.125 11.8125 1.125H2.4375C1.91973 1.125 1.5 1.54473 1.5 2.0625V13C1.5 14.0355 2.33947 14.875 3.375 14.875H14.625M4 4.25H6.5V6.75H4V4.25Z"
                    stroke="#111827"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </span>
    );
};

export default NewsletterIcon;
