import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import { EventBadgeMetadata } from "schemas/metadata";
import { EventResponse } from "schemas/response";

export default function BadgeAccordion(
  // {metadata, eventResponse}: {metadata: EventBadgeMetadata, eventResponse: EventResponse}
  ) {
  return (
    <div className="w-full rounded-2xl">
      <Disclosure defaultOpen>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span>Attributes</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel
              className="px-4 pt-4 pb-2 text-neutral-500 text-sm dark:text-neutral-400"
              as="p"
            >


              <span>Owner role</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1 mb-2">
                Attendee
              </span>
              <br />

              <span>Event</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1 mb-2">
              {/* {metadata.name} */}
              name of metadata
              </span>
              <br />
          
              <span>Session</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1 mb-2">
                A Full Day Summit
              </span>
              <br />

              <span>Keywords</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1 mb-2">
                EdTech, Talet Development
              </span>

              <br />
              <span className="line-clamp-1">Description</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100  mb-2">
              {/* {metadata.event_description} */}
              metadata event description
              </span>
              <br />


            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
      <Disclosure defaultOpen as="div" className="mt-5 md:mt-8">
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full px-4 py-2 font-medium text-left bg-neutral-100 dark:bg-neutral-700 dark:hover:bg-neutral-500 rounded-lg hover:bg-neutral-200 focus:outline-none focus-visible:ring focus-visible:ring-neutral-500 focus-visible:ring-opacity-75">
              <span>Transaction Details</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180" : ""
                } w-5 h-5 text-neutral-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 flex flex-col text-xs text-neutral-500 dark:text-neutral-400 overflow-hidden">
            
              <span>Contract Address</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100 line-clamp-1">
                {/* {eventResponse.contractAddress} */} contract address
              </span>

              <br />
              <span>Token ID</span>
              <span className="text-base text-neutral-900 dark:text-neutral-100">
                {/* {eventResponse.tokenId} */}token id
              </span>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  );
}
