import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import { nftsAbstracts } from 'constants/fakeData';
import TrideRating from 'static/images/profile/tride_rating.svg';
import parse from 'html-react-parser';
import userProfileServices from 'services/userProfile.services';

interface IssuerTagProps {
    className?: string;
    contentClassName?: string;
    issuer_name: string;
}

const IssuerTag: FC<IssuerTagProps> = ({
    className = 'absolute top-0 right-0 flex items-center border-neutral-900',
    contentClassName = 'right-1/3 translate-x-1/3 top-1/2 -translate-y-1/2',
    issuer_name,
}) => {
    return (
        <div className={className}>
            <svg
                className="text-white dark:text-neutral-900 w-24 md:w-[120px]"
                viewBox="0 0 196 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}
            >
                <path
                    d="M196 55V0H0.5V1H4.05286C12.4067 1 20.1595 5.34387 24.5214 12.4685L43.5393 43.5315C47.9012 50.6561 55.654 55 64.0078 55H196Z"
                    fill="currentColor"
                    stroke="#111827"
                    stroke-width="4"
                />
            </svg>

            <div className={`absolute ${contentClassName}`}>
                <span className="text-[10px] font-medium md:text-[12px] relative -right-1">
                    {issuer_name}
                </span>
            </div>
        </div>
    );
};

interface ProfileSkillBadgeProps {
    className?: string;
    tokenURI: string;
    issuer: string;
    name: string;
    rating: Number;
}
const ProfileSkillBadge: FC<ProfileSkillBadgeProps> = ({
    className = '',
    tokenURI,
    issuer,
    name,
    rating,
}) => {
    const [imageData, setImageData] = useState(null);
    const [imageURL, setImageURL] = useState(null);
    const [imageType, setImageType] = useState<string>('');

    const getImage = async () => {
        const meta = await userProfileServices.getMetadata(tokenURI);
        if (meta.data.image) {
            setImageURL(meta.data.image);
        } else if (meta.data.image_data) {
            setImageData(meta.data.image_data);
        }
        if ((meta.data.image as string)?.endsWith('.mp4')) {
            setImageType('video');
        }
    };

    useEffect(() => {
        getImage();
    }, []);

    return (
        <div
            className={`nc-CardNFTMusic relative group ${className} max-w-sm `}
            data-nc-id="CardNFTMusic"
        >
            <div className="block aspect-w-12 aspect-h-10 w-full h-0 rounded-3xl overflow-hidden z-0 border-2 border-neutral-900">
                {/* <div className="aspect-w-12 sm: w-120 lg:basis-1/3 xl:basis-1/4;"> */}

                {imageData ? (
                    <div className="aspect-w-12 aspect-h-12">
                        {parse(imageData)}
                    </div>
                ) : imageType === 'video' ? (
                    <div className="aspect-w-12 aspect-h-12">
                        <video src={imageURL || ''} autoPlay loop muted playsInline />
                    </div>
                ) : (
                    <NcImage
                        containerClassName="block aspect-w-12 aspect-h-10 w-full h-0 rounded-3xl overflow-hidden z-0"
                        src={imageURL || ''}
                        className="object-cover w-full h-full group-hover:scale-[1.2] transition-transform duration-300 ease-in-out"
                    />
                )}
            </div>

            {/* ----TIME--- */}
            <IssuerTag issuer_name={issuer} />

            {/* MAIN CONTENT */}
            <div className="w-11/12 max-w-[360px] transform -mt-32 relative z-10">
                <div className={`px-5 flex items-center space-x-4 relative `}>
                    <div className={`flex-grow flex justify-center`}></div>
                </div>

                <div
                    // to={'/nft-detail'}
                    className="block p-3 mt-20 bg-white dark:bg-neutral-800 shadow-xl dark:shadow-2xl rounded-3xl rounded-tl-none border-2 border-neutral-900"
                >
                    <div className="flex items-center justify-between">
                        <div className="text-xs-initial-medium skills-title">{name}</div>
                    </div>

                    <div className="w-full mt-1.5 block md:flex justify-between items-end ">
                        <div className="flex">
                            {Array.from(
                                Array(rating),
                                (e: Number, i: number) => {
                                    return <img src={TrideRating} />;
                                }
                            )}
                        </div>
                    </div>
                </div>
            </div>

            {/* <Link to={'/nft-detail'} className="absolute inset-0 "></Link> */}
        </div>
    );
};

export default ProfileSkillBadge;
