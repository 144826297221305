import { FC, useEffect, useMemo, useState } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import IconButton from 'components/Buttons/IconButton';
import { DotsHorizontalIcon, LinkIcon } from '@heroicons/react/solid';
import { QrcodeIcon } from '@heroicons/react/outline';
import OpenseaLogo from 'static/images/logos/opensea_black_and_white.svg';
import EtherscanLogo from 'static/images/logos/etherscan_black_and_white.svg';
import TextButton from 'components/Buttons/TextButton';
import GeneralModal from 'components/Modal/GeneralModal';
import WalletCardEditModel from '../Modals/WalletCardEditModel';
import WalletCardRejectModel from '../Modals/WalletCardRejectModel';

import { haveValidJwt, getJwtPayload } from 'utils/jwt';
import { useSelector } from 'react-redux';
import { RootState } from 'store/store';
import WalletCardIcon from 'components/Icon/WalletCardIcon';
import CreateProofOfOwnershipModal from '../Modals/CreateProofOfOwnershipModal';
import { OwnershipIcon } from 'components/Icon/outline';

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const ProfileCardActionBtn: FC<{ address: string }> = ({ address }) => {
    const [isOpenModel, setIsOpenModel] = useState(false);
    const [isOpenProofModel, setIsOpenProofModel] = useState(false);

    const loginSucess = useSelector((root: RootState) => root.auth.loginSucess);

    const copyLink = (content: string) => {
        navigator.clipboard.writeText(content);
    };

    const downloadWallet = () => {
        setIsOpenModel(true);
    };

    const isMobile = useMemo(() => {
        return /iPhone|iPad|iPod/i.test(navigator.userAgent);
    }, []);

    const isProfileOwner = useMemo(() => {
        return (
            haveValidJwt() &&
            (getJwtPayload().address! as String).toLowerCase() === address
        );
    }, [address, loginSucess]);

    return (
        <Menu as="div" className="relative inline-block text-left">
            <Menu.Button className="flex justify-center items-center mt-1.5">
                <div className="mt-[1px]">
                    <TextButton
                        text={
                            <div className="my-auto">
                                <DotsHorizontalIcon className="w-5 h-5 text-neutral-900" />
                            </div>
                        }
                        buttonType=""
                        size="md"
                        className="bg-white h-7 lg:h-9 px-0.5 lg:px-2 py-0.5 lg:py-1.5 lg:mt-0"
                        bgClassName="bg-neutral-900 h-7 lg:h-9"
                    />
                </div>
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute right-0 z-10 mt-2 w-48 origin-top-right bg-white border-[1px] border-neutral-200"
                    style={{
                        borderRadius: '8px',
                    }}
                >
                    <div>
                        <Menu.Item>
                            <div
                                className="action_menu_item"
                                onClick={() => copyLink(window.location.href)}
                            >
                                <div className="text-xs-initial-regular my-auto">
                                    Copy link to profile
                                </div>
                                <LinkIcon className="w-5 h-5" />
                            </div>
                        </Menu.Item>
                        {isProfileOwner && (
                            <>
                                <Menu.Item>
                                    <div className="w-100 border-b-[0.5px] border-neutral-200"></div>
                                </Menu.Item>
                                <Menu.Item>
                                    <div
                                        className="action_menu_item"
                                        onClick={downloadWallet}
                                    >
                                        <div className="text-xs-initial-regular my-auto">
                                            Create wallet pass
                                        </div>
                                        <WalletCardIcon />
                                    </div>
                                </Menu.Item>
                                <Menu.Item>
                                    <div className="w-100 border-b-[0.5px] border-neutral-200"></div>
                                </Menu.Item>
                                <Menu.Item>
                                    <div
                                        className="action_menu_item"
                                        onClick={() =>
                                            setIsOpenProofModel(true)
                                        }
                                    >
                                        <div className="text-xs-initial-regular my-auto">
                                            Prove my ownership
                                        </div>
                                        <OwnershipIcon
                                            className="w-5 h-5"
                                            iconClass="fill-black"
                                        />
                                    </div>
                                </Menu.Item>
                            </>
                        )}
                        <Menu.Item>
                            <div className="w-100 border-b-[0.5px] border-neutral-200"></div>
                        </Menu.Item>
                        <Menu.Item>
                            <div
                                className="action_menu_item"
                                onClick={() =>
                                    window.open('https://opensea.io/' + address)
                                }
                            >
                                <div className="text-xs-initial-regular my-auto">
                                    View on Opensea
                                </div>
                                <img className="w-5 h-5" src={OpenseaLogo} />
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div className="w-100 border-b-[0.5px] border-neutral-200"></div>
                        </Menu.Item>
                        <Menu.Item>
                            <div
                                className="action_menu_item"
                                onClick={() =>
                                    window.open(
                                        'https://etherscan.io/address/' +
                                            address
                                    )
                                }
                            >
                                <div className="text-xs-initial-regular my-auto">
                                    View on Etherscan
                                </div>
                                <img className="w-5 h-5" src={EtherscanLogo} />
                            </div>
                        </Menu.Item>
                        <Menu.Item>
                            <div className="w-100 border-b-[0.5px] border-neutral-200"></div>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
            {isMobile ? (
                <WalletCardEditModel
                    openEditModal={isOpenModel}
                    setOpenEditModal={setIsOpenModel}
                />
            ) : (
                <WalletCardRejectModel
                    openEditModal={isOpenModel}
                    setOpenEditModal={setIsOpenModel}
                />
            )}
            {isProfileOwner && (
                <CreateProofOfOwnershipModal
                    isOpen={isOpenProofModel}
                    setOpenEditModal={setIsOpenProofModel}
                />
            )}
        </Menu>
    );
};

export default ProfileCardActionBtn;
