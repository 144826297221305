import useWindowSize from 'hooks/useWindowResize';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import POAPExample from 'static/images/profile/poap_example.png';
import { POAPData } from './ProfilePOAP';

// export interface LoadingProfilePOAPBadgeProps {
//   className?: string;
//   hide_text?: boolean
// }

const LoadingProfilePOAPBadge: FC<{ poap?: POAPData; hide_text?: boolean }> = ({
    poap,
}) => {
    const thisRef = useRef<HTMLDivElement>(null);
    const { height, width } = useWindowSize();

    const objectWidth = useMemo(() => {
        return thisRef.current?.offsetWidth;
    }, [width, thisRef.current]);

    const objectHeight = useMemo(() => {
        return thisRef.current?.offsetHeight;
    }, [height, thisRef.current]);
    return (
        <div className="bg-loading h-[256px]" ref={thisRef}>
        </div>
    );
};

export default LoadingProfilePOAPBadge;
