import React, { FC, useEffect, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import GeneralModal from 'components/Modal/GeneralModal';
import { useAccount, useEnsAvatar, useEnsName, useEnsResolver } from 'wagmi';
import { useWeb3 } from 'utils/useWeb3';
import { useParams } from 'react-router-dom';
import LoadingSkeleton from "static/animations/component-loading.gif"
import { NftFilters } from 'alchemy-sdk';

export interface SelectNFTModalProps {
    className?: string;
    openSelectNFTModal: boolean;
    setOpenSelectNFTModal: Function;
    setProfilePicture: Function;
}

const NftImages = (nft: any) => {
    return(
        <>
        {
            nft.tokenURI.media?(
                <img src={nft?.tokenURI.media[0].thumbnail?nft?.tokenURI.media[0].thumbnail:nft?.tokenURI.media[0].raw?nft?.tokenURI.media[0].raw:""} className="w-36 h-36 rounded-2xl border-2 border-neutral-900 mx-auto object-cover" />
            )
            :null
        }
        </>
    )
}

const SelectNFTModal: FC<SelectNFTModalProps> = ({ className, openSelectNFTModal, setOpenSelectNFTModal, setProfilePicture }) => {
    const { alchemyInstance } = useWeb3()!;

      
    const alchemy = alchemyInstance;
    useEffect(() => {
        if(addr != undefined){
            alchemy.nft.getNftsForOwner(addr, {pageKey: pageKey, excludeFilters:[NftFilters.AIRDROPS, NftFilters.SPAM], pageSize: 100})
            .then((data: any) => {
                setUserNfts(data.ownedNfts);
                setPageKey(data.pageKey);
                setTotalCount(data.totalCount);
            })
        }
    }, [])

    const [userNfts, setUserNfts] = useState<Object[]>([]);
    const [pageKey, setPageKey] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const { addr } = useParams();

    // const addr = "0x7b47640ed97cc08aa188ae091efab2cf3ef48469";

    const address = (addr as `0x${string}`) || '';

    const [selectedNft, setSelectedNft] = useState(null);

    const { data: ensName } = useEnsName({ address, chainId: 1 });

    const checkBottom = (e: any) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop < 710;
        if(bottom && userNfts.length !== totalCount){
            loadMoreNfts();
        }
    }

    const confirmSelect = (image: string) => {
        setProfilePicture(image);
        setOpenSelectNFTModal(false);
        setSelectedNft(null)
    }

    const loadMoreNfts = () => {
        if(addr != undefined){
            alchemy.nft.getNftsForOwner(addr, {pageKey: pageKey, excludeFilters:[NftFilters.AIRDROPS, NftFilters.SPAM], pageSize: 100})
            .then((data: any) => {
                const list: any = [...userNfts, ...data.ownedNfts];
                setUserNfts(list);
                setPageKey(data.pageKey);
                setTotalCount(data.totalCount);
            })
        }
    }

    const nftImage = (nftData: any) => nftData?.media[0]?.thumbnail || nftData?.media[0]?.raw
    const renderContent = () => {
        return (
            <div className="h-auto max-h-[702px] overflow-y-scroll hiddenScrollbar" id="content_section" onScroll={checkBottom}>
                <div className="h-auto p-10 pb-40">
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                        {
                            userNfts.map((nftData: any, index) => {
                                return(
                                    nftData?.media && nftData.media.length>0?(
                                        <div 
                                            className={"text-center bg-neutral-100 rounded-xl p-3 w-[168px] h-[242px] mx-auto border-2 border-transparent hover:border-primary-600 cursor-pointer"+(selectedNft===nftImage(nftData)? " border-primary-600":"")} 
                                            onClick={()=>{

                                                const clicked = nftImage(nftData)
                                                selectedNft === clicked
                                                ?setSelectedNft(null)
                                                :setSelectedNft(clicked)
                                            }}
                                        >
                                            <NftImages tokenURI={nftData} />
                                            <div className="mt-3 text-xs-initial-regular text-neutral-400 max-w-full truncate">{nftData.contract.name}</div>
                                            <div className="mt-3 text-xs-initial-medium max-h-8 line-clamp-2">{nftData.title}</div>
                                        </div>
                                    )
                                    :null
                                )
                            })
                        }
                        {
                            userNfts.length !== totalCount
                            ?(
                                <div 
                                    className={"text-center bg-neutral-100 rounded-xl w-[168px] h-[242px] mx-auto border-2 border-transparent"}
                                >
                                    <img src={LoadingSkeleton} className="w-full h-full rounded-xl" />
                                </div>
                            )
                            :null
                        }
                    </div>
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="select_nft_modal_footer">
                <TextButton
                    size="sm"
                    text="Cancel"
                    buttonType="text"
                    className="h-9 my-auto"
                    onClick={() => setOpenSelectNFTModal(false)}
                />
                <div className="ml-4 h-9 my-auto">
                    <TextButton
                        size="sm"
                        text="Confirm"
                        buttonType={selectedNft == null?"secondary":"primary"}
                        className={`${selectedNft == null?"border-neutral-100 border-2":""}`}
                        bgClassName="bg-transparent"
                        onClick={() => {
                            selectedNft != null ? confirmSelect(selectedNft) : console.log('no nft selected');
                        }}
                        isDisabled={selectedNft == null?true:false}
                    />
                </div>
            </div>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <>
            <GeneralModal
                isOpenProp={openSelectNFTModal}
                onCloseModal={() => setOpenSelectNFTModal(false)}
                contentExtraClass="w-auto max-h-[690px]"
                renderContent={renderContent}
                renderFooter={renderFooter}
                renderTrigger={renderTrigger}
                modalTitle="Choose NFT"
                closeButton={true}
            />
        </>
    );
};

export default SelectNFTModal;
