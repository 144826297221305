export interface verifyAddressOwnershipTemplate {
    link: string;
    address: string
}

const verifyAddressOwnership = (
    params: verifyAddressOwnershipTemplate
) => {
    const template = `Dear [receiver_name],

I am writing to you to confirm my ownership of ${params.address}.

Please follow the steps below:

Please Navigate to the following address
  ${params.link}

Enter your email address
      Please enter the same email address at which you received this message.

The email address will be used solely for the purpose of verifying my ownership of the wallet address, and will not be used for any other purpose.

Thank you!


Best regards,
[my_name]`;

    return encodeURIComponent(template);
};
export default verifyAddressOwnership;
