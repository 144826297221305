import React, { FC } from 'react';
import { useWindowSize } from 'react-use';
import talentImg from 'static/images/PageUseCase/sections/talent.svg';

const TalentSection: FC<any> = ({ className = '' }) => {
    const { width } = useWindowSize();

    return (
        <div className="flex flex-row p-8">
            {width >= 1280 ? (
                <>
                    <div className="basis-1/3 flex items-center">
                        <img className="mx-auto" src={talentImg} />
                    </div>
                    <div className="basis-2/3">
                        <h1 className="text-2xl-initial-semibold text-primary-700 mb-2">
                            Talents
                        </h1>
                        <p className="text-base-initial-regular text-neutral-600">
                            Credentialproof.io offers a solution for businesses
                            to create NFT credentials for their{' '}
                            <span className="font-bold">
                                employment records
                            </span>
                            , enabling them to establish trust and reputation
                            within their industries and attract potential
                            employees or students.
                            <br />
                            <br />
                            Additionally, businesses can use Credentialproof.io
                            to create NFT credentials for their{' '}
                            <span className="font-bold">
                                training and development programs
                            </span>
                            , allowing them to track the progress and
                            achievements of their employees and provide them
                            with tangible rewards for their efforts.
                        </p>
                    </div>
                </>
            ) : (
                <div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start">
                            <img className="mx-auto" src={talentImg} />
                        </div>
                        <div className="basis-3/4 flex items-center">
                            <h1 className="text-2xl-initial-semibold text-primary-700">
                                Talents
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start"></div>
                        <div className="basis-3/4 flex items-center">
                            <p className="text-base-initial-regular text-neutral-600">
                                Job seekers can use Credentialproof.io to
                                create, manage NFT credentials for their
                                professional experience. They can share these
                                with potential employers during{' '}
                                <span className="font-bold">
                                    job applications
                                </span>{' '}
                                to showcase their achievements and stand out
                                from other applicants.
                                <br />
                                <br />
                                Professionals who are seeking career advancement
                                can use Credentialproof.io to create NFT
                                credentials for their{' '}
                                <span className="font-bold">
                                    professional development
                                </span>
                                , such as completing online courses or attending
                                conferences. These credentials can be used to
                                showcase their skills and knowledge to their
                                current or potential employers.
                                <br />
                                <br />
                                Credentialproof.io provides a way for
                                professionals to generate NFT credentials for
                                their{' '}
                                <span className="font-bold">
                                    accomplishments
                                </span>
                                , such as speaking engagements or industry
                                awards. These credentials can be shared with
                                colleagues and potential collaborators to
                                improve their professional reputation and expand
                                their network.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default TalentSection;
