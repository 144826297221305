import { avatarColors } from "constants/styles";
import React, { FC } from "react";

import { avatarImgs } from "constants/fakeData";
import VerifyIcon from "components/VerifyIcon";

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
  applyShadow?: boolean;
  onClick?: ()=>void;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName,
  sizeClass,
  radius = "rounded-full",
  imgUrl,
  userName,
  hasChecked,
  hasCheckedClass = "w-4 h-4 bottom-1 -right-0.5",
  applyShadow,
  onClick
}) => {
  const url = imgUrl || "";
  const name = userName || "John Doe";
  const _setBgColor = (name: string) => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };

  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold ${radius} ${sizeClass?sizeClass:"h-14 w-14 text-sm"} ${containerClassName}`}
      style={{ backgroundColor: url ? undefined : _setBgColor(name), boxShadow: `${applyShadow?"1px 1px 0px #000000":"0"}`}}
      onClick={onClick}
    >
      {url && (
        <img
          className={`absolute inset-0 w-full h-full object-cover ${radius}`}
          src={url}
          alt={name}
          style={{boxShadow: `${applyShadow?"1px 1px 0px #000000":"0"}`}}
        />
      )}
      <span className="wil-avatar__name">{name[0]}</span>

      {hasChecked && (
        <span className={`  text-white  absolute  ${hasCheckedClass}`}>
          <VerifyIcon className="" />
        </span>
      )}
    </div>
  );
};

export default Avatar;
