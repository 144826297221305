import TextButton from 'components/Buttons/TextButton';
import React, { FC, useEffect, useState } from 'react';
import badgesSample from '../../static/images/badges/microsoft-badge.svg';
import { ArrowUturnLeftIcon } from 'components/Icon/outline';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'store/hooks';
import successIssueWorkBadgesTemplate from 'utils/mail/template/SuccessIssueWorkBadges';

export interface ResultPageProps {
    className?: string;
}

const ResultPage: FC<ResultPageProps> = ({ className }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const [badgesInfo] = useState<any>(location.state || {});

    useEffect(() => {
        console.debug(badgesInfo, location.state);
    }, [location]);

    const createEmail = () => {
        window.location.href = `mailto:${
            badgesInfo?.email
        }?subject=${'[company name] issued you a work SBT'}&body=${successIssueWorkBadgesTemplate(
            {
                receiver: badgesInfo?.employee ?? 'N/A',
                claimUrl: badgesInfo?.claimUrl ?? 'N/A',
                secretCode: badgesInfo?.secretCode ?? 'N/A',
            }
        )}`;
    };

    return (
        <div
            className="flex flex-col justify-center container py-10"
            style={{ minHeight: 'calc(100vh - 64px)' }}
        >
            <img className="mx-auto h-[230px] w-[230px]" src={badgesSample} />
            <div className="text-3xl font-semibold text-center mt-10">
                You’ve successfully issued this SBT &#x1F389;
            </div>
            <div className="mx-auto w-fit mt-10">
                <TextButton
                    buttonType="primary"
                    size="md"
                    text="Notify recipient by email"
                    onClick={createEmail}
                />
            </div>
            <div className="text-base text-center mt-16">
                Or manually share the following details:
                
            </div>
            <div className="bg-white border-2 border-neutral-800 rounded-lg py-6 px-8 mt-4 flex flex-col gap-4">
                <div className="text-base font-medium w-full">
                    <div className="text-neutral-500">Claim link</div>
                    <div className="truncate w-full">
                        {badgesInfo?.claimUrl ?? 'N/A'}
                    </div>
                </div>
                <div className="text-base font-medium">
                    <div className="text-neutral-500">Secret code</div>
                    <div>{badgesInfo?.secretCode ?? 'N/A'}</div>
                </div>
            </div>
            <div className="mx-auto mt-16">
                <TextButton
                    buttonType="text"
                    size="md"
                    text="Back to portal"
                    appendIcon={<ArrowUturnLeftIcon />}
                />
            </div>
        </div>
    );
};

export default ResultPage;
