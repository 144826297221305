import React, { FC, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import DetailBackgroundIamge from "static/images/badges/sampleBadges_1.png"
import SBTSample from "static/images/badges/microsoft-badge.svg"
import { ChevronDownIcon, ChevronUpIcon, InformationCircleIcon, ChevronLeftIcon, ShareIcon, DotsHorizontalIcon } from "@heroicons/react/solid";
import TextButton from "components/Buttons/TextButton";

export interface DetailPageProps {
  className?: string;
  currentAddress?: boolean;
}
export interface Benefit{
    name: string;
}

const SBTGatedNetworkDetail: FC<DetailPageProps> = ({ className = "", currentAddress = false }) => {
    const [showMoreDescription, setShowMoreDescription] = React.useState(false)
    const [showMoreBenefits, setShowMoreBenefits] = React.useState(false)
    const dummyBenefits = [
        {name: "Token 2049 After Party"},
        {name: "Investor Matching"},
        {name: "Free pass to Hong Kong FinTech Week 2022"},
        {name: "Token 2049 After Party1"},
        {name: "Investor Matching"},
        {name: "Free pass to Hong Kong FinTech Week 2022"}
    ]
    React.useEffect(() => {
        console.log(dummyBenefits.slice(0, 3))
    }, [])
    return (
        <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
            <Helmet>
                <title>SBT-Gated Network</title>
            </Helmet>
            <div className="container">
                <div className="flex lg:hidden mb-8">
                    <div className="rounded-lg p-1 border-2 border-neutral-900 hover:cursor-pointer">
                        <ChevronLeftIcon className="h-6 w-6" />
                    </div>
                    <div className="ml-auto flex">
                    <div className="rounded-lg p-1 border-2 border-neutral-900 hover:cursor-pointer mr-4">
                        <ShareIcon className="h-6 w-6" />
                    </div>
                    <div className="rounded-lg p-1 border-2 border-neutral-900 hover:cursor-pointer">
                        <DotsHorizontalIcon className="h-6 w-6" />
                    </div>

                    </div>
                </div>
                <div className="flex justify-center lg:mt-8">
                    <img src={DetailBackgroundIamge} className="rounded-lg border-2 border-neutral-900 lg:mx-0 w-full h-auto max-h-60 max-w-lg mx-6 flex justify-center" />
                </div>
                <div className="mt-6 text-3xl-initial-bold text-primary-700">Web3 Founder Circle</div>
                <div className="text-base-initial-semibold mt-6 mb-2">SBT required</div>
                <div className="flex gap-4">
                    <img src={SBTSample} className="rounded-full border-2 border-neutral-900 h-16 w-16" />
                    <img src={SBTSample} className="rounded-full border-2 border-neutral-900 h-16 w-16" />
                    <img src={SBTSample} className="rounded-full border-2 border-neutral-900 h-16 w-16 opacity-30" />
                    <img src={SBTSample} className="rounded-full border-2 border-neutral-900 h-16 w-16 opacity-30" />
                </div>
                <div className="text-base-initial-semibold mt-6 mb-2">Description</div>
                <div className={`text-sm-initial-regular mt-2 ${showMoreDescription?"":"line-clamp-3"}`}>Network with like-minded members and share your thoughts on the Next Generation of Crypto. We gather people who navigate the challenges of trasitioning from product-market fit to go-to-market fit to hyperscale, and beyond.Network with like-minded members and share your thoughts on the Next Generation of Crypto. We gather people who navigate the challenges of trasitioning from product-market fit to go-to-market fit to hyperscale, and beyond. Network with like-minded members and share your thoughts on the Next Generation of Crypto. We gather people who navigate the challenges of trasitioning from product-market fit to go-to-market fit to hyperscale, and beyond.Network with like-minded members and share your thoughts on the Next Generation of Crypto. We gather people who navigate the challenges of trasitioning from product-market fit to go-to-market fit to hyperscale, and beyond.</div>
                {
                    showMoreDescription
                    ?(
                        <div onClick={()=>setShowMoreDescription(false)} className="hover:cursor-pointer text-primary-700 flex mt-4">
                            <span className="text-sm-initial-medium">show less</span>
                            <ChevronUpIcon className="h-6 w-6 ml-2 relative bottom-0.5" />
                        </div>
                    )
                    :(
                        <div onClick={()=>setShowMoreDescription(true)} className="hover:cursor-pointer text-primary-700 flex mt-4">
                            <span className="text-sm-initial-medium">show more</span>
                            <ChevronDownIcon className="h-6 w-6 ml-2 relative bottom-0.5" />
                        </div>
                    )
                }
                <div className="text-base-initial-semibold mt-6 mb-2">Benefits</div>
                    
                {
                    showMoreBenefits
                    ?(
                        dummyBenefits.map((benefit: Benefit) => {
                            return(
                                <div className="flex">
                                    <InformationCircleIcon className="h-8 w-8" />
                                    <div className="text-sm-initial-medium mt-1.5 ml-2">{benefit.name?benefit.name:""}</div>
                                </div>
                            )
                        })
                    )
                    :(
                        dummyBenefits.slice(0, 3).map((benefit: Benefit) => {
                            return(
                                <div className="flex">
                                    <InformationCircleIcon className="h-8 w-8" />
                                    <div className="text-sm-initial-medium mt-1.5 ml-2">{benefit.name?benefit.name:""}</div>
                                </div>
                            )
                        })
                    )
                }
                {
                    showMoreBenefits
                    ?(
                        <div onClick={()=>setShowMoreBenefits(false)} className="hover:cursor-pointer text-primary-700 flex mt-4">
                            <span className="text-sm-initial-medium">show less</span>
                            <ChevronUpIcon className="h-6 w-6 ml-2 relative bottom-0.5" />
                        </div>
                    )
                    :(
                        <div onClick={()=>setShowMoreBenefits(true)} className="hover:cursor-pointer text-primary-700 flex mt-4">
                            <span className="text-sm-initial-medium">show more</span>
                            <ChevronDownIcon className="h-6 w-6 ml-2 relative bottom-0.5" />
                        </div>
                    )
                }
                <div className="block lg:hidden mt-8 mb-6">
                    <TextButton text="1 more SBT to unlock" buttonType="primary" isDisabled={true} size="lg" className="w-full flex justify-center" />
                    <TextButton text="Back" buttonType="text" size="lg" className="w-full flex justify-center text-primary-700 mt-4" />
                </div>
                <div className="hidden lg:flex mt-8 mb-6 justify-end">
                    <TextButton text="1 more SBT to unlock" buttonType="primary" isDisabled={true} size="lg" className="flex justify-center" />
                    <TextButton text="Back" buttonType="text" size="lg" className="flex justify-center text-primary-700" />
                </div>
            </div>
        </div>
    );
};

export default SBTGatedNetworkDetail;
