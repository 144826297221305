import React, { FC, useMemo } from 'react';
import { JsxElement } from 'typescript';

export interface BasicTextChipProps {
    className?: string;
    textClassName? :string;
    size: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
    text: string;
    selected?: boolean;
    disabled?: boolean;
    onChangeHandle?: () => void;
}

const BasicTextChip: FC<BasicTextChipProps> = ({
    className = '',
    textClassName = '',
    size,
    text,
    selected = false,
    disabled = false,
    onChangeHandle,
}) => {
    const textSize = useMemo(() => {
        switch (size) {
            case 'xs':
                return 'text-xs';
            case 'sm':
            case 'md':
                return 'text-sm';
            case 'lg':
            case 'xl':
                return 'text-base';
        }
    }, [size]);

    const textColor = useMemo(() => {
        if (disabled) {
            return 'text-neutral-400';
        }

        if (selected) {
            return 'text-neutral-50';
        }

        return 'text-neutral-900';
    }, [disabled, selected]);

    const spacing = useMemo(() => {
        switch (size) {
            case 'xs':
                return 'py-1 px-2';
            case 'sm':
                return 'py-1 px-2.5';
            case 'md':
            case 'lg':
                return 'py-1.5 px-3.5';
            case 'xl':
                return 'py-2.5 px-[22px]';
        }
    }, [size]);

    const _onChangeHandle = () => {
        if (onChangeHandle) {
            onChangeHandle();
        }
    };

    return (
        <div
            className={`${className} text_chip${
                disabled ? '-disabled' : ''
            } ${spacing} ${selected ? 'bg-neutral-900' : ''} group w-fit`}
            onClick={_onChangeHandle}
        >
            <div
                className={`${textClassName} ${textSize} font-medium ${textColor} group-hover:text-neutral-900`}
            >
                {text}
            </div>
        </div>
    );
};

export default BasicTextChip;
