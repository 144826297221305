import {
    BytesLike,
    constants,
    Contract,
    ethers,
    providers,
    Signer,
} from 'ethers';
import { Address, ForwardingSignatureDTO } from 'schemas/params';
import { EventBadges__factory } from 'typechain/factories/EventBadges__factory';
import { UserProfile__factory } from 'typechain/factories/UserProfile__factory';
import { CONTRACT_ADDRESS } from 'constants/contract';
import { userProfileStruct } from 'schemas/structure';
import { BADGES_TYPE } from 'constants/types';
import { WorkBadges__factory } from 'typechain/factories/WorkBadges__factory';
import { Subscription__factory } from 'typechain/factories/Subscription__factory';
import { Forwarder__factory } from 'typechain/factories/Forwarder__factory';
import { WorkBadges } from 'typechain';
import { DataTypes } from 'typechain/WorkBadges';

export default class EtherController {
    private provider: providers.Web3Provider;
    private signer: ethers.Signer;

    constructor(provider: providers.Web3Provider, signer?: ethers.Signer) {
        this.provider = provider;
        if (signer) {
            this.signer = signer;
        } else {
            this.signer = provider.getSigner();
        }
    }

    getProvider() {
        return this.provider;
    }

    async getChainId(): Promise<number> {
        try {
            // if (window.ethereum) {
            //   new ethers.providers.Web3Provider(window.ethereum);
            // }

            const network = await this.provider.getNetwork();
            return network.chainId;
        } catch (error) {
            console.warn(error);
            return -1;
        }
    }

    async getUserId() {
        const contract = UserProfile__factory.connect(
            CONTRACT_ADDRESS.user_profile,
            this.provider
        );
        const userId = await (
            await contract.getUserId(await this.getAccount())
        ).toNumber();
        return userId;
    }

    async getEventContractDataURI(
        address: string,
        tokenId: number
    ): Promise<any> {
        const eventContract = EventBadges__factory.connect(
            address,
            this.provider
        );
        return await eventContract.uri(tokenId);
    }

    async signMessage(signer: Signer, message: string) {
        let bytesDataHash = ethers.utils.arrayify(message);
        const signMessage = await signer.signMessage(bytesDataHash);
        return ethers.utils.splitSignature(signMessage);
    }

    async signEIP712Message(domain: any, types: any, value: any) {
        const sign = await this.provider
            .getSigner()
            ._signTypedData(domain, types, value);
        return sign;
        // return ethers.utils.splitSignature(sign);
    }

    async getEIP712DomainInfo(contractAddress: String) {
        return {
            name: 'Tride_Protocol',
            version: '1',
            chainId: await this.getChainId(),
            verifyingContract: contractAddress,
        };
    }

    // ------

    async getAccount(): Promise<string> {
        try {
            const signer = await this.provider.getSigner();
            console.warn('signer', signer);

            const address = await signer.getAddress();
            return address;
        } catch (error) {
            throw error;
        }
    }

    async getSignerAccount(): Promise<any> {
        const address = await this.signer.getAddress();
        return address;
    }

    async balanceOfBadge(
        type: string,
        contractAddress: Address,
        tokenId: number
    ) {
        const currentAddress = await this.getAccount();
        let contract: Contract;
        let balance: BigInt = BigInt(0);

        switch (type) {
            case BADGES_TYPE.event:
                contract = EventBadges__factory.connect(
                    contractAddress,
                    this.provider
                );

                balance = await contract.balanceOf(currentAddress, tokenId);
                break;
        }
        return Number(balance);
    }

    async isBadgeClaimable(
        type: string,
        contractAddress: Address,
        tokenId: number
    ) {
        const currentAddress = await this.getAccount();
        let contract: Contract;
        let result: boolean = false;

        switch (type) {
            case BADGES_TYPE.event: {
                let contract = EventBadges__factory.connect(
                    contractAddress,
                    this.provider
                );

                // unable to check whitelist and quota is its private;

                result =
                    (await this.balanceOfBadge(
                        type,
                        contractAddress,
                        tokenId
                    )) > 0
                        ? true
                        : false;

                break;
            }

            case BADGES_TYPE.work: {
                let contract = WorkBadges__factory.connect(
                    contractAddress,
                    this.provider
                );

                const canClaim = await contract.canClaim(
                    tokenId,
                    currentAddress
                );

                console.log('whitelist :', currentAddress, canClaim);
                return canClaim;

                break;
            }
        }

        return result;
    }

    async isOwnBadges(type: string, contractAddress: Address, tokenId: number) {
        const currentAddress = await this.getAccount();
        let contract: Contract;
        let result: boolean = false;

        switch (type) {
            case BADGES_TYPE.event:
                result =
                    (await this.balanceOfBadge(
                        type,
                        contractAddress,
                        tokenId
                    )) > 0
                        ? true
                        : false;
                break;

            case BADGES_TYPE.work:
                contract = WorkBadges__factory.connect(
                    contractAddress,
                    this.provider
                );

                const currentOwner = await contract.ownerOf(tokenId);
                result = currentOwner && currentOwner == currentAddress;

                break;
        }

        return result;
    }

    async getBalance(): Promise<string> {
        try {
            const me = await this.getAccount();

            const balance = await this.provider.getBalance(me);
            return ethers.utils.formatEther(balance);
        } catch (error) {
            return error as string;
        }
    }

    async signRequestSubscription(contractAddress: string, profileId: number) {
        let contract: Contract;

        contract = Subscription__factory.connect(
            contractAddress,
            this.provider
        );

        let functionData = await contract.interface.encodeFunctionData(
            'requestSubscribe',
            [profileId]
        );

        return await this.signFunctionCall(contract, functionData);
    }

    async signApproveSubscription(
        contractAddress: string,
        profileId: number,
        requestorAddress: string
    ) {
        let contract: Contract;
        let sigNonces: BigInt;

        const domainInfo = await this.getEIP712DomainInfo(contractAddress);

        contract = Subscription__factory.connect(
            contractAddress,
            this.provider
        );

        sigNonces = await contract.sigNonces(await this.signer.getAddress());

        let functionData = await contract.interface.encodeFunctionData(
            'approveRequest',
            [profileId, requestorAddress]
        );

        return await this.signFunctionCall(contract, functionData);
    }

    async signIssueEventBadge(contractAddress: Address, sessionId: number) {
        const contract = EventBadges__factory.connect(
            contractAddress,
            this.provider
        );

        let functionData = await contract.interface.encodeFunctionData(
            'issueBadge',
            [sessionId]
        );

        const resp = this.signFunctionCall(contract, functionData);
        return resp;
    }

    /**
     * To create a work badges
     */
    async signCreateWorkBadges(
        workContractAddress: Address,
        employeeAddress: Address,
        badgesInfo: DataTypes.WorkInfoStruct,
        salt: string,
        password: BytesLike
    ) {
        const contract = WorkBadges__factory.connect(
            workContractAddress,
            this.provider
        );

        const functionData = contract.interface.encodeFunctionData(
            'issueWorkBadge',
            [employeeAddress, badgesInfo, salt, password]
        );

        const resp = this.signFunctionCall(contract, functionData);
        return resp;
    }

    /**
     * To update a work badges
     */
    async signUpdateWorkBadges(
        workContractAddress: Address,
        badgesId: number,
        badgesInfo: DataTypes.WorkInfoStruct
    ): Promise<ForwardingSignatureDTO> {
        const contract = WorkBadges__factory.connect(
            workContractAddress,
            this.provider
        );

        const functionData = contract.interface.encodeFunctionData(
            'updateWorkBadge',
            [badgesId, badgesInfo]
        );

        const resp = this.signFunctionCall(contract, functionData);
        return resp;
    }

    async signCreateUserProfile(
        userProfile: userProfileStruct
    ): Promise<ForwardingSignatureDTO> {
        const contract = UserProfile__factory.connect(
            CONTRACT_ADDRESS.user_profile,
            this.provider
        );

        let functionData = contract.interface.encodeFunctionData(
            'createUserProfile',
            [userProfile, []]
        );

        const resp = this.signFunctionCall(contract, functionData);
        return resp;
    }

    async domainInfo() {
        const forwarder = Forwarder__factory.connect(
            CONTRACT_ADDRESS.fowarder,
            this.provider
        );

        return {
            name: 'Tride',
            version: '1',
            chainId: await this.getChainId(),
            verifyingContract: forwarder.address,
        };
    }

    // main function for signing messages
    async signFunctionCall(contract: Contract, functionData: string) {
        const forwarder = Forwarder__factory.connect(
            CONTRACT_ADDRESS.fowarder,
            this.provider
        );

        const userAddress = await this.signer.getAddress();
        let signNonce = await forwarder.getNonce(userAddress);

        let types = {
            ForwardRequest: [
                { name: 'from', type: 'address' },
                { name: 'to', type: 'address' },
                { name: 'validUntilTime', type: 'uint256' },
                { name: 'nonce', type: 'uint256' },
                { name: 'data', type: 'bytes' },
            ],
        };
        let value = {
            from: userAddress,
            to: contract.address,
            validUntilTime: 0,
            nonce: signNonce.toString(),
            data: functionData,
        };
        const sign = await this.signEIP712Message(
            await this.domainInfo(),
            types,
            value
        );
        return { ...value, sign };
    }

    async signMessageString(message: string) {
        return await this.signer.signMessage(message)
    }
}
