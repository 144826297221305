import { NetworkSetting } from "schemas/response";

const chains = {
  private_moonbase: {
    name: "private-moonbase",
    rpc: "http://rpc.api.tride.ai:9933/",
    chainId: 1281,
    currency: "DEV",
  },
  moonbaseAlpha: {
    name: "moonbase-alpha",
    rpc: "https://rpc.api.moonbase.moonbeam.network",
    chainId: 1287,
    currency: "DEV",
  },
  polygonMumbai: {
    name: "Polygon Mumbai",
    rpc: "https://rpc.ankr.com/polygon_mumbai",
    chainId: 80001,
    currency: "MATIC",
  },
};

export const DEPLOY_CHAIN = chains.polygonMumbai;

export const DEPLOY_NETWORK_SETTING: NetworkSetting = {
  chain_id: `${DEPLOY_CHAIN.chainId}`,
  rpc: DEPLOY_CHAIN.rpc,
};
