import React, { FC, useId } from "react";

export interface CheckboxProps {
  label?: string;
  subLabel?: string;
  className?: string;
  name?:string; // should be removed
  defaultChecked?: boolean;
  onChange?: (checked: boolean) => void;
}

const Checkbox: FC<CheckboxProps> = ({
  className = "",
  label,
  subLabel,
  defaultChecked,
  onChange,
}) => {
  const _refId = useId()

  return (
    <div className={`flex text-sm sm:text-base ${className}`}>
      <input
        id={_refId}
        name={_refId}
        type="checkbox"
        className="h-6 w-6 border-2 border-neutral-900 rounded accent-neutral-900 text-neutral-900 focus:ring-0"
        defaultChecked={defaultChecked}
        onChange={(e) => onChange && onChange(e.target.checked)}
      />
      {label && (
        <label
          htmlFor={_refId}
          className="ml-2 flex flex-col flex-1 justify-center"
        >
          <span
            className={`text-neutral-900 dark:text-neutral-100 ${
              !!subLabel ? "-mt-0.5" : ""
            }`}
          >
            {label}
          </span>
          {subLabel && (
            <p className="mt-0.5 text-neutral-500 dark:text-neutral-400 text-sm font-light">
              {subLabel}
            </p>
          )}
        </label>
      )}
    </div>
  );
};

export default Checkbox;
