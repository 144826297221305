import React, { FC, useEffect, useMemo, useState } from 'react';
import badgesSample from '../../static/images/badges/microsoft-badge.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import LitHelper from 'utils/LitHelper';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useWeb3 } from 'utils/useWeb3';
import { ethers } from 'ethers';
import { createWorkBadges, updateStage } from 'store/slices/issueSlice';

import { useContractRead } from 'wagmi';
import { CONTRACT_ADDRESS } from 'constants/contract';
import { Organisation__factory } from 'typechain';

export interface LoadingPageProps {
    className?: string;
}

const LoadingPage: FC<LoadingPageProps> = ({ className }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();

    const { controller } = useWeb3()!;

    const loadingStage = useAppSelector((state) => state.issue.currentStage);
    const claimUrl = useAppSelector((state) => state.issue.claimUrl);
    const secretCode = useAppSelector((state) => state.issue.secretCode);
    const workContractAddress = useAppSelector(
        (state) => state.issue.workContractAddress
    );

    const [badgesInfo] = useState<any>(location.state || {});

    const lit = LitHelper;

    useEffect(() => {
        if (Object.keys(badgesInfo).length === 0) {
            navigate('/404');
        }
    }, []);

    useEffect(() => {
        if (secretCode && claimUrl) {
            navigate('/issue/success', { state: { claimUrl, secretCode } });
        }
    }, [claimUrl, secretCode]);

    useEffect(() => {
        if (controller && workContractAddress) {
            dispatch(updateStage('start-create-badges'));
            dispatch(
                createWorkBadges({
                    contractAddress: workContractAddress,
                    employeeAddress: ethers.constants.AddressZero,
                    metadata: badgesInfo,
                    controller: controller,
                })
            );
        }
    }, [controller, workContractAddress]);

    const displayStyle = useMemo(() => {
        let result: any = {};

        switch (loadingStage) {
            case 'start-create-badges':
                result.widthClass = 'w-0';
                result.message = 'Start creating badges...';
                break;

            case 'create-badges':
                result.widthClass = 'w-1/4';
                result.message = 'Start creating badges...';
                break;

            case 'upload-ipfs-record':
                result.widthClass = 'w-2/4';
                result.message = 'Uploading IPFS record...';
                break;

            case 'update-badges':
                result.widthClass = 'w-3/4';
                result.message = 'Inserting metadata to badges...';
                break;

            case 'finish-issue':
                result.widthClass = 'w-full';
                result.message = 'Done!';
                navigate('/issue/success', {
                    state: {
                        employee: badgesInfo.full_name,
                        email: badgesInfo.email,
                        claimUrl: claimUrl,
                        secretCode: secretCode,
                    },
                });
                break;

            default:
                result.widthClass = 'w-0';
                result.message = 'Start creating badges...';
                break;
        }

        return result;
    }, [loadingStage, badgesInfo]);

    return (
        <div className="full-page-layout min-h-[200px] flex flex-col justify-center gap-28">
            <div>
                <img
                    className="mx-auto h-[230px] w-[230px]"
                    src={badgesSample}
                />
            </div>
            <div className="flex flex-col gap-10">
                <div>
                    <div className="h-3 w-1/2 bg-[#D3FBBC] mx-auto rounded-xl">
                        <div
                            className={`h-full ${displayStyle.widthClass} bg-primary-400 rounded-xl transition-all duration-500`}
                        ></div>
                    </div>
                    <div className="text-base text-center mt-3">
                        {displayStyle.message}
                    </div>
                </div>
                <div className="text-base text-center text-neutral-500">
                    Please follow and complete all the steps before closing the
                    browser
                </div>
            </div>
        </div>
    );
};

export default LoadingPage;
