import React, { FC, useEffect, useRef, useState } from 'react';

export interface LoadingProfileSingleExperienceProps {
    className?: string;
}

const LoadingProfileSingleExperience: FC<LoadingProfileSingleExperienceProps> =
    ({ className }) => {
        return <li className="-ml-28 bg-loading h-[200px] mb-8"></li>;
    };

export default LoadingProfileSingleExperience;
