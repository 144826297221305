import React, { FC } from 'react';
import { useWindowSize } from 'react-use';
import bussinessImg from 'static/images/PageUseCase/sections/bussiness.svg';

const BusinessesSection: FC<any> = ({ className = '' }) => {
    const { width } = useWindowSize();

    return (
        <div className="flex flex-row p-8">
            {width >= 1280 ? (
                <>
                    <div className="basis-1/3 flex items-center">
                        <img className="mx-auto" src={bussinessImg} />
                    </div>
                    <div className="basis-2/3">
                        <h1 className="text-2xl-initial-semibold text-primary-700 mb-2">
                            Businesses
                        </h1>
                        <p className="text-base-initial-regular text-neutral-600">
                            Credentialproof.io can be utilised by businesses
                            to perform{' '}
                            <span className="font-bold">
                                employee verification
                            </span>{' '}
                            to confirm that their employees possess the
                            necessary qualifications and credentials for
                            their job roles.
                            <br />
                            <br />
                            Businesses can use Credentialproof.io to
                            streamline the{' '}
                            <span className="font-bold">
                                recruitment process
                            </span>
                            . This eliminates the need for time-consuming
                            and potentially biased reference checks, and
                            enables them to verify the professional
                            experience and achievements of job applicants in
                            a more efficient and accurate manner.
                        </p>
                    </div>
                </>
            ) : (
                <div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start">
                            <img className="mx-auto" src={bussinessImg} />
                        </div>
                        <div className="basis-3/4 flex items-center">
                            <h1 className="text-2xl-initial-semibold text-primary-700">
                                Businesses
                            </h1>
                        </div>
                    </div>
                    <div className="flex flex-row gap-4">
                        <div className="basis-1/4 flex items-start"></div>
                        <div className="basis-3/4 flex items-center">
                            <p className="text-base-initial-regular text-neutral-600">
                                Credentialproof.io can be utilised by businesses
                                to perform{' '}
                                <span className="font-bold">
                                    employee verification
                                </span>{' '}
                                to confirm that their employees possess the
                                necessary qualifications and credentials for
                                their job roles.
                                <br />
                                <br />
                                Businesses can use Credentialproof.io to
                                streamline the{' '}
                                <span className="font-bold">
                                    recruitment process
                                </span>
                                . This eliminates the need for time-consuming
                                and potentially biased reference checks, and
                                enables them to verify the professional
                                experience and achievements of job applicants in
                                a more efficient and accurate manner.
                            </p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BusinessesSection;
