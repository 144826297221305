import React, { FC, useEffect, useState } from 'react';
import OnChainActivitiesTab from 'containers/template/ProfilePage/Tabs/OnChainActivitiesTab';
import Avatar from 'shared/Avatar/Avatar';
import { useWeb3 } from 'utils/useWeb3';
import { ReactComponent as CopyIcon } from 'assets/images/icons/copy.svg';
import PortfolioTab from './Tabs/Portfolio';
import Footprints from './Tabs/Footprints';
import { ethers } from 'ethers';
import ProfileBanner from './Banner_Card/ProfileBanner';
import ProfileCard from './Banner_Card/ProfileCard';
import ProfileSkills from './Skills/ProfileSkills';
import ProfilePOAPs from './POAP/ProfilePOAP';
import ProfileExperience from './Experience/ProfileExperience';
import { getUserProfile } from 'store/slices/profileSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { Address } from 'wagmi';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { gql, useQuery } from '@apollo/client';
import { WorkBadges__factory } from 'typechain/factories/WorkBadges__factory';
import { DataTypes } from 'typechain/WorkBadges';
import { getProvider } from 'constants/blockchain';
import { useAccount, useEnsAvatar, useEnsName, useEnsResolver } from 'wagmi';

export interface UserProfileProps {
    className?: string;
}

const GET_USER_BADGES = gql`
    query User($userAddress: ID!) {
        user(id: $userAddress) {
            badge {
                id
                name
                description
                type
                contentUri
                ... on WorkBadge {
                    start_time
                    end_time
                    organisation {
                        name
                        description
                        metadata {
                            image
                        }
                    }
                    metadata {
                        name
                        description
                        image
                        skillAttributes {
                            id
                            name
                        }
                        attributes {
                            id
                            trait_type
                            value
                        }
                    }
                }
            }
            badge_invited {
                id
                name
                description
                type
                contentUri
                ... on WorkBadge {
                    start_time
                    end_time
                    organisation {
                        name
                        description
                        metadata {
                            image
                        }
                    }
                    metadata {
                        name
                        description
                        image
                        attributes {
                            id
                            trait_type
                            value
                        }
                        skillAttributes {
                            id
                            name
                        }
                    }
                }
            }
        }
    }
`;

const UserProfile: FC<UserProfileProps> = ({ className }) => {
    const { addr } = useParams();
    const address = ((addr?.toLowerCase() as `0x${string}`) || '');
    const { controller } = useWeb3()!;
    const dispatch = useAppDispatch();

    // const handleAddressBtn = (content: string) => {
    //   navigator.clipboard.writeText(content);
    // }
    const [badgesInfoList, setBadgesInfoList] = useState<any[]>([]);

    const {
        loading,
        error,
        data: gqlData,
        refetch,
    } = useQuery(GET_USER_BADGES, {
        variables: { userAddress: address },
    });

    useEffect(() => {
        let badge_list = gqlData?.user?.badge?.length ? gqlData?.user?.badge : gqlData?.user?.badge_invited
        let newBadgesInfoList = badge_list?.map((value: any) => {
            const [address, tokenId] = (value.id as string).split('-');

            return {
                name: value?.name || value?.metadata?.name,
                description: value?.description || value?.metadata?.description,
                metadata: value?.metadata,
                badgesImage: value?.metadata?.image,
                metadataURI: value?.contentUri,
                organisation: value?.organisation,
                attributes: value.attributes,
                address,
                tokenId,
                start_time: parseInt(value?.start_time),
                end_time: parseInt(value?.end_time),
                type: value.type,
            };
        });
        setBadgesInfoList(newBadgesInfoList);
        console.log(gqlData);
    }, [gqlData]);

    const [workInfoList, setWorkInfoList] = useState<
        DataTypes.WorkInfoStructOutput[]
    >([]);

    const profile = useAppSelector((state) => state.userProfile.currentProfile);

    console.log('graphql:', gqlData);


    const { data: ensName } = useEnsName({
        address,
        chainId: 1,
    });

    const { data: ensResolver } = useEnsResolver({
        name: ensName || '',
        chainId: 1,
    });

    useEffect(() => {
        console.log('getting username...', address);
        dispatch(getUserProfile({address: address, ensResolver: ensResolver || undefined}));
    }, [address, ensResolver]);


   

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Credential Proof</title>
            </Helmet>
            <ProfileBanner />
            <div className="container mb-[100px]">
                <ProfileCard />
                <ProfileExperience isLoading={loading ?? true} badges={badgesInfoList} />
                {/* <ProfileExperience badges={workInfoList} /> */}
                <ProfileSkills />
                <ProfilePOAPs className="mt-16" />
            </div>
        </>
    );
};

export default UserProfile;
