import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { EventBadgeMetadata } from 'schemas/metadata';
import { EventResponse } from 'schemas/response';
import eventService from 'services/event.services';
import { RootState } from 'store/store';
import IPFSHelper from 'utils/IPFSHelper';
import axios from 'axios';
import EtherController from 'utils/etherController';
import { BADGES_TYPE } from 'constants/types';

export interface EventState {
    detail: EventResponse | null;
    detailMetadata: EventBadgeMetadata | null;
    loading: boolean;
    claimed: boolean;
}

const initialState: EventState = {
    detail: null,
    detailMetadata: null,
    loading: false,
    claimed: false,
};

export const getEventDetail = createAsyncThunk(
    '/event/detail',
    async (eventId: string) => {
        const { data: eventResponse } = await eventService.getEvent(eventId);

        const uri = IPFSHelper.convertURI(eventResponse.event_badges_uri);

        const { data: metadata } = await axios.get<EventBadgeMetadata>(uri);

        console.log(metadata);
        return { response: eventResponse, metadata };
    }
);

interface claimEventInputType {
    contractAddress: string;
    eventId: string;
    tokenId: number;
    controller: EtherController;
}

export const claimEvent = createAsyncThunk(
    '/event/claim',
    async (
        { contractAddress, eventId, tokenId, controller }: claimEventInputType,
        thunkApi
    ) => {
        const userAddress = (await controller?.getAccount()) || '';

        const sign = await controller?.signIssueEventBadge(
            contractAddress,
            tokenId
        );

        if (!sign) {
            return thunkApi.rejectWithValue({ error: 'Invalid signature' });
        }

        return { transactionHash: null };
    }
);

export const eventSlice = createSlice({
    name: 'event',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getEventDetail.pending, (state, { payload }) => {
            state.loading = true;
        });
        builder.addCase(getEventDetail.fulfilled, (state, { payload }) => {
            state.detail = payload.response;
            state.detailMetadata = payload.metadata;

            console.log(payload);
            state.loading = false;
        });
        builder.addCase(getEventDetail.rejected, (state, { payload }) => {
            console.log(payload);
            state.loading = false;
        });

        builder.addCase(claimEvent.pending, (state, { payload }) => {
            console.log(payload);
            state.loading = true;
            state.claimed = false;
        });

        builder.addCase(claimEvent.fulfilled, (state, { payload }) => {
            console.log(payload);
            state.loading = false;
            state.claimed = true;
        });
        builder.addCase(claimEvent.rejected, (state, { payload }) => {
            console.error(payload);
            state.loading = false;
            state.claimed = false;
        });
    },
});

// Action creators are generated for each case reducer function
export const {} = eventSlice.actions;

export const user = (state: RootState) => state.user;

export default eventSlice.reducer;
