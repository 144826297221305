import React, { ReactElement, ReactNode, useState } from "react";
import { BrowserRouter, Route } from "react-router-dom";
import { Outlet } from 'react-router-dom';

import Footer from "./Footer";
import SiteHeader from "containers/SiteHeader";
import Sidebar from "./Sidebar";
import NavMobile from "shared/Navigation/NavMobile";
import Header from "./Header";
import MenuBar from "shared/MenuBar/MenuBar";

const DashboardLayout = ({ children }: { children?: ReactNode }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);


  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Left Frame */}
        <div className="z-40 relative">
          <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen}/>
          {/* <NavMobile onClickClose={() => { }} /> */}
        </div>

        {/* Right frame */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden grow">
          <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          <div className="overflow-scroll h-full">
            <main>
              <React.Suspense fallback={<></>}>
                <Outlet />
              </React.Suspense>
            </main>
          </div>
          <Footer />
        </div>

      </div>
    </>
  );
};

export default DashboardLayout;
