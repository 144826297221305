import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NcImage from 'shared/NcImage/NcImage';
import { nftsAbstracts } from 'constants/fakeData';
import TrideRating from 'static/images/profile/tride_rating.svg';
import parse from 'html-react-parser';
import userProfileServices from 'services/userProfile.services';

interface IssuerTagProps {
    className?: string;
    contentClassName?: string;
    issuer_name: string;
}

const IssuerTag: FC<IssuerTagProps> = ({
    className = 'absolute top-0 right-0 flex items-center border-neutral-900',
    contentClassName = 'right-1/3 translate-x-1/3 top-1/2 -translate-y-1/2',
    issuer_name,
}) => {
    return (
        <div className={className}>
            <svg
                className="text-white dark:text-neutral-900 w-24 md:w-[120px]"
                viewBox="0 0 196 55"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                style={{ transform: 'matrix(1, 0, 0, 1, 0, 0)' }}
            >
                <path
                    d="M196 55V0H0.5V1H4.05286C12.4067 1 20.1595 5.34387 24.5214 12.4685L43.5393 43.5315C47.9012 50.6561 55.654 55 64.0078 55H196Z"
                    fill="currentColor"
                    stroke="#111827"
                    stroke-width="4"
                />
            </svg>

            <div className={`absolute ${contentClassName}`}>
                <span className="text-[10px] font-medium md:text-[12px] relative -right-1">
                    {issuer_name}
                </span>
            </div>
        </div>
    );
};

interface LoadingProfileSkillBadgeProps {
    className?: string;
}

const LoadingProfileSkillBadge: FC<LoadingProfileSkillBadgeProps> = ({
    className = '',
}) => {
    return (
        <div
            className={`nc-CardNFTMusic relative group ${className} max-w-sm bg-loading h-[260px] m-4`}
        ></div>
    );
};

export default LoadingProfileSkillBadge;
