import {
    createAsyncThunk,
    createSlice,
    isRejectedWithValue,
    PayloadAction,
} from '@reduxjs/toolkit';
import authServices from 'services/auth.services';
import userServices from 'services/user.services';
import { SiweMessage } from 'siwe';
import { RootState } from 'store/store';
import EtherController from 'utils/etherController';
import { userIsLoggedIn } from 'utils/providers/ethereumProvider';
import { haveValidJwt } from 'utils/jwt';

// current user handling, jwt , etc.
export interface AuthState {
    isShowLoginModel: boolean;
    isShowSIWEPopup: boolean;
    loginSucess: boolean;
}

const initialState: AuthState = {
    isShowLoginModel: false,
    isShowSIWEPopup: userIsLoggedIn() && !haveValidJwt(),
    loginSucess: false,
};

export const loginWithSIWE = createAsyncThunk(
    '/SIWE',
    async (
        {
            address,
            controller,
        }: { address: string; controller: EtherController },
        thunkApi
    ) => {
        const { data: nonceRes } = await authServices.getNonce();
        const nonce = nonceRes.nonce;

        const message = new SiweMessage({
            domain: window.location.hostname,
            address: await controller.getAccount(),
            uri: window.location.href,
            version: '1',
            chainId: 1,
            nonce: nonce,
        });

        console.log('siwe', window.location.hostname, window.location.href);

        const signMessage = message.prepareMessage();

        const provider = controller.getProvider();

        try {
            const signature = await provider
                .getSigner()
                .signMessage(signMessage);

            const { data: jwtRes } = await authServices.getJwt(
                signMessage,
                signature
            );
            console.log('jwt');
            window.localStorage.setItem('jwt', jwtRes.jwt);
        } catch (error) {
            console.warn(error);
        }
    }
);

export const updateShowPopup = createAsyncThunk(
    '/SIWE/showPopup',
    async (newValue: boolean, thunkApi) => {
        return newValue;
    }
);

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLoginModel: (state, action: PayloadAction<boolean>) => {
            console.debug('setLoginModel', action.payload);
            state.isShowLoginModel = action.payload;
        },
        setSIWEModel: (state, action: PayloadAction<boolean>) => {
            console.debug('setSIWEModel', action.payload);
            state.isShowSIWEPopup = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(loginWithSIWE.pending, (state, { payload }) => {
            state.loginSucess = false;
        });
        builder.addCase(loginWithSIWE.rejected, (state, { payload }) => {});
        builder.addCase(loginWithSIWE.fulfilled, (state, { payload }) => {
            state.isShowSIWEPopup = false;
            state.loginSucess = true;
        });

        builder.addCase(updateShowPopup.fulfilled, (state, { payload }) => {
            state.isShowSIWEPopup = payload;
        });
    },
});

// Action creators are generated for each case reducer function
export const { setLoginModel, setSIWEModel } = authSlice.actions;

export const auth = (state: RootState) => state.auth;

export const authSliceFunction = { setLoginModel, setSIWEModel, loginWithSIWE };

export default authSlice.reducer;
