import React, { FC } from 'react';
import { AdvantageCard, AdvantageCardProps } from '../component/AdvantageCard';

import discoverabilityImage from 'static/images/index/advantage/discoverability.svg';
import encryptabilityImage from 'static/images/index/advantage/encryptability.svg';
import selfSovereigntyImage from 'static/images/index/advantage/self-sovereignty.svg';

const data: Array<AdvantageCardProps> = [
    {
        image: discoverabilityImage,
        title: 'Discoverability',
        content:
            'CredentialProof.io makes it easy for potential employers and other interested parties to discover and verify your professional background based on the identifiers you owned.',
    },
    {
        image: encryptabilityImage,
        title: 'Encryptability',
        content:
            'Our partial encryption feature ensures complete control and privacy over your professional information.',
    },
    {
        image: selfSovereigntyImage,
        title: 'Self-sovereignty',
        content: (
            <p>
                You own your own data and have complete control over it.
                <br />
                <br />
                You decide what to show publicly, what to share privately, and
                who to share with.
            </p>
        ),
    },
];

export const AdvantageSection: FC<any> = ({}) => {
    return (
        <div className="mt-20 mb-20 clip-path-to-left">
            <div className="clip-path-inner-container container flex flex-col gap-14">
                <h1 className="text-3xl-initial-bold text-center">
                    CredentialProof.io revolutionises how professional
                    footprints are discovered and verified.
                </h1>
                <div className="grid grid-cols-1 lg:grid-cols-3 gap-10">
                    {data.map((item, index) => (
                        <AdvantageCard key={index} {...item} />
                    ))}
                </div>
            </div>
        </div>
    );
};
