import http from './http-common';
import axios from 'axios';
import { BACKEND_URL } from 'constants/backend';
import { EventResponse } from 'schemas/response';
import { paginationSetting } from 'schemas/paginationSetting';

class JobService {
    client = axios.create({
        baseURL: BACKEND_URL,
    });

    async getJobList(query: string | undefined, pagination: paginationSetting) {
        const data = await this.client.get('/jobs/list', {
            params: { query: query, ...pagination },
        });
        return data;
    }

    async getJobDetail(jobId: string) {
        const data = await this.client.get(`/jobs/${jobId}`);
        return data;
    }

    async getJobTags() {
        const data = await this.client.get(`/jobs/tags`);
        return data;
    }
}

export default new JobService();
