import { FC, useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import MobileLoginModal from 'components/LoginModal';
import { userIsLoggedIn } from 'utils/providers/ethereumProvider';
import { useWeb3 } from 'utils/useWeb3';
import { useNavigate } from 'react-router-dom';
import AvatarPhoto from 'static/images/avatars/Image-16.png';
import { ethers } from 'ethers';
import TextButton from 'components/Buttons/TextButton';
import { DEPLOY_CHAIN } from 'constants/chainConfig';
import NotificationButton from './NotificationButton';
import SIWEModel from 'components/SIWEModel';
import { useDispatch } from 'react-redux';
import { AppDispatch } from 'store/store';
import { loginWithSIWE, setLoginModel } from 'store/slices/authSlice';
import { SiweMessage } from 'siwe';
import { useAppDispatch } from 'store/hooks';

export interface TrideLoginButtonProps {
    loginLabel: string;
}

function classNames(...classes: any) {
    return classes.filter(Boolean).join(' ');
}

const TrideLoginButton: FC<TrideLoginButtonProps> = ({ loginLabel }) => {
    const dispatch = useAppDispatch();

    const showLoginModel = () => {
        dispatch(setLoginModel(true));
    }

    return (
        <>
            <button onClick={showLoginModel} className="login-button">
                <div className="button-text">{loginLabel}</div>
            </button>
        </>
    );
};

export default TrideLoginButton;
