export const isMobile = () =>
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
    );

export const getOS = () => {
    const ua = navigator.userAgent;
    if (/Android/i.test(ua)) {
        return 'android';
    } else if (
        /iPad|iPhone|iPod/.test(ua) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)
    ) {
        return 'ios';
    }
    return 'other';
};
