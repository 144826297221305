import { FC, useEffect, useState } from "react";
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import MobileLoginModal from "components/LoginModal";
import { userIsLoggedIn } from "utils/providers/ethereumProvider";
import { useWeb3 } from "utils/useWeb3";
import { useNavigate } from "react-router-dom";
import Placeholder from "static/images/avatar_placeholder.png"
import { ethers } from "ethers";
import TextButton from "components/Buttons/TextButton";
import { DEPLOY_CHAIN } from "constants/chainConfig";
import { PhotographIcon, UserCircleIcon } from "@heroicons/react/solid";
import SelectNFTModal from "../Modals/SelectNFTModal";

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

const EditProfilePictureDropdown: FC<any> = ({image, imageClassName, setProfilePicture}) => {
    const [mobileModal, setMobileModal] = useState(false);
    
    const [openSelectNFTModal, setOpenSelectNFTModal] = useState(false);

    const { address, currentNetwork, disconnect, changeMetamaskNetwork } = useWeb3()!;

    const connectedNetwork = DEPLOY_CHAIN.name
    

    const navigate = useNavigate();

    
    const {displayAddress} = useWeb3()!;


    return (
        <>
        <Menu
            as="div"
            className="text-left"
        >
            <Menu.Button className="flex justify-center items-center w-20 h-20 bg-white">
                <img src={image} className={imageClassName} />
            </Menu.Button>
            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className="absolute z-10 mt-2 w-auto lg:w-64 bg-white border-2 border-neutral-900 rounded-lg px-1 py-2"
                >
                    <div className="">
                        {/* <Menu.Item>
                            <div className="flex px-5 py-2 bg-white hover:bg-neutral-100 rounded-[4px]">
                                <div className="text-sm-initial-regular">Upload Photo</div>
                                <PhotographIcon className="w-5 h-5 ml-auto" />
                            </div>
                        </Menu.Item> */}
                        <Menu.Item>
                            <div className="flex px-5 py-2 bg-white hover:bg-neutral-100 rounded-[4px]" onClick={()=>setOpenSelectNFTModal(true)}>
                                <div className="text-sm-initial-regular">Choose NFT</div>
                                <UserCircleIcon className="w-5 h-5 ml-auto" />
                            </div>
                        </Menu.Item>
                    </div>
                </Menu.Items>
            </Transition>
        </Menu>
        <SelectNFTModal
            openSelectNFTModal={openSelectNFTModal}
            setOpenSelectNFTModal={setOpenSelectNFTModal}
            setProfilePicture={setProfilePicture}
        />
        </>
    );
};

export default EditProfilePictureDropdown;
