import TextButton from 'components/Buttons/TextButton';
import { DOC_NOTION_URL } from 'constants/backend';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';

const HeroSection: FC<any> = ({}) => {
    const navigate = useNavigate();
    return (
        <div className="py-20 text-center max-w-[780px] mx-auto">
            <h2 className="text-base-initial-bold uppercase text-primary-700 mb-4">
                About the protocol
            </h2>
            <h1 className="text-5xl-initial-bold mb-6">
                What is CredentialProof.io?
            </h1>
            <p className="text-base-initial-regular text-neutral-700 mb-6">
                CredentialProof.io is an innovative web3 protocol that harnesses
                the power of blockchain technology to transform the verification
                of professional footprints. By obtaining SBT credentials on the
                blockchain, professionals can take ownership of their footprints
                and share them with ease. As part of the decentralised identity
                (DID) ecosystem, CredentialProof.io offers a secure and
                decentralised approach for issuers to provide trusted
                credentials directly to verifiers. The protocol makes verifying
                professional credentials simple, efficient, and secure.
            </p>
            <div>
                <TextButton
                    className="underline text-primary-700"
                    buttonType="text"
                    size="xl"
                    text="Read more"
                    onClick={() => window.open(DOC_NOTION_URL)}
                />
            </div>
        </div>
    );
};

export default HeroSection;
