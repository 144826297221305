import { FC, useEffect, useId, useState } from 'react';
import ColorHash from 'color-hash';

export interface GradientAvatarsProps {
    className?: string
    seed: string;
    width?: number;
    height?: number;
}

const GradientAvatars: FC<GradientAvatarsProps> = ({
    className = '',
    seed,
    width = 2560,
    height = 2560,
}) => {
    const thisId = useId();

    const [c1, setColor_1] = useState('');
    const [c2, setColor_2] = useState('');

    const colorHash = new ColorHash({ saturation: 1.0 });

    useEffect(() => {
        const _seed = seed.toLowerCase()
        const s1 = seed.substring(0, _seed.length / 2);
        const s2 = seed.substring(_seed.length / 2);

        setColor_1(colorHash.hex(s1));
        setColor_2(colorHash.hex(s2));
    }, [seed]);

    return (
        <div className={`${className} overflow-hidden object-cover`}>
            <svg
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <rect width={width} height={height} fill={`url(#${thisId})`} />
                <defs>
                    <linearGradient
                        id={thisId}
                        x1="0"
                        y1="0"
                        x2={width}
                        y2={height}
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color={`${c1}`} />
                        <stop offset="1" stop-color={`${c2}`} />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};

export default GradientAvatars;
