import axios from "axios";
import { BACKEND_URL } from "constants/backend";
import { badgesInfoRespone } from "schemas/response";

class badgesService {
    client = axios.create({ baseURL: BACKEND_URL });

    async getBadgesInfo(id: string) {
        const data = await this.client.get<badgesInfoRespone>(`/badges/${id}`);
        return data;
    }
}

export default new badgesService();
