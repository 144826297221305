import TextButton from 'components/Buttons/TextButton';
import ProfileSkillBadge from './/ProfileSkillBadge';
import React, { FC, useEffect, useMemo, useState } from 'react';
import SkillsLogo from 'static/images/profile/skills_logo.svg';
import ProfileSkillsFilter from './ProfileSkillsFilter';
import { useAppSelector } from 'store/hooks';
import Pagination from 'rc-pagination';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid';
import LoadingProfileSkillBadge from './LoadingProfileSkillBadge';

export interface ProfileSkillsProps {
    className?: string;
}
export interface SkillNftProps {
    issuer: string;
    name: string;
    rating: Number;
    no_of_owners: string;
    tag: string[];
}

const ProfileSkills: FC<ProfileSkillsProps> = ({ className }) => {
    const [currentPage, setCurrentPage] = useState(1);

    const profile = useAppSelector((state) => state.userProfile.currentProfile);
    const isLoading = useAppSelector((state) => state.userProfile.loading);

    const count = profile?.skills?.length || 0;

    const category = profile?.skill_tags || [];

    const [filter, setFilter] = useState('Overall');

    const filterSkills = (skill: string) => {
        setFilter(skill);
        setCurrentPage(1);
    };

    const filtered_skills = useMemo(() => {
        const all_filtered_skill =
            profile?.skill_objs?.filter((item: SkillNftProps) => {
                return filter === 'Overall' || item?.tag?.includes(filter);
            }) ?? [];

        return {
            total: all_filtered_skill.length,
            data: all_filtered_skill.slice(
                (currentPage - 1) * 8,
                currentPage * 8
            ),
        };
    }, [profile, filter, currentPage]);

    const updatePage = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div className={className}>
            <div className="flex flex-row justify-between">
                <div className="flex my-auto">
                    <div className="my-auto">
                        <img src={SkillsLogo} />
                    </div>
                    <div className="text-2xl-initial-semibold ml-3">
                        Skills ({count})
                    </div>
                </div>
                {/* <TextButton className="ml-auto" size="md" buttonType="primary" text={<div className="text-sm-initial-medium">Edit</div>} /> */}
            </div>
            <div className="bg-neutral-100 rounded-3xl px-6 py-6 mt-6">
                <ProfileSkillsFilter
                    items={category}
                    selectedSkill={filter}
                    filterSkills={filterSkills}
                />

                {isLoading ? (
                    <div className="flex flex-row flex-wrap mt-7">
                        {Array(8)
                            .fill(0)
                            .map((_, index) => (
                                <div className="w-full sm:w-auto sm:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                                    <LoadingProfileSkillBadge />
                                </div>
                            ))}
                    </div>
                ) : count > 0 ? (
                    <>
                        <div className="flex flex-row flex-wrap mt-7">
                            {filtered_skills.data.map((s: any) => (
                                <div className="w-full sm:w-auto sm:basis-1/2 lg:basis-1/3 xl:basis-1/4">
                                    <ProfileSkillBadge
                                        key={s._id}
                                        tokenURI={s.tokenUri}
                                        issuer={s.collectionName}
                                        name={s.title}
                                        rating={s.difficulty}
                                        className="m-4"
                                    />
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-center mt-10">
                            <Pagination
                                defaultPageSize={8}
                                total={filtered_skills.total}
                                onChange={updatePage}
                                current={currentPage}
                                locale={{
                                    items_per_page: '/ page',
                                    jump_to: 'Go to',
                                    jump_to_confirm: 'confirm',
                                    page: 'Page',
                                    prev_page: 'Previous Page',
                                    next_page: 'Next Page',
                                    prev_5: '',
                                    next_5: '',
                                    prev_3: '',
                                    next_3: '',
                                }}
                                prevIcon={
                                    <div className="pagination_button_normal">
                                        <ChevronLeftIcon />
                                    </div>
                                }
                                nextIcon={
                                    <div className="pagination_button_normal">
                                        <ChevronRightIcon />
                                    </div>
                                }
                                jumpPrevIcon={
                                    <div className="pagination_button_jump">
                                        ...
                                    </div>
                                }
                                jumpNextIcon={
                                    <div className="pagination_button_jump">
                                        ...
                                    </div>
                                }
                                className="ant-pagination text-sm-initial-semibold"
                                showLessItems={true}
                            />
                        </div>
                    </>
                ) : null}
            </div>
        </div>
    );
};

export default ProfileSkills;
