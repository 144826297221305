import React, { FC } from 'react';
import lottie from 'lottie-web';

// ==============================|| LOADER ||============================== //
export interface CustomCircularLoaderProps {
    data: Object;
    baseClassName?: string;
    iconClassName?: string;
}

const CustomCircularLoader: FC<CustomCircularLoaderProps> = ({
    data,
    baseClassName = '',
    iconClassName = '',
}) => {
    React.useEffect(() => {
        lottie.loadAnimation({
            container: document.querySelector('#circular-loader')!,
            animationData: data,
        });
    }, []);

    return (
        <div className={baseClassName ?? ''}>
            <div className={iconClassName} id="circular-loader" />
        </div>
    );
};

export default CustomCircularLoader;
