import React, { FC } from 'react';

export interface ShieldTickIconProps {
    className?: string;
    iconClass?: string;
}

const CircleTickIcon: FC<ShieldTickIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg
                className={iconClass}
                viewBox="0 0 16 16"
                fill="none"
            >
                <path fill-rule="evenodd" clip-rule="evenodd" d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM11.7659 6.00863C12.0654 5.72245 12.0761 5.24769 11.79 4.94824C11.5038 4.64879 11.029 4.63804 10.7296 4.92423C9.23022 6.35718 7.90877 7.97496 6.8018 9.74116L5.28033 8.21969C4.98744 7.9268 4.51256 7.9268 4.21967 8.21969C3.92678 8.51259 3.92678 8.98746 4.21967 9.28035L6.42031 11.481C6.58544 11.6461 6.81807 11.7249 7.04957 11.6941C7.28106 11.6633 7.48499 11.5264 7.6012 11.3239C8.73027 9.3557 10.1362 7.5662 11.7659 6.00863Z" fill="#44BF12"/>

            </svg>
        </span>
    );
};

export default CircleTickIcon;
