import React, { FC } from 'react';

export interface ArrowUturnLeftIconProps {
    className?: string;
    iconClass?: string;
}

const ArrowUturnLeftIcon: FC<ArrowUturnLeftIconProps> = ({
    className = '',
    iconClass = 'w-5 h-5',
}) => {
    return (
        <span className={className}>
            <svg className={iconClass} viewBox="0 0 20 20" fill="none">
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M7.53033 0.46967C7.82322 0.762563 7.82322 1.23744 7.53033 1.53033L2.81066 6.25H13C16.7279 6.25 19.75 9.27208 19.75 13C19.75 16.7279 16.7279 19.75 13 19.75H10C9.58579 19.75 9.25 19.4142 9.25 19C9.25 18.5858 9.58579 18.25 10 18.25H13C15.8995 18.25 18.25 15.8995 18.25 13C18.25 10.1005 15.8995 7.75 13 7.75H2.81066L7.53033 12.4697C7.82322 12.7626 7.82322 13.2374 7.53033 13.5303C7.23744 13.8232 6.76256 13.8232 6.46967 13.5303L0.46967 7.53033C0.176777 7.23744 0.176777 6.76256 0.46967 6.46967L6.46967 0.46967C6.76256 0.176777 7.23744 0.176777 7.53033 0.46967Z"
                    fill="#111827"
                />
            </svg>
        </span>
    );
};

export default ArrowUturnLeftIcon;
