import React from "react";
import lottie from "lottie-web";
import circularLoader from "../../static/animations/circular-loader-sm.json";

// ==============================|| LOADER ||============================== //
const SmallCircularLoader=({className}:{className?: any})=>
{
    React.useEffect(() => {
        lottie.loadAnimation({
          container: document.querySelector("#circular-loader-sm")!,
          animationData: circularLoader,
        });
      }, []);

    return (
    <div className={className?className:""}>
        <div id="circular-loader-sm" className="h-36 w-36  " />
        <p>
        Hold tight, your badge is on the way. It'll be worth the wait. 😉
        </p>
    </div>
)
};

export default SmallCircularLoader;
