import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";


import PageHome from "containers/template/PageHome/PageHome";
import PageSubcription from "containers/template/PageSubcription/PageSubcription";

import DashboardLayout from "layout/DashboardLayout";
import PageUploadItem from "containers/PageUploadItem";

import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";

export const pages: any[] = [
    { path: "/#", exact: true, element: <PageHome /> },
    { path: "/admin", exact: true, element: <PageHome /> },
    { path: "/dashboard", exact: true, element: <PageHome /> },

    { path: "/page-upload-item", element: <PageUploadItem/> },
    { path: "/subscription", element: <PageSubcription /> },
    { path: "/signup", element: <PageSignUp/> },
    { path: "/login", element: <PageLogin/> },
];



const DashboardRoutes = {
    path: '/',
    element: <DashboardLayout />,
    children: pages
};

export default DashboardRoutes;
