import React, { FC, useEffect, useRef } from "react";
import NcModal from "shared/NcModal/NcModal";
import MetamaskLogo from "static/images/logos/metamask.svg";
// import Web3 from "web3";
import Torus from "@toruslabs/torus-embed";
import { ethers } from "ethers";
import { useWeb3 } from "utils/useWeb3";
import { MOBILE_METAMASK_DEEPLINK, TORUS_PROVIDER_INFO } from "constants/blockchain";
import CircularLoader from "shared/Loadable/CircularLoader";
import {useLocation} from "react-router-dom"
declare var window: any
declare var window: any;

export interface MobileLoginModalProps {
  show: boolean;
  setShow: any;
  onClose: () => void
}

const MobileLoginModal: FC<MobileLoginModalProps> = ({
  show,
  setShow,
  onClose
}) => {
  const textareaRef = useRef(null);
  const location = useLocation();
  const [loading, setLoading] = React.useState(false)
  const { changeMetamaskNetwork, connectMetamask, connectTorus } = useWeb3()!;

  useEffect(() => {
    console.log(window.location.hostname);
  }, [])
  useEffect(() => {
    if (show) {
      setTimeout(() => {
        const element: HTMLTextAreaElement | null = textareaRef.current;
        if (element) {
          (element as HTMLTextAreaElement).focus();
          (element as HTMLTextAreaElement).setSelectionRange(
            (element as HTMLTextAreaElement).value.length,
            (element as HTMLTextAreaElement).value.length
          );
        }
      }, 400);
    }
  }, [show]);


  const loginMetaMask = async () => {
    if (window.ethereum) {
      await connectMetamask();
      setShow(false);
    } else {
      window.location.href = "https://metamask.app.link/dapp/" + window.location.hostname + location.pathname;
    }
  };

  //Torus login
  const loginTorus = async () => {
    setLoading(true);
    await connectTorus()
    .then(() => {
      setLoading(false)
      setShow(false);
    })
    .catch(() => {
      setLoading(false)
    })
  };

  const renderContent = () => {
    //modal content
    return (
      <>
      <div className="relative">
        
        {
          loading?(
            <div className="flex -ml-4 justify-center items-center text-center fixed w-100 h-100 bg-white opacity-80 w-full h-full backdrop-blur-sm" style={{zIndex: "60"}}>
              <CircularLoader />
            </div>
          )
          :null
        }
        </div>
        <div
          className="flex justify-center items-center text-center hover:bg-neutral-100 py-2 rounded-2xl cursor-pointer"
          onClick={() => loginMetaMask()}
        >
          <div className="">
            <img src={MetamaskLogo} className="h-16 w-16 mx-auto" />
            <div className="font-black text-2xl my-2">MetaMask</div>
            <div className="text-neutral-400">
              Connect to your MetaMask Wallet
            </div>
          </div>
        </div>
        <div className="w-100 border-b-2 border-neutral-100 mt-3"></div>
        <div
          className="flex justify-center items-center text-center mt-4 hover:bg-neutral-100 py-2 rounded-2xl cursor-pointer"
          onClick={() => loginTorus()}
        >
          <div className="">
            <img
              src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjU3IiBoZWlnaHQ9IjI3NyIgdmlld0JveD0iMCAwIDI1NyAyNzciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CiAgICA8cmVjdCB3aWR0aD0iMTUzLjg4OSIgaGVpZ2h0PSI4Mi4wNzQxIiBmaWxsPSIjMDM2NEZGIiAvPgogICAgPHJlY3QgeD0iNzEuODEzNSIgd2lkdGg9IjgyLjA3NDEiIGhlaWdodD0iMjc3IiBmaWxsPSIjMDM2NEZGIiAvPgogICAgPHBhdGggZD0iTTIxNS40NDMgODIuMDc0MUMyMzguMTA3IDgyLjA3NDEgMjU2LjQ4IDYzLjcwMTIgMjU2LjQ4IDQxLjAzN0MyNTYuNDggMTguMzcyOSAyMzguMTA3IDAgMjE1LjQ0MyAwQzE5Mi43NzkgMCAxNzQuNDA2IDE4LjM3MjkgMTc0LjQwNiA0MS4wMzdDMTc0LjQwNiA2My43MDEyIDE5Mi43NzkgODIuMDc0MSAyMTUuNDQzIDgyLjA3NDFaIiBmaWxsPSIjMDM2NEZGIiAvPgo8L3N2Zz4="
              className="h-16 w-16 mx-auto"
            />
            <div className="font-black text-2xl my-2">Torus</div>
            <div className="text-neutral-400">Connect to your Torus Wallet</div>
          </div>
        </div>
      </>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onClose}
      contentExtraClass="max-w-lg"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
      // closeButton={null}
    />
  );
};

export default MobileLoginModal;
