import { gql, useQuery } from '@apollo/client';
import TextButton from 'components/Buttons/TextButton';
import BasicTextChip from 'components/Chips/BasicTextChip';
import TextField from 'components/Input/TextFields/TextField';
import MobileLoginModal from 'components/LoginModal';
import { getProvider } from 'constants/blockchain';
import React, { FC, useEffect, useMemo, useState } from 'react';
import OTPInput from 'react-otp-input';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import CircularLoader from 'shared/Loadable/CircularLoader';
import CustomCircularLoader from 'shared/Loadable/CustomCircularLoader';
import NcImage from 'shared/NcImage/NcImage';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { badgesSliceFunction } from 'store/slices/badgesSlice';
import { subscribeSliceFunctions } from 'store/slices/subscribeSlice';
import IPFSHelper from 'utils/IPFSHelper';
import { useWeb3 } from 'utils/useWeb3';
import loaderSetup from '../../../static/animations/custom-loader.json';
import Snackbar, { SnackbarContext } from 'shared/Snackbar/Snackbar';

const BadgesInfoQuery = gql`
    query WorkBadges($workBadgeId: ID!) {
        workBadge(id: $workBadgeId) {
            metadata {
                name
                image
            }
            type
            organisation {
                name
            }
        }
    }
`;

export interface PageClaimSubscribeProps {
    className?: string;
}

const PageClaimSubscribe: FC<PageClaimSubscribeProps> = ({
    className = '',
}) => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { controller } = useWeb3()!;

    const { userAddress } = useParams();
    const [searchParams] = useSearchParams();

    const [isDoingConnection, setIsDoingConnection] = useState(false);
    const [stage, setStage] = React.useState('');

    const [secretCode, setSecretCode] = useState('');

    const [hashIndex] = useState<number>(
        parseInt(searchParams.get('i') || '-1')
    );
    const [badgesBasicInfo] = useState({
        contractAddress: searchParams.get('contractAddress') || '',
        tokenId: searchParams.get('tokenId') || '',
    });

    const { loading, error, data } = useQuery(BadgesInfoQuery, {
        variables: {
            workBadgeId: `${badgesBasicInfo.contractAddress?.toLocaleLowerCase()}-${
                badgesBasicInfo.tokenId
            }`,
        },
    });

    const isSuccess = useAppSelector((state) => state.subscribe.success);

    const [snackbarContext, setSnackbarContext] =
        React.useContext<any>(SnackbarContext);

    useEffect(() => {
        if (!(hashIndex && hashIndex >= 0)) {
            navigate('/404');
        }
    }, [hashIndex]);

    useEffect(() => {
        if (controller) {
            setStage('enter-secret-code');
        } else {
            setStage('request-login');
        }
    }, [controller]);

    const processBarLength = useMemo(() => {
        switch (stage) {
            case 'request-login':
                return `${(1 / 3) * 100}%`;
            case 'enter-secret-code':
            case 'loading':
                return `${(2 / 3) * 100}%`;
            default:
                return 0;
        }
    }, [stage]);

    const submitCode = () => {
        console.debug(123);
        setStage('loading');
        dispatch(
            subscribeSliceFunctions.claimSubscribe({
                toFollowAddress: userAddress!,
                hashIndex,
                secretCode: secretCode,
                controller: controller!,
            })
        );
    };

    useEffect(() => {
        if (isSuccess) {
            const content = (
                <div className="flex text-xs-initial-regular text-white">
                    You can now access the private data in this token.
                </div>
            );
            setSnackbarContext({
                ...snackbarContext,
                show: true,
                text: content,
            });
            navigate(
                `/badge/${badgesBasicInfo.contractAddress}/${badgesBasicInfo.tokenId}`
            );
        }
    }, [isSuccess]);

    const renderContent = () => {
        switch (stage) {
            case 'request-login':
                return (
                    <div className="flex flex-col gap-4">
                        <h1 className="text-3xl-initial-bold text-primary-700">
                            Access private credentials
                        </h1>
                        <div>
                            Please connect your wallet.
                            <br />
                            <br />
                            If you do not own a wallet, we highly recommend
                            choosing Torus for a seamless onboarding experience.
                        </div>
                        <TextButton
                            bgClassName="w-fit"
                            size="md"
                            text="Connect your wallet"
                            buttonType="primary"
                            onClick={() => setIsDoingConnection(true)}
                        />
                        <MobileLoginModal
                            show={isDoingConnection}
                            setShow={setIsDoingConnection}
                            onClose={() => setIsDoingConnection(false)}
                        />
                    </div>
                );
            case 'enter-secret-code':
                return (
                    <div className="flex flex-col gap-4">
                        <h1 className="text-3xl-initial-bold text-primary-700">
                            Access private credentials
                        </h1>
                        <div>
                            Please enter the{' '}
                            <span className="text-primary-600 font-semibold">
                                6-digit OTP
                            </span>{' '}
                            in the same email.
                        </div>
                        {renderOTPinput()}
                        <TextButton
                            bgClassName="w-fit"
                            size="md"
                            text="Submit"
                            buttonType="primary"
                            onClick={submitCode}
                        />
                    </div>
                );
            case 'loading':
                return (
                    <div className="flex flex-col gap-4">
                        <h1 className="text-3xl-initial-bold text-primary-700">
                            Access private credentials
                        </h1>
                        <div>
                            Please enter the{' '}
                            <span className="text-primary-600 font-semibold">
                                6-digit OTP
                            </span>{' '}
                            in the same email.
                        </div>
                        {renderOTPinput(true)}
                        <TextButton
                            bgClassName="w-fit pointer-events-none"
                            className="bg-primary-100 h-11"
                            size="md"
                            text={
                                <CustomCircularLoader
                                    data={loaderSetup}
                                    iconClassName="h-5 w-5"
                                />
                            }
                            buttonType="primary"
                        />
                    </div>
                );
            default:
                return null;
        }
    };

    const renderSummaryCard = () => {
        return (
            <div className="bg-neutral-900 rounded-xl">
                <div
                    className="p-6 pb-8 flex flex-col bg-white border-2 border-neutral-900 rounded-xl gap-5"
                    style={{ transform: 'translateX(-2px) translateY(-2px)' }}
                >
                    <div>
                        <NcImage
                            containerClassName="border-2 border-neutral-900 rounded-[20px] overflow-hidden w-full"
                            src={
                                data?.workBadge?.metadata?.image
                                    ? IPFSHelper.convertURI(
                                          data?.workBadge?.metadata?.image
                                      )
                                    : ''
                            }
                        />
                    </div>
                    <div className="flex flex-col gap-3 items-center">
                        <div>
                            <BasicTextChip
                                className={`${className} border-blue-500 pointer-events-none`}
                                textClassName="text-blue-500"
                                size="xs"
                                text="Proof of Employment SBT"
                            />
                        </div>
                        <div className="text-base-initial-semibold">
                            {data?.workBadge?.metadata?.name ??
                                'Unknown badges'}
                        </div>
                        <div className="text-xs-initial-regular">
                            {data?.workBadge?.organisation?.name ??
                                'Unknown Company'}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const renderOTPinput = (isDisable: boolean = false) => {
        return (
            <OTPInput
                value={secretCode}
                onChange={setSecretCode}
                shouldAutoFocus
                numInputs={6}
                containerStyle={{
                    gap: '8px',
                }}
                inputStyle="rounded-md border-2 border-neutral-900 text-neutral-900 focus:border-neutral-900 focus:ring-primary-500 focus:ring-2 sm:text-sm text-center"
                renderInput={(props) => (
                    <input
                        {...props}
                        disabled={isDisable}
                        style={{
                            width: '40px',
                            height: '48px',
                        }}
                    />
                )}
            />
        );
    };

    return (
        <>
            <div
                className="h-2 bg-primary-600 transition-all duration-300"
                style={{ width: processBarLength }}
            ></div>

            <div
                className="container grid grid-cols-3 gap-28 justify-center items-center"
                style={{ minHeight: 'calc(100vh - 62px - 8px)' }}
            >
                <div className="col-span-2">{renderContent()}</div>
                <div className="">{data?.workBadge && renderSummaryCard()}</div>
            </div>
        </>
    );
};

export default PageClaimSubscribe;
