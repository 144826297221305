import React, { FC, ReactElement } from 'react';

export interface TextButtonProps {
    className?: string;
    //   colorClass?: string;
    //   isLiked?: boolean;
    size: string; // xs, sm, md, lg, xl
    isDisabled?: boolean; // normal, hover, active, disabled
    text?: string | ReactElement;
    onClick?: any;
    buttonType: string;
    prependIcon?: ReactElement;
    appendIcon?: ReactElement;
    textClassName?: string;
    bgClassName?: string;
}

const TextButton: FC<TextButtonProps> = ({
    className = '',
    size,
    isDisabled,
    text,
    onClick,
    buttonType,
    prependIcon,
    appendIcon,
    textClassName = '',
    bgClassName = '',
}) => {
    const getSize = () => {
        switch (true) {
            case size == 'xs':
                return 'text-xs-initial-medium py-1.5 px-2.5';
            case size == 'sm':
                return 'text-sm-initial-medium py-1.5 px-3';
            case size == 'md':
                return 'text-md-initial-medium py-2 px-4';
            case size == 'lg':
                return 'text-lg-initial-medium py-2 px-4';
            case size == 'xl':
                return 'text-xl-initial-medium py-3 px-6';
            default:
                return 'text-md-initial-medium py-2 px-4';
        }
    };
    const getIconSize = () => {
        switch (true) {
            case size == 'xs':
                return '';
            case size == 'sm':
                return 'h-5';
            case size == 'md':
                return 'h-5 w-5';
            case size == 'lg':
                return '';
            case size == 'xl':
                return '';
            default:
                return '';
        }
    };
    const getButtonType = () => {
        switch (true) {
            case buttonType == 'primary':
                return 'text-button-primary';
            case buttonType == 'secondary':
                return 'text-button-secondary';
            case buttonType == 'tertiary':
                return 'text-button-tertiary';
            case buttonType == 'neutral':
                return 'text-button-neutral';
            case buttonType == 'text':
                return 'text-button-text';
            default:
                return 'text-button-primary';
        }
    };
    const getMarginToLogo = (iconType: 'prepend' | 'append') => {
        switch (true) {
            case size == 'xs' || size == 'sm' || size == 'md':
                if (iconType == 'prepend') {
                    return 'mr-2';
                } else {
                    return 'ml-2';
                }
            default:
                if (iconType == 'prepend') {
                    return 'mr-2.5';
                } else {
                    return 'ml-2.5';
                }
        }
    };

    return (
        <div
            className={`${
                buttonType == 'primary' ? 'bg-neutral-900' : ''
            } rounded-lg ${bgClassName ? bgClassName : ''}`}
            onClick={
                isDisabled
                    ? () => console.log('disabled')
                    : () => {
                          onClick && onClick();
                      }
            }
        >
            <button
                className={`text-button transform ${
                    isDisabled ? 'text-button-disabled' : getButtonType()
                } ${getSize()} ${className ?? ''}`}
            >
                <div className={`${textClassName} flex items-center`}>
                    {prependIcon ? (
                        <div
                            className={`${getMarginToLogo(
                                'prepend'
                            )} ${getIconSize()}`}
                        >
                            {prependIcon}
                        </div>
                    ) : (
                        <></>
                    )}
                    <div>{text}</div>
                    {appendIcon ? (
                        <div
                            className={`${getMarginToLogo(
                                'append'
                            )} ${getIconSize()}`}
                        >
                            {appendIcon}
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </button>
        </div>
    );
};

export default TextButton;
