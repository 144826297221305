import React, { FC, useState } from 'react';
import TextButton from 'components/Buttons/TextButton';
import GeneralModal from 'components/Modal/GeneralModal';
import TextField from 'components/Input/TextFields/TextField';

import imageSrc from 'static/images/profile/WalletCardModelImage.png';
import { BACKEND_URL } from 'constants/backend';
import { getOS } from 'utils/isMobile';

import userProfileServices from 'services/userProfile.services';

export interface EditProfileModalProps {
    className?: string;
    openEditModal: boolean;
    setOpenEditModal: Function;
}

const ConnectSocialNetwork = () => {
    return (
        <div className="basis-1/4">
            <div className="text-sm-initial-medium">Discord</div>
            <TextButton
                size="sm"
                text="Connect"
                buttonType="secondary"
                className="mt-2"
            />
        </div>
    );
};

const WalletCardEditModel: FC<EditProfileModalProps> = ({
    className,
    openEditModal,
    setOpenEditModal,
}) => {
    const [companyName, setCompanyName] = useState('');
    const [title, setTitle] = useState('');
    const [phone, setPhone] = useState('');

    const downloadWallet = async () => {
        let type = getOS();
        const response = await userProfileServices.generatePass(
            type,
            phone,
            companyName,
            title
        );
        const pass = response.passUrl;

        if(pass){
            window.location.assign(pass);
        }else{
            alert('Error generating pass');
        }
    };

    const renderContent = () => {
        return (
            <div className="h-[80vh] overflow-y-scroll hiddenScrollbar">
                <div className="px-8 pb-20 flex flex-col gap-6">
                    <img src={imageSrc} />
                    <div>
                        <div className="text-xl-initial-semibold text-neutral-800">
                            Enter your contact details
                        </div>
                        <p className="text-sm-initial-regular text-neutral-500 mt-3">
                            We prioritise your data ownership and privacy!
                            <br />
                            <br />
                            Therefore, we would like to clarify that the data
                            you enter{' '}
                            <span className="text-primary-700 font-bold">
                                WILL NOT
                            </span>{' '}
                            pass through our backend servers.
                        </p>
                    </div>
                    <TextField
                        label="Company"
                        placeholder="Ex: CredentialProof.io"
                        onChangeHandle={setCompanyName}
                    />
                    <TextField
                        label="Job title"
                        placeholder="Ex: Software Engineer"
                        onChangeHandle={setTitle}
                    />
                    <TextField
                        label="Phone number with country code"
                        placeholder="Ex: +852 92345678"
                        onChangeHandle={setPhone}
                    />
                </div>
            </div>
        );
    };

    const renderFooter = () => {
        return (
            <div className="edit_user_profile_modal_footer">
                <TextButton
                    size="sm"
                    text="Cancel"
                    buttonType="text"
                    className="h-9 my-auto"
                    onClick={() => setOpenEditModal(false)}
                />
                <div className="ml-4 h-9 my-auto">
                    <TextButton
                        size="sm"
                        text="Save"
                        buttonType="primary"
                        className=""
                        onClick={() => {
                            downloadWallet();
                        }}
                    />
                </div>
            </div>
        );
    };

    const renderTrigger = () => {
        return null;
    };

    return (
        <>
            <GeneralModal
                isOpenProp={openEditModal}
                onCloseModal={() => setOpenEditModal(false)}
                contentExtraClass="max-w-lg"
                renderContent={renderContent}
                renderFooter={renderFooter}
                renderTrigger={renderTrigger}
                modalTitle="Create wallet pass"
                closeButton={true}
            />
        </>
    );
};

export default WalletCardEditModel;
